import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ArtistForm } from 'NewVersion/components/Forms/ArtistForm'
import { ManagerForm } from 'NewVersion/components/Forms/ManagerForm'
import { ProposalForm } from 'NewVersion/components/Forms/ProposalForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import * as path from 'Routes/Paths'
import { PROPOSALS_PATH } from 'Routes/Paths'
import { useAddressForm, useGetVocabulariesProposalForm } from 'hooks'
import { useEdition } from 'hooks/useEdition'
import { useYears } from 'hooks/useYears'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { getPropostaGuillotina } from 'utils/apiCalls'
import { ENS_TYPES_OBJ } from 'utils/entityUtils'

const CREATE_PROPOSAL_STEPS = {
  MANAGER: 0,
  ARTIST: 1,
  PROPOSAL: 2,
}

export const ProposalFormPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { idProposal, idArtist, year } = useParams()

  const { loading: loadingYears, years } = useYears()
  const { currentEdition, loading: loadingCurrentEdition } = useEdition()
  const [loadProposal, setLoadProposal] = useState(false)

  const {
    isLoaded: loadedVocabularies,
    countriesOptions,
    stateOptions,
    regionOptions,
  } = useAddressForm()

  const {
    isLoaded: loadedVocabulariesProposalForm,
    area,
    proposalType,
    features,
    audience,
    languages,
    release,
    keys,
    statement,
  } = useGetVocabulariesProposalForm()

  function getCurrentStep() {
    if (new URLSearchParams(location.search).get('type') === 'manager') {
      return CREATE_PROPOSAL_STEPS.MANAGER
    } else if (new URLSearchParams(location.search).get('type') === 'artist') {
      return CREATE_PROPOSAL_STEPS.ARTIST
    } else if (idProposal && year) {
      return CREATE_PROPOSAL_STEPS.PROPOSAL
    }
    navigate('propostes')
  }

  const [state, setState] = useState({
    step: getCurrentStep(),
    representantObj: null,
    entitatsLoading: true,
    arrayAnys: [],
    representant: null, // representant que crea la proposta
    artista: null, // artista que té la proposta
    showDetailObj: null,
    propostaObj: null,
    editMode: null,
  })

  const isAllDataLoaded = React.useCallback(() => {
    return !!(
      loadedVocabulariesProposalForm() &&
      loadedVocabularies() &&
      !loadingYears &&
      !loadingCurrentEdition &&
      years
    )
  }, [
    loadedVocabulariesProposalForm,
    loadedVocabularies,
    loadingYears,
    years,
    loadingCurrentEdition,
  ])

  const setRepresentantObj = (representantV, representantObjV) => {
    setState(Object.assign(state, { representantObj: representantObjV }))
    setRepresentant(representantV)
  }

  const setRepresentant = (representant) => {
    setState(Object.assign(state, { representant: representant }))
    toggleArtista()
  }

  function setArtista(artista) {
    setState(Object.assign(state, { artista: artista }))
    toggleProposta()
  }

  const toggleArtista = (artista = null) => {
    setState({ ...state, step: CREATE_PROPOSAL_STEPS.ARTIST })

    if (artista) {
      // Selectors
      artista.pais = artista.pais && countriesOptions.filter((o) => o.value === artista.pais)[0]
      artista.comunitat_autonoma =
        artista.comunitat_autonoma &&
        stateOptions.filter((o) => o.value === artista.comunitat_autonoma)[0]
      artista.comarca =
        artista.comarca && regionOptions.filter((o) => o.value === artista.comarca)[0]

      setState(Object.assign(state, { showDetailObj: artista }))
    }
  }

  const toggleProposta = useCallback(
    (proposta = null) => {
      if (proposta) {
        console.log('Toogle proposta')
        // Selectors
        proposta.ambits = proposta.ambits && area.filter((o) => o.value === proposta.ambits)[0]
        proposta.altres_ambits = getMultipleOptions(proposta.altres_ambits, area)
        proposta.tipus = proposta.tipus && proposalType.filter((e) => e.value === proposta.tipus)[0]
        proposta.caracteristiques =
          proposta.caracteristiques &&
          features.filter((e) => e.value === proposta.caracteristiques)[0]
        proposta.public = proposta.public && audience.filter((e) => e.value === proposta.public)[0]
        proposta.idioma = getMultipleOptions(proposta.idioma, languages)
        proposta.estrena =
          proposta.estrena && release.filter((e) => e.value === proposta.estrena)[0]
        proposta.claus = getMultipleOptions(proposta.claus, keys)
        proposta.declaracio_drets =
          proposta.declaracio_drets &&
          statement.filter((e) => e.value === proposta.declaracio_drets)[0]
        proposta.pais = getMultipleOptions(proposta.pais, countriesOptions)
        proposta.comunitat_autonoma = getMultipleOptions(proposta.comunitat_autonoma, stateOptions)
        proposta.comarca = getMultipleOptions(proposta.comarca, regionOptions)

        setState(Object.assign(state, { propostaObj: proposta }))
      }
      setState({ ...state, step: CREATE_PROPOSAL_STEPS.PROPOSAL })
    },
    [
      area,
      proposalType,
      features,
      audience,
      languages,
      release,
      keys,
      statement,
      countriesOptions,
      regionOptions,
      stateOptions,
      state,
    ]
  )

  useEffect(() => {
    if (
      idProposal &&
      state.step === CREATE_PROPOSAL_STEPS.PROPOSAL &&
      !loadProposal &&
      !state.propostaObj &&
      isAllDataLoaded()
    ) {
      setLoadProposal(true)
      getPropostaGuillotina(`${year}/${idArtist}/${idProposal}`, (data) => {
        toggleProposta(data)
        setLoadProposal(false)
      })
    }
  }, [
    idProposal,
    idArtist,
    state.step,
    loadProposal,
    state.propostaObj,
    toggleProposta,
    year,
    isAllDataLoaded,
  ])

  function getMultipleOptions(data, values) {
    const arrayOptions = {
      name: '',
      value: [],
    }
    data &&
      data.forEach((v) => {
        const valuesFiltered = values.filter((o) => o.value === v)
        if (valuesFiltered.length > 0) {
          arrayOptions.value.push(valuesFiltered[0].value)
        }
      })
    return arrayOptions
  }

  function toggleInit() {
    navigate(path.PROPOSALS_PATH)
  }

  const renderManagerForm = () => {
    return (
      <>
        <ManagerForm
          setRepresentantObj={setRepresentantObj}
          showDetailObj={state.showDetailObj}
          editMode={state.editMode}
          toggleInit={toggleInit}
          ensTypeObj={ENS_TYPES_OBJ['representant']}
        />
      </>
    )
  }

  const renderArtistForm = () => {
    return (
      <>
        <ArtistForm
          representant={state.representantObj}
          setArtista={setArtista}
          ensTypeObj={ENS_TYPES_OBJ['artista']}
          toggleInit={toggleInit}
          showDetailObj={state.showDetailObj}
        />
      </>
    )
  }

  const renderProposalForm = () => {
    return (
      <>
        <ProposalForm
          artista={state.artista}
          showDetailObj={state.propostaObj}
          toggleInit={toggleInit}
          representant={state.representantObj}
          ensTypeObj={ENS_TYPES_OBJ['proposta']}
          years={years}
        />
      </>
    )
  }

  if (
    !isAllDataLoaded() ||
    (state.step === CREATE_PROPOSAL_STEPS.PROPOSAL && loadProposal && !state.propostaObj)
  )
    return <LoadingComponent />

  if (currentEdition.callIsFinished() && state.step !== CREATE_PROPOSAL_STEPS.PROPOSAL) {
    navigate(path.PROPOSALS_PATH)
  }

  return (
    <div className="formPage page-wrapper">
      <GoBackButton onClick={() => navigate(PROPOSALS_PATH)} text={t('backToProposals')} />
      <div className="Shows Propostes">
        {state.step === CREATE_PROPOSAL_STEPS.MANAGER && renderManagerForm()}
        {state.step === CREATE_PROPOSAL_STEPS.ARTIST && renderArtistForm()}
        {state.step === CREATE_PROPOSAL_STEPS.PROPOSAL && renderProposalForm()}
      </div>
    </div>
  )
}

export default ProposalFormPage
