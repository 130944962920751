import * as path from 'Routes/Paths'
import { RATES } from 'utils/constants'

export class Edition {
  constructor(data, userPerms) {
    this.data = data
    this.userPerms = userPerms
  }

  homeComponent() {
    new Error('must implement homeComponent for Edition types')
  }

  getUrl() {
    new Error('must implement getUrl for Edition types')
  }

  getConfigData() {
    return this.data
  }

  canRegisterToQuickMeetings() {
    if (!this.data) return false
    return this.data && this.data.inscriure_reunions_rapides
  }

  areQuickMeetingsClosed() {
    if (!this.data) return false
    return this.data && this.data.reunions_rapides_completat
  }

  canShowShedule() {
    if (!this.data) return false
    return this.data && this.data.mostrar_programacio_oficial
  }

  canCreateEnsProfessionals() {
    return false
  }

  canCreateEnsPremsa() {
    return false
  }

  canCreateEnsServeis() {
    return false
  }

  callIsFinished() {
    return true
  }

  getConfigRoutes() {
    return [
      {
        exact: true,
        path: path.REGISTER_PATH,
      },
      {
        exact: true,
        path: path.HOME_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROFESSIONALS_PATH,
      },
      {
        exact: true,
        path: path.ARTIST_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_PATH,
      },
      {
        exact: true,
        path: path.REGISTER_ENTITY_PATH,
      },
      {
        exact: true,
        path: path.EDIT_ENTITY_FORM_PATH,
      },
      {
        exact: true,
        path: path.CREATE_ENTITY_FORM_PATH,
      },
      {
        exact: true,
        path: path.CREATE_ACCREDITATION_PATH,
      },
      {
        exact: true,
        path: path.EDIT_ACCREDITATION_PATH,
      },
      {
        exact: true,
        path: path.CREATE_STAND_PATH,
      },
      {
        exact: true,
        path: path.EDIT_STAND_PATH,
      },
      {
        exact: true,
        path: path.CREATE_SHOP_PATH,
      },
      {
        exact: true,
        path: path.EDIT_SHOP_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_ENTITY_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.LIVE_PATH,
      },
      {
        exact: true,
        path: path.TIKETS_PATH,
      },
      {
        exact: true,
        path: path.AGENDA_PATH,
      },
      {
        exact: true,
        path: path.NOTIFICATIONS_PATH,
      },
      {
        exact: true,
        path: path.USER_SETTINGS_PATH,
      },
      {
        exact: true,
        path: path.XATS_PATH,
      },
      {
        exact: true,
        path: path.MEETINGS_PATH,
      },
      {
        exact: true,
        path: path.MY_MEETINGS_PATH,
      },
      {
        exact: true,
        path: path.ACCOUNT_PATH,
      },
      {
        exact: true,
        path: path.FIRAPASSEIG,
      },
      {
        exact: true,
        path: path.PREGUNTES_FREQUENTS,
      },
      {
        exact: true,
        path: path.ACCREDITED_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.ADMIN_PATH,
      },
      {
        exact: true,
        path: path.PAYMENT_INFO_PATH,
      },
      {
        exact: true,
        path: path.NEW_PASSWORD_PATH,
      },
      {
        exact: true,
        path: path.ERROR_404_PATH,
      },
      {
        redirect: true,
        exact: true,
        from: '/',
        to: path.HOME_PATH,
      },
      {
        redirect: true,
        from: path.LOGIN_PATH,
        to: path.HOME_PATH,
      },
    ]
  }

  getPaymentInfo() {
    return 'not regulation info'
  }

  getRegulationInfo() {
    return 'not regulation info'
  }

  getStatementOfResponsibility() {
    return 'not statement responsibility info'
  }

  getCurrentRateByType() {
    return RATES.standard
  }

  async canAccesToInfo() {
    return false
  }

  getFiraDaysFilters() {
    return []
  }

  getFiraProfessionalActivitiesDaysFilters() {
    return []
  }
}
