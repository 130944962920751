import Button from '../UI/Buttons/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next'

const DialogProposalFormComponent = ({
  dialogIcon,
  dialogTitle,
  dialogDescription,
  handleOk,
  handleCancel = null,
  open,
  t,
}) => {
  return (
    <Dialog
      data-test="dialogProposalFormTest"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="proposalFormDialog"
    >
      {dialogIcon()}
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="p">{dialogDescription}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" autoFocus>
          <Typography variant="h5">{t('ok')}</Typography>
        </Button>
        {handleCancel && (
          <Button onClick={handleCancel} color="primaryOutlinedIcon outlined" autoFocus>
            <Typography variant="h5">{t('Cancel·la')}</Typography>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export const DialogProposalForm = withTranslation()(DialogProposalFormComponent)

export default DialogProposalForm
