import { useEffect, useState } from 'react'
import LoadingComponent from '../Components/HelperComponents/LoadingComponent'
import { getAdminPermissions, guillotinaActionGeneric } from '../utils/apiCalls'
function AdminElement(props) {
  const [title, titleChange] = useState('')
  const [message, messageChange] = useState('')
  // const [absEntitats, setAbsEntitats] = useState([])
  // const [absAcreditats, setAbsAcreditats] = useState([])
  const [isLoading, setLoading] = useState(true)
  // const Dropbox = require('dropbox').Dropbox;
  // const dbx = new Dropbox({ accessToken: "" });

  useEffect(() => {
    getAdminPermissions(setLoading)
  }, [])

  // const create2019 = () => {
  //     axios({
  //         method: 'get',
  //         url: JSON.parse(localStorage.edition)[1]+'@search',
  //         params:{
  //             'advanced_search': true,
  //             'metadata_fields':'_all',
  //             'portal_type':'mediterrania.core.acreditacio',
  //             'b_size':9999

  //         },
  //         headers: {
  //                     'Accept': 'application/json',
  //                     'Authorization': `Bearer ${getCurrentJWToken()}`
  //         }
  //     }).then(function (response) {
  //         let tmpArray = []
  //         if(response.status === 200){
  //             if(response.data){
  //                 tmpArray = response.data.items;
  //             }
  //         }
  //         setAbsAcreditats(tmpArray)
  //         getAllEns()
  //         // setCurrentAcreditats(tmpArray);
  //     })
  //     .catch(function (response) {
  //         console.log(response);
  //         console.log("failed fetch, proceding");
  //     });
  // }

  // const getAllEns = () => {
  //     axios({
  //         method: 'get',
  //         url: JSON.parse(localStorage.edition)[1]+'@search',
  //         params:{
  //             'advanced_search': true,
  //             'metadata_fields':'_all',
  //             'portal_type':'mediterrania.core.ens',
  //             'b_size':9999

  //         },
  //         headers: {
  //                     'Accept': 'application/json',
  //                     'Authorization': `Bearer ${getCurrentJWToken()}`
  //         }
  //     }).then(function (response) {
  //         let tmpArray = []
  //         if(response.status === 200){
  //             if(response.data){
  //                 tmpArray = response.data.items;
  //             }
  //         }
  //         setAbsEntitats(tmpArray)
  //     })
  //     .catch(function (response) {
  //         console.log(response);
  //         console.log("failed fetch, proceding");
  //     });
  // }

  // const mergeLists = () => {
  //     let absoluteArray = {}
  //     let regg = new RegExp(/^(\D{1}\d{1,})(\D{1}\d{1,})$/)

  //     // let url = regg.exec(currentAcreditat.num)[1]+"/"+regg.exec(currentAcreditat.num)[2]
  //     absEntitats.map((element) => {
  //         absoluteArray[element.num] = []
  //     })
  //     // console.log(absAcreditats)
  //     absEntitats.map((element) => {
  //         absAcreditats.map((acreditat) => {
  //             if(regg.exec(acreditat.num)){
  //                 if(regg.exec(acreditat.num)[1] === element.num){
  //                     absoluteArray[element.num].push(regg.exec(acreditat.num)[2])
  //                 }
  //             }

  //         })
  //     })
  //     function sleep(milliseconds) {
  //         const date = Date.now();
  //         let currentDate = null;
  //         do {
  //           currentDate = Date.now();
  //         } while (currentDate - date < milliseconds);
  //       }
  //     let pathsList = []

  //     //

  //     // PATHS ENTITATS
  //     // Object.keys(absoluteArray).forEach(key => {

  //     //     console.log(key);
  //     //     pathsList.push('/FM/2020/'+key)
  //     //     // absoluteArray[key].map((element) => {
  //     //     //     console.log(element)
  //     //     //     dbx.filesCreateFolderBatchCheck({path: '/FM/2020/'+key+"/"+element})
  //     //     // })
  //     //  })
  //     //  dbx.filesCreateFolderBatch({"paths": pathsList})

  //     //PATHS ACREDITATS
  //     //  Object.keys(absoluteArray).forEach(key => {
  //     //     absoluteArray[key].map((element) => {
  //     //         console.log(key + " " + element)
  //     //         pathsList.push('/FM/2020/'+key+"/"+element)
  //     //         // dbx.filesCreateFolderBatchCheck({path: '/firamediterrania/2019/'+key+"/"+element})
  //     //     })
  //     //  })
  //     //  dbx.filesCreateFolderBatch({"paths": pathsList})

  //     //  dbx.filesCreateFolderBatchCheck({paths: '/firamediterrania/2019/'+key})
  // }

  const onSubmit = (data, e) => {
    data.preventDefault()
    guillotinaActionGeneric({
      method: 'POST',
      action: '@broadcast',
      setCallBack: alertMessage,
      data: { cos: message, titol: title, type: 'broadcast' },
    })
  }

  const alertMessage = (response) => {
    // falta fer control de response Ok o Nok
    alert('Notificació enviada')
  }
  if (isLoading) {
    return <LoadingComponent />
  }
  return (
    <div className="Admin-component">
      <div className="Login" style={{ 'grid-template-columns': 'unset' }}>
        <div className="">
          <form onSubmit={onSubmit} style={{ display: 'grid' }}>
            <label>Títol</label>
            <input
              name="title"
              value={title}
              onChange={(event, newValue) => titleChange(event.currentTarget.value)}
            />
            <label>Missatge</label>
            <textarea
              name="message"
              value={message}
              onChange={(event, newValue) => messageChange(event.currentTarget.value)}
            />
            <button className="login-button" type="submit">
              Envia notificació global
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default AdminElement
