import AddAlertRounded from '@mui/icons-material/AddAlertRounded'
import EventRounded from '@mui/icons-material/EventRounded'
import ExitToAppRounded from '@mui/icons-material/ExitToAppRounded'
import LiveTvRounded from '@mui/icons-material/LiveTvRounded'
import LocationOnRounded from '@mui/icons-material/LocationOnRounded'
import NotificationsActiveRounded from '@mui/icons-material/NotificationsActiveRounded'
import SearchRounded from '@mui/icons-material/SearchRounded'
import DaysSelectorComponent from 'Components/DaysSelectorComponent'
import SelectorComponent from 'Components/HelperComponents/SelectorComponent'
import * as path from 'Routes/Paths'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { isMobile as mobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import ScrollUpButton from 'react-scroll-up-button'
import ReactTooltip from 'react-tooltip'
import { formatDate } from 'utils/utils'

function searchReducer(state, action) {
  switch (action.type) {
    case 'set':
      return {
        volatileProgramationList: action.payload.list,
        query: '',
        days: [],
        typeProgramation: 'tots',
      }
    case 'search':
      function arrayContains(element) {
        if (element.title.toLowerCase().includes(this.toLowerCase())) {
          return element
        }
      }
      let newArray = []
      newArray = action.payload.list.filter(arrayContains, action.payload.query)
      // Filter de days
      if (action.payload.days.length !== 0) {
        function filterDays(element) {
          const d = new Date(element.data)
          const day = d.getDate()
          if (action.payload.days.includes(day.toString())) {
            return element
          }
        }
        newArray = newArray.filter(filterDays, action.payload.days)
      }
      if (
        action.payload.typeProgramation !== 'tots' &&
        action.payload.typeProgramation !== undefined
      ) {
        function filterType(element) {
          if (element.ambit === action.payload.typeProgramation) {
            return element
          }
        }
        newArray = newArray.filter(filterType, action.payload.typeProgramation)
      }
      return {
        volatileProgramationList: newArray,
        query: action.payload.query,
        typeProgramation: action.payload.typeProgramation,
        days: action.payload.days,
      }
    default:
      throw new Error()
  }
}

export function ProfessionalActivities(props) {
  const { t } = useTranslation()
  const initialState = { volatileProgramationList: [] }
  const [state, dispatch] = React.useReducer(searchReducer, initialState)
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(mobile)

  useEffect(() => {
    dispatch({ type: 'set', payload: { list: props.programationList } })
  }, [props.programationList])
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      console.log('rendiment')
      setIsMobile(window.innerWidth < 768)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])
  useLayoutEffect(() => {
    setTimeout(() => {
      const currentDate = Date.now()
      const currentElement = props.programationList.findIndex(
        (x) => Date.parse(x.start) > currentDate
      )
      const element = document.getElementById('programation_' + currentElement)
      if (element) {
        element.classList.add('current-time')
        window.scrollTo({ behavior: 'smooth', top: element.offsetTop })
      }
    }, 1000)
  }, [props.programationList])

  const onFilterText = (e) => {
    dispatch({
      type: 'search',
      payload: {
        query: e.target.value,
        list: props.programationList,
        days: state.days,
        typeProgramation: state.typeProgramation,
      },
    })
  }
  const onFilterDays = (e) => {
    dispatch({
      type: 'search',
      payload: {
        query: state.query,
        list: props.programationList,
        days: e,
        typeProgramation: state.typeProgramation,
      },
    })
  }
  const onFilterAmbit = (e) => {
    dispatch({
      type: 'search',
      payload: {
        query: state.query,
        list: props.programationList,
        days: state.days,
        typeProgramation: e,
      },
    })
  }
  const renderStyledTitle = (element) => {
    return (
      <div className="show-name no-margin ">
        <div className="d-grid show-ellipsis">
          <b>{element.artista}</b>
          {element.nom}
        </div>
      </div>
    )
  }

  const toggleAlert = (e, element) => {
    if (!e) {
      e = window.event
    }
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    props.addFavourite(element)
    // AQUI FARIEM EL SUBSCRIBE DEL EVENT
  }
  const removetoggleAlert = (e, element) => {
    if (!e) {
      e = window.event
    }
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    props.removeFavourite(element)
  }
  const videoLive = (e, uid) => {
    if (!e) {
      e = window.event
    }
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    navigate(path.LIVE_PATH)
  }
  const currentDate = Date.now()

  const getBellIcon = (element) => {
    if (props.favouiteIds.indexOf(element.UID) !== -1) {
      return (
        <div
          data-tip={t("Treure programació de l'agenda")}
          className="bell-icon"
          onClick={(e) => removetoggleAlert(e, element)}
        >
          {!mobile && <ReactTooltip />}
          <NotificationsActiveRounded className="pulsate-svg" style={{ fontSize: 20 }} />
        </div>
      )
    } else {
      return (
        <div
          data-tip={t("Afegir programació a l'agenda")}
          className="bell-icon"
          onClick={(e) => toggleAlert(e, element)}
        >
          {!mobile && <ReactTooltip />}
          <AddAlertRounded style={{ fontSize: 20 }} />
        </div>
      )
    }
  }

  const renderDaysSelector = (callback, days) => {
    if (JSON.parse(localStorage.edition)[0] === '2020') {
      return (
        <DaysSelectorComponent
          changeCallback={callback}
          arrayOptions={[
            [t('Dimarts'), '13'],
            [t('Dimecres'), '14'],
            [t('Dijous'), '15'],
            [t('Divendres'), '16'],
            [t('Dissabte'), '17'],
          ]}
          currentOption={days ? days : []}
          id="days"
        />
      )
    } else if (JSON.parse(localStorage.edition)[0] === '2019') {
      return (
        <DaysSelectorComponent
          changeCallback={callback}
          arrayOptions={[
            [t('Dijous'), '10'],
            [t('Divendres'), '11'],
            [t('Dissabte'), '12'],
            [t('Diumenge'), '13'],
          ]}
          currentOption={days ? days : []}
          id="days"
        />
      )
    } else {
      return (
        <DaysSelectorComponent
          changeCallback={callback}
          arrayOptions={[
            [t('Dijous'), '4'],
            [t('Divendres'), '5'],
            [t('Dissabte'), '6'],
            [t('Diumenge'), '7'],
          ]}
          currentOption={days ? days : []}
          id="days"
        />
      )
    }
  }

  const isDue = (element, currentDate) => {
    return true
    // if(JSON.parse(localStorage.edition)[0] === "2020"){
    //   return Date.parse(element.data) > currentDate
    // }else{
    //   return true
    // }
  }

  const getVariableOptions = () => {
    let unique = props.programationList.map(function (item) {
      return item['ambit']
    })
    unique = ['tots'].concat(unique)
    const uniqueSet = new Set(unique)
    const back = [...uniqueSet]
    return back
    // return ["tots","music","dance","imatgeria","exhibition","games","circus","castells","theatre"]
  }

  return (
    <div className="Shows">
      {isMobile && <ScrollUpButton />}
      <div className={`shows-filters${isMobile ? '-mobile' : ''}`}>
        <div className="title">
          {t('Programació Professional')}
          <div className="filter-back-search">
            <input type="text" placeholder="buscar" onChange={onFilterText} />
            <SearchRounded style={{ fontSize: 20, marginTop: 10 }} className="search-svg" />
          </div>
        </div>
        <div className="selectors">
          <div className="selector">{renderDaysSelector(onFilterDays, state.days)}</div>
          <div className="selector-options" style={isMobile ? {} : { marginLeft: '10px' }}>
            <div className=" filter-container">
              <SelectorComponent
                changeCallback={onFilterAmbit}
                arrayOptions={getVariableOptions()}
                currentOption={state.typeShow}
                id="favs"
                filterText={
                  state.typeProgramation === 'tots' ? 'Filtrar per àmbit' : state.typeProgramation
                }
              />
            </div>
          </div>
        </div>
      </div>

      {state.volatileProgramationList.length > 0 && (
        <div className="list">
          {state.volatileProgramationList.map((element, key) => (
            <div
              id={'programation_' + key}
              key={key}
              className={`show-item-container${isMobile ? '-mobile' : ''}${''}`}
              style={
                isDue(element, currentDate)
                  ? {}
                  : { opacity: '0.3', backgroundColor: 'unset', color: 'unset' }
              }
              onClick={() => navigate(path.PROGRAMMING_PATH.concat('/'.concat(element.UID)))}
            >
              <div
                className="inner programation"
                style={
                  isDue(element, currentDate) ? {} : { backgroundColor: 'unset', color: 'unset' }
                }
              >
                <div className="inner-col">
                  <div className="name-container">
                    <div className="type-line">
                      <div className="activity-type ">{element.ambit}</div>
                      {isMobile && getBellIcon(element)}
                    </div>
                    {renderStyledTitle(element)}
                  </div>
                </div>
                <div className="inner-col">
                  <div
                    className="access-info "
                    style={!isMobile ? { width: '30%', flexShrink: '0' } : {}}
                  >
                    <EventRounded style={{ fontSize: 20 }} />
                    {formatDate(element.data)}
                  </div>
                  {element.espai === 'Online / En línia' ? (
                    <div
                      className="access-info "
                      onClick={(e) => videoLive(e, element)}
                      style={!isMobile ? { width: '30%', flexShrink: '0' } : {}}
                    >
                      <LiveTvRounded
                        style={
                          !isMobile
                            ? { width: '30%', flexShrink: '0', fontSize: 20 }
                            : { fontSize: 20 }
                        }
                      />
                      <span>{t(element.espai)}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {element.espai !== 'Online / En línia' ? (
                    <div
                      className="access-info "
                      style={!isMobile ? { width: '30%', flexShrink: '0' } : {}}
                    >
                      <LocationOnRounded style={{ fontSize: 20 }} />
                      <span>{t(element.espai)}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="access-info " style={!isMobile ? { width: '30%' } : {}}>
                    <ExitToAppRounded style={{ fontSize: 20 }} />
                    <span>{t(element.acces)}</span>
                  </div>
                  {/* <div className="access-info "><EuroRounded style={{ fontSize: 20 }} />Preu</div> */}
                  {!isMobile && getBellIcon(element)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {state.volatileProgramationList.length === 0 && (
        <div>
          <div className="wrapper-empty-info">
            <br></br>
            <br></br>
            <b>{t('Sense resultats!')}</b>
          </div>
        </div>
      )}
    </div>
  )
}
