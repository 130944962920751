export const FavouriteIcon = ({ width = '20', height = '20', outline = false }) => {
  if (outline) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3296_6114)">
          <path
            d="M11.0007 2C9.84065 2 8.72732 2.54 8.00065 3.39333C7.27398 2.54 6.16065 2 5.00065 2C2.94732 2 1.33398 3.61333 1.33398 5.66667C1.33398 8.18667 3.60065 10.24 7.03398 13.36L8.00065 14.2333L8.96732 13.3533C12.4007 10.24 14.6673 8.18667 14.6673 5.66667C14.6673 3.61333 13.054 2 11.0007 2ZM8.06732 12.3667L8.00065 12.4333L7.93398 12.3667C4.76065 9.49333 2.66732 7.59333 2.66732 5.66667C2.66732 4.33333 3.66732 3.33333 5.00065 3.33333C6.02732 3.33333 7.02732 3.99333 7.38065 4.90667H8.62732C8.97398 3.99333 9.97398 3.33333 11.0007 3.33333C12.334 3.33333 13.334 4.33333 13.334 5.66667C13.334 7.59333 11.2407 9.49333 8.06732 12.3667Z"
            fill="#E90C1B"
          />
        </g>
        <defs>
          <clipPath id="clip0_3296_6114">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2555_25831)">
        <path
          d="M9.99935 17.7917L8.79101 16.6917C4.49935 12.8 1.66602 10.2333 1.66602 7.08333C1.66602 4.51667 3.68268 2.5 6.24935 2.5C7.69935 2.5 9.09101 3.175 9.99935 4.24167C10.9077 3.175 12.2993 2.5 13.7493 2.5C16.316 2.5 18.3327 4.51667 18.3327 7.08333C18.3327 10.2333 15.4993 12.8 11.2077 16.7L9.99935 17.7917Z"
          fill="#E90C1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2555_25831">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
