const LogoIcon = ({ fill }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.4115 36.0978V40C9.5703 39.9976 11.64 39.1389 13.1665 37.6124C14.693 36.0859 15.5516 34.0162 15.5541 31.8574H11.6518C11.6494 32.9813 11.2019 34.0585 10.4072 34.8532C9.61251 35.6478 8.53537 36.0954 7.4115 36.0978Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M28.0466 31.8574H24.0713C24.0689 32.9813 23.6213 34.0585 22.8266 34.8532C22.0319 35.6478 20.9548 36.0954 19.8309 36.0978V40C21.0197 39.9991 22.1939 39.7376 23.2707 39.2339C24.3475 38.7302 25.3008 37.9966 26.0635 37.0848C26.8236 37.9974 27.7751 38.7316 28.8506 39.2354C29.9261 39.7392 31.0993 40.0003 32.2869 40V36.0978C31.1631 36.0954 30.0859 35.6478 29.2912 34.8532C28.4965 34.0585 28.049 32.9813 28.0466 31.8574Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M32.2869 3.94804V0.0366821C30.1273 0.0415176 28.0576 0.902198 26.5314 2.43015C25.0052 3.95811 24.1468 6.02874 24.1444 8.18838H28.0466C28.0466 7.06377 28.4933 5.98522 29.2886 5.19001C30.0838 4.39479 31.1623 3.94804 32.2869 3.94804Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M19.8674 0.0365521C18.6796 0.0291468 17.5046 0.283199 16.4259 0.780691C15.3472 1.27818 14.3912 2.00693 13.6257 2.91524C12.863 2.00336 11.9097 1.26976 10.8329 0.766087C9.75605 0.262414 8.58192 0.000915449 7.39313 0V3.91135C8.51774 3.91135 9.59629 4.3581 10.3915 5.15332C11.1867 5.94854 11.6335 7.02709 11.6335 8.1517H15.6088C15.6088 7.02709 16.0555 5.94854 16.8508 5.15332C17.646 4.3581 18.7245 3.91135 19.8491 3.91135L19.8674 0.0365521Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M31.5923 24.0165V27.9187C32.7162 27.9211 33.7934 28.3686 34.5881 29.1633C35.3827 29.958 35.8303 31.0352 35.8327 32.159H39.7349C39.7325 30.0002 38.8738 27.9305 37.3473 26.404C35.8208 24.8775 33.7511 24.0189 31.5923 24.0165Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M39.7349 7.28349H35.8327C35.8327 8.4081 35.3859 9.48665 34.5907 10.2819C33.7955 11.0771 32.717 11.5238 31.5923 11.5238V15.4992C32.7162 15.5016 33.7934 15.9491 34.5881 16.7438C35.3827 17.5385 35.8303 18.6156 35.8327 19.7395H39.7349C39.735 18.5528 39.4749 17.3805 38.9727 16.3052C38.4706 15.2299 37.7388 14.2778 36.8288 13.5161C37.7381 12.7517 38.4693 11.7978 38.9712 10.7212C39.4732 9.64465 39.7339 8.47136 39.7349 7.28349Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M8.14256 15.4261V11.5239C7.01795 11.5239 5.9394 11.0771 5.14418 10.2819C4.34896 9.48667 3.90222 8.40812 3.90222 7.28351H0C0.00482789 9.44157 0.864251 11.5099 2.39023 13.0358C3.91621 14.5618 5.9845 15.4212 8.14256 15.4261Z"
        fill={fill ? fill : '#E90C1B'}
      />
      <path
        d="M8.14257 27.8455V23.9341C7.01796 23.9341 5.93941 23.4874 5.14419 22.6922C4.34897 21.8969 3.90222 20.8184 3.90222 19.6938H7.25347e-06C0.000922703 20.8826 0.262422 22.0567 0.766095 23.1335C1.26977 24.2103 2.00337 25.1637 2.91524 25.9264C2.00144 26.6874 1.2665 27.6404 0.762647 28.7176C0.258797 29.7948 -0.00158443 30.9697 7.25347e-06 32.1589H3.91136C3.91376 31.0367 4.36003 29.9609 5.15276 29.1664C5.94548 28.372 7.02029 27.9234 8.14257 27.9186V27.8455Z"
        fill={fill ? fill : '#E90C1B'}
      />
    </svg>
  )
}

export default LogoIcon
