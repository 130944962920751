import { maxSizeError, requiredError } from 'Components/Forms/FormErrors'
import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { InputCheckbox } from 'NewVersion/components/Fields/InputCheckBox'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Form.scss'
import { ReactComponent as PdfLogo } from 'Statics/pdf.svg'
import { useFormik } from 'formik'
import { useGuillotina } from 'hooks/useGuillotina'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OBJECT_TYPE_HAVE_RATE } from 'utils/constants'
import { getOptionFromValue } from 'utils/formUtils'
import { get } from 'utils/objectUtils'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'
import EditComponent from '../Fields/EditComponent'
import Button from '../UI/Buttons/Button'

const fieldsWithPrefixLabel = ['title', 'tipus', 'imatge']

export const ShopForm = ({
  entity,
  submitAction,
  dataShop,
  vocabularies,
  currentEdition,
  readOnlyForm = false,
}) => {
  const { t } = useTranslation()
  const [orderFieldsForm, setOrderFieldsForm] = useState([])
  const [requiredFields, setRequiredFields] = useState([])
  const [errorSubmitProposal, setErrorSubmitProposal] = useState({
    hasError: false,
    message: '',
  })
  const token = getCurrentJWToken()

  const handleError = (error) => {
    console.error(`Shop form error: ${error}`)
    if (error) {
      setErrorSubmitProposal({
        hasError: true,
        message: `Error: ${t('error_create_or_update_ens')}`,
      })
    } else {
      setErrorSubmitProposal({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const { data: schema, isLoading: schemaLoading } = useGuillotina({ path: '@types/Parada' })

  useEffect(() => {
    const dataShopForm = ['title', 'tipus', 'tram', 'descompte_artesa', 'descripcio_activitat']
    const requiredFieldsForm = [
      'title',
      'tipus',
      'tram',
      'fitxer_dni',
      'fitxer_asseguranca',
      'fitxer_seguretat_social',
      'imatge',
      'descripcio_activitat',
      'acords_participacio',
    ]
    // if (!currentEdition.getConfigData()['hi_ha_dos_trams']) {
    //   dataShopForm = dataShopForm.filter((key) => key !== 'tram')
    //   requiredFieldsForm = requiredFieldsForm.filter((key) => key !== 'tram')
    // }
    setRequiredFields(requiredFieldsForm)
    setOrderFieldsForm([
      {
        title: 'data_shop_form',
        fields: dataShopForm,
      },
      {
        title: 'docs_shop_form',
        fields: ['fitxer_dni', 'fitxer_asseguranca', 'fitxer_seguretat_social', 'imatge'],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['acords_participacio'],
      },
    ])
  }, [currentEdition])

  const patchFile = (values = {}, path = '', field) => {
    let filename = undefined
    if (values.filename) {
      filename = values.filename
    } else if (values.name) {
      filename = values.name
    }

    return fetchGuillotina({
      path: path + '/@upload/' + field,
      method: 'PATCH',
      token: token,
      headers: {
        'X-UPLOAD-EXTENSION': values.type.split('/')[1],
        // 'X-UPLOAD-SIZE': values.size,
        'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
        'CONTENT-TYPE': values.type,
      },
      notStringify: true,
      data: values,
    })
  }

  const getInitialValues = React.useMemo(() => {
    if (dataShop && vocabularies) {
      const tipus = getOptionFromValue(vocabularies['tipus_parada'], dataShop.tipus)
      const tram = getOptionFromValue(vocabularies['trams_parada'], dataShop.tram)
      return {
        ...dataShop,
        tipus: tipus?.value ?? '',
        tram: tram?.value ?? '',
        acords_participacio: false,
      }
    }
    const result = {}
    requiredFields.forEach((item) => {
      result[item] = undefined
    })
    return result
  }, [dataShop, vocabularies])

  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      const data = Object.assign({}, values, {
        '@type': 'Parada',
        tarifa: currentEdition.getCurrentRateByType(OBJECT_TYPE_HAVE_RATE.service),
        idioma: entity.idioma,
      })
      if (data['imatge'] !== null) {
        delete data['imatge']
      }
      if (data['fitxer_asseguranca'] !== null) {
        delete data['fitxer_asseguranca']
      }
      if (data['fitxer_dni'] !== null) {
        delete data['fitxer_dni']
      }
      if (data['fitxer_seguretat_social'] !== null) {
        delete data['fitxer_seguretat_social']
      }
      try {
        let path = ''
        if (dataShop) {
          path = cleanObjectIds(dataShop['@id'])
          await fetchGuillotina({ path: path, method: 'PATCH', data, token })
        } else {
          path = cleanObjectIds(entity['@id'])
          const responsePost = await fetchGuillotina({ path: path, method: 'POST', data, token })
          path = `${path}/${responsePost['@name']}`
        }
        // Afegir documentacio
        if (values['fitxer_asseguranca'] && values['fitxer_asseguranca'] instanceof File) {
          await patchFile(values['fitxer_asseguranca'], path, 'fitxer_asseguranca')
        }
        if (values['fitxer_dni'] && values['fitxer_dni'] instanceof File) {
          await patchFile(values['fitxer_dni'], path, 'fitxer_dni')
        }
        if (
          values['fitxer_seguretat_social'] &&
          values['fitxer_seguretat_social'] instanceof File
        ) {
          await patchFile(values['fitxer_seguretat_social'], path, 'fitxer_seguretat_social')
        }
        if (values['imatge'] && values['imatge'] instanceof File) {
          await patchFile(values['imatge'], path, 'imatge')
        }

        if (submitAction) {
          submitAction()
        }
      } catch (error) {
        handleError(error)
      } finally {
        setSubmitting(false)
      }
    },
    validate: (values) => {
      const errors = {}
      requiredFields.forEach((key) => {
        if (!(key in values) || !values[key]) {
          errors[key] = requiredError()
        }
      })

      if (values.fitxer_asseguranca && values.fitxer_asseguranca.size > 4194304) {
        errors.fitxer_asseguranca = maxSizeError()
      }
      if (values.fitxer_dni && values.fitxer_dni.size > 4194304) {
        errors.fitxer_dni = maxSizeError()
      }
      if (values.fitxer_seguretat_social && values.fitxer_seguretat_social.size > 4194304) {
        errors.fitxer_seguretat_social = maxSizeError()
      }
      if (values.imatge && values.imatge.size > 4194304) {
        errors.imatge = maxSizeError()
      }

      return errors
    },
  })

  const renderInputAcceptRegulation = (key) => {
    return (
      <div>
        <div>
          <PdfLogo />
          <a href="/Acord_FiraPasseig.pdf" download>
            {t('acords_participacio')}
          </a>
        </div>
        <InputCheckbox
          name={key}
          label={t('Accepto')}
          error={formik.touched[key] && formik.errors[key]}
          onChange={(e) => {
            formik.setFieldValue(key, e.target.checked)
            formik.validateForm(Object.assign({}, formik.values, { key: e.target.checked }))
          }}
          onBlur={formik.onBlur}
          checked={!!formik.values[key]}
          dataTest={`inputFieldTest_${key}`}
          required
        />
      </div>
    )
  }

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `shop_${key}`
    return key
  }

  if (schemaLoading) {
    return <LoadingComponent />
  }

  return (
    <>
      {errorSubmitProposal.hasError && errorSubmitProposal.message && (
        <AlertComponent type="danger"> {errorSubmitProposal.message}</AlertComponent>
      )}
      <form className="Form" onSubmit={formik.handleSubmit} data-test="formTest">
        {schema &&
          schema &&
          !schemaLoading &&
          orderFieldsForm.map((item) => {
            return (
              <Fragment key={`shopFormSection_${item['title']}`}>
                {item['title'] && <h5>{t(item['title'])}</h5>}
                <div className="fragment">
                  {item['fields'].map((key) => {
                    if (get(schema, `properties[${key}]`, null)) {
                      const value = schema.properties[key]
                      return (
                        <EditComponent
                          key={key}
                          name={key}
                          label={t(getLabel(key))}
                          schema={value}
                          description={value.description ? t(`${getLabel(key)}_description`) : null}
                          value={formik.values[key]}
                          required={requiredFields.includes(key)}
                          onBlur={() => {
                            formik.setFieldTouched(key)
                          }}
                          onChange={(ev) => {
                            formik.setFieldValue(key, ev)
                            formik.validateForm()
                          }}
                          error={formik.touched[key] && formik.errors[key]}
                          dataTest={`inputFieldTest_${key}`}
                          path={dataShop ? cleanObjectIds(dataShop['@id']) : undefined}
                        />
                      )
                    }
                    return renderInputAcceptRegulation(key)
                  })}
                </div>
              </Fragment>
            )
          })}
        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!formik.isSubmitting && (
            <div className="footer-buttons">
              <Button type="submit" data-test="btnSubmitFormTest" disabled={formik.isSubmitting}>
                {t('next')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}
