import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded'
import { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import PrintRounded from '@mui/icons-material/PrintRounded'
import { useNavigate } from 'react-router'

function AccreditationDetail({ accreditation }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const goBack = () => {
    navigate(-1)
  }

  const getQRCode = () => {
    const QRCode = require('qrcode.react')
    return <QRCode id="QR-CODE" value={accreditation.num ?? ''} />
  }

  const printQR = () => {
    window.print()
  }

  return (
    <div id="Acreditat">
      <div className="title-generic account-1 noprint">
        <div>{t('acreditacio')}</div>
        <div>{accreditation.title}</div>
      </div>
      {!isMobile && (
        <div onClick={goBack} className="go-back-container">
          <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
          {t('Tornar enrere')}
        </div>
      )}
      <br></br>
      <div className="personal-data-container">
        <div className="qr-container">{getQRCode()}</div>
        <div className="data-container">
          <b>{accreditation.num}</b>
          <div>PRO</div>
        </div>
      </div>
      <div className="profile-separator noprint"></div>
      <div className="go-back-container no-bg noprint" onClick={printQR}>
        <PrintRounded style={{ fontSize: 20 }}></PrintRounded> {t('Imprimeix')}
      </div>
    </div>
  )
}
export default memo(AccreditationDetail)
