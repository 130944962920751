import { TableCell, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { AcreditacioIcon } from 'NewVersion/icons/AcreditacioIcon'
import { HomeIcon } from 'NewVersion/icons/HomeIcon'
import * as React from 'react'
import { downloadImageGuillotina } from 'utils/apiCalls'
import { cleanObjectIds } from 'utils/utils'

const ArtistTableItemComponent = ({ artist, onClick, gridView }) => {
  const [blobImg, setBlobImg] = React.useState(null)

  const getImg = async (responseData) => {
    try {
      const image = await downloadImageGuillotina(
        cleanObjectIds(responseData['@id']),
        'imatge/preview',
        'images'
      )
      if (image) {
        setBlobImg(image)
      }
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (artist && artist.imatge) {
      getImg(artist)
    }
  }, [artist])

  const renderStyledName = (name) => {
    const captialize = (words) =>
      words
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ')
    return captialize(name.toLowerCase())
  }

  return (
    <>
      {!gridView && (
        <TableRow className={`table-row`}>
          <TableCell
            className="cursor-pointer"
            onClick={() => onClick(artist.uuid)}
            data-test="tableCellProName"
          >
            <b>{renderStyledName(artist.title)}</b>
          </TableCell>
          <TableCell>
            {artist.title_representant ? renderStyledName(artist.title_representant) : '--'}
          </TableCell>
        </TableRow>
      )}
      <Box className={`table-row-mobile artist-item display-sm`}>
        {gridView && (
          <Box
            className={'artist-item-imatge'}
            style={blobImg ? { backgroundImage: `url('${blobImg}')` } : {}}
          />
        )}
        <Box className={`artist-item-content`} flex={1}>
          <Box flex={1}>
            <Box
              className="line cursor-pointer"
              onClick={() => onClick(artist.uuid)}
              data-test="tableCellProName"
            >
              {!gridView && <AcreditacioIcon />}
              <p>
                <b>{renderStyledName(artist.title)}</b>
              </p>
            </Box>
            <Box data-test="tableCellProName">
              {!gridView && <HomeIcon />}
              <p>
                {artist.title_representant ? renderStyledName(artist.title_representant) : '--'}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const ArtistTableItem = React.memo(ArtistTableItemComponent)
