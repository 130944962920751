import { Box } from '@mui/system'
import Badges from 'NewVersion/components/UI/Badges/Badges'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { CheckIcon } from 'NewVersion/icons/CheckIcon'
import { ViewIcon } from 'NewVersion/icons/ViewIcon'
import { PAYMENT_INFO_PATH } from 'Routes/Paths'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { PAYMENT_WORKFLOW_STATE } from 'utils/constants'
import Chips from '../UI/Chips/Chips'

const AccountPaymentComponent = ({ paymentInfo }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const renderViewBtn = () => (
    <Box ml={2}>
      <Chips
        text={t('veure')}
        icon={<ViewIcon />}
        dataTest={'entityDetailItemChip'}
        onClick={() => navigate(`${PAYMENT_INFO_PATH.replace(':paymentId', paymentInfo['@uid'])}`)}
      />
    </Box>
  )

  return (
    <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      {paymentInfo.review_state === PAYMENT_WORKFLOW_STATE.paid ? (
        <Badges
          text={t('payment_workflow_paid_num', { num: paymentInfo.num })}
          icon={<CheckIcon />}
          className="pagamentPaid"
        />
      ) : (
        <Badges
          text={t('payment_workflow_pending_num', { num: paymentInfo.num })}
          icon={<AlertIcon />}
          className="pagamentPendent"
        />
      )}
      {renderViewBtn()}
    </Box>
  )
}

export const AccountPayment = memo(AccountPaymentComponent)
