import { AlertComponent } from 'Components/HelperComponents'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { DescriptionAndArtisticSheet } from 'NewVersion/components/Show/DescriptionAndArtisticSheet'
import { MainShow } from 'NewVersion/components/Show/MainShow'
import { TopShow } from 'NewVersion/components/Show/TopShow'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ExpanMoreIcon } from 'NewVersion/icons/ExpanMoreIcon'
import 'NewVersion/styles/ShowDetail.scss'
import { SHOWS_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useVocabularies } from 'hooks/useVocabularies'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { getEmailFromToken } from 'utils/utils'

const Accordion = ({ title, children, dataTest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={'accordion ' + (isOpen ? 'active' : 'disabled')}>
      <div
        className={'accordionHeader ' + (isOpen ? 'activeHeader' : '')}
        onClick={() => setIsOpen(!isOpen)}
        data-test={`accordionButtonTest${dataTest}`}
      >
        <h5>{title}</h5>
        <ExpanMoreIcon />
      </div>
      <div className="accordionPanel" data-test={`accordionPanelTest${dataTest}`}>
        {children}
      </div>
    </div>
  )
}

export const ShowDetailPage = () => {
  const { edition, currentEdition } = useEdition()
  const { t } = useTranslation()
  const { espectacleId } = useParams()
  const navigate = useNavigate()
  const vocabularies = useVocabularies(
    [
      { vocabulary: 'espai' },
      { vocabulary: 'eix' },
      { vocabulary: 'ambits' },
      { vocabulary: 'canvis' },
    ],
    false
  )

  const {
    data: espectacleData,
    isLoading: espectacleDataLoading,
    error: espectacleDataError,
  } = useGuillotina({
    path: `${edition}/espectacles/${espectacleId}`,
  })

  const { data: publishedAccreditations, isLoading: publishedAccreditationsLoading } =
    useGuillotina({
      path: `${edition}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa&review_state=publicat&owners=${getEmailFromToken()}`,
    })

  const showData = espectacleData ? espectacleData : null

  if (espectacleDataError) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }

  if (
    espectacleDataLoading ||
    !vocabularies ||
    vocabularies.loading ||
    publishedAccreditationsLoading
  )
    return <LoadingComponent />

  // const renderAccordion = (component, title, state, setState) => {
  //   return (
  //     <div className={'accordion ' + (state ? 'active' : 'disabled')}>
  //       <div
  //         className={'accordionHeader ' + (state ? 'activeHeader' : '')}
  //         onClick={() => setState(!state)}
  //       >
  //         <h5>{t(title)}</h5>
  //         <ExpanMoreIcon />
  //       </div>
  //       <div className="accordionPanel">{component}</div>
  //     </div>
  //   )
  // }

  return (
    <section className="showDetail">
      <div className="goBackButton">
        <GoBackButton onClick={() => navigate(SHOWS_PATH)} text={t('Tornar a Programació')} />
      </div>
      {showData && (
        <>
          <TopShow
            show={showData}
            vocabulary={vocabularies.data}
            edition={edition}
            currentEdition={currentEdition}
          />

          <div className="showDetailContent">
            {publishedAccreditations.items_total > 0 && <div className="separator" />}
            {publishedAccreditations.items_total > 0 && (
              <Accordion title={t('contact_data')} dataTest={`contactData`}>
                {showData.artistes.map((artist) => (
                  <MainShow artist={artist} showData={showData} key={artist['@id']} />
                ))}
              </Accordion>
            )}
            <div className="separator"></div>
            <Accordion title={t('description_and_artist_card')} dataTest={`description`}>
              <DescriptionAndArtisticSheet showData={showData} />
            </Accordion>

            <div className="separator"></div>
          </div>
        </>
      )}
    </section>
  )
}

export default ShowDetailPage
