import React from 'react'

function DaysSelectorComponent(props) {
  const setOption = (element) => {
    if (props.currentOption.includes(element)) {
      const nw = props.currentOption.filter((x) => x !== element)
      props.changeCallback(nw)
    } else {
      props.currentOption.push(element)
      props.changeCallback(props.currentOption)
    }
  }
  return (
    <div className="selector-class-horitzontal">
      <div className="options">
        {props.arrayOptions.map((element, key) => (
          <div
            key={key}
            className={props.currentOption.includes(element[1]) ? 'option selected' : 'option '}
            onClick={() => setOption(element[1])}
          >
            <div className="option-structure">
              <div className="name">{element[0]}</div>
              <div className="day">{element[1]}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default DaysSelectorComponent
