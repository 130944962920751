import { useContext, useEffect, useState } from 'react'
import YearsContext from 'context/YearsContext'
import { getAllAnysGuillotina } from 'utils/apiCalls'

export function useYears() {
  const [loading, setLoading] = useState(false)
  const { years, setYears } = useContext(YearsContext)

  useEffect(() => {
    if (!years && !loading) {
      setLoading(true)
      getAllAnysGuillotina((years) => {
        setYears(years)
        setLoading(false)
      })
    }
  }, [setYears, loading, years])

  return { loading, years }
}
