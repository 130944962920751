import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { EntityForm } from 'NewVersion/components/Forms/EntityForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { NewInscriptionIcon } from 'NewVersion/icons/NewInscriptionIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { ACCOUNT_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { OBJECT_TYPE_HAVE_RATE } from 'utils/constants'
import { ENS_TYPES, ENS_TYPES_OBJ } from 'utils/entityUtils'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken } from 'utils/utils'

const EditPage = () => {
  // TODO fer valors inicials
  const { entityId, year } = useParams()
  const { currentEdition, edition } = useEdition()
  const [submitModal, setSubmitModal] = useState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitError, setSubmitError] = useState({
    hasError: false,
    message: '',
  })

  const {
    data: entityDetail,
    isLoading: loading,
    error: errorFetchData,
    refresh: refreshEntity,
  } = useGuillotina({
    path: `${year}/${entityId}`,
  })

  const entityTypeObj = useMemo(() => {
    if (entityDetail) {
      return Object.values(ENS_TYPES_OBJ).find((obj) => {
        if (entityDetail['type_name'] === ENS_TYPES_OBJ.artista.interface) {
          return obj.text === entityDetail.tipus
        }
        return obj.interface === entityDetail['type_name']
      })
    }
    return null
  }, [entityDetail])

  if (loading || !currentEdition || !entityTypeObj || (!entityDetail && !errorFetchData)) {
    return <LoadingComponent />
  }

  if (errorFetchData) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }

  const handleEntityError = (error) => {
    console.error(`Edit Entity form error: ${error}`)
    if (error) {
      if (get(error, 'code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const isArtistOrManager = () => {
    return (
      entityTypeObj['interface'] === ENS_TYPES.artist ||
      entityTypeObj['interface'] === ENS_TYPES.manager
    )
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const token = getCurrentJWToken()
    const dataEntity = Object.assign({}, values, {
      '@type': entityTypeObj['interface'],
      tarifa: currentEdition.getCurrentRateByType(
        isArtistOrManager()
          ? OBJECT_TYPE_HAVE_RATE.ens_artist_or_manager
          : OBJECT_TYPE_HAVE_RATE.ens
      ),
    })

    if (dataEntity.pais !== 'country-ES') {
      dataEntity.comunitat_autonoma = null
      dataEntity.comarca = null
    } else if (dataEntity.comunitat_autonoma !== 'catalunya') {
      dataEntity.comarca = null
      dataEntity.comunitat_autonoma = dataEntity.comunitat_autonoma.value
    }

    await fetchGuillotina({
      path: `${edition}/${entityId}`,
      method: 'PATCH',
      data: dataEntity,
      token: token,
    })
      .then((response) => {
        if (response) {
          if (response['code']) {
            handleEntityError(response)
            return null
          }
          if (submitAction) {
            submitAction()
          }
        }
      })
      .catch((error) => {
        handleEntityError(error)
        setSubmitting(false)
        return
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const submitAction = () => {
    refreshEntity()
    setSubmitModal(true)
  }
  return (
    <>
      <div className="page-wrapper">
        <GoBackButton onClick={() => navigate(ACCOUNT_PATH)} text={t('Tornar al perfil')} />
        <div onClick={() => navigate(ACCOUNT_PATH)} className="cursor-pointer">
          <h4>{t(ENS_TYPES_OBJ[entityTypeObj.text].titleForm, { year: edition })}</h4>
        </div>
        {submitError.hasError && submitError.message && (
          <AlertComponent type="danger"> {submitError.message}</AlertComponent>
        )}
        <div>
          <EntityForm
            entity={entityDetail}
            ensTypeObj={entityTypeObj}
            handleSubmit={handleSubmit}
          />
        </div>
        <ModalInfo
          open={submitModal}
          setOpen={setSubmitModal}
          handleOk={() => navigate(ACCOUNT_PATH)}
          title={t('entity_updated')}
          icon={<NewInscriptionIcon fill={'#273BF3'} />}
        />
      </div>
    </>
  )
}

export default EditPage
