export const AcreditacionsIcon = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99997 18.9846C5.89998 18.1013 6.94581 17.4055 8.13748 16.8971C9.32914 16.3888 10.6166 16.1346 12 16.1346C13.3833 16.1346 14.6708 16.3888 15.8625 16.8971C17.0541 17.4055 18.1 18.1013 19 18.9846V6.30772C19 6.23079 18.9679 6.16026 18.9038 6.09614C18.8397 6.03204 18.7692 5.99999 18.6923 5.99999H5.3077C5.23077 5.99999 5.16024 6.03204 5.09612 6.09614C5.03202 6.16026 4.99997 6.23079 4.99997 6.30772V18.9846ZM12 13.75C11.0974 13.75 10.3301 13.4339 9.69808 12.8019C9.06603 12.1699 8.75 11.4026 8.75 10.5C8.75 9.59743 9.06603 8.83013 9.69808 8.19809C10.3301 7.56604 11.0974 7.25002 12 7.25002C12.9025 7.25002 13.6698 7.56604 14.3019 8.19809C14.9339 8.83013 15.25 9.59743 15.25 10.5C15.25 11.4026 14.9339 12.1699 14.3019 12.8019C13.6698 13.4339 12.9025 13.75 12 13.75ZM5.3077 21.5C4.80257 21.5 4.375 21.325 4.025 20.975C3.675 20.625 3.5 20.1974 3.5 19.6923V6.30772C3.5 5.80259 3.675 5.37502 4.025 5.02502C4.375 4.67502 4.80257 4.50002 5.3077 4.50002H6.69233V2.38464H8.23075V4.50002H15.8077V2.38464H17.3076V4.50002H18.6923C19.1974 4.50002 19.625 4.67502 19.975 5.02502C20.325 5.37502 20.5 5.80259 20.5 6.30772V19.6923C20.5 20.1974 20.325 20.625 19.975 20.975C19.625 21.325 19.1974 21.5 18.6923 21.5H5.3077Z"
        fill={fill ? fill : '#2B2A29'}
      />
    </svg>
  )
}
