import { MenuItem } from '@mui/material'
import { FolderIcon } from 'NewVersion/icons/FolderIcon'
import { OKIcon } from 'NewVersion/icons/OKIcon'
import { ALL_EDITIONS } from 'context/EditionContext'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import { AuthContext } from 'App'
import 'NewVersion/styles/UI/BasicSelector.scss'
import { useEdition } from 'hooks/useEdition'
import { useContext, useState } from 'react'

export function EditionSelector({ label, header = false }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { edition: selectedEdition, setNewEdition } = useEdition()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { dispatch } = useContext(AuthContext)
  const onChangeEditionActions = (newEdition) => {
    setNewEdition(newEdition)
    const obj = { edition: newEdition, url: '' }
    dispatch({ type: 'EDITION', payload: obj })
  }

  return (
    <div className="base-selector">
      <Button
        id="edition-button"
        aria-controls={open ? 'edition-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="name"
        data-test={`btnEditionChangeTest`}
      >
        <div className={`label-selector ${header ? 'white' : ''}`}>
          <FolderIcon fill={header ? 'white' : 'black'} />
          <span>{label}</span>
        </div>
        {open ? (
          <ArrowDropUpIcon sx={{ color: header ? 'white' : 'black' }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: header ? 'white' : 'black' }} />
        )}
      </Button>
      <Menu
        id="edition-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edition-button',
        }}
      >
        {ALL_EDITIONS.sort((a, b) => b - a).map((edition, index) => {
          return (
            <MenuItem
              key={index}
              value={edition}
              data-test={`btnEditionChangeTest${edition}`}
              onClick={() => {
                onChangeEditionActions(edition)
                handleClose()
              }}
            >
              <div className="selected-icon">{selectedEdition === edition && <OKIcon />}</div>
              {edition}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
