import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import * as path from 'Routes/Paths'

import Chips from 'NewVersion/components/UI/Chips/Chips'
import { AddProfessionalsIcon } from 'NewVersion/icons/AddProfessionalsIcon'
import { useNavigate } from 'react-router'
import { AccountStand } from './Stand'

const EstandInfoBoxComponent = ({ entityInfo, dataCanido }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  console.log('stand canido', dataCanido)
  return (
    <div
      className="entityDetailItem_element"
      data-test={`boxStandsTest_${entityInfo.entity['id']}`}
    >
      <div className="entityDetailItem__title mb-10">
        <p>{t('Estand')}</p>
        <Chips
          text={t('add')}
          icon={<AddProfessionalsIcon />}
          dataTest={'entityDetailItemChip'}
          onClick={() =>
            navigate(`${path.ENTITY_PATH}${entityInfo.entity['path']}/${path.STAND}/add`)
          }
        />
      </div>
      {entityInfo.stands.map((stand) => {
        return (
          <AccountStand
            key={stand['@id']}
            stand={stand}
            entityPath={entityInfo.entity['path']}
            dataCanido={dataCanido}
          />
        )
      })}
      {entityInfo.stands.length === 0 && (
        <div className="entityDetailItem__box">
          <div />
          <div className="empty-data">
            <p>{t('any_stand_added')}</p>
            <br />
          </div>
        </div>
      )}
    </div>
  )
}

export const EstandInfoBox = memo(EstandInfoBoxComponent)
