export const CheckIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3669_10874)">
        <circle cx="7.99992" cy="8.00016" r="6.16667" fill="#27F39D" stroke="#27F39D" />
        <path
          d="M11.0599 5.05322L6.66659 9.44656L4.27325 7.05989L3.33325 7.99989L6.66659 11.3332L11.9999 5.99989L11.0599 5.05322Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3669_10874">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
