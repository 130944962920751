import { ArrowBack } from '@mui/icons-material'
import 'NewVersion/styles/UI/GoBackButton.scss'

export const GoBackButton = ({ text, onClick }) => {
  return (
    <button
      className="go-back-button"
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        textDecoration: 'none',
        color: '#5C5958',
        border: 'none',
        backgroundColor: 'inherit',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <ArrowBack /> <p style={{ margin: '0' }}>{text}</p>
    </button>
  )
}
