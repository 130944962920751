import { Edition2023Home } from 'Components/Home/Edition2023Home'
import { BACKEND_TYPE, OBJECT_TYPE_HAVE_RATE, RATES } from 'utils/constants'
import Edition2022 from './Edition2022'

export default class Edition2023 extends Edition2022 {
  canCreateEnsProfessionals() {
    if (!this.data) return false
    return this.data && this.data.registrar_professionals
  }

  canCreateEnsPremsa() {
    if (!this.data) return false
    return this.data && this.data.registrar_premsa
  }

  canCreateEnsServeis() {
    if (!this.data) return false
    return this.data.registrar_fira_passeig
  }

  getRegulationInfo(lang) {
    if (!this.data) return ''
    return this.data[`normativa_proposta_${lang}`]
  }

  getPaymentInfo(lang) {
    if (!this.data) return ''
    return this.data[`informacio_pagament_${lang}`]
  }

  getStatementOfResponsibility(lang) {
    if (!this.data) return ''
    return this.data[`declaracio_responsable_proposta_${lang}`]
  }

  callIsFinished() {
    if (!this.data || !this.userPerms) return true
    return (
      new Date(this.data.data_hora_limit_presentar_proposta).getTime() < new Date().getTime() &&
      !this.userPerms['fm.PresentarProposta']
    )
  }

  homeComponent() {
    return <Edition2023Home />
  }

  getUrl() {
    return `${process.env.REACT_APP_GUILLOTINA}2023/`
  }

  getCurrentRateByType(type) {
    let result = RATES.standard
    if (type === OBJECT_TYPE_HAVE_RATE.ens_artist_or_manager) {
      result = RATES.free
    } else if (type === OBJECT_TYPE_HAVE_RATE.ens || type === OBJECT_TYPE_HAVE_RATE.stand) {
      if (
        new Date(this.data.data_hora_final_tarifa_reduida_entitats_professionals).getTime() >
        new Date().getTime()
      ) {
        result = RATES.reduced
      }
    } else if (type === OBJECT_TYPE_HAVE_RATE.service) {
      if (
        new Date(this.data.data_hora_final_tarifa_reduida_parades).getTime() > new Date().getTime()
      ) {
        result = RATES.reduced
      }
    }
    return result
  }

  getBackendType() {
    return BACKEND_TYPE.GUILLOTINA
  }

  async canAccesToInfo() {
    return true
  }

  getFiraDaysFilters(t) {
    return [
      [t('Dijous'), '5', '1696464000'],
      [t('Divendres'), '6', '1696550400'],
      [t('Dissabte'), '7', '1696636800'],
      [t('Diumenge'), '8', '1696723200'],
    ]
  }

  getFiraProfessionalActivitiesDaysFilters(t) {
    return [
      [t('Dijous'), '5', '1696464000'],
      [t('Divendres'), '6', '1696550400'],
      [t('Dissabte'), '7', '1696636800'],
      [t('Diumenge'), '8', '1696723200'],
    ]
  }
}
