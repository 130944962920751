import 'NewVersion/styles/MobileHeaderOptions.scss'
import { LanguageButtonsSelector } from '../UI/Buttons/LanguageButtonsSelector'
import { UserOptionsSelector } from '../UI/Selector/UserOptionsSelector'
import { Sidebar } from './Sidebar'

export const MobileHeaderOptions = ({ isOpen, setIsOpen }) => {
  if (!isOpen) return <></>
  return (
    <div className="mobile-options-container">
      <div className="large-header-section">
        <div className="header-buttons">
          <LanguageButtonsSelector />
          <UserOptionsSelector isMobile />
        </div>
      </div>
      <Sidebar isMobile setMobileSidebarOpen={setIsOpen} />
    </div>
  )
}
