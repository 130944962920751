import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { MeetingTable } from 'NewVersion/components/Meeting/MeetingTable'
import 'NewVersion/styles/AgendaListPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getEmailFromToken } from 'utils/utils'

function MyMeetsPage() {
  const { t } = useTranslation()
  const { edition, currentEdition } = useEdition()
  const [searchParams] = useSearchParams()

  const getFiltersSearch = () => {
    const filterOrder = `&_sort_${
      searchParams.get('sort-direction') ? searchParams.get('sort-direction') : 'des'
    }=${searchParams.get('sort-by') ? searchParams.get('sort-by') : 'data'}`

    return `${filterOrder}`
  }

  const { data: { items: acreditation } = [], isLoading: isLoadingAcreditation } = useGuillotina({
    path: `${edition}/@search?type_name=AcreditacioProfessional&owners=${getEmailFromToken()}&correu=${getEmailFromToken()}&b_start=0&b_size=1`,
  })

  const {
    refresh,
    data: { items: meetings } = [],
    isLoading,
  } = useGuillotina({
    path:
      acreditation && acreditation[0] && acreditation[0].id
        ? `${edition}/@search?type_name=FranjaReunioRapida${getFiltersSearch()}&acreditat=${
            acreditation[0].id
          }`
        : null,
  })

  return (
    <Box className="page-wrapper">
      <Box display={'flex'} alignItems={'end'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box maxWidth={'500px'}>
          <h1>{t('meves_reunions')}</h1>
          {currentEdition && currentEdition.areQuickMeetingsClosed() ? (
            <p>{t('quick_meetings_closed')}</p>
          ) : (
            <p>{t('meves_reunions_desc')}</p>
          )}
        </Box>
      </Box>
      {isLoadingAcreditation ? (
        <LoadingComponent />
      ) : (acreditation ?? []).length === 0 ? (
        <Box textAlign={'center'} my={3}>
          {t('no_acreditation_active_to_meeting')}
        </Box>
      ) : (
        <MeetingTable meetings={meetings} loading={isLoading} refresh={refresh} />
      )}
    </Box>
  )
}

export default MyMeetsPage
