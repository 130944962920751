import { Tooltip } from '@mui/material'
import 'NewVersion/styles/UI/Chips.scss'
import { useNavigate } from 'react-router'

const Chips = ({
  text,
  icon,
  url,
  dataTest,
  tooltip = null,
  onClick = null,
  style = '',
  iconDirection = 'right',
  disabled = false,
}) => {
  const navigate = useNavigate()

  const renderButton = () => (
    <button
      className={`basic-chips ${style}`}
      onClick={(e) => {
        if (onClick) onClick(e)
        if (url) navigate(url)
      }}
      data-test={dataTest}
      disabled={disabled}
    >
      {iconDirection === 'left' && icon}
      {text}
      {iconDirection === 'right' && icon}
    </button>
  )
  return tooltip ? <Tooltip title={tooltip}>{renderButton()}</Tooltip> : renderButton()
}

export default Chips
