import React from 'react'

import * as path from 'Routes/Paths'
import arbreUrl from 'Statics/21FMM-arbre.jpg'
import calaveraUrl from 'Statics/21FMM-calavera.jpg'
import diableUrl from 'Statics/21FMM-diable.jpg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Edition2018HomeComponent() {
  const { t } = useTranslation()
  return (
    <div className="Home">
      <div className="home-grid">
        <div className="link-container">
          <Link to={path.SHOWS_PATH}>
            <div
              className="home-link professional-link"
              style={{ backgroundImage: `url(${diableUrl})` }}
            >
              <span className="text  ">{t('Programació Artística')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.PROGRAMMING_PATH}>
            <div className="home-link direct-link" style={{ backgroundImage: `url(${arbreUrl})` }}>
              <span className="text  ">{t('Programació Professional')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.PROFESSIONALS_PATH}>
            <div
              className="home-link programation-link"
              style={{ backgroundImage: `url(${calaveraUrl})` }}
            >
              <span className="text  ">{t('Acreditats')}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const Edition2018Home = React.memo(Edition2018HomeComponent)
