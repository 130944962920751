import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountEntitiy } from 'NewVersion/components/Account/AccountEntity'

function Account({ accreditations, entities, shops, stands, payments, currentEdition }) {
  const { t } = useTranslation()

  const getObjectEnsAndAccreditations = React.useMemo(() => {
    const result = []

    entities.forEach((entity) => {
      const accreditationsFilter = accreditations.filter((accreditation) => {
        return accreditation['path'].indexOf(entity['@name']) !== -1
      })
      const shopsFilter = shops.filter((shop) => {
        return shop['path'].indexOf(entity['@name']) !== -1
      })
      const standsFilter = stands.filter((stand) => {
        return stand['path'].indexOf(entity['@name']) !== -1
      })
      const paymentFilter = payments.filter((payment) => {
        return payment['path'].indexOf(entity['@name']) !== -1
      })
      result.push({
        entity,
        accreditations: accreditationsFilter,
        shops: shopsFilter,
        stands: standsFilter,
        payments: paymentFilter,
      })
    })

    return result
  }, [accreditations, entities, shops, stands, payments])

  return (
    <section className="account page-wrapper">
      <h1>{t('El meu perfil')}</h1>
      {entities.length > 0 ? (
        <div className="account-class">
          {getObjectEnsAndAccreditations.map((objEnsAndAccreditations, index) => {
            const pastTypeName = getObjectEnsAndAccreditations[index - 1]?.entity.tipus || ''
            const presentTypeName = objEnsAndAccreditations.entity.tipus
            return (
              <>
                {pastTypeName !== presentTypeName && presentTypeName === 'artista' && (
                  <>
                    <div className="separator-entity" />
                    <h2>{t('Artista')}</h2>
                  </>
                )}
                <AccountEntitiy
                  entityInfo={objEnsAndAccreditations}
                  key={objEnsAndAccreditations.entity['@id']}
                  currentEdition={currentEdition}
                />
              </>
            )
          })}
        </div>
      ) : (
        <div className="wrapper-empty-info">
          <b>{t('user_not_have_entities')}</b>
        </div>
      )}
    </section>
  )
}

export default memo(Account)
