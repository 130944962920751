import { TextInputField as UITextInputField } from '../UI/Forms/TextInputField'

export const TextInputField = ({
  type,
  label,
  onChange,
  dataTest,
  name,
  error,
  required,
  description,
  onBlur,
  value,
  disabled,
  placeholder,
  multiline,
  helperText,
}) => {
  return (
    <UITextInputField
      type={type}
      label={label}
      onChange={onChange}
      dataTest={dataTest}
      name={name}
      error={error}
      required={required}
      description={description}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      multiline={multiline}
      helperText={helperText}
    />
  )
}
