import React from 'react'
import { useEdition } from 'hooks/useEdition'

import { BACKEND_TYPE } from 'utils/constants'
import { AccreditationPage as AccreditationPagePlone } from './Plone/AccreditationPage'
import { AccreditationPage as AccreditationPageGuillotina } from './Guillotina/AccreditationPage'

export function AccreditationPage() {
  const { currentEdition } = useEdition()

  if (currentEdition.getBackendType() === BACKEND_TYPE.PLONE) {
    return <AccreditationPagePlone />
  } else {
    return <AccreditationPageGuillotina />
  }
}
export default AccreditationPage
