import { Tooltip } from '@mui/material'
import { OKIcon } from 'NewVersion/icons/OKIcon'
import 'NewVersion/styles/UI/FilterButton.scss'

const FilterButton = ({ children, dataTest, tooltip, onClick, isSelected }) => {
  const renderButton = () => (
    <button
      className={`filter-button ${isSelected && 'selected'}`}
      onClick={() => {
        if (onClick) onClick()
      }}
      data-test={dataTest}
      aria-label={`Filter button ${children}`}
    >
      {isSelected && <OKIcon />}
      {children}
    </button>
  )
  return tooltip ? <Tooltip title={tooltip}>{renderButton()}</Tooltip> : renderButton()
}

export default FilterButton
