export const EntradaIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 10.4C20.01 10.4 19.2 11.21 19.2 12.2C19.2 13.19 20.01 14 21 14V17.6C21 18.59 20.19 19.4 19.2 19.4H4.8C3.81 19.4 3 18.59 3 17.6V14C3.999 14 4.8 13.19 4.8 12.2C4.8 11.21 3.999 10.4 3.009 10.4V6.8C3.009 5.81 3.81 5 4.8 5H19.2C20.19 5 21 5.81 21 6.8V10.4ZM12.0016 14.45L15.2236 16.52L14.2426 12.821L17.2036 10.4L13.3876 10.175L12.0016 6.61997L10.6066 10.166L6.79061 10.391L9.75161 12.812L8.77961 16.52L12.0016 14.45Z"
      fill="#2B2A29"
    />
  </svg>
)
