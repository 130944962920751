import { memo, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box } from '@mui/system'
import { EntitiyDetailInfo } from 'Components/Guillotina/AccountComponents/EntitiyDetailInfo'
import Modal from 'Components/HelperComponents/modal'
import { AccountAccreditation } from 'NewVersion/components/Account/Accreditation'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import * as path from 'Routes/Paths'
import { LLOTJA_WORKFLOW_STATE, PAYMENT_WORKFLOW_STATE } from 'utils/constants'
import { ENS_TYPES, ENS_TYPES_OBJ } from 'utils/entityUtils'

import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { AccountPayment } from 'NewVersion/components/Account/Payment'
import { AccountShop } from 'NewVersion/components/Account/Shop'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { AddProfessionalsIcon } from 'NewVersion/icons/AddProfessionalsIcon'
import { AddProfessionalsIconOutline } from 'NewVersion/icons/AddProfessionalsIconOutline'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { useGuillotina } from 'hooks/useGuillotina'
import { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import Badges from '../UI/Badges/Badges'
import ProfileImage from '../UI/Image/ProfileImage'
import { EstandInfoBox } from './EstandInfoBox'
import { ProjectResumeBox } from './ProjectResumeBox'

const AccountEntitiyComponent = ({ entityInfo, currentEdition }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isModalOpen, toggleModal] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [submitModalProps, setsubmitModalProps] = useState({
    text: '',
    handleOk: null,
    handleCancel: null,
  })

  const { data: dataCanido, loading: loadingCanIdo } = useGuillotina({
    path: entityInfo?.entity?.path
      ? `${entityInfo?.entity?.path}/@canido?permissions=guillotina.ModifyContent,guillotina.AddContent`
      : null,
  })

  const { data: dataRepresentats } = useGuillotina({
    path: entityInfo?.entity?.path ? `@search?representant=${entityInfo?.entity?.path}` : null,
  })

  const entityTypeObj = useMemo(() => {
    const { entity } = entityInfo

    if (entity) {
      return Object.values(ENS_TYPES_OBJ).find((obj) => {
        if (entity['type_name'] === ENS_TYPES_OBJ.artista.interface) {
          return obj.text === entity.tipus
        }
        return obj.interface === entity['type_name']
      })
    }
    return null
  }, [entityInfo])

  const privatePayments = useMemo(() => {
    return entityInfo.payments.filter(
      (payment) => payment.review_state === PAYMENT_WORKFLOW_STATE.private
    )
  }, [entityInfo.payments])

  const isTotalAccreditationsFilled = useMemo(() => {
    /*
      Block per introduïr les regles de num.maxim de persones acreditades. Per exemple, Prensa sembla que nomes en pot tenir 4.
    */
    if (entityInfo.entity.type_name === ENS_TYPES.press && 4 > entityInfo.accreditations.length) {
      return false
    } else if (
      currentEdition &&
      entityTypeObj &&
      entityTypeObj.totalAccreditationAttribute &&
      currentEdition.getConfigData()[entityTypeObj.totalAccreditationAttribute] >
        entityInfo.accreditations.length
    ) {
      return false
    }
    return true
  }, [currentEdition, entityInfo, entityTypeObj])

  const modalCallback = () => {
    toggleModal(!isModalOpen)
  }

  const renderBadges = (text) => {
    return <Badges text={text} className="accountAlert" icon={<AlertIcon />} />
  }

  const renderResume = () => {
    if (privatePayments.length > 0 && entityInfo.entity['type_name'] !== ENS_TYPES.service) {
      const haveInscription = privatePayments.some((payment) => {
        return payment.objectes_pagament_data.some(
          (object) => object.type_name === 'EnsProfessional'
        )
      })

      return (
        <AlertComponent className="mb-10" type="warning">
          <>
            {haveInscription
              ? renderBadges(t('finish_register_info', { email: entityInfo.entity['correu'] }))
              : renderBadges(t('finish_payment_info'))}
          </>
        </AlertComponent>
      )
    }

    if (entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.pending) {
      let text = t('entity_workflow_pending')
      if (entityInfo.entity['type_name'] === ENS_TYPES_OBJ.professional.ens_type_name) {
        text = t('entity_workflow_pending_professional')
      } else if (entityInfo.entity['type_name'] === ENS_TYPES_OBJ.serveis.ens_type_name) {
        text = t('entity_workflow_pending_service')
      }
      return (
        <AlertComponent className="mb-10" type="warning">
          {renderBadges(text)}
        </AlertComponent>
      )
    }
    if (entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.rejected) {
      return (
        <AlertComponent className="mb-10" type="danger">
          {renderBadges(t('entity_workflow_rejected'))}
        </AlertComponent>
      )
    }
  }

  const isArtistRejected = () => {
    console.log('is Artist rejected', entityInfo.entity['@name'], dataRepresentats)
    if (
      entityInfo.entity['type_name'] === ENS_TYPES_OBJ.representant.interface &&
      entityInfo.entity.tipus === ENS_TYPES_OBJ.representant.text
    ) {
      return !(
        dataRepresentats &&
        dataRepresentats.items_total > 0 &&
        dataRepresentats.items.filter(
          (item) =>
            item.tipus === 'artista' && item.review_state === LLOTJA_WORKFLOW_STATE.published
        ).length > 0
      )
    }
    if (
      entityInfo.entity.tipus === 'artista' &&
      entityInfo.entity.review_state !== LLOTJA_WORKFLOW_STATE.published
    ) {
      return true
    }
    return false
  }

  if (!dataCanido || loadingCanIdo) return <LoadingComponent />
  return (
    <>
      <div className="entityDetailItem">
        {renderResume()}
        <div className="entityDetailItem_list">
          <div
            className="entityDetailItem_element"
            data-test={`boxEntityTest_${entityInfo.entity['id']}`}
          >
            <div className="entityDetailItem__title mb-10">
              <p>{t('my_entity')}</p>
              {dataCanido['guillotina.ModifyContent'] && (
                <Chips
                  text={t('editar')}
                  icon={<EditIcon />}
                  dataTest={'entityDetailItemChip'}
                  onClick={() => navigate(`${path.ENTITY_PATH}/edit${entityInfo.entity['path']}`)}
                />
              )}
            </div>
            <div className="entityDetailItem__box_acreditation">
              <div className="entityDetailItem__box">
                <div
                  className="cursor-pointer justify-content-center"
                  onClick={() => toggleModal(true)}
                >
                  <ProfileImage
                    defaultLetter={entityInfo.entity.title.charAt(0)}
                    hasImage={false}
                  />
                </div>
                <div className="entityDetailItem__box__item">
                  <div className="entityDetailItem__box__item__title">
                    <p className="body-bold">{entityInfo.entity.title}</p>
                    {entityInfo.entity.tipus === 'artista' &&
                      entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.published && (
                        <Badges
                          text={t('artista_actuant')}
                          className="actua"
                          icon={<AlertIcon />}
                        />
                      )}
                  </div>
                  <a
                    className="entityDetailItem__box__item__email"
                    href={`mailto:${entityInfo.entity.correu}`}
                  >
                    <p>{entityInfo.entity.correu}</p>
                  </a>
                </div>
              </div>
              {entityInfo.entity.tipus === 'artista' &&
                entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.published && (
                  <Box mr={2}>
                    <Badges text={t('artista_actuant')} className="actua" icon={<AlertIcon />} />
                  </Box>
                )}
            </div>
          </div>

          <div
            className={`entityDetailItem_element ${
              entityInfo.payments.length === 0 ? 'disabled' : ''
            } `}
            data-test={`boxPaymentsTest_${entityInfo.entity['id']}`}
          >
            <div className="entityDetailItem__title mb-10">
              <p>{t('Pagaments')}</p>
            </div>
            <div className="entityDetailItem__box">
              <div className="entityDetailItem__box__item" style={{ width: '100%' }}>
                {entityInfo.payments.map((payment) => {
                  return (
                    <Fragment key={payment['@id']}>
                      <AccountPayment entityInfo={entityInfo} paymentInfo={payment} />
                    </Fragment>
                  )
                })}
              </div>
            </div>
          </div>

          {entityTypeObj && entityTypeObj.haveDescription && (
            <ProjectResumeBox entityInfo={entityInfo.entity} dataCanido={dataCanido} />
          )}

          {entityTypeObj && entityTypeObj.canAddAccreditation && isArtistRejected() && (
            <div
              className="entityDetailItem_element"
              data-test={`boxEntityCreateProfesionalTest_${entityInfo.entity['id']}`}
            >
              <div className="entityDetailItem__title mb-10">
                <p>{t('si_vols_acreditacio_professional')}</p>
              </div>
              <Box
                className="entityDetailItem__box artistRejectedDescription"
                flexDirection={'column'}
              >
                <p>
                  {t('si_vols_acreditacio_professional')}&nbsp;
                  <Link
                    to={`${path.CREATE_ENTITY_PATH}/professional?entityID=${entityInfo.entity['@uid']}`}
                  >
                    {t('clica_aqui')}
                  </Link>
                </p>
              </Box>
            </div>
          )}

          {entityTypeObj && entityTypeObj.canAddAccreditation && !isArtistRejected() && (
            <div
              className="entityDetailItem_element"
              data-test={`boxAccreditationsTest_${entityInfo.entity['id']}`}
            >
              <div className="entityDetailItem__title mb-10">
                <p>{t('Acreditats')}</p>
                {!isArtistRejected() && dataCanido['guillotina.AddContent'] && (
                  <Chips
                    text={t('add')}
                    icon={<AddProfessionalsIcon />}
                    dataTest={'entityDetailItemChip'}
                    onClick={() => {
                      if (isTotalAccreditationsFilled) {
                        setSubmitModal(true)
                        setsubmitModalProps({
                          text: <Trans i18nKey="alert_professional_acreditat" />,
                          handleOk: () =>
                            navigate(
                              `${path.ENTITY_PATH}${entityInfo.entity['path']}/${path.ACCREDITATION}/add`
                            ),
                          handleCancel: () => setSubmitModal(false),
                          icon: <AddProfessionalsIconOutline fill={'#273BF3'} />,
                          textOk: t('alert_professional_acreditat_ok'),
                          textCancel: t('alert_professional_acreditat_cancel'),
                        })
                      } else {
                        navigate(
                          `${path.ENTITY_PATH}${entityInfo.entity['path']}/${path.ACCREDITATION}/add`
                        )
                      }
                    }}
                  />
                )}
              </div>
              {entityInfo.accreditations.map((accreditation, index) => {
                return (
                  <>
                    <AccountAccreditation
                      key={accreditation['@id']}
                      entityInfo={entityInfo}
                      accreditationInfo={accreditation}
                    />
                    {!(index + 1 === entityInfo.accreditations.length) && (
                      <div className="separator" />
                    )}
                  </>
                )
              })}
              {entityInfo.accreditations.length === 0 && (
                <div className="entityDetailItem__box">
                  <div />
                  <div className="empty-data">
                    <p>{t('any_professional_with_accreditation')}</p>
                    <br />
                  </div>
                </div>
              )}
            </div>
          )}

          {currentEdition.getConfigData()['afegir_estands'] &&
            entityTypeObj &&
            entityTypeObj.canAddStand &&
            dataCanido['guillotina.AddContent'] && (
              <EstandInfoBox entityInfo={entityInfo} dataCanido={dataCanido} />
            )}

          {entityTypeObj && entityTypeObj.canAddShop && dataCanido['guillotina.AddContent'] && (
            <div
              className="entityDetailItem_element"
              data-test={`boxShopsTest_${entityInfo.entity['id']}`}
            >
              <div className="entityDetailItem__title mb-10">
                <p>{t('Parada')}</p>
                <Chips
                  text={t('add')}
                  icon={<AddProfessionalsIcon />}
                  dataTest={'entityDetailItemChip'}
                  onClick={() =>
                    navigate(`${path.ENTITY_PATH}${entityInfo.entity['path']}/${path.SHOP}/add`)
                  }
                />
              </div>
              {entityInfo.shops.map((shop) => {
                return (
                  <AccountShop
                    key={shop['@id']}
                    shop={shop}
                    entityPath={entityInfo.entity['path']}
                    dataCanido={dataCanido}
                  />
                )
              })}
              {entityInfo.shops.length === 0 && (
                <div className="entityDetailItem__box">
                  <div />
                  <div className="empty-data">
                    <p>{t('any_shop_added')}</p>
                    <br />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="entityDetailItem_last" />
        </div>
      </div>
      {isModalOpen && (
        <Modal className="title-generic" isOpen={isModalOpen} toggle={modalCallback}>
          <EntitiyDetailInfo entity={entityInfo.entity} />
        </Modal>
      )}
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        text={submitModalProps.text}
        handleOk={submitModalProps.handleOk}
        handleCancel={submitModalProps.handleCancel}
        icon={submitModalProps.icon ? submitModalProps.icon : null}
        textOk={submitModalProps.textOk ? submitModalProps.textOk : null}
        textCancel={submitModalProps.textCancel ? submitModalProps.textCancel : null}
      ></ModalInfo>
    </>
  )
}

export const AccountEntitiy = memo(AccountEntitiyComponent)
