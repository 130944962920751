import { Box } from '@mui/system'
import SearchIcon from 'NewVersion/icons/SearchIcon'
import 'NewVersion/styles/UI/FilterSearch.scss'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const FilterSearch = ({ placeholder, children, dataTest, tooltip, onClick, isSelected }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryInput, setQueryInput] = useState({
    value: searchParams.get('search') || '',
    typingTimeout: null,
  })

  return (
    <Box className="filter-search">
      <SearchIcon />
      <input
        value={queryInput.value}
        placeholder={placeholder}
        aria-label="Search text"
        data-test="inputSearchScheduleTest"
        onChange={(e) => {
          if (queryInput.typingTimeout) {
            clearTimeout(queryInput.typingTimeout)
          }

          setQueryInput({
            value: e.target.value,
            typingTimeout: setTimeout(() => {
              searchParams.set('search', e.target.value)
              setSearchParams(searchParams)
            }, 1000),
          })
        }}
      />
    </Box>
  )
}

export default FilterSearch
