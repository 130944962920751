const MAIN_IMAGE_KEY = 'principal'
const SLIDER_IMAGE_KEY = 'slider'

export const getImageUrl = ({
  showId,
  imageKey,
  edition,
  size = 'preview',
  type = 'espectacles',
}) => {
  return `${process.env.REACT_APP_GUILLOTINA}/${edition}/${type}/${showId}/@images/images/${imageKey}/${size}`
}

export const getImageFiles = (item) => {
  let files = {}
  const images = []

  if (item.multiimagesattachment_files) {
    files = item.multiimagesattachment_files
  } else if ('guillotina.contrib.image.behaviors.IMultiImageAttachment' in item) {
    files = item['guillotina.contrib.image.behaviors.IMultiImageAttachment'].images
  }

  for (const [key, image] of Object.entries(files)) {
    images.push({ key, image })
  }
  return images
}

export const haveMainImage = (item) => {
  return getMainImageKey(getImageFiles(item))
}

export const getMainImageKey = (images) => {
  let key
  if (images.find((image) => image.key === MAIN_IMAGE_KEY)) {
    key = MAIN_IMAGE_KEY
  } else if (images.length > 0) {
    key = images[0].key
  }
  return key
}

const getSliderImageKeyOrMainImage = (images) => {
  let key
  if (images.find((image) => image.key === SLIDER_IMAGE_KEY)) {
    key = SLIDER_IMAGE_KEY
  } else {
    key = getMainImageKey(images)
  }
  return key
}

export const getSliderImage = ({ item, edition, size = 'preview', type = 'espectacles' }) => {
  const images = getImageFiles(item)
  const key = getSliderImageKeyOrMainImage(images)
  if (key) {
    return getImageUrl({ showId: item['@name'], imageKey: key, edition, size, type })
  } else {
    return '/images/black.webp'
  }
}

export const getMainImage = ({ item, edition, size = 'preview', type = 'espectacles' }) => {
  const images = getImageFiles(item)
  const key = getMainImageKey(images)
  if (key) {
    return getImageUrl({
      showId: item['@name'] ? item['@name'] : item['id'],
      imageKey: key,
      edition,
      size,
      type,
    })
  } else {
    return '/images/black.webp'
  }
}

export const getSecondaryImages = ({ item, edition, size = 'preview', type = 'espectacles' }) => {
  const images = getImageFiles(item)
  const key = getMainImageKey(images)
  return images
    .filter((image) => image.key !== key)
    .map((image) =>
      getImageUrl({
        showId: item['@name'] ? item['@name'] : item['id'],
        imageKey: image.key,
        edition,
        size,
        type,
      })
    )
}
