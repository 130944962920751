import dayjs from 'dayjs'

require('dayjs/locale/ca')
require('dayjs/locale/es')

const getDate = (codedDate) => {
  // codeDate te el format "data": "2021-03-18T16:20:00"
  if (codedDate.length > 10) {
    return new Date(
      parseInt(codedDate.substring(0, 4)), // Any
      parseInt(codedDate.substring(5, 7)) - 1, // Mes
      parseInt(codedDate.substring(8, 10)), // Dia
      parseInt(codedDate.substring(11, 13)), // Hora
      parseInt(codedDate.substring(14, 16)), // Min
      parseInt(codedDate.substring(17, 19)) // Seg
    )
  }

  return new Date(
    parseInt(codedDate.substring(0, 4)), // Any
    parseInt(codedDate.substring(5, 7)) - 1, // Mes
    parseInt(codedDate.substring(8, 10)) // Dia
  )
}

const sortDates = (obj) => {
  return obj.sort((a, b) => a.data.localeCompare(b.data))
}

const formatDateOnlyDate = (date, lang) => {
  if (lang === 'en') {
    return dayjs(getDate(date)).locale(lang).format('dddd[,] DD MMMM YYYY')
  }
  return dayjs(getDate(date)).locale(lang).format('dddd[,] DD MMMM YYYY').toLowerCase()
}

const formatDateOnlyDay = (date, lang) => {
  return dayjs(getDate(date)).locale(lang).format('dddd DD')
}

const getDayBefore = (date, lang) => {
  return dayjs(getDate(date)).subtract(3, 'hours').locale(lang).format('dddd')
}

const formatDateDayHours = (date, lang) => {
  return dayjs(getDate(date)).locale(lang).format('dddd DD: hh:mm')
}

export {
  formatDateDayHours,
  formatDateOnlyDate,
  formatDateOnlyDay,
  getDate,
  getDayBefore,
  sortDates,
}
