export const AddProfessionalsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3906_15308)">
      <path
        d="M10.0001 8.00033C11.4734 8.00033 12.6667 6.80699 12.6667 5.33366C12.6667 3.86033 11.4734 2.66699 10.0001 2.66699C8.52675 2.66699 7.33342 3.86033 7.33342 5.33366C7.33342 6.80699 8.52675 8.00033 10.0001 8.00033ZM4.00008 6.66699V4.66699H2.66675V6.66699H0.666748V8.00033H2.66675V10.0003H4.00008V8.00033H6.00008V6.66699H4.00008ZM10.0001 9.33366C8.22008 9.33366 4.66675 10.227 4.66675 12.0003V13.3337H15.3334V12.0003C15.3334 10.227 11.7801 9.33366 10.0001 9.33366Z"
        fill="#E90C1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3906_15308">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
