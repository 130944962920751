import { useContext } from 'react'
import EditionContext from 'context/EditionContext'

export function useEdition() {
  const { edition, loading, reset, setLastEdition, currentEdition, setNewEdition } =
    useContext(EditionContext)

  return {
    edition,
    setNewEdition,
    loading,
    currentEdition,
    reset,
    setLastEdition,
  }
}
