import { ArrowDown } from 'Components/CustomIcons/ArrowDown'
import { ArrowUp } from 'Components/CustomIcons/ArrowUp'
import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { ShopForm } from 'NewVersion/components/Forms/ShopForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { FiraPasseigIcon } from 'NewVersion/icons/FiraPasseigIcon'
import 'NewVersion/styles/ShopFormPage.scss'
import { ACCOUNT_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useVocabularies } from 'hooks/useVocabularies'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { get } from 'utils/objectUtils'

const AddPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitModal, setSubmitModal] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const { edition, currentEdition, loading } = useEdition()
  const { entityId } = useParams()

  const { loading: loadingVocabularies, data: vocabularies } = useVocabularies([
    {
      vocabulary: 'tipus_parada',
    },
    {
      vocabulary: 'trams_parada',
    },
  ])

  const getRates = (value) => {
    const result = get(currentEdition.getConfigData(), `tarifes_parades.${value}.estandard`, null)
    if (!result) {
      return get(currentEdition.getConfigData(), 'preu_defecte_parades', '--')
    }
    return result
  }

  const {
    data: entityDetail,
    loading: loadingEntity,
    error: entityError,
  } = useGuillotina({ path: `${edition}/${entityId}` })

  return !loading && !loadingVocabularies && !loadingEntity ? (
    <div className="page-wrapper">
      <GoBackButton onClick={() => navigate(ACCOUNT_PATH)} text={t('Tornar a acreditacions')} />
      <div onClick={() => navigate(ACCOUNT_PATH)} className="cursor-pointer">
        <h4>{t('add_shop_title')}</h4>
      </div>
      <AlertComponent type="info" className="info-tarifes">
        <div onClick={() => setOpenInfo(!openInfo)} className={`header ${openInfo ? 'open' : ''}`}>
          <strong>{t('rates_information')}</strong>
          {openInfo ? (
            <ArrowUp width={25} height={25} fill="black" />
          ) : (
            <ArrowDown width={25} height={25} fill="black" />
          )}
        </div>

        {openInfo && (
          <article>
            {vocabularies['trams_parada'].map((tram, idx) => {
              if (currentEdition.getConfigData()['hi_ha_dos_trams'] || idx === 0) {
                return (
                  <Fragment key={tram.value}>
                    <div>
                      {t('rates')}
                      {currentEdition.getConfigData()['hi_ha_dos_trams'] && ` - ${t(tram.value)}`}
                    </div>
                    <ul>
                      {vocabularies.tipus_parada.map((item) => {
                        return (
                          <li key={item.value}>
                            {t(item.value)} -- {getRates(`${tram.value}.${item.value}`)}€
                          </li>
                        )
                      })}
                    </ul>
                  </Fragment>
                )
              }
              return null
            })}
          </article>
        )}
      </AlertComponent>
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        handleOk={() => navigate(ACCOUNT_PATH)}
        title={t('shop_created')}
        text=""
        icon={<FiraPasseigIcon fill="#273BF3" />}
        closeAction={() => navigate(ACCOUNT_PATH)}
      />
      {entityError && <AlertComponent type="danger">{t('error_generic')}</AlertComponent>}
      {!entityError && (
        <ShopForm
          vocabularies={vocabularies}
          entity={entityDetail}
          submitAction={() => setSubmitModal(true)}
          currentEdition={currentEdition}
        />
      )}
    </div>
  ) : (
    <LoadingComponent />
  )
}

export default AddPage
