import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useEdition } from 'hooks/useEdition'
import { useVocabularies } from 'hooks/useVocabularies'
import { get } from 'utils/objectUtils'

import { requiredError } from 'Components/Forms/FormErrors'
import { ENS_TYPES, getVocabularies } from 'utils/entityUtils'
import EditComponent from '../Fields/EditComponent'
import { InputAcceptRegulation } from '../Fields/InputAcceptRegulation'

import { Box } from '@mui/system'
import { ArrowDown } from 'Components/CustomIcons/ArrowDown'
import { ArrowUp } from 'Components/CustomIcons/ArrowUp'
import { AlertComponent } from 'Components/HelperComponents'
import 'NewVersion/styles/Form.scss'
import { ReactComponent as PdfLogo } from 'Statics/pdf.svg'
import { useGuillotina } from 'hooks/useGuillotina'
import Button from '../UI/Buttons/Button'

const OEDER_FIELDS_SHOP_FORM = [
  {
    title: 'data_shop_form',
    fields: ['title', 'tipus', 'tram', 'descompte_artesa', 'descripcio_activitat'],
  },
  {
    title: 'docs_shop_form',
    fields: ['fitxer_dni', 'fitxer_asseguranca', 'fitxer_seguretat_social', 'imatge'],
  },
]

const REQUIERED_FIELDS_SHOP_FORM = [
  'title',
  'tipus',
  'tram',
  'fitxer_dni',
  'fitxer_asseguranca',
  'fitxer_seguretat_social',
  'imatge',
  'descripcio_activitat',
]

export const EntityForm = ({ entity, ensTypeObj, handleSubmit }) => {
  const entityVocabularies = useVocabularies(getVocabularies(ensTypeObj))
  const { loading } = useEdition()

  const {
    data: entitySchemaData,
    isLoading: entitySchemaLoading,
    error: entitySchemaError,
  } = useGuillotina({
    path: `@types/${ensTypeObj['interface']}`,
  })
  const { data: schemaShop, isLoading: schemaLoading } = useGuillotina({ path: '@types/Parada' })

  const { loading: loadingVocabularies, data: vocabularies } = useVocabularies([
    {
      vocabulary: 'tipus_parada',
    },
    {
      vocabulary: 'trams_parada',
    },
  ])

  if (
    loading ||
    loadingVocabularies ||
    !entityVocabularies.data ||
    entityVocabularies.loading ||
    schemaLoading ||
    entitySchemaLoading
  ) {
    return <LoadingComponent />
  }

  return (
    <EntityFormContent
      entityVocabularies={entityVocabularies}
      vocabularies={vocabularies}
      entitySchema={{
        data: entitySchemaData,
        error: entitySchemaError,
        loading: entitySchemaLoading,
      }}
      handleSubmit={handleSubmit}
      ensTypeObj={ensTypeObj}
      entity={entity}
      schemaShop={schemaShop}
      addParades={ensTypeObj.interface === ENS_TYPES.service}
    />
  )
}

const EntityFormContent = ({
  entity,
  entitySchema,
  ensTypeObj,
  readOnlyForm = false,
  handleSubmit,
  schemaShop,
  vocabularies,
  addParades,
}) => {
  const [openInfo, setOpenInfo] = useState(false)
  const { currentEdition } = useEdition()
  const { t } = useTranslation()

  const getRates = (value) => {
    const result = get(currentEdition.getConfigData(), `tarifes_parades.${value}.estandard`, null)
    if (!result) {
      return get(currentEdition.getConfigData(), 'preu_defecte_parades', '--')
    }
    return result
  }

  const renderShopForm = () => !entity && addParades

  const getInitialValues = React.useMemo(() => {
    const allFields = []
    const result = {}

    if (entity)
      ensTypeObj.orderFieldsForm.forEach((item) => {
        if (item.fields && Array.isArray(item.fields)) {
          allFields.push(...item.fields)
        }
      })

    allFields.map((field) => (result[field] = entity[field]))

    if (renderShopForm()) {
      REQUIERED_FIELDS_SHOP_FORM.forEach((key) => (result[key] = null))
    }

    return result
  }, [ensTypeObj])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      ensTypeObj['requiredFields'].forEach((key) => {
        if (key !== 'declaracio_acceptar') {
          const keyForm = `${key}`
          if ((!(keyForm in values) || !values[keyForm]) && renderLocationFields(key)) {
            errors[keyForm] = requiredError()
          }
        }
      })

      const keyForm = `entity_correu`
      if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        errors[keyForm] = t('invalid_email')
      }

      if (!('declaracio_acceptar' in values) || !values['declaracio_acceptar']) {
        errors['declaracio_acceptar'] = requiredError()
      }

      if (renderShopForm()) {
        REQUIERED_FIELDS_SHOP_FORM.forEach((key) => {
          const keyForm = `shop_${key}`
          if (!(keyForm in values) || !values[keyForm]) {
            errors[keyForm] = requiredError()
          }
        })
        if (
          values[`shop_correu`] &&
          !values[`shop_correu`].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
        ) {
          errors[keyForm] = t('invalid_email')
        }
      }

      console.log(errors)
      return errors
    },
  })

  const fieldsWithPrefixLabel = ['idioma', 'correu', 'telefon', 'mobil']
  const fieldsWithPrefixLabelShop = ['title', 'tipus', 'imatge']

  const getLabel = (key, fieldsPrefix, prefix = 'ens_') => {
    if (fieldsPrefix.includes(key)) return `${prefix}${key}`
    return key
  }

  const renderInputAcceptRegulation = (key) => {
    return (
      <>
        {renderShopForm() && (
          <div>
            <PdfLogo />
            <a href="/Acord_FiraPasseig.pdf" download>
              {t('acords_participacio')}
            </a>
          </div>
        )}
        <InputAcceptRegulation
          onChange={(e) => {
            formik.setFieldValue(key, e.target.checked)
            formik.validateForm(Object.assign({}, formik.values, { key: e.target.checked }))
          }}
          onBlur={formik.onBlur}
          checked={!!formik.values[key]}
          error={formik.touched[key] && formik.errors[key]}
          readOnly={readOnlyForm}
          name={key}
        />
      </>
    )
  }

  const renderLocationFields = (key) => {
    if (key === 'comunitat_autonoma' && get(formik, 'values.entity_pais', '') !== 'country-ES') {
      return false
    }
    if (
      key === 'comarca' &&
      (get(formik, 'values.entity_pais', '') !== 'country-ES' ||
        get(formik, 'values.entity_comunitat_autonoma', '') !== 'catalunya')
    ) {
      return false
    }
    return true
  }

  const renderFields = (key) => {
    if (key === 'declaracio_acceptar') return false
    return renderLocationFields(key)
  }

  const getDescription = (value, key) => {
    if (key === 'correu') {
      return t('ens_correu_description')
    } else if (key === 'web') {
      return t('web_description')
    } else if (key === 'facebook') {
      return t('facebook_description')
    } else if (key === 'twitter') {
      return t('twitter_description')
    } else if (key === 'instagram') {
      return t('instagram_description')
    } else if (key === 'linkedin') {
      return t('linkedin_description')
    } else if (key === 'youtube') {
      return t('youtube_description')
    }
    return value.description ? t(`${key}_description`) : null
  }

  const renderFiraPasseig = () => {
    return (
      <>
        <h4 className="header-add-accreditations">{t('add_shop_title')}</h4>
        <Box mt={2}>
          <AlertComponent type="info" className="info-tarifes">
            <div
              onClick={() => setOpenInfo(!openInfo)}
              className={`header ${openInfo ? 'open' : ''}`}
            >
              <strong>{t('rates_information')}</strong>
              {openInfo ? (
                <ArrowUp width={25} height={25} fill="black" />
              ) : (
                <ArrowDown width={25} height={25} fill="black" />
              )}
            </div>

            {openInfo && (
              <article>
                {vocabularies['trams_parada'].map((tram, idx) => {
                  if (currentEdition.getConfigData()['hi_ha_dos_trams'] || idx === 0) {
                    return (
                      <Fragment key={tram.value}>
                        <div>
                          {t('rates')}
                          {currentEdition.getConfigData()['hi_ha_dos_trams'] &&
                            ` - ${t(tram.value)}`}
                        </div>
                        <ul>
                          {vocabularies.tipus_parada.map((item) => {
                            return (
                              <li key={item.value}>
                                {t(item.value)} -- {getRates(`${tram.value}.${item.value}`)}€
                              </li>
                            )
                          })}
                        </ul>
                      </Fragment>
                    )
                  }
                  return null
                })}
              </article>
            )}
          </AlertComponent>
        </Box>
        <div key={`shopForm`} className="acreditacio">
          <div>
            {OEDER_FIELDS_SHOP_FORM.map((item) => {
              return (
                <Fragment key={`shopFormSection_${item['title']}`}>
                  {item['title'] && <h5>{`${t(item['title'])}`}</h5>}
                  <div className="fragment">
                    {item['fields'].map((key) => {
                      if (get(schemaShop, `properties[${key}]`, null)) {
                        const valueSchema = schemaShop.properties[key]
                        const keyForm = `shop_${key}`
                        return (
                          <EditComponent
                            key={keyForm}
                            name={keyForm}
                            label={t(getLabel(key, fieldsWithPrefixLabelShop, 'shop_'))}
                            schema={valueSchema}
                            description={
                              valueSchema.description
                                ? t(
                                    `${getLabel(
                                      key,
                                      fieldsWithPrefixLabelShop,
                                      'shop_'
                                    )}_description`
                                  )
                                : null
                            }
                            value={formik.values[keyForm]}
                            required={REQUIERED_FIELDS_SHOP_FORM.includes(key)}
                            onChange={(ev) => {
                              formik.setFieldValue(keyForm, ev)
                            }}
                            error={formik.errors[keyForm]}
                            dataTest={`inputFieldTest_${keyForm}`}
                          />
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <form
        className="Form"
        onSubmit={(values) => {
          formik.handleSubmit(values)
        }}
        data-test="formTest"
      >
        {entitySchema &&
          entitySchema.data &&
          !entitySchema.loading &&
          ensTypeObj['orderFieldsForm']
            .filter((item) => item.title !== 'declaracio_fieldset')
            .map((item) => {
              return (
                <Fragment key={item['title']}>
                  <h5>{t(item['title'])}&nbsp;</h5>
                  {item['subtitle'] && item['subtitle'] !== '' && <p>{t(item['subtitle'])}</p>}
                  <div className="fragment">
                    {item['fields'].map((key) => {
                      if (renderFields(key) && get(entitySchema, `data.properties[${key}]`, null)) {
                        const value = entitySchema.data.properties[key]
                        return (
                          <EditComponent
                            key={key}
                            name={key}
                            label={
                              key === 'title'
                                ? t(getLabel(ensTypeObj['labelName'], fieldsWithPrefixLabel))
                                : t(getLabel(key, fieldsWithPrefixLabel))
                            }
                            schema={value}
                            description={getDescription(value, key)}
                            value={formik.values[key]}
                            required={(ensTypeObj['requiredFields'] ?? []).includes(key)}
                            onChange={(ev) => {
                              formik.setFieldValue(key, ev)
                            }}
                            error={formik.errors[key]}
                            dataTest={`inputFieldTest_${key}`}
                          />
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}
        {renderShopForm() && renderFiraPasseig()}
        <Box mt={5} mb={3}>
          <h5>{t('declaracio_fieldset')}&nbsp;</h5>
          {renderInputAcceptRegulation('declaracio_acceptar')}
        </Box>
        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!readOnlyForm && !formik.isSubmitting && (
            <div className="footer-buttons">
              <Button type="submit" data-test="btnSubmitFormTest" disabled={formik.isSubmitting}>
                {entity ? t('save') : t('next')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export default EntityForm
