export const NewInscriptionIcon = ({ fill }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4209 16.421C9.4209 14.3863 11.0704 12.7368 13.1051 12.7368H42.5788C44.6135 12.7368 46.263 14.3863 46.263 16.421V45.8947C46.263 47.9294 44.6135 49.5789 42.5788 49.5789H13.1051C11.0704 49.5789 9.4209 47.9294 9.4209 45.8947V16.421ZM13.1051 15.8947C12.8144 15.8947 12.5788 16.1304 12.5788 16.421V45.8947C12.5788 46.1854 12.8144 46.421 13.1051 46.421H42.5788C42.8695 46.421 43.1051 46.1854 43.1051 45.8947V16.421C43.1051 16.1304 42.8695 15.8947 42.5788 15.8947H13.1051Z"
        fill={fill ? fill : '#273BF3'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.421 8.00013V14.3159H16.4736V8.00013H19.421Z"
        fill={fill ? fill : '#273BF3'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3683 8.00013V14.3159H35.4209V8.00013H38.3683Z"
        fill={fill ? fill : '#273BF3'}
      />
      <path
        d="M36.6701 24L24.9172 35.7529L18.5146 29.3682L16 31.8828L24.9172 40.8L39.1847 26.5325L36.6701 24Z"
        fill={fill ? fill : '#273BF3'}
      />
    </svg>
  )
}
