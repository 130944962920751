import WarningRounded from '@mui/icons-material/WarningRounded'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as path from '../Routes/Paths'

function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname === '/index.html') {
      navigate(path.HOME_PATH)
    }
  }, [])

  return (
    <div className="wrapper-empty-info">
      <b>{t('Pagina no trobada!')}</b>
      <br></br>
      <br></br>
      <WarningRounded style={{ fontSize: 80 }} />
      <br></br>
      <br></br>
      <b>
        <Link style={{ color: 'black', textTransform: 'none' }} to={path.HOME_PATH}>
          {t("Torna a la pantalla d'inici")}
        </Link>
      </b>
    </div>
  )
}
export default NotFound
