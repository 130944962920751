const XIcon = ({ color = '#FAFAFA' }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.991 0C6.71316 0 0 6.71269 0 14.99C0 23.2673 6.71316 29.98 14.991 29.98C23.2689 29.98 29.9821 23.2673 29.9821 14.99C29.9821 6.71269 23.2858 0 14.991 0Z"
        fill={color}
      />
      <path
        d="M16.427 13.8507L22.0111 7.49921H20.688L15.8372 13.013L11.9656 7.49921H7.49921L13.3551 15.8377L7.49921 22.4976H8.82228L13.9418 16.6736L18.0312 22.4976H22.4976L16.427 13.8507ZM9.29942 8.4752H11.3319L15.1427 13.8075L15.7345 14.6384L20.6874 21.5692H18.6549L9.29942 8.4752Z"
        fill="#2B2A29"
      />
    </svg>
  )
}

export default XIcon
