import React, { useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { AuthContext } from './../App'

import ConfirmationNumberRounded from '@mui/icons-material/ConfirmationNumberRounded'
import DateRangeRounded from '@mui/icons-material/DateRangeRounded'
import EventNoteRounded from '@mui/icons-material/EventNoteRounded'
import EventRounded from '@mui/icons-material/EventRounded'
import ExitToAppRounded from '@mui/icons-material/ExitToAppRounded'
import Forum from '@mui/icons-material/Forum'
import HomeRounded from '@mui/icons-material/HomeRounded'
import LiveTvRounded from '@mui/icons-material/LiveTvRounded'
import NotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded'
import PermContactCalendarRounded from '@mui/icons-material/PermContactCalendarRounded'
import PersonOutlineRounded from '@mui/icons-material/PersonOutlineRounded'
import ReceiptLong from '@mui/icons-material/Receipt'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import { useLogout } from 'hooks'
import { useEdition } from 'hooks/useEdition'
import { useLocation, useNavigate } from 'react-router'
import { useSetState } from 'react-use'
import { fetchGuillotina } from 'utils/apiCalls'
import { get } from 'utils/objectUtils'
import * as path from '../Routes/Paths'

function SidebarElements(props) {
  const { currentEdition, edition, reset } = useEdition()
  const { dispatch, authState } = React.useContext(AuthContext)
  const { t } = useTranslation()
  const logout = useLogout()
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = useSetState({
    accreditations: undefined,
    loading: false,
  })

  useEffect(() => {
    ;(async () => {
      if (!data.accreditations && !data.loading && authState.user && edition) {
        setData({ loading: true })
        try {
          const responseAccreditations = await fetchGuillotina(`${edition}/@search`, {
            params: {
              type_name__in: 'AcreditacioProfessional,AcreditacioPremsa',
              owners: authState.user,
              review_state: 'publicat',
            },
          })
          setData({
            accreditations: get(responseAccreditations, 'data.items', null) ?? [],
            loading: false,
          })
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 401) {
            logout()
          } else {
            setData({
              accreditations: [],
              loading: false,
            })
          }
        }
      }
      if (authState.user === null && data.accreditations) {
        setData({
          accreditations: undefined,
          loading: false,
        })
      }
    })()
  }, [data, currentEdition, setData, edition, dispatch, location, logout, authState.user])

  const logoutHandler = () => {
    props.onSetSidebarOpen(false)
    reset()
    logout()
  }

  const redirect = (url) => {
    props.onSetSidebarOpen(false)
    navigate(url)
  }

  const canCreateEns = useMemo(() => {
    if (currentEdition) {
      return (
        currentEdition.canCreateEnsProfessionals() ||
        currentEdition.canCreateEnsPremsa() ||
        currentEdition.canCreateEnsServeis()
      )
    }
    return false
  }, [currentEdition])

  const lastEdition = useMemo(() => edition && edition === '2020', [edition])

  return (
    <div className="Sidebar-Elements">
      <div className="element-s " onClick={() => redirect(path.HOME_PATH)}>
        <HomeRounded style={{ fontSize: 20 }} />
        {t('Inici')}
      </div>

      {currentEdition && (
        <div className="element-s" onClick={() => redirect(path.PROPOSALS_PATH)}>
          <ReceiptLong style={{ fontSize: 20 }} />
          {t('presentar proposta')}
        </div>
      )}
      {edition > 2020 && canCreateEns && (
        <div
          className="element-s bottom"
          data-test="btnRegisterFiraTest"
          onClick={() => redirect(path.REGISTER_ENTITY_PATH)}
        >
          <ReceiptLong style={{ fontSize: 20 }} />
          {t('add_professional_press_or_service_entitiy')}
        </div>
      )}

      <div className="element-s" onClick={() => redirect(path.SHOWS_PATH)}>
        <EventRounded style={{ fontSize: 20 }} />
        {t('Programació Artística')}
      </div>

      <div className="element-s" onClick={() => redirect(path.PROGRAMMING_PATH)}>
        <EventNoteRounded style={{ fontSize: 20 }} />
        {t('Programació Professional')}
      </div>

      {(lastEdition || (currentEdition && currentEdition.getConfigData()['veure_directe_pwa'])) && (
        <div className="element-s" onClick={() => redirect(path.LIVE_PATH)}>
          <LiveTvRounded style={{ fontSize: 20 }} />
          {t('audiovisual_content')}
        </div>
      )}

      {data.accreditations && !data.loading && data.accreditations.length === 1 && (
        <div
          className="element-s"
          onClick={() => redirect(path.ACCREDITED_PATH + '/' + data.accreditations[0]['@uid'])}
        >
          <PermContactCalendarRounded style={{ fontSize: 20 }} />
          {t('sidebar_my_accreditation')}
        </div>
      )}

      <div className="element-s bottom" onClick={() => redirect(path.PROFESSIONALS_PATH)}>
        <PermContactCalendarRounded style={{ fontSize: 20 }} />
        {t('sidebar_accreditations')}
      </div>

      <div className="element-s bottom" onClick={() => redirect(path.ARTIST_PATH)}>
        <PermContactCalendarRounded style={{ fontSize: 20 }} />
        {t('artists')}
      </div>

      {edition && edition > 2018 && (
        <div className="element-s" onClick={() => redirect(path.TIKETS_PATH)}>
          <ConfirmationNumberRounded style={{ fontSize: 20 }} />
          {t('Les meves entrades')}
        </div>
      )}

      {edition && edition > 2018 && (
        <div className="element-s" onClick={() => redirect(path.AGENDA_PATH)}>
          <DateRangeRounded style={{ fontSize: 20 }} />
          {t('La meva agenda')}
        </div>
      )}

      {edition && edition > 2018 && (
        <div className="element-s" onClick={() => redirect(path.NOTIFICATIONS_PATH)}>
          <NotificationsNoneRounded style={{ fontSize: 20 }} />
          {t('Avisos')}
        </div>
      )}

      {edition && edition > 2018 && (
        <div className="element-s" onClick={() => redirect(path.XATS_PATH)}>
          <Forum style={{ fontSize: 20 }} />
          {t('Missatges')}
        </div>
      )}

      {edition && edition > 2018 && (
        <div
          className="element-s bottom"
          data-test="btnMyAccountTest"
          onClick={() => redirect(path.ACCOUNT_PATH)}
        >
          <PersonOutlineRounded style={{ fontSize: 20 }} />
          {t('El meu perfil')}
        </div>
      )}

      {edition && edition > 2018 && (
        <div className="element-s" onClick={() => redirect(path.USER_SETTINGS_PATH)}>
          <SettingsRounded style={{ fontSize: 20 }} />
          {t('Preferències')}
        </div>
      )}

      <div className="element-s" onClick={() => logoutHandler()}>
        <ExitToAppRounded style={{ fontSize: 20 }} />
        {t('Tanca sessió')}
      </div>
    </div>
  )
}
export default SidebarElements
