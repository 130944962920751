import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as path from 'Routes/Paths'
import dansaUrl from 'Statics/22FMM-dansa.jpg'
import musicaUrl from 'Statics/22FMM-musica.jpg'
import teatreUrl from 'Statics/22FMM-teatre.jpg'

function Edition2019HomeComponent() {
  const { t } = useTranslation()
  return (
    <div className="Home">
      <div className="home-grid">
        <div className="link-container">
          <Link to={path.SHOWS_PATH}>
            <div
              className="home-link professional-link"
              style={{ backgroundImage: `url(${musicaUrl})` }}
            >
              <span className="text  ">{t('Programació Artística')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.PROGRAMMING_PATH}>
            <div className="home-link direct-link" style={{ backgroundImage: `url(${teatreUrl})` }}>
              <span className="text  ">{t('Programació Professional')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.PROFESSIONALS_PATH}>
            <div
              className="home-link programation-link"
              style={{ backgroundImage: `url(${dansaUrl})` }}
            >
              <span className="text  ">{t('Acreditats')}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const Edition2019Home = React.memo(Edition2019HomeComponent)
