import WrapperUnauthAction from 'Components/WrapperUnauthAction'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoadingComponent from '../Components/HelperComponents/LoadingComponent'
import * as path from '../Routes/Paths'
import imgLogo from '../Statics/logo-fira-240x80px.png'
import { postGuillotinaObjectUnAuth } from '../utils/apiCalls'

import { useTranslation } from 'react-i18next'
import '../styles/Login.scss'

function Register(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [userName, userNameChange] = useState('')
  const [email, emailChange] = useState('')
  const [signUpComplete, setSignUpComplete] = useState(false)
  const [isLoading, setLoader] = useState(false)
  const [signup_error, setSignupError] = useState({
    haveError: false,
    message: '',
  })

  const validateForm = (e) => {
    return false
  }

  const onRegister = async (data) => {
    setSignupError({
      haveError: false,
      message: '',
    })
    data.preventDefault()
    if (!email || !userName) {
      setSignupError({
        haveError: true,
        message: t('Aquest camp és obligatori'),
      })
    } else {
      setLoader(true)
      postGuillotinaObjectUnAuth('@users', {
        id: email,
        fullname: userName,
        email: email,
      })
        .then(function () {
          console.log('signIn send')
          setSignUpComplete(true)
          userNameChange('')
          emailChange('')
          setLoader(false)
        })
        .catch(function (error) {
          console.log('error.response.data.text ', error.response)
          if (
            error.response &&
            error.response.data &&
            error.response.data.text === 'Invalid login'
          ) {
            setSignupError({
              haveError: true,
              message: t('email_already_exists_register'),
            })
          } else {
            setSignupError({
              haveError: true,
              message: t('error_generic'),
            })
          }
          userNameChange('')
          emailChange('')
          setLoader(false)
        })
    }
  }

  return (
    <WrapperUnauthAction>
      <div className="title-login">{t('Benvinguda')}</div>
      <div className="login-container">
        <div className="Login">
          <div className="img-login" style={{ marginBottom: 40 }}>
            <img src={imgLogo} alt="Fira logo" />
          </div>
          <div className="form-login-container">
            <form onSubmit={onRegister} className="form-login">
              <input
                id="user"
                className={'input'}
                name="email"
                autoCorrect="off"
                autoCapitalize="none"
                aria-label="user"
                placeholder={t('correu')}
                value={email}
                onChange={(event) => emailChange(event.currentTarget.value)}
              />
              <label htmlFor="user"></label>
              <input
                id="userName"
                aria-label="userName"
                className={'input'}
                type={''}
                name="userName"
                placeholder={t('username')}
                value={userName}
                onChange={(event) => userNameChange(event.currentTarget.value)}
              />
              <label htmlFor="userName"></label>
              {signup_error.message && (
                <span className="error-message">{signup_error.message}</span>
              )}
              <div className="buttons-container">
                {!signUpComplete && !isLoading && (
                  <button disabled={validateForm()} className="login-button" type="submit">
                    {t('Registrat')}
                  </button>
                )}
                {isLoading && <LoadingComponent noClass={true} />}
                <div className="d-flex" style={{ width: '100%' }}>
                  {!signUpComplete && (
                    <div
                      className="forgotten-userName cursor-pointer"
                      style={{ textAlign: 'left' }}
                    >
                      <Link to={path.LOGIN_PATH}>{t('Vull fer login')}</Link>
                    </div>
                  )}
                </div>
              </div>
            </form>
            {signUpComplete && <div className="signup-complete">{t('registre completat')}</div>}
            {signUpComplete && (
              <button onClick={() => navigate(path.LOGIN_PATH)} className="login-button">
                OK
              </button>
            )}
          </div>
        </div>
        {/* {renderRegister()} */}
      </div>
    </WrapperUnauthAction>
  )
}

export default Register
