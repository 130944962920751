export const FolderIcon = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.68421 8.36848H3V19.3159C3 20.2422 3.75789 21.0001 4.68421 21.0001H19V19.3159H4.68421V8.36848Z"
        fill={fill ? fill : '#2B2A29'}
      />
      <path
        d="M19.842 6.68421H13.9473L12.2631 5H8.05256C7.12624 5 6.37677 5.75789 6.37677 6.68421L6.36835 15.9474C6.36835 16.8737 7.12624 17.6316 8.05256 17.6316H19.842C20.7683 17.6316 21.5262 16.8737 21.5262 15.9474V8.36842C21.5262 7.44211 20.7683 6.68421 19.842 6.68421Z"
        fill={fill ? fill : '#2B2A29'}
      />
    </svg>
  )
}
