import { Box } from '@mui/system'
import { ScheduleTable } from 'NewVersion/components/Schedule/ScheduleTable'
import { ShowGrid } from 'NewVersion/components/Show/ShowGrid'
import { Filter, VIEW_TYPE_GRID, VIEW_TYPE_KEY } from 'NewVersion/components/UI/Filters/Filter'
import FilterSearch from 'NewVersion/components/UI/Filters/FilterSearch'
import { MAX_TIEMS_PAGE_DEFAULT, Pagination } from 'NewVersion/components/UI/Pagination/Pagination'
import 'NewVersion/styles/ScheduleListPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getCurrentLanguage } from 'utils/utils'

function ScheduleListPage() {
  const { t } = useTranslation()
  const { edition, currentEdition } = useEdition()
  const currentLanguage = getCurrentLanguage()
  const [searchParams, setSearchParams] = useSearchParams()

  const getTypeNameShows = () => {
    let filterTypeName = ''
    if (searchParams.get('type_event') === 'act_prof') {
      filterTypeName = 'type_name=ActivitatProfessional'
    } else if (searchParams.get('type_event') === 'off') {
      filterTypeName = 'type_name=Actuacio&seccions__in=off'
    } else if (searchParams.get('type_event') === 'oficial') {
      filterTypeName = 'type_name=Actuacio&seccions__in=oficial&seccions__not=off'
    } else {
      filterTypeName = 'type_name__in=ActivitatProfessional,Actuacio'
    }

    return filterTypeName
  }

  const getFiltersSearch = () => {
    let filterProfessionalAmbit = ''
    let filterArtisticAmbit = ''
    let filterDay = ''
    let search = ''

    if (
      searchParams.get('professional_ambit') &&
      searchParams.get('professional_ambit') !== 'all'
    ) {
      const professionaAmbitArray = searchParams.get('professional_ambit').split(',')
      filterProfessionalAmbit = `&parent_uuid__or=${professionaAmbitArray
        .map((item) => `parent_uuid=${item}`)
        .join('%26')}`
    }

    if (searchParams.get('artistic_ambit') && searchParams.get('artistic_ambit') !== 'all') {
      filterArtisticAmbit = `&ambits__in=${searchParams.get('artistic_ambit')}`
    }

    if (searchParams.get('search') && searchParams.get('search') !== '') {
      search = `&__or=${['artista', `nom_${currentLanguage}`, 'shows_names']
        .map((item) => `${item}=${searchParams.get('search')}`)
        .join('%26')}`
    }

    if (searchParams.get('day') && searchParams.get('day') !== 'all') {
      const dayArray = searchParams.get('day').split(',')
      filterDay = `&data_timestamp__or=${dayArray
        .map((item) => `data_timestamp=${item}`)
        .join('%26')}`
    }

    const filterOrder = `&_sort_${
      searchParams.get('sort-direction') ? searchParams.get('sort-direction') : 'asc'
    }=${searchParams.get('sort-by') ? searchParams.get('sort-by') : 'data'}`

    return `${filterProfessionalAmbit}${filterArtisticAmbit}${filterDay}${search}${filterOrder}`
  }

  const getTypeNameEspectacle = () => {
    let filterTypeName = ''
    if (searchParams.get('type_event') === 'act_prof') {
      filterTypeName = 'type_name=ActivitatProfessional'
    } else if (searchParams.get('type_event') === 'off') {
      filterTypeName = 'type_name=Espectacle&seccio=off'
    } else if (searchParams.get('type_event') === 'oficial') {
      filterTypeName = 'type_name=Espectacle&seccio=oficial&seccio__not=off'
    } else {
      filterTypeName = 'type_name__in=ActivitatProfessional,Espectacle'
    }

    return filterTypeName
  }

  const getFiltersSearchEspectacle = () => {
    let filterProfessionalAmbit = ''
    let filterArtisticAmbit = ''
    let filterDay = ''
    let search = ''

    if (
      searchParams.get('professional_ambit') &&
      searchParams.get('professional_ambit') !== 'all'
    ) {
      const professionaAmbitArray = searchParams.get('professional_ambit').split(',')
      filterProfessionalAmbit = `&parent_uuid__or=${professionaAmbitArray
        .map((item) => `parent_uuid=${item}`)
        .join('%26')}`
    }

    if (searchParams.get('artistic_ambit') && searchParams.get('artistic_ambit') !== 'all') {
      filterArtisticAmbit = `&ambit__in=${searchParams.get('artistic_ambit')}`
    }

    if (searchParams.get('search') && searchParams.get('search') !== '') {
      search = `&title%26artistes_titles__or=title=${searchParams.get(
        'search'
      )}%26artistes_titles=${searchParams.get(
        'search'
      )}%26parent_title_${currentLanguage}=${searchParams.get(
        'search'
      )}%26artista=${searchParams.get('search')}`
    }

    if (searchParams.get('day') && searchParams.get('day') !== 'all') {
      const dayArray = searchParams.get('day').split(',')
      filterDay = `&data_timestamp__or=${dayArray
        .map((item) => `data_timestamp=${item}`)
        .join(
          '%26'
        )}%26data_timestamp=null&dies_actuacions__or=dies_actuacions__in=${searchParams.get(
        'day'
      )}%26dies_actuacions=null`
    }

    const filterOrder = `&_sort_${
      searchParams.get('sort-direction') ? searchParams.get('sort-direction') : 'asc'
    }=${searchParams.get('sort-by') ? searchParams.get('sort-by') : 'artistes_titles'}`

    return `${filterProfessionalAmbit}${filterArtisticAmbit}${filterDay}${search}${filterOrder}`
  }

  const { data: { items_total: itemsTotal } = 0 } = useGuillotina({
    path: `${edition}/@search?__or=type_name=Actuacio%26type_name=ActivitatProfessional&b_start=0&b_size=1`,
  })

  const { data: { items_total: itemsTotalEspectacles } = 0 } = useGuillotina({
    path: `${edition}/@search?type_name__in=ActivitatProfessional,Espectacle&b_start=0&b_size=1`,
  })

  const { data: { items: typeProfessionalActivities } = [] } = useGuillotina({
    path: `${edition}/@search?type_name=TipologiaActivitatProfessional`,
  })

  const getPaginationFilter = () => {
    let paginationFilter = `&b_size=${MAX_TIEMS_PAGE_DEFAULT}`
    if (searchParams.get('page')) {
      paginationFilter = `&b_start=${
        (parseInt(searchParams.get('page')) - 1) * MAX_TIEMS_PAGE_DEFAULT
      }${paginationFilter}`
    } else paginationFilter = `&b_start=0${paginationFilter}`
    return paginationFilter
  }

  const { isLoading, data: { items: showsOfSchedule, items_total: showingItems } = [] } =
    useGuillotina({
      path: `${edition}/@search?${getTypeNameShows()}${getFiltersSearch()}${getPaginationFilter()}`,
    })

  const {
    isLoadingEspectacles,
    data: { items: espectaclesOfSchedule, items_total: showingItemsEspectacles } = [],
  } = useGuillotina({
    path: `${edition}/@search?${getTypeNameEspectacle()}${getFiltersSearchEspectacle()}${getPaginationFilter()}`,
  })

  const { data: { items: vocabularyAmbits } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/ambits`,
    useStatic: true,
  })

  const filtersDefiniton = useMemo(() => {
    const filters = [
      {
        key: 'type_event',
        label: t('event'),
        sorted: true,
        unique: true,
        data: [
          { value: 'all', label: t('show_all'), default: true },
          { value: 'act_prof', label: t('Activitat professional') },
          { value: 'off', label: t('Programació no oficial') },
          { value: 'oficial', label: t('Programació oficial') },
        ],
      },
    ]
    if (currentEdition) {
      filters.push({
        key: 'day',
        label: t('day'),
        sorted: false,
        data: [
          { value: 'all', label: t('show_all_days'), default: true },
          ...currentEdition
            .getFiraProfessionalActivitiesDaysFilters(t)
            .map((day) => ({ value: day[2], label: `${day[0]} ${day[1]}` })),
        ],
      })
    }
    if (typeProfessionalActivities) {
      filters.push({
        key: 'professional_ambit',
        label: t('Àmbit professional'),
        sorted: false,
        data: [
          { value: 'all', label: t('view_all_professional_ambit'), default: true },
          ...typeProfessionalActivities.map((item) => ({
            value: item['@uid'],
            label: item[`title_${currentLanguage}`],
          })),
        ],
      })
    }
    if (vocabularyAmbits) {
      filters.push({
        key: 'artistic_ambit',
        label: t('Programació Artística'),
        sorted: false,
        data: [
          { value: 'all', label: t('view_all_artistic_ambit'), default: true },
          ...vocabularyAmbits.map((item) => ({
            value: item['token'],
            label: item['title'][`lang-${currentLanguage}`] || item['title']['default'],
          })),
        ],
      })
    }
    return filters
  }, [currentEdition, typeProfessionalActivities, vocabularyAmbits, currentLanguage])

  return (
    <Box
      className={'container-schedule-list-page page-wrapper'}
      dataTest="container-schedule-list-page"
    >
      <Box display={'flex'} alignItems={'end'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box maxWidth={'500px'}>
          <h1>{t('programacio')}</h1>
          <p>{t('programacio_page_detail')}</p>
        </Box>
        <FilterSearch
          placeholder="Cerca la programació"
          onChange={({ target }) => {
            searchParams.set('search', target.value)
            searchParams.set('page', 1)
            setSearchParams(searchParams)
          }}
          dataTest="inputSearchScheduleTest"
          value={searchParams.get('search') || ''}
          variant={'outlined'}
        />
      </Box>
      <Filter
        itemsTotal={
          searchParams.get(VIEW_TYPE_KEY) !== VIEW_TYPE_GRID ? itemsTotal : itemsTotalEspectacles
        }
        showingItems={
          searchParams.get(VIEW_TYPE_KEY) !== VIEW_TYPE_GRID
            ? showingItems
            : showingItemsEspectacles || 0
        }
        filtersDefiniton={filtersDefiniton}
      />
      {searchParams.get(VIEW_TYPE_KEY) !== VIEW_TYPE_GRID ? (
        <ScheduleTable showsOfSchedule={showsOfSchedule} loading={isLoading} />
      ) : (
        <ShowGrid shows={espectaclesOfSchedule} loading={isLoadingEspectacles} />
      )}

      {!isLoading && !isLoadingEspectacles && (
        <Pagination
          itemsTotal={
            searchParams.get(VIEW_TYPE_KEY) !== VIEW_TYPE_GRID
              ? showingItems
              : showingItemsEspectacles
          }
        />
      )}
    </Box>
  )
}

export default ScheduleListPage
