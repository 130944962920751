import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken } from 'utils/utils'
import { useEdition } from './useEdition'

const getOptionsFormat = (items, t) => {
  const options = []
  items.forEach((item) => {
    options.push({ label: t(item.token), value: item.token })
  })
  return options
}

export function useAddressForm() {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const [loading, setLoading] = useState(false)
  const [countriesOptions, setCountriesOptions] = useState(undefined)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [regionOptions, setRegionOptions] = useState(undefined)
  const token = getCurrentJWToken()

  useEffect(() => {
    if ((!countriesOptions || !stateOptions || !regionOptions) && !loading) {
      setLoading(true)

      Promise.all([
        fetchGuillotina({ path: `${edition}/@vocabularies/countriesfm`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/comunitat_autonoma`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/comarca`, token }),
      ]).then(([countries, states, regions]) => {
        setCountriesOptions(getOptionsFormat(get(countries, 'items', []), t))
        setStateOptions(getOptionsFormat(get(states, 'items', []), t))
        setRegionOptions(getOptionsFormat(get(regions, 'items', []), t))
        setLoading(false)
      })
    }
  }, [countriesOptions, stateOptions, regionOptions, loading, t, edition])

  const isLoaded = () => {
    return countriesOptions && stateOptions && regionOptions && !loading
  }
  return { isLoaded, countriesOptions, stateOptions, regionOptions }
}
