import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import 'NewVersion/styles/UI/ModalInfo.scss'
import { useTranslation } from 'react-i18next'
import Button from '../Buttons/Button'

export const ModalInfo = ({
  open,
  setOpen,
  icon,
  title,
  text,
  handleOk,
  handleCancel,
  textOk,
  textCancel,
  closeAction,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} className="dialog">
      <div
        className="cross-icon"
        onClick={() => {
          if (closeAction) closeAction()
          setOpen(false)
        }}
      >
        <CrossIcon fill={'black'} width="22" height="22" />
      </div>
      <div className="dialog-content">
        <div>{icon}</div>
        {title && (
          <DialogTitle>
            <h4>{title}</h4>
          </DialogTitle>
        )}
        {text && (
          <DialogContent>
            <p>{text}</p>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            onClick={() => {
              handleOk()
              setOpen(false)
            }}
            dataTest="modalButtonOkTest"
          >
            {textOk ? textOk : t('ok')}
          </Button>
          {handleCancel && (
            <Button
              onClick={() => {
                handleCancel()
                setOpen(false)
              }}
              color="primaryOutlinedIcon outlined"
              dataTest="modalButtonCancelTest"
            >
              {textCancel ? textCancel : t('cancel')}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  )
}
