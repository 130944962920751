import React from 'react'
import { Edition2020Home } from 'Components/Home/Edition2020Home'
import { Edition } from './Edition'
import { BACKEND_TYPE, FIRA_EMAILS } from 'utils/constants'
import { fetchPlone } from 'utils/apiCalls'
import { get } from 'utils/objectUtils'
import { getEmailFromToken } from 'utils/utils'

export default class Edition2020 extends Edition {
  homeComponent() {
    return <Edition2020Home />
  }

  getUrl() {
    return `${process.env.REACT_APP_PLONE}mediterrania2020/`
  }

  getBackendType() {
    return BACKEND_TYPE.PLONE
  }

  async canAccesToInfo() {
    const email = getEmailFromToken()
    if (FIRA_EMAILS.includes(email)) {
      return true
    }
    try {
      const response = await fetchPlone({
        path: `${this.getUrl()}infouser?email=${getEmailFromToken()}`,
      })
      return get(response, 'data.entitats', []).length > 0
    } catch (err) {
      console.log('can acces err', err)
      return false
    }
  }
}
