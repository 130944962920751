import ConfirmationNumberRounded from '@mui/icons-material/ConfirmationNumberRounded'
import DateRangeRounded from '@mui/icons-material/DateRangeRounded'
import PermContactCalendarRounded from '@mui/icons-material/PermContactCalendarRounded'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as path from 'Routes/Paths'
import groc from 'Statics/_groc_baixa_1920px.png'
import verd from 'Statics/_verd_baixa_1920px.png'
import vermell from 'Statics/_vermell_baixa_1920px.png'

export function Edition2020HomeComponent() {
  const { t } = useTranslation()
  return (
    <div className="Home">
      <div className="home-grid">
        <div className="link-container">
          <Link to={path.SHOWS_PATH}>
            <div
              className="home-link professional-link"
              style={{ backgroundImage: `url(${verd})` }}
            >
              <span className="text  ">{t('Programació Artística')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.PROGRAMMING_PATH}>
            <div className="home-link direct-link" style={{ backgroundImage: `url(${groc})` }}>
              <span className="text  ">{t('Programació Professional')}</span>
            </div>
          </Link>
        </div>
        <div className="link-container">
          <Link to={path.LIVE_PATH}>
            <div
              className="home-link programation-link"
              style={{ backgroundImage: `url(${vermell})` }}
            >
              <span className="text  ">{t('audiovisual_content')}</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="home-grid-lower separator-home">
        <div className={isMobile ? 'small-mobile' : 'link-container '}>
          <Link to={path.PROFESSIONALS_PATH}>
            <div className="home-link small direct-link">
              <span className="text">
                <PermContactCalendarRounded style={{ fontSize: 20 }} />
                {t('Acreditats')}
              </span>
            </div>
          </Link>
        </div>
        <div className={isMobile ? 'small-mobile' : 'link-container '}>
          <Link to={path.TIKETS_PATH}>
            <div className="home-link small programation-link light">
              <span className="text">
                <ConfirmationNumberRounded style={{ fontSize: 20 }} />
                {t('Les meves entrades')}
              </span>
            </div>
          </Link>
        </div>
        <div className={isMobile ? 'small-mobile' : 'link-container '}>
          <Link to={path.AGENDA_PATH}>
            <div className="home-link small professional-link lighter">
              <span className="text">
                <DateRangeRounded style={{ fontSize: 20 }} />
                {t('Agenda pro')}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const Edition2020Home = React.memo(Edition2020HomeComponent)
