export const AlertIcon = ({ fill = '#273BF3' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9654 24C18.5702 24 23.9307 18.624 23.9307 12C23.9307 5.376 18.5702 -4.69985e-07 11.9654 -1.04907e-06C5.36065 -1.62816e-06 0.000230505 5.376 0.000229928 12C0.00022935 18.624 5.36065 24 11.9654 24ZM10.7699 5.99996L13.1629 5.99996L13.1629 8.39996L10.7699 8.39996L10.7699 5.99996ZM13.1629 10.8L10.7699 10.8L10.7699 18L13.1629 18L13.1629 10.8Z"
        fill={fill}
      />
    </svg>
  )
}
