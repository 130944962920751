import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import MeetingItem from 'NewVersion/components/Meeting/MeetingItem'
import { Filter } from 'NewVersion/components/UI/Filters/Filter'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { CalendarAccpetIcon } from 'NewVersion/icons/CalendarAcceptIcon'
import { CalendarDeleteIcon } from 'NewVersion/icons/CalendarDeleteIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/HomePage.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getCurrentJWToken, getCurrentLanguage, getEmailFromToken } from 'utils/utils'

const modalRestart = {
  open: false,
  icon: null,
  text: null,
  handleOk: null,
}

function QuickMeetingsPage() {
  const { t, lang } = useTranslation()
  const { edition, currentEdition, loading: loadingEdition } = useEdition()
  const currentLanguage = getCurrentLanguage()
  const [searchParams] = useSearchParams()
  const [submitModalProps, setsubmitModalProps] = useState(modalRestart)
  const [meetingsFilteredData, setMeetingsFilteredData] = useState(null)
  const [meetingsFormattedData, setMeetingsFormattedData] = useState(null)
  const [itemsTotal, setItemsTotal] = useState(0)
  const [showingItems, setShowingItems] = useState(0)
  const [tab, setTab] = useState(0)

  const { data: { items: acreditation } = [], isLoading: isLoadingAcreditation } = useGuillotina({
    path: `${edition}/@search?type_name=AcreditacioProfessional&owners=${getEmailFromToken()}&correu=${getEmailFromToken()}&b_start=0&b_size=1`,
  })

  const { data: { items: meetings } = [] } = useGuillotina({
    path: `${edition}/@search?type_name=ReunioRapida&b_start=0&b_size=500`,
  })

  const { data: { items: meetingTimes } = [] } = useGuillotina({
    path: `${edition}/@search?type_name=FranjaReunioRapida&_sort_asc=data_inici&b_start=0&b_size=500`,
  })

  const { data: { items: vocabularyAmbitsReunionsRapides } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/ambits_reunions_rapides`,
    useStatic: true,
  })

  useEffect(() => {
    const newMeetingsFormattedData = {}
    let newItemsTotal = 0
    if (meetings && meetingTimes && meetings.length > 0 && meetingTimes.length > 0) {
      meetingTimes.forEach((meetingTime) => {
        const dataMeet = meetingTime.data_inici.split('T')[0]
        if (!newMeetingsFormattedData.hasOwnProperty(dataMeet)) {
          newMeetingsFormattedData[dataMeet] = {}
        }
        const meetUUID = meetingTime.parent_uuid
        if (!newMeetingsFormattedData[dataMeet].hasOwnProperty(meetUUID)) {
          const meetFound = meetings.find((meet) => meet['@uid'] === meetUUID)
          if (meetFound) {
            newItemsTotal = newItemsTotal + 1
            newMeetingsFormattedData[dataMeet][meetUUID] = { meet: meetFound, meetTimes: {} }
          } else {
            return
          }
        }
        newMeetingsFormattedData[dataMeet][meetUUID]['meetTimes'] = {
          ...newMeetingsFormattedData[dataMeet][meetUUID]['meetTimes'],
          [meetingTime.path]: meetingTime,
        }
      })
    }
    setItemsTotal(newItemsTotal)
    setMeetingsFormattedData(newMeetingsFormattedData)
    setShowingItems(newItemsTotal)
    setMeetingsFilteredData(newMeetingsFormattedData)
  }, [meetings, meetingTimes])

  useEffect(() => {
    if (meetingsFormattedData) {
      if (searchParams && searchParams.get('ambit') && searchParams.get('ambit') !== 'all') {
        const newMeetingsFilteredData = {}
        let newShowingItems = 0
        const ambitArray = searchParams.get('ambit').split(',')
        Object.keys(meetingsFormattedData).forEach((keyDay) => {
          Object.keys(meetingsFormattedData[keyDay]).forEach((keyMeet) => {
            if (
              meetingsFormattedData[keyDay][keyMeet]['meet'].ambits.some((r) =>
                ambitArray.includes(r)
              )
            ) {
              if (!newMeetingsFilteredData[keyDay]) {
                newMeetingsFilteredData[keyDay] = {}
              }
              newShowingItems = newShowingItems + 1
              newMeetingsFilteredData[keyDay][keyMeet] = {
                ...meetingsFormattedData[keyDay][keyMeet],
              }
            }
          })
        })
        setMeetingsFilteredData(newMeetingsFilteredData)
        setShowingItems(newShowingItems)
      } else {
        setMeetingsFilteredData(meetingsFormattedData)
        setShowingItems(itemsTotal)
      }
    }
  }, [searchParams])

  const modifyAcreditat = (dateString, keyMeet, path, acreditationID) => {
    const newmeetingsFilteredData = { ...meetingsFilteredData }
    newmeetingsFilteredData[dateString][keyMeet]['meetTimes'][path] = {
      ...newmeetingsFilteredData[dateString][keyMeet]['meetTimes'][path],
      acreditat: acreditationID,
    }
    setMeetingsFilteredData(newmeetingsFilteredData)

    const newmeetingsFormattedData = { ...meetingsFormattedData }
    newmeetingsFormattedData[dateString][keyMeet]['meetTimes'][path] = {
      ...newmeetingsFormattedData[dateString][keyMeet]['meetTimes'][path],
      acreditat: acreditationID,
    }
    setMeetingsFormattedData(newmeetingsFormattedData)
  }

  const reserveMeet = (dateString, keyMeet, path) => {
    setsubmitModalProps({ ...submitModalProps, handleOk: null, handleCancel: null })
    const token = getCurrentJWToken()
    fetchGuillotina({
      path: path,
      method: 'PATCH',
      data: {
        acreditat: acreditation[0].id,
      },
      token: token,
      checkError: false,
    })
      .then((respone) => {
        if (respone.error_code) {
          if (respone.error_code === 'entity-reached-limit') {
            setsubmitModalProps({
              open: true,
              icon: <AlertIcon />,
              title: t('limit_exceeded'),
              text: t('meeting_limit_exceeded'),
              handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
              handleCancel: null,
              textOk: t('ok'),
            })
          } else {
            setsubmitModalProps({
              open: true,
              icon: <CalendarAccpetIcon />,
              title: t('confirm_reunio_rapida_error'),
              text: t('confirm_reunio_rapida_error_desc'),
              handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
              handleCancel: null,
              textOk: t('ok'),
              textCancel: null,
            })
          }
        } else {
          setsubmitModalProps({
            open: true,
            icon: <CalendarAccpetIcon />,
            title: t('confirm_reunio_rapida_result'),
            text: t('confirm_reunio_rapida_result_desc'),
            handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
            handleCancel: null,
            textOk: t('ok'),
            textCancel: null,
          })
          modifyAcreditat(dateString, keyMeet, path, acreditation[0].id)
        }
      })
      .catch((error) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarAccpetIcon />,
          title: t('confirm_reunio_rapida_error'),
          text: t('confirm_reunio_rapida_error_desc'),
          handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
          handleCancel: null,
          textOk: t('ok'),
          textCancel: null,
        })
      })
  }

  const cancelMeet = (dateString, keyMeet, path) => {
    setsubmitModalProps({ ...submitModalProps, handleOk: null, handleCancel: null })
    const token = getCurrentJWToken()
    fetchGuillotina({
      path: path,
      method: 'PATCH',
      data: {
        acreditat: null,
      },
      token: token,
    })
      .then((respones) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarDeleteIcon />,
          title: t('cancel_reunio_rapida_result'),
          text: t('cancel_reunio_rapida_result_desc'),
          handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
          handleCancel: null,
          textOk: t('ok'),
          textCancel: null,
        })
        modifyAcreditat(dateString, keyMeet, path, null)
      })
      .catch((error) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarDeleteIcon />,
          title: t('cancel_reunio_rapida_error'),
          text: t('cancel_reunio_rapida_error_desc'),
          handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
          handleCancel: null,
          textOk: t('ok'),
          textCancel: null,
        })
      })
  }

  function CustomTabPanel(props) {
    const { children, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`meet-tabpanel-${index}`}
        aria-labelledby={`meet-tabpanel-${index}`}
        {...other}
      >
        {tab === index && <Box>{children}</Box>}
      </div>
    )
  }

  const filtersDefiniton = useMemo(() => {
    const filters = []
    if (vocabularyAmbitsReunionsRapides) {
      filters.push({
        key: 'ambit',
        label: null,
        sorted: false,
        data: [
          { value: 'all', label: t('show_all'), default: true },
          ...vocabularyAmbitsReunionsRapides.map((item) => ({
            value: item['token'],
            label: item['title'][`lang-${currentLanguage}`] || item['title']['default'],
          })),
        ],
      })
    }
    return filters
  }, [vocabularyAmbitsReunionsRapides, currentLanguage])

  const renderContent = () => {
    if (!meetingsFilteredData || isLoadingAcreditation || loadingEdition) {
      return <LoadingComponent />
    }

    if ((acreditation ?? []).length === 0) {
      return (
        <Box textAlign={'center'} my={3}>
          {t('no_acreditation_active_to_meeting')}
        </Box>
      )
    }

    if (currentEdition && !currentEdition.canRegisterToQuickMeetings()) {
      return (
        <Box textAlign={'center'} my={3}>
          {t('can_not_register_to_quick_meetings')}
        </Box>
      )
    }

    if (Object.keys(meetingsFilteredData).length === 0) {
      return (
        <Box textAlign={'center'} my={3}>
          {t('no_quick_meetings')}
        </Box>
      )
    }

    return (
      <>
        <Box display={'flex'} flexWrap={'wrap-reverse'}>
          <Tabs
            value={tab}
            classes={{ root: 'meet-tabs' }}
            onChange={(e, newTab) => {
              setTab(newTab)
            }}
            aria-label="Meet date tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {Object.keys(meetingsFilteredData).map((dateString, index) => {
              const dateLabel = new Intl.DateTimeFormat(lang, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }).format(new Date(dateString))
              return (
                <Tab
                  classes={{ root: 'meet-tab' }}
                  label={dateLabel}
                  key={`meet-tab-${index}`}
                  id={`meet-tab-${index}`}
                  aria-controls={`meet-tabpanel-${index}`}
                />
              )
            })}
          </Tabs>
          <Box flex={1} className={'hide-sm'} />
          <Box display={'flex'} gap={2}>
            <Box className="meeting-legend free-time">
              <Box className="square-color" />
              {t('free_time')}
            </Box>
            <Box className="meeting-legend busy-schedule">
              <Box className="square-color" />
              {t('busy_schedule')}
            </Box>
            <Box className="meeting-legend scheduled-schedule">
              <Box className="square-color" />
              {t('scheduled_schedule')}
            </Box>
          </Box>
        </Box>

        <Box className="meeting-container">
          {Object.keys(meetingsFilteredData).map((dateString, index) => {
            return (
              <CustomTabPanel key={`meet-tabpanel-${index}`} index={index}>
                {Object.keys(meetingsFilteredData[dateString]).map((keyMeet) => (
                  <MeetingItem
                    key={`meeting-${meetingsFilteredData[dateString][keyMeet].meet['uuid']}`}
                    meeting={meetingsFilteredData[dateString][keyMeet]}
                    acreditationActId={acreditation && acreditation[0] ? acreditation[0].id : ''}
                    onClick={(path, type) => {
                      setsubmitModalProps({
                        open: true,
                        icon: type === 'cancel' ? <CalendarDeleteIcon /> : <CalendarAccpetIcon />,
                        title:
                          type === 'cancel'
                            ? t('cancel_reunio_rapida')
                            : t('confirm_reunio_rapida'),
                        handleOk: () =>
                          type === 'cancel'
                            ? cancelMeet(dateString, keyMeet, path)
                            : reserveMeet(dateString, keyMeet, path),
                        handleCancel: () => {
                          setsubmitModalProps({ ...submitModalProps, open: false })
                        },
                        textOk: t('si'),
                        textCancel: t('no'),
                      })
                    }}
                  />
                ))}
              </CustomTabPanel>
            )
          })}
        </Box>
      </>
    )
  }

  return (
    <Box className="page-wrapper">
      <Box maxWidth={'600px'}>
        <h1>{t('reunions_rapides')}</h1>
        <p>{t('reunions_rapides_desc', { edition: edition })}</p>
      </Box>
      {currentEdition && currentEdition.canRegisterToQuickMeetings() && (
        <Box mb={3}>
          <Filter
            itemsTotal={itemsTotal || 0}
            showingItems={showingItems}
            filtersDefiniton={filtersDefiniton}
            filterButtonText={t('Filtrar per àmbit')}
          />
        </Box>
      )}

      {renderContent()}

      <ModalInfo
        open={submitModalProps.open}
        setOpen={() => {
          setsubmitModalProps({ ...submitModalProps, open: false })
        }}
        title={submitModalProps.title}
        text={submitModalProps.text}
        handleOk={submitModalProps.handleOk}
        handleCancel={submitModalProps.handleCancel}
        icon={submitModalProps.icon ? submitModalProps.icon : null}
        textOk={submitModalProps.textOk ? submitModalProps.textOk : null}
        textCancel={submitModalProps.textCancel ? submitModalProps.textCancel : null}
      ></ModalInfo>
    </Box>
  )
}

export default QuickMeetingsPage
