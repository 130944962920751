import { SelectInputField as UISelectInputField } from '../UI/Selector/SelectInputField'

export const SelectInputField = ({
  label,
  icon,
  type,
  value,
  dataTest,
  description,
  disabled,
  error,
  name,
  callback,
  onBlur,
  required,
  isSearchable,
  options,
  isMulti,
  onChange,
  onClear,
}) => {
  const handleChange = (value) => {
    // This is going to call setFieldValue and manually update values.id if there's any
    onChange(name, value)
    callback && callback(value, name)
  }
  return (
    <UISelectInputField
      label={label}
      icon={icon}
      items={options}
      type={type}
      value={value ? value : isMulti ? [] : ''}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      isMulti={isMulti}
      searchable={isSearchable}
      error={error}
      name={name}
      required={required}
      description={description}
      onClear={onClear}
      dataTest={dataTest}
    />
  )
}
