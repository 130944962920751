import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import 'typeface-roboto'
import App from './App'
import './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

Sentry.init({
  dsn: 'https://c12135ffb41f47f5a38cd78fca61dc58@o1412931.ingest.sentry.io/4505240628822016',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) si usas TypeScript
root.render(<App />)

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      console.log('On Update service worker')
      if (
        window.confirm(
          "S'ha detectat una nova versió, actualiza la pàgina per actualitzar l'aplicació"
        )
      ) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      }
    }
  },
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(configuration)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.msg === 'notificat-xat') {
      localStorage.setItem('xatNOW', true)
      // add class
      const element = document.getElementById('msgBoxID')
      element.classList.add('notificated')
    } else if (event.data.msg === 'notificat-broadcast') {
      localStorage.setItem('broadcastNOW', true)
      // add classnotificationBoxID
      const notificationElement = document.getElementById('notificationBoxID')
      notificationElement.classList.add('notificated')
      notificationElement.classList.add('blue')
    }
  })
}
