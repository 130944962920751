import i18n from 'i18next'

// Required error string. Used when some form field is required.
function requiredError() {
  return i18n.t('Aquest camp és obligatori')
}

// Format error string. Used when some form field is not well formatted.
function formatError() {
  return i18n.t('Format invàlid')
}

// Max size error
function maxSizeError() {
  return i18n.t('max_size_error')
}

export { requiredError, formatError, maxSizeError }
