import { useState, useEffect } from 'react'
import http from './utils/http'
// the function to call the push server: https://github.com/Spyna/push-notification-demo/blob/master/front-end-react/src/utils/http.js
import axios from 'axios'
import { getEmailFromToken, getCurrentJWToken } from './utils/utils'

import {
  isPushNotificationSupported,
  askUserPermission,
  createNotificationSubscription,
  getUserSubscription,
} from './push-notifications'
// import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported()
// first thing to do: check if the push notifications are supported by the browser

export default function usePushNotifications() {
  // to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  // We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState(null)
  // to manage the use push notification subscription
  const [pushServerSubscriptionId] = useState()
  // to manage the push server subscription
  const [error, setError] = useState(null)
  // to manage errors
  const [loading, setLoading] = useState(true)
  // to manage async actions

  // //Cascade effect
  // useEffect(() => {
  //   if(userConsent){//FIRST TIME USER GRANTS CONSENT, WE CREATE PUSH NOTITICATION SUBSCRIPTION
  //     console.log("creating push");
  //     onClickSusbribeToPushNotification()
  //   }
  // }, [userConsent]);

  // useEffect(() => {
  //   if(userSubscription && userConsent){//FIRST TIME WE CREATE PUSH NOTITICATION SUBSCRIPTION, NOW WE SEND IT
  //     console.log("sending push");
  //     onClickSendSubscriptionToPushServer()
  //   }
  // }, [userSubscription]);

  useEffect(() => {
    setLoading(true)
    setError(false)
    const getExixtingSubscription = async () => {
      const existingSubscription = await getUserSubscription()
      setUserSubscription(existingSubscription)
      setLoading(false)
    }
    getExixtingSubscription()
  }, [])
  // Retrieve if there is any push notification subscription for the registered service worker
  // this use effect runs only in the first render

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  const onClickAskUserPermission = () => {
    setLoading(true)
    setError(false)
    askUserPermission().then((consent) => {
      if (consent !== 'granted') {
        setError({
          name: 'Consent denied',
          message: 'You denied the consent to receive notifications',
          code: 0,
        })
      }
      setLoading(false)
    })
  }
  //

  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const onClickSusbribeToPushNotification = () => {
    setLoading(true)
    setError(false)
    createNotificationSubscription()
      .then(function (subscrition) {
        console.log(subscrition)
        console.log('worked')
        // setUserSubscription(subscrition);
        onClickSendSubscriptionToPushServer(subscrition)
        setLoading(false)
      })
      .catch((err) => {
        console.error(
          "Couldn't create the notification subscription",
          err,
          'name:',
          err.name,
          'message:',
          err.message,
          'code:',
          err.code
        )
        setError(err)
        setLoading(false)
      })
  }

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  const onClickSendSubscriptionToPushServer = (subscrition) => {
    setLoading(true)
    setError(false)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_GUILLOTINA_DB}` + getEmailFromToken(getCurrentJWToken()),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getCurrentJWToken()}`,
      },
    })
      .then(function (response) {
        axios({
          method: 'PATCH',
          url: `${process.env.REACT_APP_GUILLOTINA_DB}` + getEmailFromToken(getCurrentJWToken()),
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${getCurrentJWToken()}`,
          },
          data: {
            subscripcions: {
              op: 'assign',
              value: {
                key: subscrition.endpoint,
                value: subscrition,
              },
            },
          },
        })
          .then(function (response) {
            console.log('subscribed')
          })
          .catch(function (response) {
            console.log(response)
            console.log('failed part, proceding')
          })
      })
      .catch(function (response) {
        const obj = {}
        obj[subscrition.endpoint] = subscrition
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_GUILLOTINA_DB}@creaUsuari/`,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${getCurrentJWToken()}`,
          },
          data: {
            subscripcions: obj,
            preferits: [],
          },
        })
          .then(function (response) {
            console.log('worked')
          })
          .catch(function (response) {
            console.log('failed part, proceding')
          })
      })
  }

  /**
   * define a click handler that requests the push server to send a notification, passing the id of the saved subscription
   */
  const onClickSendNotification = async () => {
    setLoading(true)
    setError(false)
    await http.get(`/subscription/${pushServerSubscriptionId}`).catch((err) => {
      setLoading(false)
      setError(err)
    })
    setLoading(false)
  }

  /**
   * returns all the stuff needed by a Component
   */
  return {
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendNotification,
    pushNotificationSupported,
    userSubscription,
    error,
    loading,
  }
}
