import { Box } from '@mui/system'
import { UploadFileIcon } from 'NewVersion/icons/UploadFileIcon'
import 'NewVersion/styles/UI/FileInputField.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadImageGuillotina } from 'utils/apiCalls'

export const FileInputField = ({
  label = '',
  description = '',
  value = '',
  error = '',
  required = false,
  name,
  modified = false,
  canDownload = true,
  onDelete = null,
  path,
  disabled,
  dataTest,
  color = 'primary',
  variant = '',
  ...rest
}) => {
  const { t } = useTranslation()
  const [formatError, setFormatError] = useState(false)
  return (
    <div className="file-form-element">
      {label && (
        <label>
          <span>
            {label}
            <span className="asterisk">{required ? '*' : ''}</span>
          </span>
          {description && <span className="description">{`(${description})`}</span>}
        </label>
      )}{' '}
      {!disabled && (
        <div>
          <input
            data-test={dataTest}
            id={`upload-photo_${name}`}
            type="file"
            name={name}
            accept={name == 'imatge_companyia2' || name == 'imatge_companyia' ? 'image/*' : '*'}
            style={{ display: 'none' }}
            {...rest}
            onChange={(e) => {
              setFormatError(false)
              const selectedFile = e.target.files[0]
              if (name == 'imatge_companyia2' || name == 'imatge_companyia') {
                console.log(selectedFile, selectedFile.type.includes('image/'))
                if (selectedFile.type.includes('image/')) {
                  rest.onChange(e)
                } else {
                  setFormatError(true)
                }
              } else {
                rest.onChange(e)
              }
            }}
          />
          <Box display={'flex'} gap={3} alignItems={'center'} flexWrap={'wrap'}>
            <button
              type="button"
              className={`add-file-button ${color} ${variant}`}
              data-test={dataTest}
              onClick={() => document.getElementById(`upload-photo_${name}`).click()}
            >
              <UploadFileIcon fill={variant != 'outlined' ? 'white' : undefined} />{' '}
              {t('select_file')}
            </button>
            {value && (
              <span className="Form-element-span-file">{value.name || value.filename}</span>
            )}
          </Box>
          {formatError && (
            <div style={{ marginTop: '10px' }} className="error">
              {t('fileIsNotImage')}
            </div>
          )}
          {error && (
            <div style={{ color: 'red', marginTop: '10px' }} data-test={`errorTest_${dataTest}`}>
              {error}
            </div>
          )}
        </div>
      )}
      <Box display={'flex'} gap={3} alignItems={'center'}>
        {canDownload && value && !modified && (
          <div
            className="cursor-pointer"
            onClick={() => {
              downloadImageGuillotina(path, name).then((data) => {
                const a = document.createElement('a')
                a.href = data
                a.download = value.name || value.filename
                a.click()
              })
            }}
          >
            {`${t('download')}${value && disabled ? ` --- ${value.name || value.filename}` : ''}`}
          </div>
        )}
        {value && !disabled && (
          <div
            className="cursor-pointer color-error"
            onClick={() => {
              document.getElementById(`upload-photo_${name}`).value = ''
              onDelete()
              setFormatError(false)
            }}
          >
            {t('delete')}
          </div>
        )}
      </Box>
    </div>
  )
}
