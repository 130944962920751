import { Box } from '@mui/system'
import { ArrowDropDownIcon } from 'NewVersion/icons/ArrowDropDownIcon'
import { FilterIcon } from 'NewVersion/icons/FilterIcon'
import { GridViewIcon } from 'NewVersion/icons/GridViewIcon'
import { ListViewIcon } from 'NewVersion/icons/ListViewIcon'
import 'NewVersion/styles/UI/Filter.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Button from '../Buttons/Button'
import FilterButton from './FilterButton'

export const VIEW_TYPE_KEY = 'view-mode'
export const VIEW_TYPE_GRID = 'grid'
export const VIEW_TYPE_LIST = 'list'

export const Filter = ({
  showingItems,
  itemsTotal,
  filtersDefiniton,
  filterButtonText = null,
  showTypeView = true,
  onlyShowTypeView = false,
}) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = useState(false)

  const renderFilter = (key, filter, unique) => {
    let isSelected = false
    if (searchParams.get(key) && searchParams.get(key) !== '') {
      isSelected = searchParams.get(key).split(',').includes(filter.value)
    } else {
      isSelected = filter.default
    }

    return (
      <FilterButton
        key={`filter-button-${key}-${filter.value}`}
        isSelected={isSelected}
        dataTest={
          key === 'professional_ambit'
            ? filter.value === 'all'
              ? filter.value
              : 'reunions_rapides'
            : filter.value
        }
        onClick={() => {
          let newValue = []
          if (filter.value === 'all') {
            newValue = ['all']
          } else {
            if (unique) {
              if (!isSelected) {
                newValue = [filter.value]
              } else {
                newValue = ['all']
              }
            } else {
              if (!isSelected) {
                newValue = searchParams.get(key) ? searchParams.get(key).split(',') : []
                newValue.push(filter.value)
                newValue = newValue.filter((item) => item !== 'all')
              } else {
                newValue = searchParams.get(key).split(',')
                newValue = newValue.filter((item) => item !== filter.value && item !== 'all')
              }
            }
          }
          searchParams.set(key, newValue)
          searchParams.set('page', 1)
          setSearchParams(searchParams)
        }}
      >
        {filter.label}
      </FilterButton>
    )
  }

  const renderFilterRow = (filterRow) => {
    const items = filterRow.data
    if (filterRow.sorted) {
      items.sort((a, b) => a - b)
    }

    return (
      <Box key={`filter-row-${filterRow.key}`} mb={3}>
        <div className="title">{filterRow.label ? filterRow.label : <br />}</div>
        <Box display={'flex'} gap={2} flexWrap={'wrap'} dataTest={filterRow.label}>
          {items.map((filter) => renderFilter(filterRow.key, filter, filterRow.unique ?? false))}
        </Box>
      </Box>
    )
  }

  return (
    <div className="filterContainer">
      <Box className={'filter-acordion-header'}>
        {!onlyShowTypeView && (
          <>
            <Button
              size="small"
              color="light-grey"
              variant="filled"
              icon={<FilterIcon />}
              iconEnd={<ArrowDropDownIcon style={open ? { transform: 'rotate(180deg)' } : null} />}
              onClick={() => {
                setOpen(!open)
              }}
              dataTest={'filterbtn'}
              ariaLabel={`${open ? 'Colse' : 'Open'} filters`}
            >
              {filterButtonText ? filterButtonText : t('filtrar')}
            </Button>
          </>
        )}

        <div className={'filter-info'}>
          <span className={'hide-sm'}>
            {!onlyShowTypeView &&
              t('showing_of_total', { showing: showingItems ?? 0, total: itemsTotal ?? 0 })}
          </span>
        </div>

        {showTypeView && (
          <Box className={'filter-acordion-header-select-view-container'}>
            <button
              aria-label="View format grid"
              data-test="gridViewBtn"
              className={`filter-acordion-header-select-view-button ${
                searchParams.get(VIEW_TYPE_KEY) === VIEW_TYPE_GRID ? 'active' : ''
              }`}
              onClick={() => {
                searchParams.set(VIEW_TYPE_KEY, VIEW_TYPE_GRID)
                searchParams.set('page', 1)
                setSearchParams(searchParams)
              }}
            >
              <GridViewIcon />
            </button>
            <button
              aria-label="View format list"
              data-test="listViewBtn"
              className={`filter-acordion-header-select-view-button ${
                searchParams.get(VIEW_TYPE_KEY) !== VIEW_TYPE_GRID ? 'active' : ''
              }`}
              onClick={() => {
                searchParams.set(VIEW_TYPE_KEY, VIEW_TYPE_LIST)
                searchParams.set('page', 1)
                setSearchParams(searchParams)
              }}
            >
              <ListViewIcon />
            </button>
          </Box>
        )}
      </Box>
      {!onlyShowTypeView && (
        <>
          <div className={`filter-acordion-body ${open && 'open'}`}>
            <div className="filter-acordion-body-container">
              {filtersDefiniton.map((filterRow) => renderFilterRow(filterRow))}
            </div>
          </div>
          <Box mt={2} className={'filter-info display-sm'}>
            {t('showing_of_total', { showing: showingItems ?? 0, total: itemsTotal ?? 0 })}
          </Box>
        </>
      )}
    </div>
  )
}
