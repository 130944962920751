import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { ViewIcon } from 'NewVersion/icons/ViewIcon'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const ProposalEntitiesListItemComponent = ({ entity }) => {
  const { t } = useTranslation()
  const { loading, currentEdition } = useEdition()

  const getChip = () => {
    return (
      <>
        {currentEdition.callIsFinished() ? (
          <Chips
            text={t('veure')}
            icon={<ViewIcon />}
            url={path.PROPOSALS_ENTITY_PATH.concat(`${entity.path}`)}
            dataTest={'viewChip'}
          ></Chips>
        ) : (
          <Chips
            text={t('editar')}
            icon={<EditIcon />}
            url={path.PROPOSALS_ENTITY_PATH.concat(`${entity.path}`)}
            dataTest={'editChip'}
          ></Chips>
        )}
      </>
    )
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <>
      {entity.tipus === 'representant' ? (
        <div className="proposalEntities-managerContainer">
          <div className="proposalItem-header">
            <p>{t('my_entity')}</p>
            {getChip()}
          </div>
          <div className="proposalEntities-content">
            <div>
              <div className="userImg">{entity.title.charAt(0)}</div>
            </div>
            <div className="proposalEntities-text">
              <p>
                {entity.title} ({entity.year})
              </p>
              <p>{entity.correu}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="proposalEntities-artistContainer">
          <div className="proposalEntities-content">
            <div>
              <div className="userImg">{entity.title.charAt(0)}</div>
            </div>
            <div className="proposalEntities-text">
              <p>{entity.title}</p>
              <p style={{ textTransform: 'capitalize' }}>{entity.tipus}</p>
            </div>
          </div>
          {getChip()}
        </div>
      )}
    </>
  )
}

export const ProposalEntitiesListItem = React.memo(ProposalEntitiesListItemComponent)
