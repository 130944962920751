export const FiraPasseigIcon = ({ fill = '#E90C1B' }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill={fill} />
    <g clipPath="url(#clip0_3045_15137)">
      <path
        d="M29.9 16.89L28.85 12.52C28.63 11.62 27.85 11 26.94 11H13.05C12.15 11 11.36 11.63 11.15 12.52L10.1 16.89C9.86001 17.91 10.08 18.95 10.72 19.77C10.8 19.88 10.91 19.96 11 20.06V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V20.06C29.09 19.97 29.2 19.88 29.28 19.78C29.92 18.96 30.15 17.91 29.9 16.89ZM26.91 12.99L27.96 17.36C28.06 17.78 27.97 18.2 27.71 18.53C27.57 18.71 27.27 19 26.77 19C26.16 19 25.63 18.51 25.56 17.86L24.98 13L26.91 12.99ZM21 13H22.96L23.5 17.52C23.55 17.91 23.43 18.3 23.17 18.59C22.95 18.85 22.63 19 22.22 19C21.55 19 21 18.41 21 17.69V13ZM16.49 17.52L17.04 13H19V17.69C19 18.41 18.45 19 17.71 19C17.37 19 17.06 18.85 16.82 18.59C16.57 18.3 16.45 17.91 16.49 17.52ZM12.04 17.36L13.05 13H15.02L14.44 17.86C14.36 18.51 13.84 19 13.23 19C12.74 19 12.43 18.71 12.3 18.53C12.03 18.21 11.94 17.78 12.04 17.36ZM13 27V20.97C13.08 20.98 13.15 21 13.23 21C14.1 21 14.89 20.64 15.47 20.05C16.07 20.65 16.87 21 17.78 21C18.65 21 19.43 20.64 20.01 20.07C20.6 20.64 21.4 21 22.3 21C23.14 21 23.94 20.65 24.54 20.05C25.12 20.64 25.91 21 26.78 21C26.86 21 26.93 20.98 27.01 20.97V27H13Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3045_15137">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)
