const LinkedinIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9929 0C6.71414 0 0.000244141 6.71343 0.000244141 14.9916C0.000244141 23.2699 6.71414 29.9833 14.9929 29.9833C23.2717 29.9833 29.9856 23.2699 29.9856 14.9916C29.9856 6.71343 23.2886 0 14.9929 0ZM10.8535 22.479H7.74058V12.4846H10.8535V22.479ZM9.30548 11.1049C8.31269 11.1049 7.50501 10.2973 7.50501 9.30458C7.50501 8.31186 8.31269 7.50423 9.30548 7.50423C10.2983 7.50423 11.1059 8.31186 11.1059 9.30458C11.1059 10.2973 10.2983 11.1049 9.30548 11.1049ZM22.4977 22.479H19.3847V17.6164C19.3847 16.4555 19.3679 14.958 17.7694 14.958C16.1708 14.958 15.9016 16.2199 15.9016 17.5323V22.479H12.7886V12.4846H15.767V13.8475H15.8006C16.2213 13.0567 17.2309 12.2322 18.7453 12.2322C21.8919 12.2322 22.4809 14.3018 22.4809 16.9939V22.479H22.4977Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default LinkedinIcon
