import { requiredError } from 'Components/Forms/FormErrors'
import { LoadingComponent } from 'Components/HelperComponents'
import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { LLOTJA_WORKFLOW_STATE, OBJECT_TYPE_HAVE_RATE } from 'utils/constants'
import { getOptionFromValue } from 'utils/formUtils'
import { get } from 'utils/objectUtils'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'
import EditComponent from '../Fields/EditComponent'
import Button from '../UI/Buttons/Button'

const fieldsWithPrefixLabel = ['title', 'tipus']

export const StandForm = ({
  entity,
  submitAction,
  dataStand,
  vocabularies,
  currentEdition,
  readOnly = false,
  schema,
}) => {
  const { t } = useTranslation()
  const requiredFields = ['title', 'tipus']
  const orderFieldsForm = ['title', 'tipus']
  const [errorSubmit, setErrorSubmit] = useSetState({
    hasError: false,
    message: '',
  })

  const getInitialValues = useMemo(() => {
    const tipus = getOptionFromValue(get(vocabularies, 'estand', []), dataStand?.tipus)
    if (dataStand && vocabularies) {
      return {
        ...dataStand,
        tipus: tipus?.value,
      }
    }
    const result = {}
    requiredFields.forEach((item) => {
      result[item] = undefined
    })
    return result
  }, [dataStand, vocabularies])

  const handleFormError = (error) => {
    console.error(`Stand form error: ${error}`)
    if (error) {
      setErrorSubmit({
        hasError: true,
        message: `Error: ${t('error_create_or_update_ens')}`,
      })
    } else {
      setErrorSubmit({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const token = getCurrentJWToken()
    setSubmitting(true)
    const data = Object.assign({}, values, {
      '@type': 'Estand',
      tarifa: currentEdition.getCurrentRateByType(OBJECT_TYPE_HAVE_RATE.stand),
      idioma: entity.idioma,
    })
    data.tipus = data.tipus || data.tipus.value
    try {
      let path = ''
      if (dataStand) {
        path = cleanObjectIds(dataStand['@id'])
        await fetchGuillotina({ path: path, method: 'PATCH', data, token })
      } else {
        path = entity['@id'].replace(process.env.REACT_APP_GUILLOTINA, '')
        await fetchGuillotina({ path: path, method: 'POST', data, token })
      }

      if (submitAction) {
        submitAction()
      }
    } catch (error) {
      handleFormError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      requiredFields.forEach((key) => {
        if (!(key in values) || !values[key]) {
          errors[key] = requiredError()
        }
      })
      return errors
    },
  })

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `stand_form_${key}`
    return key
  }

  return (
    <div className="Stand">
      {errorSubmit.hasError && errorSubmit.message && (
        <AlertComponent type="danger"> {errorSubmit.message}</AlertComponent>
      )}

      <h4>{t('Estand')}</h4>

      <form className="Form" onSubmit={formik.handleSubmit} data-test="formTest">
        <div className="fragment">
          {schema &&
            orderFieldsForm.map((key) => {
              if (get(schema, `properties[${key}]`, null)) {
                const value = schema.properties[key]
                return (
                  <EditComponent
                    key={key}
                    name={key}
                    label={t(getLabel(key))}
                    schema={value}
                    description={/* value.description ? t(`${getLabel(key)}_description`) : */ null}
                    value={formik.values[key]?.value || formik.values[key]}
                    required={requiredFields.includes(key)}
                    onBlur={() => {
                      formik.setFieldTouched(key)
                    }}
                    onChange={(ev) => {
                      formik.setFieldValue(key, ev)
                      formik.validateForm()
                    }}
                    error={formik.touched[key] && formik.errors[key]}
                    dataTest={`inputFieldTest_${key}`}
                  />
                )
              }
            })}
        </div>

        {formik.isSubmitting && <LoadingComponent />}
        {!formik.isSubmitting && dataStand?.review_state !== LLOTJA_WORKFLOW_STATE.published && (
          <div className="footer-buttons">
            <Button
              type="submit"
              data-test="btnEnviarFormTest"
              color="primary"
              disabled={formik.isSubmitting}
            >
              {t('Enviar')}
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
