import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { CalendarDeleteIcon } from 'NewVersion/icons/CalendarDeleteIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { ClockIcon } from 'NewVersion/icons/ClockIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrentJWToken } from 'utils/utils'
import { ModalInfo } from '../UI/Modals/Modal'
import { TableHead } from '../UI/Table/TableHead'
import { MeetingTableItem } from './MeetingTableItem'

const modalRestart = {
  open: false,
  icon: null,
  text: null,
  handleOk: null,
}

const MeetingTableComponent = ({ meetings = [], loading, refresh }) => {
  const [submitModalProps, setsubmitModalProps] = React.useState(modalRestart)

  const { t } = useTranslation()

  const tableHeader = [
    {
      id: 'data_inici',
      label: t('data'),
      sortable: true,
      icon: <CalendarIcon />,
    },
    {
      id: 'name_programer',
      label: t('name_programer'),
      icon: <UserIcon />,
    },
    {
      id: 'entitat',
      label: t('Entitat'),
      icon: <UserIcon />,
    },
    {
      id: 'inici',
      label: t('inici'),
      icon: <ClockIcon />,
    },
    {
      id: 'fi',
      label: t('fi'),
    },
    {
      id: 'actions',
    },
  ]

  const renderMeet = (item) => (
    <MeetingTableItem
      key={`meetTableItem-${item.id}`}
      meeting={item}
      removeMeet={(path) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarDeleteIcon />,
          title: t('cancel_reunio_rapida'),
          handleOk: () => cancelMeet(path),
          handleCancel: () => {
            setsubmitModalProps({ ...submitModalProps, open: false })
          },
          textOk: t('si'),
          textCancel: t('no'),
        })
      }}
    />
  )

  const cancelMeet = (path) => {
    setsubmitModalProps({ ...submitModalProps, handleOk: null, handleCancel: null })
    const token = getCurrentJWToken()
    fetchGuillotina({
      path: path,
      method: 'PATCH',
      data: {
        acreditat: null,
      },
      token: token,
    })
      .then((respones) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarDeleteIcon />,
          title: t('cancel_reunio_rapida_result'),
          text: t('cancel_reunio_rapida_result_desc'),
          handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
          handleCancel: null,
          textOk: t('ok'),
          textCancel: null,
        })
        refresh()
      })
      .catch((error) => {
        setsubmitModalProps({
          open: true,
          icon: <CalendarDeleteIcon />,
          title: t('cancel_reunio_rapida_error'),
          text: t('cancel_reunio_rapida_error_desc'),
          handleOk: () => setsubmitModalProps({ ...submitModalProps, open: false }),
          handleCancel: null,
          textOk: t('ok'),
          textCancel: null,
        })
      })
  }

  return (
    <>
      <TableContainer className="table-container">
        <Table aria-label="Meetings table" data-test={'table'}>
          <TableHead headCells={tableHeader} />
          <TableBody>
            {!loading && meetings.length > 0 ? (
              meetings.map((item) => renderMeet(item))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {loading ? <LoadingComponent /> : t('reunions_not_found')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="table-container-mobile">
        {!loading && meetings.length > 0 ? (
          meetings.map((item) => renderMeet(item))
        ) : (
          <Box>{loading ? <LoadingComponent /> : t('reunions_not_found')}</Box>
        )}
      </Box>
      <ModalInfo
        open={submitModalProps.open}
        setOpen={() => {
          setsubmitModalProps({ ...submitModalProps, open: false })
        }}
        title={submitModalProps.title}
        text={submitModalProps.text}
        handleOk={submitModalProps.handleOk}
        handleCancel={submitModalProps.handleCancel}
        icon={submitModalProps.icon ? submitModalProps.icon : null}
        textOk={submitModalProps.textOk ? submitModalProps.textOk : null}
        textCancel={submitModalProps.textCancel ? submitModalProps.textCancel : null}
      ></ModalInfo>
    </>
  )
}

export const MeetingTable = React.memo(MeetingTableComponent)
