import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import Chips from 'NewVersion/components/UI/Chips/Chips'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { FiraPasseigIcon } from 'NewVersion/icons/FiraPasseigIcon'
import * as path from 'Routes/Paths'
import { useNavigate } from 'react-router'

const AccountStandComponent = ({ entityPath, stand, dataCanido }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <div className="entityDetailItem__box_stand">
      <div className="entityDetailItem__box" key={stand['@id']}>
        <div className="justify-content-center">
          <div className="image-profile">
            <FiraPasseigIcon fill="#D60D1A" />
          </div>
        </div>
        <div className="entityDetailItem__box__item">
          <p className="body-bold">{stand.title}</p>
          <p className="body-bold">{t(stand.tipus)}</p>
          <p className="body-bold alert-danger">{t('Fira Mediterrània de Manresa')}</p>
        </div>
      </div>

      <div className="entityDetailItem__box__item">
        {dataCanido && dataCanido['guillotina.ModifyContent'] && (
          <Chips
            text={t('editar')}
            icon={<EditIcon />}
            dataTest={`btnEditStandTest_${stand.id}`}
            onClick={() =>
              navigate(`${path.ENTITY_PATH}${entityPath}/${path.STAND}/edit/${stand['@name']}`)
            }
          />
        )}
      </div>
    </div>
  )
}

export const AccountStand = memo(AccountStandComponent)
