import { useEffect, useLayoutEffect, useState } from 'react'
// RCE CSS
import Forum from '@mui/icons-material/Forum'
import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded'
import SendRounded from '@mui/icons-material/SendRounded'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import 'react-chat-elements/dist/main.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
function Xat(props) {
  const [text, textChange] = useState('')
  const { t } = useTranslation()

  useLayoutEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('input-mobile')
      if (element) {
        window.scrollTo({ behavior: 'smooth', top: element.offsetTop })
      }
    }, 1000)
  }, [props.forceRender])

  const onSubmit = (data, e) => {
    data.preventDefault()
    props.sendMessage(text)
    textChange('')
  }

  useEffect(() => {
    const objDiv = document.getElementById('xat-messages')
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight
    }
  }, [props.forceRender, props.currentXat])

  const goBack = () => {
    props.renderTarget([])
    props.renderXat([])
  }

  const renderTitle = () => {
    if (isMobile) {
      return (
        <div>
          <div onClick={goBack} className="go-back-container black">
            <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
            {t('Torna als teus missatges')}
          </div>
          <div>
            <div className="account-1">
              <b>{props.currentTarget.title}</b>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderForm = () => {
    if (isMobile) {
      return (
        <div id="input-mobile" className="form-container-mobile">
          <form onSubmit={onSubmit} className="form-msg">
            <div className="styled-input">
              <input
                name="text"
                value={text}
                onChange={(event, newValue) => textChange(event.currentTarget.value)}
              />
            </div>
            <button className="unstyled-button">
              <SendRounded style={{ fontSize: 20 }} />
            </button>
          </form>
        </div>
      )
    } else {
      return (
        <div className="form-container">
          <form onSubmit={onSubmit} className="form-msg">
            <div className="styled-input">
              <input
                name="text"
                value={text}
                onChange={(event, newValue) => textChange(event.currentTarget.value)}
              />
            </div>
            <button className="unstyled-button">
              <SendRounded style={{ fontSize: 20 }} />
            </button>
          </form>
        </div>
      )
    }
  }

  const getLastMessageHour = (timestamp) => {
    const time = timestamp
    const d = new Date(time)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const hour = d.getHours()
    return (
      <div className="date-small">
        {day}/{month} - {hour}:{(d.getMinutes() < 10 ? '0' : '') + d.getMinutes()}
      </div>
    )
  }

  const renderConversation = () => {
    if (props.currentXat.conversation && props.currentXat.conversation.length !== 0) {
      const convo = [...props.currentXat.conversation].reverse()
      return (
        <div className="xat-messages" id="xat-messages">
          {convo.map((element, key) => (
            <div key={key} className="bubble-container">
              <div
                data-animation-offset="3.0"
                className={
                  element.propietari !== props.currentTarget.UID
                    ? 'bubble-text right '
                    : 'bubble-text left '
                }
              >
                {getLastMessageHour(element.timestamp)}
                {element.contingut}
              </div>
            </div>
          ))}
          <div id="endOfChat"></div>
        </div>
      )
    } else {
      return (
        <div className="xat-messages empty" id="xat-messages">
          <Forum />
          {t('Enviali un missatge a')} {props.currentTarget.title}!
        </div>
      )
    }
  }
  if (props.isLoading) {
    return <LoadingComponent />
  } else if (props.currentTarget && props.currentTarget.length !== 0) {
    return (
      <div className="xat-container">
        {renderTitle()}
        {renderConversation()}
        {renderForm()}
      </div>
    )
  } else {
    return (
      <div className="xat-container">
        <div className="xat-messages empty" id="xat-messages">
          <Forum />
        </div>
      </div>
    )
  }
}
export default Xat
