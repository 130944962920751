export const TwitterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0008 0C5.37411 0 0 5.37374 0 12C0 18.6263 5.37411 24 12.0008 24C18.6276 24 24.0017 18.6263 24.0017 12C24.0017 5.37374 18.641 0 12.0008 0Z"
        fill="#5C5958"
      />
      <path
        d="M13.1509 11.088L17.6212 6.00342H16.562L12.6787 10.4174L9.57941 6.00342H6.00391L10.6917 12.6787L6.00391 18.0102H7.06307L11.1614 13.3478L14.4351 18.0102H18.0106L13.1509 11.088ZM7.44504 6.78473H9.07213L12.1228 11.0534L12.5965 11.7185L16.5615 17.2669H14.9344L7.44504 6.78473Z"
        fill="white"
      />
    </svg>
  )
}
