const YoutubeIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0581 12.1986L17.9884 15.0085L13.0581 17.8183V12.2154V12.1986ZM29.9859 15.0085C29.9859 23.2867 23.272 30.0001 14.9932 30.0001C6.71439 30.0001 0.000488281 23.2867 0.000488281 15.0085C0.000488281 6.73025 6.69756 0 14.9764 0C23.2551 0 29.969 6.71343 29.969 14.9916L29.9859 15.0085ZM24.4162 15.0085C24.4162 15.0085 24.4162 11.9125 24.0292 10.4487C23.8104 9.62426 23.171 8.98489 22.3633 8.76616C20.8994 8.36234 14.9932 8.36234 14.9932 8.36234C14.9932 8.36234 9.10379 8.36234 7.62303 8.76616C6.81535 8.98489 6.17593 9.62426 5.95718 10.4487C5.57017 11.9294 5.57016 15.0085 5.57016 15.0085C5.57016 15.0085 5.57017 18.1044 5.95718 19.5682C6.17593 20.3927 6.81535 21.032 7.62303 21.2508C9.08697 21.6546 14.9932 21.6546 14.9932 21.6546C14.9932 21.6546 20.8826 21.6546 22.3633 21.2508C23.171 21.032 23.8104 20.3927 24.0292 19.5682C24.4162 18.0876 24.4162 15.0085 24.4162 15.0085Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default YoutubeIcon
