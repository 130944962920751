export const GLOBAL_MEDIA_QUERIES = {
  small: '(max-width: 539px)',
  medium: '(min-width: 540px) and (max-width: 991px)',
  large: '(min-width: 992px) and (max-width: 1279px)',
  xlarge: '(min-width: 1280px) and (max-width: 1599px)',
  xxlarge: '(min-width: 1600px) and (max-width: 1919px)',
  xxxlarge: '(min-width: 1920px)',
}

export const LIMIT_ELEMENTS_GUILLOTINA = 50
export const PROPOSAL_WORKFLOW_STATE = {
  submitted: 'presentada',
  draft: 'esborrany',
}
export const LLOTJA_WORKFLOW_STATE = {
  pending: 'pendent',
  rejected: 'descartat',
  published: 'publicat',
}

export const PAYMENT_WORKFLOW_STATE = {
  private: 'privat',
  pending: 'pendent',
  paid: 'pagat',
}

export const PAYMENT_TYPE_LINES = {
  AMOUNT: 'AMOUNT',
  DISCOUNT: 'DISCOUNT',
}

export const GOOGLE_RECAPTCHAKEY = process.env.REACT_APP_GOOGLE_RECAPTCHAKEY

export const RATES = {
  standard: 'estandard',
  reduced: 'reduida',
  free: 'gratuita',
}

export const OBJECT_TYPE_HAVE_RATE = {
  ens: 'ens',
  stand: 'stand',
  service: 'service',
  ens_artist_or_manager: 'ens_artist_or_manager',
}

export const BACKEND_TYPE = {
  GUILLOTINA: 'guillotina',
  PLONE: 'plone',
}

export const SIZES_IMAGE = ['high', 'large', 'preview', 'mini', 'thumb', 'tile', 'icon']
export const ACCREDITATIONS_SIZES_IMAGE = ['preview', 'mini', 'thumb', 'tile', 'icon']

export const FIRA_EMAILS = [
  'programadors@firamediterrania.cat',
  'comunicacio@firamediterrania.cat',
  'artistes@firamediterrania.cat',
  'direccioartistica@firamediterrania.cat',
  'info@firamediterrania.cat',
]

export const AFIRMATIVE_VALUE = 'si'
export const NEGATIVE_VALUE = 'no'

export const PROCEDENCIES_PROFESSIONAL = ['catalunya', 'espanya', 'internacional']
