export const ListViewIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 14.6666H12V17.3333H28V14.6666ZM28 6.66658H12V9.33325H28V6.66658ZM28 22.6666H12V25.3333H28V22.6666ZM9.33333 21.3333H4V26.6666H9.33333V21.3333ZM9.33333 13.3333H4V18.6666H9.33333V13.3333ZM9.33333 5.33325H4V10.6666H9.33333V5.33325Z"
        fill="#5C5958"
      />
    </svg>
  )
}
