import React from 'react'
import { useEdition } from 'hooks/useEdition'

import { BACKEND_TYPE } from 'utils/constants'
import { FiraLivePage as FiraLivePagePlone } from './Plone/FiraLivePage'
import { FiraLivePage as FiraLivePageGuillotina } from './Guillotina/FiraLivePage'

export function FiraLivePage(props) {
  const { currentEdition } = useEdition()

  if (currentEdition.getBackendType() === BACKEND_TYPE.PLONE) {
    return <FiraLivePagePlone {...props} />
  } else {
    return <FiraLivePageGuillotina />
  }
}
export default FiraLivePage
