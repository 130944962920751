import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/system'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { FiraPasseigIcon } from 'NewVersion/icons/FiraPasseigIcon'
import * as path from 'Routes/Paths'
import { useNavigate } from 'react-router'

const AccountShopComponent = ({ entityPath, shop, dataCanido }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box className="entityDetailItem__box_shop" mr={2}>
      <div className="entityDetailItem__box">
        <div className="justify-content-center">
          <FiraPasseigIcon />
        </div>
        <div className="entityDetailItem__box__item">
          <p className="body-bold">{t(shop.tram)}</p>
          <p className="body-bold">{t(shop.tipus)}</p>
          <p className="body-bold alert-danger">{t('Fira Mediterrània de Manresa')}</p>
        </div>
      </div>
      <div className="entityDetailItem__box__item">
        {dataCanido && dataCanido['guillotina.ModifyContent'] && (
          <Chips
            text={'editar'}
            icon={<EditIcon />}
            dataTest={`btnEditShopTest_${shop.id}`}
            onClick={() =>
              navigate(`${path.ENTITY_PATH}${entityPath}/${path.SHOP}/edit/${shop['@name']}`)
            }
          />
        )}
      </div>
    </Box>
  )
}

export const AccountShop = memo(AccountShopComponent)
