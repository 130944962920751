import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ProfessionalActivity } from 'Components/Guillotina/ProfessionalActivityComponents/ProfessionalActivity'
import { AlertComponent } from 'Components/HelperComponents'
import * as path from 'Routes/Paths'
import { useLogout } from 'hooks'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useVocabularies } from 'hooks/useVocabularies'
import { useTranslation } from 'react-i18next'
import { fetchGuillotina } from 'utils/apiCalls'

export function ProfessionalActivityDetailPage(props) {
  const logout = useLogout()
  const { t } = useTranslation()
  const { programacioId } = useParams()
  const navigate = useNavigate()
  const { edition } = useEdition()
  const [isLoading, setIsLoading] = useState(false)
  const [currentProfessionalActivity, setCurrentProfessionalActivity] = useState(undefined)
  const [errorFetchData, setErrorFetchData] = useState(undefined)
  const vocabularies = useVocabularies([{ vocabulary: 'espai' }], false)
  const {
    favouiteIds,
    loading: loadingFavourites,
    addFavourite: addFavouriteHookFn,
    removeFavourite: removeFavouriteHookFn,
  } = useFavourites()

  useEffect(() => {
    ;(async () => {
      if (!isLoading && !currentProfessionalActivity && edition) {
        setIsLoading(true)
        try {
          const response = await fetchGuillotina(`${edition}/@search?uuid=${programacioId}`)
          if (response.data.items.length > 0) {
            const responseData = await fetchGuillotina(response.data.items[0]['path'])
            setCurrentProfessionalActivity(responseData.data)
          } else {
            logout()
          }
        } catch (error) {
          if (error.response.status === 401) {
            logout()
          } else if (error.response.status === 404) {
            navigate(path.ERROR_404_PATH)
          } else {
            setErrorFetchData(error.response)
            setCurrentProfessionalActivity({})
          }
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [isLoading, programacioId, currentProfessionalActivity, logout, edition])

  const addFavourite = (element) => {
    addFavouriteHookFn(element['@uid'], {
      category: 'Cerca',
      action: 'afegir programacio agenda',
      label: element['@uid'],
    })
  }

  const removeFavourite = (element) => {
    removeFavouriteHookFn(element['@uid'], {
      category: 'Cerca',
      action: 'treure programacio agenda',
      label: element['@uid'],
    })
  }

  if (errorFetchData) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }

  if (isLoading || loadingFavourites || !vocabularies.data || vocabularies.loading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <ProfessionalActivity
        removeFavourite={removeFavourite}
        addFavourite={addFavourite}
        favouiteIds={favouiteIds}
        currentProfessionalActivity={currentProfessionalActivity}
        vocabularies={vocabularies}
      />
    </div>
  )
}
