export const OKIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00003 11.17L1.83003 7L0.410034 8.41L6.00003 14L18 2L16.59 0.589996L6.00003 11.17Z"
        fill="#2B2A29"
      />
    </svg>
  )
}

export const OKIconBlue = ({ fill = '#273BF3' }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3024_14948)">
        <circle cx="28.0003" cy="28.0003" r="22.8333" fill={fill} stroke={fill} />
        <path
          d="M38.7103 17.6865L23.3337 33.0632L14.957 24.7099L11.667 27.9999L23.3337 39.6665L42.0003 20.9999L38.7103 17.6865Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3024_14948">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
