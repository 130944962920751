export const CalendarDeleteIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2524_9311)">
      <path
        d="M12 38C12 40.2 13.8 42 16 42H32C34.2 42 36 40.2 36 38V14H12V38ZM38 8H31L29 6H19L17 8H10V12H38V8Z"
        fill="#273BF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_2524_9311">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
