import React from 'react'
import { isMobile } from 'react-device-detect'
function Notification(props) {
  const renderTitle = () => {
    if (isMobile) {
      return (
        <div>
          {/* <div onClick={goBack} className="go-back-container black">
                        <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
                        {t('Torna als meus avisos')}
                    </div> */}
        </div>
      )
    }
  }

  const renderNotification = (data) => {
    if (props.currentNotification && props.currentNotification.length !== 0) {
      return (
        <div className="notification-messages" id="xat-messages">
          <div className="timestamp">
            {props.formatTimestamp(props.currentNotification.creation_date)}
          </div>
          <div className="title">{props.currentNotification.titol}</div>
          <div className="text">
            {htmlToReactParser.parse(props.currentNotification.cos.replace('\n', '<br>'))}
          </div>
        </div>
      )
    }
  }
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()
  if (props.currentNotification && props.currentNotification.length !== 0) {
    return (
      <div className="notification-containers">
        {renderTitle()}
        {renderNotification()}
      </div>
    )
  } else {
    return <div></div>
  }
}
export default Notification
