import { AlertComponent } from 'Components/HelperComponents'
import { AddAccreditationsForm } from 'NewVersion/components/Forms/AddAccreditationsForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { NewInscriptionIcon } from 'NewVersion/icons/NewInscriptionIcon'
import { ACCOUNT_PATH, REGISTER_ENTITY_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ENS_TYPES_OBJ, getEnsType } from 'utils/entityUtils'

const AddPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { edition } = useEdition()
  const [submitModal, setSubmitModal] = useState(false)
  const { entityId } = useParams()

  const {
    data: entity,
    loading: loadingEntity,
    error: entityError,
  } = useGuillotina({ path: `${edition}/${entityId}` })
  const ensType = getEnsType(entity?.type_name, entity?.tipus)

  return (
    <div className="page-wrapper">
      <GoBackButton onClick={() => navigate(ACCOUNT_PATH)} text={t('Tornar al perfil')} />
      <div onClick={() => navigate(REGISTER_ENTITY_PATH)} className="cursor-pointer">
        <h4>{t(ENS_TYPES_OBJ[ensType]?.titleForm, { year: edition })}</h4>
      </div>
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        handleOk={() => navigate(ACCOUNT_PATH)}
        title={t('accreditation_created')}
        icon={<NewInscriptionIcon />}
        closeAction={() => navigate(ACCOUNT_PATH)}
      />
      {entityError && <AlertComponent type="danger">{t('error_generic')}</AlertComponent>}
      {!entityError && (
        <AddAccreditationsForm
          ensType={ensType}
          entity={entity}
          loadingEntity={loadingEntity}
          submitAction={() => {
            setSubmitModal(true)
          }}
        />
      )}
    </div>
  )
}

export default AddPage
