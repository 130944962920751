export const SaveIcon = ({ fill = '#273BF3' }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2503_4339)">
        <path
          d="M39.6667 7H11.6667C9.07667 7 7 9.1 7 11.6667V44.3333C7 46.9 9.07667 49 11.6667 49H44.3333C46.9 49 49 46.9 49 44.3333V16.3333L39.6667 7ZM28 44.3333C24.1267 44.3333 21 41.2067 21 37.3333C21 33.46 24.1267 30.3333 28 30.3333C31.8733 30.3333 35 33.46 35 37.3333C35 41.2067 31.8733 44.3333 28 44.3333ZM35 21H11.6667V11.6667H35V21Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2503_4339">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
