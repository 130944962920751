export const MyMeetingsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.806 19.2L13.62 16.014L14.889 14.745L16.797 16.653L20.613 12.837L21.9 14.106L16.806 19.2ZM12 16.5C12 13.017 14.817 10.2 18.3 10.2C19.272 10.2 20.181 10.425 21 10.812V4.8C21 3.81 20.19 3 19.2 3H4.8C3.81 3 3 3.81 3 4.8V21L6.6 17.4H12C12 17.247 12.009 17.103 12.027 16.95C12.009 16.806 12 16.653 12 16.5Z"
        fill="#2B2A29"
      />
    </svg>
  )
}
