import React from 'react'
import { useEdition } from 'hooks/useEdition'

import { BACKEND_TYPE } from 'utils/constants'
import { XatPage as XatPagePlone } from './Plone/XatPage'
import { XatPage as XatPageGuillotina } from './Guillotina/XatPage'

function XatPage(props) {
  const { currentEdition } = useEdition()

  if (currentEdition.getBackendType() === BACKEND_TYPE.PLONE) {
    return <XatPagePlone {...props} />
  } else {
    return <XatPageGuillotina />
  }
}
export default XatPage
