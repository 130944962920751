import { LoadingComponent } from 'Components/HelperComponents'
import { ExpanMoreIcon } from 'NewVersion/icons/ExpanMoreIcon'
import 'NewVersion/styles/PreguntesFrequents.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'
import { getCurrentLanguage } from 'utils/utils'

function PreguntesFrequentsPage() {
  const { t } = useTranslation()

  const { edition } = useEdition()

  const { data, loading } = useGuillotina({
    path: `${edition}/faqs/@search?type_name=Faq`,
  })

  const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <div className={'accordion ' + (isOpen ? 'active' : 'disabled')}>
        <div
          className={'accordionHeader ' + (isOpen ? 'activeHeader' : '')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <h4>{title}</h4>
          <ExpanMoreIcon />
        </div>
        <div className="accordionPanel">
          <p>{children}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="preguntesFrequents">
      <div className="faqsHeader">
        <h1>{t('Preguntes_freqüents')}</h1>
      </div>
      <div className="faqsList">
        {loading && <LoadingComponent />}
        {(data?.items ?? []).map((faqEl) => {
          return (
            <>
              <div className="separator"></div>
              <Accordion
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: get(faqEl, `pregunta_${getCurrentLanguage()}`, '--'),
                    }}
                  />
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: get(faqEl, `resposta_${getCurrentLanguage()}`, '--'),
                  }}
                />
              </Accordion>
            </>
          )
        })}
        <div className="separator"></div>
      </div>
    </div>
  )
}

export default PreguntesFrequentsPage
