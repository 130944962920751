import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { MeetingBusyIcon } from 'NewVersion/icons/MeetingBusyIcon'
import { MeetingFreeIcon } from 'NewVersion/icons/MeetingFreeIcon'
import { MeetingSheduledIcon } from 'NewVersion/icons/MeetingSheduledIcon'
import { PointerIcon } from 'NewVersion/icons/PointerIcon'
import 'NewVersion/styles/Meeting.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'
import ProfileImage from '../UI/Image/ProfileImage'

function MeetingItem({ meeting, onClick, acreditationActId }) {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const currentLanguage = getCurrentLanguage()
  const meetingInfo = meeting.meet

  const { data: { items: vocabularyAmbitsReunionsRapides } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/ambits_reunions_rapides`,
    useStatic: true,
  })

  const { data: accreditationInfo } = useGuillotina({
    path: meetingInfo && meetingInfo.parent_uuid ? `@resolveuid/${meetingInfo.parent_uuid}` : null,
  })

  const parseTimeWithPad = (time) => time.toString().padStart(2, '0')
  const renderMeetTimeRow = (meetTime) => {
    const dateStart = new Date(meetTime.data_inici)
    const dateFi = new Date(meetTime.data_fi)
    const itsBusy = Boolean(meetTime.acreditat)
    const itsScheduled = itsBusy && meetTime.acreditat === acreditationActId
    return (
      <Box
        key={`meeting-item-time-${meetTime.uuid}`}
        className={`meeting-item-time ${itsScheduled ? 'scheduled' : itsBusy ? 'busy' : ''}`}
        onClick={() => {
          if (itsScheduled) {
            onClick(meetTime.path, 'cancel')
          } else if (!itsBusy) {
            onClick(meetTime.path, 'acept')
          }
        }}
        data-test={itsScheduled ? 'itemScheduledTest' : itsBusy ? 'itemBusyTest' : 'itemFreeTest'}
      >
        {itsScheduled ? (
          <MeetingSheduledIcon />
        ) : itsBusy ? (
          <MeetingBusyIcon />
        ) : (
          <MeetingFreeIcon />
        )}
        {`${parseTimeWithPad(dateStart.getHours())}:${parseTimeWithPad(
          dateStart.getMinutes()
        )} - ${parseTimeWithPad(dateFi.getHours())}:${parseTimeWithPad(dateFi.getMinutes())}`}
      </Box>
    )
  }

  const renderStyledName = (name) => {
    const captialize = (words) =>
      words
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ')
    return captialize(name.toLowerCase())
  }

  if (!accreditationInfo) {
    return <LoadingComponent />
  }

  return (
    <Box className="meeting-item">
      <Box className="meeting-item-content">
        <Box className="meeting-item-content-image-bloc">
          <Box>
            <ProfileImage
              pathImage={accreditationInfo['@id']}
              defaultLetter={accreditationInfo.nom.charAt(0)}
              hasImage={accreditationInfo && accreditationInfo.imatge}
              big={true}
            />
          </Box>
          <Box>
            <p>
              <strong>{accreditationInfo.ens_title}</strong>
            </p>
            <p className="meeting-item-content-image-bloc-subtitle">
              {renderStyledName(accreditationInfo.title)}
            </p>
          </Box>
        </Box>
        <Box className="meeting-item-content-area-bloc" mt={1}>
          <Box mb={2}>
            <p
              dangerouslySetInnerHTML={{
                __html: meetingInfo[`short_description_${currentLanguage}`],
              }}
            />
          </Box>
          <p>
            {accreditationInfo.poblacio && t(accreditationInfo.poblacio)}
            {accreditationInfo.poblacio && accreditationInfo.comunitat_autonoma ? (
              <>&sbquo;&nbsp;</>
            ) : (
              <>&nbsp;&nbsp;</>
            )}
            {accreditationInfo.comunitat_autonoma && (
              <>{t(accreditationInfo.comunitat_autonoma)}&sbquo;&nbsp;</>
            )}
            ({t(accreditationInfo.pais)})
          </p>
        </Box>
        {meetingInfo.ambits && meetingInfo.ambits.length > 0 && (
          <>
            <Box className="meeting-item-content-divider" />
            <Box className="meeting-item-content-area-bloc">
              <p>
                <strong>{t('Àmbits')}</strong>
              </p>
              <Box className="meeting-item-content-area-bloc-wrapper">
                {meetingInfo.ambits.map((item) => (
                  <Box key={`ambit-meeting-${item}`}>
                    {getValueVocabularyDynamic(
                      vocabularyAmbitsReunionsRapides,
                      item,
                      currentLanguage
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
        {meetingInfo.website && (
          <>
            <Box className="meeting-item-content-divider" />
            <Box className="meeting-item-content-area-bloc">
              <a href={meetingInfo.website} target="_blank" rel="noreferrer">
                {meetingInfo.website}
              </a>
            </Box>
          </>
        )}
      </Box>
      <Box className="meeting-item-times" data-test="meetingItemsBox">
        <Box className="meeting-item-desc" display={'flex'} flexBasis={'100%'} gap={1}>
          <Box>
            <PointerIcon />
          </Box>
          <Box className="body-2">{t('click_for_meet')}</Box>
        </Box>
        {Object.values(meeting.meetTimes).map((meetTime) => renderMeetTimeRow(meetTime))}
      </Box>
    </Box>
  )
}
export default MeetingItem
