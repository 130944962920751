import { useSetState } from 'react-use'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchGuillotina } from 'utils/apiCalls'
import { useEdition } from './useEdition'

const vocabulariesData = {}

const getOptionsFormat = (items, t) => {
  const options = []
  items.forEach((item) => {
    options.push({ label: t(item.token), value: item.token })
  })

  return options
}

export function useVocabularies(allVocabularies, formatItems = true) {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const [vocabularies, setVocabularies] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })

  const getVocabulary = useCallback(
    async (vocabularyName) => {
      const url = `${edition}/@vocabularies/${vocabularyName}`
      try {
        if (url in vocabulariesData) {
          return vocabulariesData[url]
        }
        const result = await fetchGuillotina(url, {})
        if (formatItems) {
          const resultFormated = getOptionsFormat(result.data.items, t)
          vocabulariesData[url] = resultFormated
          return resultFormated
        } else {
          vocabulariesData[url] = result.data.items
          return result.data.items
        }
      } catch (err) {
        return {}
      }
    },
    [edition, t, formatItems]
  )

  useEffect(() => {
    ;(async () => {
      if (allVocabularies && vocabularies.data === undefined && !vocabularies.loading) {
        setVocabularies({ loading: true })
        const result = {}
        for (let index = 0; index < allVocabularies.length; index++) {
          const data = await getVocabulary(allVocabularies[index]['vocabulary'])
          result[allVocabularies[index]['vocabulary']] = data
        }
        setVocabularies({ loading: false, data: result })
      }
    })()
  }, [allVocabularies, vocabularies, getVocabulary, setVocabularies])

  return vocabularies
}
