import { createNewCaptchaCode } from 'NewVersion/utils/captcha'
import { checkErrorFn } from './error'

export const fetchGuillotina = async ({
  path,
  method,
  data,
  token,
  headers = {},
  checkError = true,
  notStringify = false,
  getFile = false,
  unAuth = false,
  guillotinadb = false,
}) => {
  const isModificationMethod = method === 'POST' || method === 'PATCH' || method === 'DELETE'

  const allHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Wait': isModificationMethod ? '40' : '0',
    ...headers,
  }

  if (token) {
    allHeaders['Authorization'] = `Bearer ${token}`
  } else if (unAuth) {
    const recaptchaToken = await createNewCaptchaCode()
    allHeaders['X-VALIDATION-G'] = recaptchaToken
  }

  let pathWIthoutStartBar = path
  if (pathWIthoutStartBar.includes(process.env.REACT_APP_GUILLOTINA)) {
    pathWIthoutStartBar = pathWIthoutStartBar.replace(process.env.REACT_APP_GUILLOTINA, '')
  }
  if (pathWIthoutStartBar.charAt(0) === '/') {
    pathWIthoutStartBar = pathWIthoutStartBar.substring(1)
  }

  const res = await fetch(
    `${guillotinadb ? process.env.REACT_APP_GUILLOTINA_DB : process.env.REACT_APP_GUILLOTINA}${
      pathWIthoutStartBar ?? ''
    }`,
    {
      headers: allHeaders,
      method: method ?? 'GET',
      body: data ? (notStringify ? data : JSON.stringify(data)) : null,
    }
  )

  if (checkError) {
    const error = await checkErrorFn(res)
    if (error) {
      throw error
    }
  }

  if (getFile) {
    const blob = await res.blob()
    return URL.createObjectURL(blob)
  }

  return res.status === 204 ? {} : res.json()
}
