import { sortDates } from './date'

const arePerformancesEquals = (resultPerformance, performance) => {
  const date = new Date(performance.data).setHours(0, 0, 0, 0)

  return (
    new Date(resultPerformance.data).setHours(0, 0, 0, 0) === date &&
    resultPerformance.cancelat === performance.cancelat &&
    resultPerformance.amagar_info_data === performance.amagar_info_data &&
    resultPerformance.espai === performance.espai &&
    resultPerformance.amagar_info_espai === performance.amagar_info_espai &&
    resultPerformance.te_stream_obert === performance.te_stream_obert &&
    resultPerformance.link_stream_obert === performance.link_stream_obert &&
    JSON.stringify(resultPerformance.link_stream_obert) ===
      JSON.stringify(performance.link_stream_obert)
  )
}

const arePerformancesEqualsNoEspai = (resultPerformance, performance) => {
  const date = new Date(performance.data).setHours(0, 0, 0, 0)

  return (
    new Date(resultPerformance.data).setHours(0, 0, 0, 0) === date &&
    resultPerformance.cancelat === performance.cancelat &&
    resultPerformance.amagar_info_data === performance.amagar_info_data &&
    resultPerformance.amagar_info_espai === performance.amagar_info_espai
  )
}

const formattedPerformancesBySameData = (performances, espai = true) => {
  const result = []

  performances.sort((a, b) => {
    return a.data <= b.data ? -1 : 1
  })

  performances.forEach((actuacio) => {
    let find = -1
    for (let i = 0; i < result.length; i++) {
      const item = result[i]
      if (espai) {
        if (arePerformancesEquals(item, actuacio)) {
          find = i
        }
      } else {
        if (arePerformancesEqualsNoEspai(item, actuacio)) {
          find = i
        }
      }
    }

    const data = `${actuacio.data}.000+02:00`
    const time = new Date(data).toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid' })
    const splitTime = time.split(':')
    const renderTime = `${splitTime[0].padStart(2, '0')}:${splitTime[1]}`
    const isAtNight =
      splitTime[0] === '0' ||
      splitTime[0] === '00' ||
      splitTime[0] === '1' ||
      splitTime[0] === '01' ||
      splitTime[0] === '2' ||
      splitTime[0] === '02'
    if (find > -1) {
      result[find].hores.push(renderTime)
      result[find].hores.sort((a, b) => {
        return a <= b ? -1 : 1
      })
      if (!result[find].night) {
        result[find].night = isAtNight
      }
    } else {
      result.push({
        ...actuacio,
        data: new Intl.DateTimeFormat('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(data)),
        hores: [renderTime],
        night: isAtNight,
      })
    }
  })
  return sortDates(result)
}

export { arePerformancesEquals, arePerformancesEqualsNoEspai, formattedPerformancesBySameData }
