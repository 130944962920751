import { getCurrentLanguage } from 'utils/utils'

export function DescriptionAndArtisticSheet({ showData }) {
  const currentLanguage = getCurrentLanguage()

  return (
    <div className={'descriptionAndArtisticSheetBox'}>
      <div className="descriptionBox">
        <span dangerouslySetInnerHTML={{ __html: showData[`sinopsi_${currentLanguage}`] }}></span>

        {showData[`nota_${currentLanguage}`] && (
          <div
            className="colaboration"
            dangerouslySetInnerHTML={{ __html: showData[`nota_${currentLanguage}`] }}
          />
        )}
      </div>
      {showData[`fitxa_${currentLanguage}`] && (
        <div
          className="artisticSheet"
          dangerouslySetInnerHTML={{ __html: showData[`fitxa_${currentLanguage}`] }}
        />
      )}
    </div>
  )
}
