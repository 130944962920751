import { Box } from '@mui/system'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import * as path from 'Routes/Paths'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { LLOTJA_WORKFLOW_STATE } from 'utils/constants'
import Badges from '../UI/Badges/Badges'
import ProfileImage from '../UI/Image/ProfileImage'

const AccountAccreditationComponent = ({ entityInfo, accreditationInfo }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const acreditatView = () => {
    navigate(path.ACCREDITED_PATH + '/' + accreditationInfo['@uid'])
  }

  return (
    <Box className="entityDetailItem__box_acreditation">
      <div className="entityDetailItem__box">
        <div className="cursor-pointer" onClick={acreditatView}>
          <ProfileImage
            pathImage={accreditationInfo.path}
            defaultLetter={accreditationInfo.nom.charAt(0)}
            hasImage={accreditationInfo && accreditationInfo.imatge}
          />
        </div>

        <div className="entityDetailItem__box__item">
          <div className="entityDetailItem__box__item__title">
            <p className="body-bold">{`${accreditationInfo.nom} ${accreditationInfo.cognoms}`}</p>
            {entityInfo.entity.tipus === 'artista' &&
              entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.published && (
                <Badges text={t('artista_actuant')} className="actua" icon={<AlertIcon />} />
              )}
          </div>
          <a
            className="entityDetailItem__box__item__email"
            href={`mailto:${accreditationInfo.correu}`}
          >
            {accreditationInfo.correu}
          </a>
        </div>
        <div className="badges_Chips">
          {entityInfo.entity.tipus === 'artista' &&
            entityInfo.entity.review_state === LLOTJA_WORKFLOW_STATE.published && (
              <Badges text={t('artista_actuant')} className="actua" icon={<AlertIcon />} />
            )}
          <Chips
            text={t('editar')}
            icon={<EditIcon />}
            dataTest={'entityDetailItemChip'}
            onClick={() =>
              navigate(
                `${path.ENTITY_PATH}${entityInfo.entity['path']}/${path.ACCREDITATION}/edit/${accreditationInfo['@name']}`
              )
            }
          />
        </div>
      </div>
    </Box>
  )
}

export const AccountAccreditation = memo(AccountAccreditationComponent)
