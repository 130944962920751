import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'

import translationCA from './translations/translationCA.json'
import translationEN from './translations/translationEN.json'
import translationES from './translations/translationES.json'
// import translationDE from '../public/locales/de/translation.json';
const resources = {
  ca: {
    translation: translationCA,
  },
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

i18n
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: {
      default: ['ca'],
      'en-US': ['en'],
      'ca-ES': ['ca'],
      'es-ES': ['es'],
    },
    debug: true,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
