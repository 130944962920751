import { AuthContext } from 'App'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { swrOptions, swrOptionsStatic } from 'NewVersion/utils/swrOptions'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import useSWR from 'swr'
import { getCurrentJWToken } from 'utils/utils'

export function useGuillotina({ path, useStatic = false, getFile = false, guillotinadb = false }) {
  const { dispatch } = useContext(AuthContext)
  const token = getCurrentJWToken()
  const navigate = useNavigate()
  const { data, error, mutate, isLoading } = useSWR(
    path ? path : null,
    (url) => {
      return fetchGuillotina({
        path: url,
        checkError: true,
        getFile: getFile,
        token: token,
        guillotinadb: guillotinadb,
      })
    },
    useStatic ? swrOptionsStatic : swrOptions
  )

  useEffect(() => {
    if (error && error.status === 401) {
      dispatch({
        type: 'LOGOUT',
      })
      navigate(path.LOGIN_PATH)
    }
  }, [error])

  return {
    data,
    isLoading,
    error,
    refresh: mutate,
  }
}
