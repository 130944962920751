const SearchIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.68571 15.2C4.93333 15.2 2.17143 12.4381 2.17143 8.68571C2.17143 4.93333 4.93333 2.17143 8.68571 2.17143C12.4381 2.17143 15.2 4.93333 15.2 8.68571C15.2 12.4381 12.4381 15.2 8.68571 15.2ZM8.68571 17.3714C13.5048 17.3714 17.3714 13.5048 17.3714 8.68571C17.3714 3.86667 13.5048 0 8.68571 0C3.86667 0 0 3.86667 0 8.68571C0 13.5048 3.86667 17.3714 8.68571 17.3714ZM20 18.4571L14.1143 12.5714L12.5714 14.1143L18.4571 20L20 18.4571Z"
        fill="#2B2A29"
      />
    </svg>
  )
}

export default SearchIcon
