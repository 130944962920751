import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded'
import { useLogout } from 'hooks'
import { useEdition } from 'hooks/useEdition'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player/lazy'

import { isMobile } from 'react-device-detect'
import noStream from 'Statics/noStream.jpg'
import { fetchGuillotina } from 'utils/apiCalls'
import { formatDate, getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'

import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useVocabularies } from 'hooks/useVocabularies'
import { useNavigate } from 'react-router'

const LIVE_TYPES = {
  performances: 'performances',
  activities: 'activities',
}
export function FiraLivePage() {
  const logout = useLogout()
  const { currentEdition, edition, loading: loadingCurrentEdition } = useEdition()
  const [showList, setShowList] = useState(undefined)
  const [professionalActivities, setProfessionalActivities] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [liveType, setLiveType] = useState(LIVE_TYPES.performances)
  const navigate = useNavigate()
  const { t, lang } = useTranslation()
  const vocabularies = useVocabularies([{ vocabulary: 'ambits' }], false)

  useEffect(() => {
    ;(async () => {
      if (!showList && !professionalActivities && !isLoading) {
        try {
          setIsLoading(true)
          const response = await fetchGuillotina(
            `${edition}/@search?type_name=Actuacio&b_size=500&_sort_asc=data&te_stream_tancat=true`
          )
          const responseActivities = await fetchGuillotina(
            `${edition}/@search?type_name=ActivitatProfessional&b_size=500&_sort_asc=data`
          )

          setShowList(response.data.items)
          setProfessionalActivities(
            responseActivities.data.items.filter((event) => {
              if (event.videos.length > 0) {
                return event.videos.find((v) => v.includes('http')) !== null
              } else {
                return false
              }
            })
          )
        } catch (error) {
          setProfessionalActivities([])
          setShowList([])
          if (error.response.status === 401) {
            logout()
          }
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [
    setShowList,
    setProfessionalActivities,
    setIsLoading,
    isLoading,
    professionalActivities,
    showList,
    logout,
    edition,
  ])

  const renderGoBack = () => {
    return (
      <div onClick={() => navigate(-1)} className="go-back-container">
        <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
        {t('Tornar enrere 2')}
      </div>
    )
  }
  const renderLiveShows = () => {
    return (
      <div className="LIVE">
        <div className="directes">
          <div className="switch-offtype" style={{ width: 'unset' }}>
            <input
              onClick={() => setLiveType(LIVE_TYPES.performances)}
              type="radio"
              checked={liveType === LIVE_TYPES.performances}
              onChange={() => {}}
            ></input>
            <span className={`slider-offtype round`}>&nbsp;{t('Directes')}</span>
            &nbsp;|&nbsp;
            <input
              onClick={() => setLiveType(LIVE_TYPES.activities)}
              type="radio"
              checked={liveType === LIVE_TYPES.activities}
              onChange={() => {}}
            ></input>
            <span className={`slider-offtype round`}>&nbsp;{t('Programació Professional')}</span>
          </div>
        </div>
        <div className="grid-name">
          {liveType === LIVE_TYPES.performances &&
            (showList ?? []).map((element, key) => (
              <div className="name-container-fira" key={key} id={element['@uid']}>
                {element.stream !== null ? (
                  <div className="player-small">
                    <ReactPlayer
                      controls={true}
                      url={(element.link_stream_tancat ?? '').concat('?rel=0')}
                    />
                  </div>
                ) : (
                  <div className="image">
                    <img src={noStream} alt="placeholder" />
                  </div>
                )}
                <div className="type-line">
                  <div className="activity-type ">
                    {vocabularies &&
                      element.ambits
                        .map((ambit) =>
                          getValueVocabularyDynamic(
                            vocabularies.data.ambits,
                            ambit,
                            `lang_${getCurrentLanguage()}`
                          )
                        )
                        .join(',')}
                  </div>
                </div>
                <div className="show- no-margin ">
                  <div className="d-grid show-ellipsis">
                    <div className="show-title">
                      {element.espectacles_data[0].artistes_info.join(',')}
                    </div>
                    <div className="show-subtitle">{element.espectacles_data[0].title}</div>
                  </div>
                </div>
                <div className="access-info">{formatDate(element.data)}</div>
              </div>
            ))}
          {liveType === LIVE_TYPES.activities &&
            (professionalActivities ?? []).map((element, key) => (
              <div className="name-container-fira" key={key} id={element['@uid']}>
                {element.video !== null ? (
                  <div className="player-small">
                    <ReactPlayer controls={true} url={element.videos[0].concat('?rel=0')} />
                  </div>
                ) : (
                  <div className="image">
                    <img src={noStream} alt="placeholder" />
                  </div>
                )}
                <div className="type-line">
                  <div className="activity-type ">{t(element.ambit)}</div>
                </div>
                <div className="show- no-margin ">
                  <div className="d-grid show-ellipsis">
                    <div className="show-title">{element.artista}</div>
                    <div className="show-subtitle">{element[`nom_${lang}`]}</div>
                  </div>
                </div>
                <div className="access-info">{formatDate(element.data)}</div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  if (isLoading || loadingCurrentEdition) {
    return <LoadingComponent />
  }

  const HtmlToReactParser = require('html-to-react').Parser
  const htmlInput = currentEdition.getConfigData()[`text_pagina_directe_${getCurrentLanguage()}`]
  const htmlToReactParser = new HtmlToReactParser()
  const reactElement = htmlToReactParser.parse(htmlInput)
  const firaLink = currentEdition.getConfigData()['id_enllac_directe']
  const showXat = currentEdition.getConfigData()['veure_xat_directe']
  return (
    <div>
      {!isMobile && renderGoBack()}
      <div className="Live-title">{t('audiovisual_content')}</div>
      <div className="stream-container">
        <ReactPlayer
          controls={true}
          width="100%"
          height={isMobile ? '400px' : '600px'}
          playing={true}
          url={'https://www.youtube.com/embed/'.concat(firaLink).concat('?rel=0')}
        />
        {!isMobile && showXat && (
          <iframe
            title="fira-live"
            src={'https://www.youtube.com/live_chat?v='
              .concat(firaLink)
              .concat('&embed_domain=pro.firamediterrania.cat')}
            width="40%"
            height="600"
          ></iframe>
        )}
      </div>
      <div style={{ textAlign: 'left' }}>{reactElement}</div>
      {renderLiveShows()}
    </div>
  )
}
