import { Pagination as PaginationMaterial } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const MAX_TIEMS_PAGE_DEFAULT = 12
export const Pagination = ({ itemsTotal = 0, maxItems = MAX_TIEMS_PAGE_DEFAULT }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const pages = useMemo(() => {
    return itemsTotal !== 0 ? Math.ceil(itemsTotal / maxItems) : 0
  }, [itemsTotal, maxItems])

  const handlePagination = (event, newPage) => {
    searchParams.set('page', newPage)
    setSearchParams(searchParams)
  }

  return pages > 1 ? (
    <Box mt={2} sx={{ float: 'right' }}>
      <PaginationMaterial
        count={pages}
        page={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
        onChange={handlePagination}
      />
    </Box>
  ) : (
    <></>
  )
}
