import 'NewVersion/styles/UI/Link.scss'
import { Link as LinkRouter } from 'react-router-dom'

const Link = ({ color = 'secondary', children = undefined, href = '/' }) => {
  return (
    <LinkRouter to={href} underline="always" className={`link ${color}-link`}>
      {children}
    </LinkRouter>
  )
}

export default Link
