import * as path from './Paths'

// import Login from 'Pages/Login'
import Proposals from 'NewVersion/pages/Proposals/Proposals'

import AccountPage from 'NewVersion/pages/AccountPage'
import AddPage from 'NewVersion/pages/Entity/AddPage'
import EntradesPage from 'NewVersion/pages/EntradesPage'
import DetailEntityPage from 'NewVersion/pages/Proposals/DetailEntity'
import ProposalFormPage from 'NewVersion/pages/Proposals/ProposalsFormPage'
import ShowDetailPage from 'NewVersion/pages/ShowDetailPage'
import AdminComponent from 'Pages/AdminComponent'
import FiraLivePage from 'Pages/FiraLivePage'
import NotAllowPage from 'Pages/NotAllowPage'
import NotFound from 'Pages/NotFound'
import NotificationsHandler from 'Pages/NotificationsHandler'
import ProfessionalActivityDetailPage from 'Pages/ProfessionalActivityDetailPage'
import Register from 'Pages/Register'
import UserSettings from 'Pages/UserSettings'
import XatPage from 'Pages/XatPage'

import { Acreditacions } from 'NewVersion/pages/Acreditacions'
import AccreditationAddPage from 'NewVersion/pages/Acreditacions/AddPage'
import AccreditationEditPage from 'NewVersion/pages/Acreditacions/EditPage'
import AgendaListPage from 'NewVersion/pages/AgendaListPage'
import ArtistListPage from 'NewVersion/pages/Artist/ArtistListPage'
import EntityEditPage from 'NewVersion/pages/Entity/EditPage'
import FiraPasseigPage from 'NewVersion/pages/FiraPasseigPage'
import HomePage from 'NewVersion/pages/HomePage'
import MyMeetsPage from 'NewVersion/pages/Meets/MyMeetsPage'
import QuickMeetingsPage from 'NewVersion/pages/Meets/QuickMeetingsPage'
import NewPasswordPage from 'NewVersion/pages/NewPasswordPage'
import PaymentInfoPage from 'NewVersion/pages/PaymentInfoPage'
import PreguntesFrequentsPage from 'NewVersion/pages/PreguntesFrequentsPage'
import ProfessionalsListPage from 'NewVersion/pages/Professionals/ProfessionalsListPage'
import ScheduleListPage from 'NewVersion/pages/ScheduleListPage'
import ShopAddPage from 'NewVersion/pages/Shop/AddPage'
import ShopEditPage from 'NewVersion/pages/Shop/EditPage'
import StandAddPage from 'NewVersion/pages/Stand/AddPage'
import StandEditPage from 'NewVersion/pages/Stand/EditPage'
import AccreditationPage from 'Pages/AccreditationPage'

export const PathToPageMap = {
  [path.REGISTER_PATH]: <Register />,
  [path.HOME_PATH]: <HomePage />,
  [path.SHOWS_PATH]: <ScheduleListPage />,
  [path.SHOWS_DETAIL_PATH]: <ShowDetailPage />,
  [path.PROGRAMMING_PATH]: <ScheduleListPage />,
  [path.PROGRAMMING_DETAIL_PATH]: <ProfessionalActivityDetailPage />,
  [path.PROFESSIONALS_PATH]: <ProfessionalsListPage />,
  [path.ARTIST_PATH]: <ArtistListPage />,
  [path.PROPOSALS_PATH]: <Proposals />,
  [path.PROPOSALS_FORM_DETAIL_PATH]: <ProposalFormPage />,
  [path.PROPOSALS_FORM_PATH]: <ProposalFormPage />,
  [path.PROPOSALS_ENTITY_DETAIL_PATH]: <DetailEntityPage />,
  [path.LIVE_PATH]: <FiraLivePage />,
  [path.TIKETS_PATH]: <EntradesPage />,
  [path.AGENDA_PATH]: <AgendaListPage />,
  [path.NOTIFICATIONS_PATH]: <NotificationsHandler />,
  [path.USER_SETTINGS_PATH]: <UserSettings />,
  [path.XATS_PATH]: <XatPage />,
  [path.MEETINGS_PATH]: <QuickMeetingsPage />,
  [path.MY_MEETINGS_PATH]: <MyMeetsPage />,
  [path.ACCOUNT_PATH]: <AccountPage />,
  [path.ACCREDITED_DETAIL_PATH]: <AccreditationPage />,
  [path.ADMIN_PATH]: <AdminComponent />,
  [path.NEW_PASSWORD_PATH]: <NewPasswordPage />,
  [path.REGISTER_ENTITY_PATH]: <Acreditacions />,
  [path.EDIT_ENTITY_FORM_PATH]: <EntityEditPage />,
  [path.CREATE_ENTITY_FORM_PATH]: <AddPage />,
  [path.CREATE_ACCREDITATION_PATH]: <AccreditationAddPage />,
  [path.EDIT_ACCREDITATION_PATH]: <AccreditationEditPage />,
  [path.CREATE_STAND_PATH]: <StandAddPage />,
  [path.EDIT_STAND_PATH]: <StandEditPage />,
  [path.CREATE_SHOP_PATH]: <ShopAddPage />,
  [path.EDIT_SHOP_PATH]: <ShopEditPage />,
  [path.ERROR_404_PATH]: <NotFound />,
  [path.ERROR_NOT_ALLOW]: <NotAllowPage />,
  [path.PAYMENT_INFO_PATH]: <PaymentInfoPage />,
  [path.FIRAPASSEIG]: <FiraPasseigPage />,
  [path.PREGUNTES_FREQUENTS]: <PreguntesFrequentsPage />,
}
