import React from 'react'
import { Edition } from './Edition'
import { Edition2019Home } from 'Components/Home/Edition2019Home'
import * as path from 'Routes/Paths'
import { BACKEND_TYPE, FIRA_EMAILS } from 'utils/constants'
import { fetchPlone } from 'utils/apiCalls'
import { get } from 'utils/objectUtils'
import { getEmailFromToken } from 'utils/utils'

export default class Edition2019 extends Edition {
  homeComponent() {
    return <Edition2019Home />
  }

  getUrl() {
    return `${process.env.REACT_APP_PLONE}mediterrania2019/`
  }

  getConfigRoutes() {
    return [
      {
        exact: true,
        path: path.REGISTER_PATH,
      },
      {
        exact: true,
        path: path.HOME_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROFESSIONALS_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_ENTITY_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.AGENDA_PATH,
      },
      {
        exact: true,
        path: path.ERROR_404_PATH,
      },
      {
        redirect: true,
        exact: true,
        from: '/',
        to: path.HOME_PATH,
      },
      {
        redirect: true,
        from: path.LOGIN_PATH,
        to: path.HOME_PATH,
      },
      {
        exact: true,
        path: path.NEW_PASSWORD_PATH,
      },
    ]
  }

  getBackendType() {
    return BACKEND_TYPE.PLONE
  }

  async canAccesToInfo() {
    const email = getEmailFromToken()
    if (FIRA_EMAILS.includes(email)) {
      return true
    }
    try {
      const response = await fetchPlone({
        path: `${this.getUrl()}infouser?email=${getEmailFromToken()}`,
      })
      return get(response, 'data.entitats', []).length > 0
    } catch (err) {
      return false
    }
  }
}
