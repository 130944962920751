import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { AcreditacioIcon } from 'NewVersion/icons/AcreditacioIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { HomeIcon } from 'NewVersion/icons/HomeIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import { useFavourites } from 'hooks/useFavourites'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TableHead } from '../UI/Table/TableHead'
import { ProfessionalTableItem } from './ProfessionalTableItem'

const ProfessionalTableComponent = ({ professionals = [], loading, onClick, gridView }) => {
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()

  const { t } = useTranslation()

  const tableHeader = [
    {
      id: 'ens_title',
      label: t('Entitat'),
      sortable: true,
      icon: <HomeIcon />,
    },
    {
      id: 'title',
      label: t('Professionals'),
      sortable: true,
      icon: <AcreditacioIcon />,
    },
    {
      id: 'ens_type_name',
      label: t('perfil_professional'),
      sortable: true,
      icon: <UserIcon />,
    },
    {
      id: 'favourites',
      label: t('favourites'),
      icon: <FavouriteIcon />,
    },
  ]

  const renderProfessionals = (item) => (
    <ProfessionalTableItem
      key={`professionalTableItem-${item.id}`}
      professional={item}
      addFavourite={addFavourite}
      removeFavourite={removeFavourite}
      isFavouritesLoading={isFavouritesLoading}
      isFavourite={favouiteIds && favouiteIds.includes(item['@uid'])}
      onClick={onClick}
      gridView={gridView}
    />
  )

  return (
    <>
      <TableContainer className={`table-container ${gridView ? 'grid-view' : ''}`}>
        <Table aria-label="Professionals table" data-test={'table'}>
          <TableHead headCells={tableHeader} />
          <TableBody>
            {!loading && professionals.length > 0 ? (
              professionals.map((item) => renderProfessionals(item))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {loading ? <LoadingComponent /> : t('no_results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        className={`table-container-mobile ${gridView ? 'grid-view' : ''}`}
        data-test="table-container"
      >
        {!loading && professionals.length > 0 ? (
          professionals.map((item) => renderProfessionals(item))
        ) : (
          <Box>{loading ? <LoadingComponent /> : t('no_results')}</Box>
        )}
        <Box flexGrow={1}></Box>
      </Box>
    </>
  )
}

export const ProfessionalTable = React.memo(ProfessionalTableComponent)
