import Button from 'NewVersion/components/UI/Buttons/Button'
import { AcreditacionsIcon } from 'NewVersion/icons/AcreditacionsIcon'
import { ArtistesIcon } from 'NewVersion/icons/ArtistesIcon'
import { PremsaIcon } from 'NewVersion/icons/PremsaIcon'
import { ACCOUNT_PATH, CREATE_ENTITY_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import LoadingComponent from 'Components/HelperComponents/LoadingComponent'

export const Acreditacions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentEdition, loading: loadingCurrentEdition, edition } = useEdition()

  if (loadingCurrentEdition) {
    return <LoadingComponent />
  }

  // const canCreateEntity =
  //   currentEdition.canCreateEnsPremsa() ||
  //   currentEdition.canCreateEnsProfessionals() ||
  //   currentEdition.canCreateEnsServeis()

  // useEffect(() => {
  //   if (!canCreateEntity) {
  //     navigate('/home')
  //   }
  // }, [currentEdition])

  // if (!canCreateEntity) {
  //   return null
  // }

  return (
    <div className="acreditacions page-wrapper">
      <h1>{t('acreditacions')}</h1>
      <p>{t('accreditation_page_detail')}</p>
      <div className="acreditacions-buttons">
        <Button
          color="primary"
          type="submit"
          dataTest="btnAddProfessionalEntityTest"
          icon={<AcreditacionsIcon fill={'white'} />}
          onClick={() => navigate(`${CREATE_ENTITY_PATH}/professional`)}
          disabled={!currentEdition.canCreateEnsProfessionals()}
        >
          {t('Professionals')}
        </Button>
        <Button
          color="primary"
          type="submit"
          dataTest="btnAddArtistaManagerEntityTest"
          icon={<ArtistesIcon />}
          onClick={() => navigate(`${ACCOUNT_PATH}`)}
          disabled={!currentEdition.canCreateEnsProfessionals()}
        >
          {t('artistes-i-representants', { edition: edition })}
        </Button>
        <Button
          color="primary"
          type="submit"
          dataTest="btnAddPressEntityTest"
          icon={<PremsaIcon />}
          onClick={() => navigate(`${CREATE_ENTITY_PATH}/premsa`)}
          disabled={!currentEdition.canCreateEnsPremsa()}
        >
          {t('premsa')}
        </Button>
      </div>
    </div>
  )
}
