export const commonOrderFieldsForm = [
  'nom',
  'cognoms',
  'carrec',
  'telefon',
  'correu',
  'tipologia',
  'primer_cop_fira',
]

export const commonRequiredFields = [
  'nom',
  'cognoms',
  'telefon',
  'correu',
  'tipologia',
  'primer_cop_fira',
  'declaracio_acceptar',
]

export const ACCREDITATION_TYPES_OBJ = {
  premsa: {
    tipologia: 'tipologia_premsa',
    interface: 'AcreditacioPremsa',
    orderFieldsForm: [
      {
        title: '',
        fields: [...commonOrderFieldsForm, 'reporter_grafic'],
      },
      {
        title: '',
        fields: ['imatge'],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: commonRequiredFields,
  },
  professional: {
    tipologia: 'tipologia_professional',
    interface: 'AcreditacioProfessional',
    orderFieldsForm: [
      {
        title: '',
        fields: commonOrderFieldsForm,
      },
      {
        title: '',
        fields: ['imatge'],
      },
      {
        title: 'declaracio_fieldset',
        fields: ['declaracio_acceptar'],
      },
    ],
    requiredFields: commonRequiredFields,
  },
}
