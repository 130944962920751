import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import 'NewVersion/styles/UI/LanguageButtonsSelector.scss'
import { initReactI18next, useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'utils/utils'

export const LanguageButtonsSelector = () => {
  const { t, i18n } = useTranslation()

  const currentLanguage = getCurrentLanguage()

  const languageOptions = [
    {
      id: 'ca',
      title: t('Català'),
    },
    {
      id: 'es',
      title: t('Español'),
    },
    {
      id: 'en',
      title: t('English'),
    },
  ]

  const changeLanguage = (lang) => {
    i18n.use(initReactI18next).init({ lng: lang })
  }

  return (
    <div className="button-language-group">
      <ButtonGroup variant="text">
        {languageOptions.map((language) => {
          return (
            <Button
              onClick={() => changeLanguage(language.id)}
              className={`${currentLanguage === language.id ? 'selected' : ''}`}
              key={language.id}
            >
              {language.title.slice(0, 3)}
            </Button>
          )
        })}
      </ButtonGroup>
    </div>
  )
}
