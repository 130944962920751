import AddAlertRounded from '@mui/icons-material/AddAlertRounded'
import EventRounded from '@mui/icons-material/EventRounded'
import Facebook from '@mui/icons-material/Facebook'
import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded'
import LinkedIn from '@mui/icons-material/LinkedIn'
import LocationOnRounded from '@mui/icons-material/LocationOnRounded'
import NotificationsActiveRounded from '@mui/icons-material/NotificationsActiveRounded'
import QueryBuilderRounded from '@mui/icons-material/QueryBuilderRounded'
import Telegram from '@mui/icons-material/Telegram'
import Twitter from '@mui/icons-material/Twitter'
import WhatsApp from '@mui/icons-material/WhatsApp'
import * as path from 'Routes/Paths'
import { ReactComponent as Calendar } from 'Statics/calendar-plus.svg'
import moment from 'moment'
import 'moment/locale/ca'
import 'moment/locale/es'
import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import ICalendarLink from 'react-icalendar-link'
import ReactPlayer from 'react-player/lazy'
import { useNavigate } from 'react-router'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import ReactTooltip from 'react-tooltip'
import { getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'

export function ProfessionalActivity({
  currentProfessionalActivity,
  removeFavourite,
  addFavourite,
  favouiteIds,
  vocabularies,
}) {
  const { t } = useTranslation()

  const getIcalEvent = (event) => {
    return {
      title: event.artista,
      description: event.artista,
      startTime: event.data,
      endTime: event.data,
      location: getValueVocabularyDynamic(
        vocabularies.data.espai,
        event.espai,
        getCurrentLanguage()
      ),
    }
  }
  const mainImage = useMemo(() => {
    if (currentProfessionalActivity) {
      return `${currentProfessionalActivity['@id']}/@images/images/principal/large`
    }
    return null
  }, [currentProfessionalActivity])

  const renderPerformances = () => {
    const hours = Math.floor(currentProfessionalActivity.durada / 60)
    let minutes = currentProfessionalActivity.durada % 60
    if (minutes === 0) {
      minutes = '00'
    }
    moment.locale(getCurrentLanguage())
    return (
      <div className="schedule-container">
        <div className="info-small">
          <div className="specific-date">
            <span>
              <EventRounded style={{ fontSize: 20 }} />
              {moment(currentProfessionalActivity.data).format('HH') === '00'
                ? moment(currentProfessionalActivity.data)
                    .subtract(1, 'days')
                    .format('D MMMM YYYY, kk:mm')
                : moment(currentProfessionalActivity.data).format('D MMMM YYYY, HH:mm')}
              &nbsp;&nbsp;
              {!isMobile && <div className="access-infos-separator"></div>}
              <div className="acces-infos">
                {favouiteIds.indexOf(currentProfessionalActivity['@uid']) !== -1 ? (
                  <div
                    className="access-info"
                    data-tip={t("Treure programació de l'agenda")}
                    onClick={(e) => removeFavourite(currentProfessionalActivity)}
                  >
                    <NotificationsActiveRounded className="pulsate-svg" style={{ fontSize: 20 }} />
                  </div>
                ) : (
                  <div
                    className="access-info"
                    data-tip={t("Afegir programació a l'agenda")}
                    onClick={(e) => addFavourite(currentProfessionalActivity)}
                  >
                    <AddAlertRounded style={{ fontSize: 20 }} />
                  </div>
                )}
                {!isMobile && <ReactTooltip />}
              </div>
              <ICalendarLink
                className="ical-link"
                data-tip={t('Afegir event al calendari')}
                event={getIcalEvent(currentProfessionalActivity, currentProfessionalActivity)}
              >
                <div className={isMobile ? 'access-info d-flex' : 'access-info'}>
                  <Calendar
                    style={
                      isMobile ? { fontSize: 20, fill: 'black' } : { fontSize: 20, fill: 'white' }
                    }
                  />
                </div>
              </ICalendarLink>
            </span>
            <span>
              <LocationOnRounded style={{ fontSize: 20 }} />
              {getValueVocabularyDynamic(
                vocabularies.data.espai,
                currentProfessionalActivity.espai,
                getCurrentLanguage()
              )}
            </span>
          </div>
        </div>
        <span className="" style={{ fontSize: 12 }}>
          <QueryBuilderRounded style={{ fontSize: 20 }} />
          {t('durada')} {hours}:{minutes}
        </span>
      </div>
    )
  }

  const navigate = useNavigate()
  const goBack = () => {
    navigate(path.PROGRAMMING_PATH, { replace: true })
  }

  const renderSocialLinks = () => {
    return (
      <div className="share-container">
        {t('Compartir')}
        <div className="item social-links white">
          <FacebookShareButton url={window.location.href}>
            <div className="facebook">
              <Facebook style={{ fontSize: 20 }} />
            </div>
          </FacebookShareButton>
          <LinkedinShareButton url={window.location.href}>
            <div className="linkedin">
              <LinkedIn style={{ fontSize: 20 }} />
            </div>
          </LinkedinShareButton>
          <TelegramShareButton url={window.location.href}>
            <div className="telegram">
              <Telegram style={{ fontSize: 20 }} />
            </div>
          </TelegramShareButton>
          <TwitterShareButton url={window.location.href}>
            <div className="twitter">
              <Twitter style={{ fontSize: 20 }} />
            </div>
          </TwitterShareButton>
          <WhatsappShareButton url={window.location.href}>
            <div className="whatsapp">
              <WhatsApp style={{ fontSize: 20 }} />
            </div>
          </WhatsappShareButton>
        </div>
      </div>
    )
  }

  const renderGoBack = () => {
    return (
      <div onClick={goBack} className="go-back-container">
        <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
        {t('Tornar enrere')}
      </div>
    )
  }

  const renderArtistName = () => {
    return currentProfessionalActivity[`nom_${getCurrentLanguage()}`]
  }

  const renderPrimaryInfo = () => {
    if (isMobile) {
      return (
        <div className="primary-info-show-container">
          <div className="image-content">
            {currentProfessionalActivity.videos.length > 0 && (
              <ReactPlayer
                width="100%"
                controls={true}
                height={'100%'}
                playing={true}
                url={currentProfessionalActivity.videos[0]}
              />
            )}
            {!currentProfessionalActivity.videos.length === 0 && (
              <div
                className="image-container"
                style={
                  mainImage
                    ? {
                        backgroundImage: 'url(' + mainImage + ')',
                        animation: 'fadeBackground 0.5s',
                      }
                    : {}
                }
              ></div>
            )}
          </div>
          <div className="title-container">
            <div>{currentProfessionalActivity.ambit}</div>
            <div className="artist">{currentProfessionalActivity.artista}</div>
            <div className="name">{renderArtistName()}</div>
          </div>
          <div className="performance-container">{renderPerformances()}</div>
        </div>
      )
    } else {
      return (
        <div className="primary-info-show-container">
          <div className="info-content">
            {/* Get info containers */}
            <span className="">{currentProfessionalActivity.ambit} </span>
            <div className="info-big  ">
              <span className="bold">{currentProfessionalActivity.artista}</span>
              <span className="bold">{renderArtistName()}</span>
            </div>
            {renderPerformances()}
          </div>
          <div className="image-content">
            {currentProfessionalActivity.videos.length > 0 && (
              <ReactPlayer
                width="100%"
                controls={true}
                height={'100%'}
                playing={true}
                url={currentProfessionalActivity.videos[0]}
              />
            )}
            {!currentProfessionalActivity.videos.length === 0 && (
              <div
                className="image-container"
                style={
                  mainImage
                    ? {
                        backgroundImage: 'url(' + mainImage + ')',
                        animation: 'fadeBackground 0.5s',
                      }
                    : {}
                }
              ></div>
            )}
          </div>
        </div>
      )
    }
  }

  const renderSecondaryInfo = () => {
    if (isMobile) {
      return (
        <div className="secondary-info-show-container">
          {currentProfessionalActivity['sinopsi_' + getCurrentLanguage()] && (
            <div
              className="divider"
              dangerouslySetInnerHTML={{
                __html: currentProfessionalActivity['sinopsi_' + getCurrentLanguage()],
              }}
            />
          )}
          {currentProfessionalActivity['fitxa_' + getCurrentLanguage()] && (
            <div
              className="divider"
              dangerouslySetInnerHTML={{
                __html: currentProfessionalActivity['fitxa_' + getCurrentLanguage()],
              }}
            />
          )}
          {renderSocialLinks()}
        </div>
      )
    } else {
      return (
        <div className="secondary-info-show-container">
          <div className="secondary-info-show-container-left">
            {currentProfessionalActivity['sinopsi_' + getCurrentLanguage()] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentProfessionalActivity['sinopsi_' + getCurrentLanguage()],
                }}
              />
            )}
          </div>
          <div className="secondary-info-show-container-center">
            {currentProfessionalActivity['fitxa_' + getCurrentLanguage()] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentProfessionalActivity['fitxa_' + getCurrentLanguage()],
                }}
              />
            )}
          </div>
          <div className="secondary-info-show-container-right">{renderSocialLinks()}</div>
        </div>
      )
    }
  }

  if (currentProfessionalActivity) {
    return (
      <div className={`Show${isMobile ? '-mobile' : ''}`}>
        {!isMobile && renderGoBack()}
        {renderPrimaryInfo()}
        {renderSecondaryInfo()}
      </div>
    )
  } else {
    return <></>
  }
}
