import { Box } from '@mui/system'
import { ArtistTable } from 'NewVersion/components/Artists/ArtistTable'
import { ProfessionalEntityDialog } from 'NewVersion/components/Professional/ProfessionalEntityDialogComponent'
import { Filter, VIEW_TYPE_GRID, VIEW_TYPE_KEY } from 'NewVersion/components/UI/Filters/Filter'
import FilterSearch from 'NewVersion/components/UI/Filters/FilterSearch'
import { MAX_TIEMS_PAGE_DEFAULT, Pagination } from 'NewVersion/components/UI/Pagination/Pagination'
import 'NewVersion/styles/ArtistsListPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

function ArtistListPage() {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const [searchParams, setSearchParams] = useSearchParams()
  const [artistEntityStack, setArtistEntityStack] = useState([])
  const { favouiteIds } = useFavourites()

  const getFiltersSearch = () => {
    let order = ''
    let filterFavourites = ''
    let search = ''

    if (searchParams.get('search') && searchParams.get('search') !== '') {
      search = `&title__in=${searchParams.get('search')}`
    }

    if (searchParams.get('favourites') && searchParams.get('favourites') !== 'all') {
      if (searchParams.get('favourites') === 'yes') {
        filterFavourites = favouiteIds
          ? `&uuid__or=${favouiteIds.map((item) => `uuid=${item}`).join('%26')}`
          : ''
      } else if (searchParams.get('favourites') === 'no') {
        filterFavourites = favouiteIds
          ? `&uuid__and=${favouiteIds.map((item) => `uuid__not=${item}`).join('%26')}`
          : ''
      }
    }

    if (searchParams.get('sort-by') && searchParams.get('sort-direction')) {
      order = `&_sort_${searchParams.get('sort-direction')}=${searchParams.get('sort-by')}`
    }

    return `${filterFavourites}${search}${order}`
  }

  const getPaginationFilter = () => {
    let paginationFilter = `&b_size=${MAX_TIEMS_PAGE_DEFAULT}`
    if (searchParams.get('page')) {
      paginationFilter = `&b_start=${
        (parseInt(searchParams.get('page')) - 1) * MAX_TIEMS_PAGE_DEFAULT
      }${paginationFilter}`
    } else paginationFilter = `&b_start=0${paginationFilter}`
    return paginationFilter
  }

  const { isLoading, data: { items: artists, items_total: showingItems } = [] } = useGuillotina({
    path: `${edition}/@search?type_name=Ens&review_state=publicat&tipus=artista${getFiltersSearch()}${getPaginationFilter()}`,
  })

  return (
    <Box className={'artist-list-page-container page-wrapper'}>
      <Box display={'flex'} alignItems={'end'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box maxWidth={'500px'}>
          <h1>{t('artists')}</h1>
          <p>{t('artists_page_detail', { edition: edition })}</p>
        </Box>
        <FilterSearch
          placeholder={t('artists_search')}
          onChange={({ target }) => {
            searchParams.set('search', target.value)
            searchParams.set('page', 1)
            setSearchParams(searchParams)
          }}
          dataTest="inputSearchArtistTest"
          value={searchParams.get('search') || ''}
          variant={'outlined'}
        />
      </Box>
      <Filter onlyShowTypeView={true} />
      <ArtistTable
        key={`artistTable_${getFiltersSearch()}${getPaginationFilter()}`}
        artists={artists}
        loading={isLoading}
        onClick={(artist) => {
          setArtistEntityStack([...artistEntityStack, artist])
        }}
        gridView={searchParams.get(VIEW_TYPE_KEY) === VIEW_TYPE_GRID}
      />
      {!isLoading && <Pagination itemsTotal={showingItems} />}
      <ProfessionalEntityDialog
        uuid={artistEntityStack.length > 0 ? artistEntityStack.at(-1) : null}
        onClose={() => setArtistEntityStack([])}
        onClick={(uuid) => setArtistEntityStack([...artistEntityStack, uuid])}
        onBack={() => {
          const newProfessionalEntityStack = [...artistEntityStack]
          newProfessionalEntityStack.splice(-1)
          setArtistEntityStack(newProfessionalEntityStack)
        }}
      />
    </Box>
  )
}

export default ArtistListPage
