import 'NewVersion/styles/UI/ProfileImage.scss'
import { useGuillotina } from 'hooks/useGuillotina'

const ProfileImage = ({ defaultLetter, pathImage, hasImage, big = false }) => {
  const { data: image } = useGuillotina({
    path: hasImage ? `${pathImage}/@images/imatge/preview` : null,
    getFile: true,
  })

  return (
    <div
      style={image ? { backgroundImage: `url(${image})` } : {}}
      className={`image-profile ${big && 'big'}`}
    >
      {!image ? defaultLetter : ''}
    </div>
  )
}

export default ProfileImage
