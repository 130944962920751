import React from 'react'
import { Edition2018Home } from 'Components/Home/Edition2018Home'
import { Edition } from './Edition'
import * as path from 'Routes/Paths'
import { BACKEND_TYPE } from 'utils/constants'

export default class Edition2018 extends Edition {
  homeComponent() {
    return <Edition2018Home />
  }

  getUrl() {
    return `${process.env.REACT_APP_PLONE}mediterrania2018/`
  }

  getConfigRoutes() {
    return [
      {
        exact: true,
        path: path.REGISTER_PATH,
      },
      {
        exact: true,
        path: path.HOME_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_PATH,
      },
      {
        exact: true,
        path: path.SHOWS_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_PATH,
      },
      {
        exact: true,
        path: path.PROGRAMMING_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROFESSIONALS_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_FORM_PATH,
      },
      {
        exact: true,
        path: path.PROPOSALS_ENTITY_DETAIL_PATH,
      },
      {
        exact: true,
        path: path.AGENDA_PATH,
      },
      {
        exact: true,
        path: path.ERROR_404_PATH,
      },
      {
        redirect: true,
        exact: true,
        from: '/',
        to: path.HOME_PATH,
      },
      {
        redirect: true,
        from: path.LOGIN_PATH,
        to: path.HOME_PATH,
      },
      {
        exact: true,
        path: path.NEW_PASSWORD_PATH,
      },
    ]
  }

  getBackendType() {
    return BACKEND_TYPE.PLONE
  }

  async canAccesToInfo() {
    // TODO: Activar quan es solucioni la vista 2018
    return false
  }
}
