import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { EntityForm } from 'NewVersion/components/Forms/EntityForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import * as path from 'Routes/Paths'
import { PROPOSALS_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { OBJECT_TYPE_HAVE_RATE } from 'utils/constants'
import { ENS_TYPES, ENS_TYPES_OBJ } from 'utils/entityUtils'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken } from 'utils/utils'

export const DetailEntityPage = () => {
  const { entityId, year } = useParams()
  const { currentEdition, loadingEdition, edition } = useEdition()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [submitError, setSubmitError] = useState({
    hasError: false,
    message: '',
  })

  const {
    data: entityDetail,
    isLoading: entityDetailLoading,
    error: entityDetailError,
  } = useGuillotina({
    path: `${year}/${entityId}`,
  })

  const entityTypeObj = useMemo(() => {
    if (entityDetail) {
      return Object.values(ENS_TYPES_OBJ).find((obj) => {
        if (entityDetail['type_name'] === ENS_TYPES_OBJ.artista.interface) {
          return obj.text === entityDetail.tipus
        }
        return obj.interface === entityDetail['type_name']
      })
    }
    return null
  }, [entityDetail])

  const handleEntityError = (error) => {
    if (error) {
      if (get(error, 'code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const isArtistOrManager = () => {
    return (
      entityTypeObj['interface'] === ENS_TYPES.artist ||
      entityTypeObj['interface'] === ENS_TYPES.manager
    )
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const token = getCurrentJWToken()
    const dataEntity = Object.assign({}, values, {
      '@type': entityTypeObj['interface'],
      tarifa: currentEdition.getCurrentRateByType(
        isArtistOrManager()
          ? OBJECT_TYPE_HAVE_RATE.ens_artist_or_manager
          : OBJECT_TYPE_HAVE_RATE.ens
      ),
    })

    if (dataEntity.pais !== 'country-ES') {
      dataEntity.comunitat_autonoma = null
      dataEntity.comarca = null
    } else if (dataEntity.comunitat_autonoma !== 'catalunya') {
      dataEntity.comarca = null
      dataEntity.comunitat_autonoma = dataEntity.comunitat_autonoma.value
    }

    await fetchGuillotina({
      path: `${edition}/${entityId}`,
      method: 'PATCH',
      data: dataEntity,
      token: token,
    })
      .then((response) => {
        if (response) {
          if (response['code']) {
            handleEntityError(response)
            return null
          }
          navigate(path.PROPOSALS_PATH)
        }
      })
      .catch((error) => {
        handleEntityError(error)
        setSubmitting(false)
        return
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (entityDetailLoading || loadingEdition || !entityDetail) return <LoadingComponent />
  if (entityDetailError) {
    return <span className="error-message">{t('error-fetching-data')}</span>
  }

  return (
    <div className="formPage page-wrapper">
      <GoBackButton onClick={() => navigate(PROPOSALS_PATH)} text={t('backToProposals')} />
      <div className="Shows Propostes">
        {/* <div
          className="title-generic account-1 cursor-pointer"
          onClick={() => navigate(path.PROPOSALS_PATH)}
        >
          {entityDetail.tipus === ENS_TYPES_OBJ.representant.text
            ? t('entity_manager_title')
            : t('entity_artist_title')}
        </div> */}

        {submitError.hasError && submitError.message && (
          <AlertComponent type="danger"> {submitError.message}</AlertComponent>
        )}
        <EntityForm
          ensTypeObj={ENS_TYPES_OBJ[entityDetail.tipus]}
          entity={entityDetail}
          readOnlyForm={currentEdition.callIsFinished()}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}

export default DetailEntityPage
