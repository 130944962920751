export const ComprarEntradaIcon = () => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 2.625V6.875C20.0812 6.875 19.125 7.83125 19.125 9C19.125 10.1687 20.0812 11.125 21.25 11.125V15.375C21.25 16.5438 20.2938 17.5 19.125 17.5H2.125C0.95625 17.5 0 16.5438 0 15.375V11.125C1.17938 11.125 2.125 10.1687 2.125 9C2.125 7.83125 1.17938 6.875 0.010625 6.875V2.625C0.010625 1.44563 0.95625 0.5 2.125 0.5H19.125C20.2938 0.5 21.25 1.44563 21.25 2.625ZM9.5625 14.8438H11.6875V12.7188H9.5625V14.8438ZM11.6875 10.0625H9.5625V7.9375H11.6875V10.0625ZM9.5625 5.28125H11.6875V3.15625H9.5625V5.28125Z"
      fill="white"
    />
  </svg>
)
