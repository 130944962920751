import React, { useEffect, useState } from 'react'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useParams } from 'react-router'
import { useSetState } from 'react-use'
import { AlertComponent } from 'Components/HelperComponents'
import { useTranslation } from 'react-i18next'
import AccreditationDetail from 'Components/AccreditationComponents/AccreditationDetail'
import { fetchGuillotina } from 'utils/apiCalls'
import { useLogout } from 'hooks'
import { useEdition } from 'hooks/useEdition'

export function AccreditationPage() {
  const logout = useLogout()
  const { t } = useTranslation()
  const { acreditatId } = useParams()
  const [errorFetchData, setErrorFetchData] = useState(undefined)
  const [data, setData] = useSetState({
    accreditation: undefined,
    loading: false,
  })
  const { edition } = useEdition()

  useEffect(() => {
    ;(async () => {
      if (!data.accreditation && !data.loading && edition) {
        setData({ loading: true })
        try {
          const response = await fetchGuillotina(`${edition}/@search?uuid=${acreditatId}`)
          if (response.data.items.length > 0) {
            const responseData = await fetchGuillotina(response.data.items[0]['path'])
            setData({
              accreditation: responseData.data,
              loading: false,
            })
          } else {
            logout()
          }
        } catch (error) {
          if (error.response.status === 401) {
            logout()
          } else {
            setErrorFetchData(error.response)
            setData({
              accreditation: {},
              loading: false,
            })
          }
        }
      }
    })()
  }, [data, acreditatId, setData, logout, edition])

  if (data.loading || !data.accreditation) return <LoadingComponent />
  if (errorFetchData) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }
  return <AccreditationDetail accreditation={data.accreditation} />
}
export default AccreditationPage
