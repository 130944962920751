import React from 'react'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { GOOGLE_RECAPTCHAKEY } from 'utils/constants'

function WrapperUnauthAction({ children }) {
  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHAKEY}>
        <GoogleReCaptcha />
      </GoogleReCaptchaProvider>
      {children}
    </div>
  )
}
export default WrapperUnauthAction
