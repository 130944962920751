import KeyboardBackspaceRounded from '@mui/icons-material/KeyboardBackspaceRounded'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player/lazy'
import { useNavigate } from 'react-router'
import { getCurrentJWToken, getCurrentLanguage } from 'utils/utils'
import noStream from '../../Statics/noStream.jpg'
import { getApiCallPlone, getApiCallPloneGeneric } from '../../utils/apiCalls'
import { formatDate } from '../../utils/utils'

export function FiraLivePage() {
  const [firaContent, setFiraContent] = useState('')
  const [firaLink, setFiraLink] = useState('')
  const [showXat, setShowXat] = useState(false)
  const [showStreamingList, setShowStreamingList] = useState([])
  const [programationStreamingList, setProgramationStreamingList] = useState([])
  const [showStreaming1, setShowStreaming1] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    getApiCallPloneGeneric({
      path:
        'https://oldplone.firamediterrania.cat/mediterrania2019/' +
        getCurrentLanguage() +
        '/endirecte',
      finishCallBack: handleFiraContent,
    })
    getApiCallPloneGeneric({
      path: 'https://oldplone.firamediterrania.cat/mediterrania2019/ca/video-en-directe',
      finishCallBack: handleSetFiraLink,
    })

    getApiCallPlone(
      JSON.parse(localStorage.edition)[1] + '@search',
      getCurrentJWToken(),
      setShowStreamingList,
      false,
      false,
      {
        portal_type: 'mediterrania.core.actuacio',
        mediterrania_tipus: 'stream',
      }
    )
    getApiCallPlone(
      JSON.parse(localStorage.edition)[1] + '@search',
      getCurrentJWToken(),
      handleStreamingList,
      false,
      false,
      {
        portal_type: 'mediterrania.core.activitat_professional',
        Language: getCurrentLanguage(),
      }
    )
  }, [])

  const handleStreamingList = (elements) => {
    function filterStreaming(event) {
      if (event.video) {
        return event.video.includes('http')
      } else {
        return false
      }
    }

    const filtered = elements.filter(filterStreaming)
    setProgramationStreamingList(filtered)
  }

  const handleFiraContent = (element) => {
    setFiraContent(element.data.text.data)
  }
  const handleSetFiraLink = (element) => {
    const newEl = element.data.text.data.replace('<p>', '').replace('</p>', '')
    setFiraLink(newEl)
    setShowXat(element.description === 'chat')
    // gestio de si hem de mostrar xat o no
  }
  const renderGoBack = () => {
    return (
      <div onClick={() => navigate(-1)} className="go-back-container">
        <KeyboardBackspaceRounded style={{ fontSize: 20 }} />
        {t('Tornar enrere 2')}
      </div>
    )
  }
  const renderLiveShows = () => {
    return (
      <div className="LIVE">
        <div className="directes">
          <div className="switch-offtype" style={{ width: 'unset' }}>
            <input
              onClick={() => setShowStreaming1(!showStreaming1)}
              type="radio"
              checked={showStreaming1}
              onChange={() => {}}
            ></input>
            <span className={`slider-offtype round`}>&nbsp;{t('Directes')}</span>
            &nbsp;|&nbsp;
            <input
              onClick={() => setShowStreaming1(!showStreaming1)}
              type="radio"
              checked={!showStreaming1}
              onChange={() => {}}
            ></input>
            <span className={`slider-offtype round`}>&nbsp;{t('Programació Professional')}</span>
          </div>
        </div>
        <div className="grid-name">
          {showStreaming1 &&
            showStreamingList.map((element, key) => (
              <div className="name-container-fira" key={key} id={'live_'.concat(element.artista)}>
                {element.stream !== null ? (
                  <div className="player-small">
                    <ReactPlayer controls={true} url={element.stream.concat('?rel=0')} />{' '}
                  </div>
                ) : (
                  <div className="image">
                    <img src={noStream} alt="placeholder" />
                  </div>
                )}
                <div className="type-line">
                  <div className="activity-type ">{t(element.ambit)}</div>
                </div>
                <div className="show- no-margin ">
                  <div className="d-grid show-ellipsis">
                    <div className="show-title">{element.artista}</div>
                    <div className="show-subtitle">{element.nom}</div>
                  </div>
                </div>
                <div className="access-info">{formatDate(element.data)}</div>
              </div>
            ))}
          {!showStreaming1 &&
            programationStreamingList.map((element, key) => (
              <div className="name-container-fira" key={key} id={'live_'.concat(element.artista)}>
                {element.video !== null ? (
                  <div className="player-small">
                    <ReactPlayer controls={true} url={element.video.concat('?rel=0')} />{' '}
                  </div>
                ) : (
                  <div className="image">
                    <img src={noStream} alt="placeholder" />
                  </div>
                )}
                <div className="type-line">
                  <div className="activity-type ">{t(element.ambit)}</div>
                </div>
                <div className="show- no-margin ">
                  <div className="d-grid show-ellipsis">
                    <div className="show-title">{element.artista}</div>
                    <div className="show-subtitle">{element.nom}</div>
                  </div>
                </div>
                <div className="access-info">{formatDate(element.data)}</div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  const HtmlToReactParser = require('html-to-react').Parser
  const htmlInput = firaContent
  const htmlToReactParser = new HtmlToReactParser()
  const reactElement = htmlToReactParser.parse(htmlInput)
  return (
    <div>
      {!isMobile && renderGoBack()}
      <div className="Live-title">{t('audiovisual_content')}</div>
      <div className="stream-container">
        <ReactPlayer
          controls={true}
          width="100%"
          height={isMobile ? '400px' : '600px'}
          playing={true}
          url={'https://www.youtube.com/embed/'.concat(firaLink).concat('?rel=0')}
        />
        {!isMobile && showXat && (
          <iframe
            title="fira-live"
            src={'https://www.youtube.com/live_chat?v='
              .concat(firaLink)
              .concat('&embed_domain=pro.firamediterrania.cat')}
            width="40%"
            height="600"
          ></iframe>
        )}
      </div>
      <div style={{ textAlign: 'left' }}>{reactElement}</div>
      {renderLiveShows()}
    </div>
  )
}
