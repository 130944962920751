import React from 'react'
import loadingGif from '../../Statics/loading.gif'

export function LoadingComponent({ noClass, width = '100px', height = '100px' }) {
  return (
    <div className={noClass ? '' : 'loading-styles'} data-test="loadingComponentTest">
      <img width={width} height={height} src={loadingGif} alt="loading..." />
    </div>
  )
}
export default LoadingComponent
