import { Dialog } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { ArrowBackIcon } from 'NewVersion/icons/ArrowBackIcon'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import { EmailIcon } from 'NewVersion/icons/EmailIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import { LinkIcon } from 'NewVersion/icons/LinkIcon'
import LinkedinIcon from 'NewVersion/icons/LinkedinIcon'
import { TwitterIcon } from 'NewVersion/icons/TwitterIcon'
import YoutubeIcon from 'NewVersion/icons/YoutubeIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/ProfessionalDialog.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadImageGuillotina } from 'utils/apiCalls'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'

const ProfessionalEntityDialogComponent = ({ uuid, onClose, onClick, onBack }) => {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const { favouiteIds, addFavourite, removeFavourite } = useFavourites()
  const [blobImg, setBlobImg] = useState(null)
  const [loading, setLoading] = useState(true)
  const [professionalEntity, setProfessionalEntity] = useState(null)
  const [managerEntity, setManagerEntity] = useState(null)
  const token = getCurrentJWToken()
  const isFavourite = favouiteIds && uuid && favouiteIds.includes(uuid)

  const getProfessionalEntityInfo = async () => {
    const response = await fetchGuillotina({
      path: `${edition}/@search?uuid=${uuid}`,
      token: token,
    })
    if (response.items.length > 0) {
      const responseData = await fetchGuillotina({
        path: response.items[0]['path'],
        token: token,
      })
      if (responseData && responseData.imatge) {
        await getImg(responseData)
      } else {
        setBlobImg(null)
      }
      if (responseData.representant) {
        const responseRepresentantData = await fetchGuillotina({
          path: responseData.representant,
          token: token,
        })
        setManagerEntity(responseRepresentantData)
      } else {
        setManagerEntity(null)
      }
      setProfessionalEntity(responseData)
      setLoading(false)
    } else {
      setBlobImg(null)
    }
  }

  useEffect(() => {
    if (uuid) {
      setLoading(true)
      getProfessionalEntityInfo()
    } else {
      setLoading(false)
      setProfessionalEntity(null)
    }
  }, [uuid])

  const getImg = async (responseData) => {
    try {
      const image = await downloadImageGuillotina(
        cleanObjectIds(responseData['@id']),
        'imatge/preview',
        'images'
      )
      if (image) {
        setBlobImg(image)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (uuid === null) {
    return <></>
  }

  const renderInfo = (title, value) => (
    <Box className="professional-dialog-content-info">
      <p className="body-2-bold">{title}</p>
      {value}
    </Box>
  )

  const getSocialLinks = () => {
    return (
      <div className="professional-social-links">
        {professionalEntity.facebook && (
          <a target="_blank" href={professionalEntity.facebook} rel="noreferrer">
            <FacebookIcon />
          </a>
        )}
        {professionalEntity.instagram && (
          <a target="_blank" href={professionalEntity.instagram} rel="noreferrer">
            <InstagramIcon />
          </a>
        )}
        {professionalEntity.twitter && (
          <a target="_blank" href={professionalEntity.twitter} rel="noreferrer">
            <TwitterIcon />
          </a>
        )}
        {professionalEntity.youtube && (
          <a target="_blank" href={professionalEntity.youtube} rel="noreferrer">
            <YoutubeIcon />
          </a>
        )}
        {professionalEntity.linkedin && (
          <a target="_blank" href={professionalEntity.linkedin} rel="noreferrer">
            <LinkedinIcon />
          </a>
        )}
      </div>
    )
  }

  const handleClose = () => {
    onClose()
    setLoading(true)
    setBlobImg(null)
  }

  const renderWeb = () => {
    let entity = professionalEntity
    if (managerEntity) {
      entity = managerEntity
    }
    if (entity.web) {
      let web = entity.web
      if (!web.startsWith('https')) {
        if (web.startsWith('http:')) {
          web = web.replace('http:', 'https:')
        } else {
          web = `https://${web}`
        }
      }
      return (
        <a href={web} rel="noreferrer" target="_blank">
          <LinkIcon width="24px" height="24px" />
        </a>
      )
    }
    return null
  }

  const renderEmailIcon = () => {
    let entity = professionalEntity
    if (managerEntity) {
      entity = managerEntity
    }
    if (entity.correu) {
      return (
        <a href={`mailto:${entity.correu}`} rel="noreferrer" target="_blank">
          <EmailIcon width="24px" height="24px" />
        </a>
      )
    }
    return null
  }

  const renderFavouriteIcon = () => {
    if (isTypeAcreditaccio(professionalEntity['@type'])) {
      return (
        <Box
          onClick={() => {
            if (isFavourite) {
              removeFavourite(professionalEntity['@uid'])
            } else {
              addFavourite(professionalEntity['@uid'])
            }
          }}
        >
          <FavouriteIcon width="24px" height="24px" outline={!isFavourite} />
        </Box>
      )
    }
    return null
  }

  const renderEmail = () => {
    let entity = professionalEntity
    if (managerEntity) {
      entity = managerEntity
    }
    return (
      <a href={`mailto:${entity.correu}`} target="_blank" rel="noreferrer">
        {entity.correu}
      </a>
    )
  }

  const renderPhone = () => {
    let entity = professionalEntity
    if (managerEntity) {
      entity = managerEntity
    }
    if (entity.telefon) {
      return (
        <a href={`tel:${entity.telefon}`} target="_blank" rel="noreferrer">
          {entity.telefon}
        </a>
      )
    }
    return null
  }

  const renderProcedencia = () => {
    let entity = professionalEntity
    if (managerEntity) {
      entity = managerEntity
    }
    return (
      <p>{`${entity.comarca ? `${t(entity.comarca)}, ` : ''}${
        entity.comunitat_autonoma ? `${t(entity.comunitat_autonoma)}, ` : ''
      }${t(entity.pais)}`}</p>
    )
  }

  const isTypeAcreditaccio = (type) =>
    type === 'AcreditacioPremsa' || type === 'AcreditacioProfessional'

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={Boolean(uuid)}
        onClose={() => handleClose()}
        classes={{ paper: 'professional-dialog' }}
      >
        <Box
          className="professional-dialog-back-icon"
          onClick={() => onBack()}
          data-test="backDialog-icon"
        >
          <ArrowBackIcon />
        </Box>
        <Box
          className={`professional-dialog-imatge ${
            professionalEntity &&
            (professionalEntity['@type'] === 'AcreditacioProfessional' ||
              professionalEntity['@type'] === 'AcreditacioPremsa')
              ? 'image'
              : ''
          }`}
          data-test="professional-dialog-imatge"
          style={blobImg ? { backgroundImage: `url('${blobImg}')` } : {}}
        />
        {loading || !professionalEntity ? (
          <Box
            className={'professional-dialog-content professional-dialog-loading'}
            data-test="professional-dialog-content"
          >
            <LoadingComponent />
          </Box>
        ) : (
          <Box className={'professional-dialog-content'}>
            <Box
              className="professional-dialog-close-icon"
              onClick={() => handleClose()}
              data-test="closeDialog-icon"
            >
              <CrossIcon />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <h4 className="hide-sm" data-test="dialog-title">
                {professionalEntity.nom
                  ? `${professionalEntity.nom} ${professionalEntity.cognoms}`
                  : professionalEntity.title}
              </h4>
              <Box className={'professional-dialog-content-buttons'}>
                {renderWeb()}
                {renderEmailIcon()}
                {renderFavouriteIcon()}
              </Box>
            </Box>
            <h4 className="display-sm">{`${professionalEntity.nom} ${professionalEntity.cognoms}`}</h4>
            <Box className="divider" mt={'4px'} mb={2} />
            {managerEntity &&
              managerEntity.title &&
              renderInfo(
                t('managment_company'),
                <Box
                  className="cursor-pointer"
                  onClick={() => {
                    if (managerEntity['@uid']) {
                      setBlobImg(null)
                      onClick(managerEntity['@uid'])
                    }
                  }}
                >
                  {managerEntity.title}
                </Box>
              )}
            {professionalEntity.ens_title &&
              renderInfo(
                t('Entitat'),
                <Box
                  className="cursor-pointer"
                  onClick={() => {
                    if (professionalEntity.parent && professionalEntity.parent['@uid']) {
                      setBlobImg(null)
                      onClick(professionalEntity.parent['@uid'])
                    }
                  }}
                >
                  {professionalEntity.ens_title}
                </Box>
              )}
            {professionalEntity.carrec &&
              renderInfo(t('Càrrec'), <p>{professionalEntity.carrec}</p>)}
            {(professionalEntity.codi_postal || professionalEntity.pais) &&
              renderInfo(t('procedencia'), renderProcedencia())}
            <Box className="split-content">
              {professionalEntity.telefon && renderInfo(t('telefon'), renderPhone())}
              {professionalEntity.correu && renderInfo(t('correu'), renderEmail())}
            </Box>
            {managerEntity &&
              managerEntity.items &&
              managerEntity.items.filter((item) => isTypeAcreditaccio(item['@type'])).length > 0 &&
              renderInfo(
                t('manager_acreditats'),
                <>
                  {managerEntity.items
                    .filter((item) => isTypeAcreditaccio(item['@type']))
                    .map((item) => (
                      <Box
                        onClick={() => {
                          if (item['@uid']) {
                            setBlobImg(null)
                            onClick(item['@uid'])
                          }
                        }}
                        key={`others-${item['@uid']}`}
                        className="cursor-pointer"
                        data-test="managerAccrBox"
                      >
                        {item.title}
                      </Box>
                    ))}
                </>
              )}
            {professionalEntity.items &&
              professionalEntity.items.length > 0 &&
              renderInfo(
                t('Acreditats'),
                <>
                  {professionalEntity.items
                    .filter(
                      (item) =>
                        item['@type'] === 'AcreditacioProfessional' ||
                        item['@type'] === 'AcreditacioPremsa'
                    )
                    .map((item) => (
                      <Box
                        onClick={() => {
                          if (item['@uid']) {
                            setBlobImg(null)
                            onClick(item['@uid'])
                          }
                        }}
                        key={`others-${item['@uid']}`}
                        className="cursor-pointer"
                        data-test="accrBox"
                      >
                        {item.title}
                      </Box>
                    ))}
                </>
              )}
            <Box className="divider" mt={1} my={2} />
            {getSocialLinks()}
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  )
}

export const ProfessionalEntityDialog = React.memo(ProfessionalEntityDialogComponent)
