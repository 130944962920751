import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProposalsListItem } from './ProposalsListItem'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'

const ProposalsListComponent = ({ proposals = [], submitProposal, canSubmitProposal }) => {
  const { t } = useTranslation()
  if (proposals.length === 0) {
    return (
      <div className="notInYear-container">
        <AlertIcon />
        <p>{t('not_have_proposals_in_year')}</p>
      </div>
    )
  }

  return proposals.map((proposal) => {
    return (
      <ProposalsListItem
        proposal={proposal}
        key={proposal.path}
        canSubmitProposal={canSubmitProposal}
        submitProposal={submitProposal}
      />
    )
  })
}

export const ProposalsList = React.memo(ProposalsListComponent)
