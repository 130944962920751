import { AuthContext } from 'App'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ProfessionalActivities } from 'Components/Plone/ProfessionalActivityComponents/ProfessionalActivities'
import { ProfessionalActivity } from 'Components/Plone/ProfessionalActivityComponents/ProfessionalActivity'
import * as path from 'Routes/Paths'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  getApiCallPlone,
  getApiCallPloneGeneric,
  guillotinaActionGeneric,
  postFavouriteGuillotina,
} from 'utils/apiCalls'
import { getCurrentJWToken, getCurrentLanguage, getEmailFromToken } from 'utils/utils'

export function ProfessionalActivityPage(props) {
  const [programationList, setProgramationList] = useState(undefined)
  const [currentProgramation, setProgramation] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [favouiteIds, setFavouriteIds] = useState([])
  const { authState } = React.useContext(AuthContext)
  const [reRender, setReRender] = useState(false)

  const getSpecificProgramation = React.useCallback((result) => {
    getApiCallPloneGeneric({
      path: result['@id'],
      loadingCallBack: setIsLoading,
      finishCallBack: handleSetProgramation,
    })
  }, [])

  const handleGetSpecificProgramation = React.useCallback(
    (response) => {
      let tmpArray = []
      // search true, proceed false
      if (response.status === 200) {
        if (response.data.items.length !== 0) {
          tmpArray = response.data.items[0]
        } else {
          navigate(path.ERROR_404_PATH)
        }
      } else {
        navigate(path.ERROR_404_PATH)
      }
      getSpecificProgramation(tmpArray)
    },
    [getSpecificProgramation]
  )

  useEffect(() => {
    if (!props.match.params.hasOwnProperty('programacioId') && !programationList && !isLoading) {
      setIsLoading(true)
      const token = getCurrentJWToken()
      const lang = getCurrentLanguage()
      setProgramation([])
      getApiCallPlone(
        JSON.parse(localStorage.edition)[1] + '@search',
        token,
        setProgramationList,
        setIsLoading,
        false,
        {
          Language: lang,
          review_state: 'published',
          portal_type: 'mediterrania.core.activitat_professional',
        }
      )
    }
  }, [authState.edition, props.match.params, reRender, programationList, isLoading])

  useEffect(() => {
    if (props.match.params.hasOwnProperty('programacioId') && currentProgramation.length === 0) {
      setIsLoading(true)
      getApiCallPloneGeneric({
        path: JSON.parse(localStorage.edition)[1] + '@search',
        finishCallBack: handleGetSpecificProgramation,
        params: {
          UID: props.match.params.programacioId,
          portal_type: 'mediterrania.core.activitat_professional',
        },
      })
    }
  }, [props.match.params, authState.edition, currentProgramation, handleGetSpecificProgramation])

  const handleSetProgramation = (response) => {
    // search false, proceed true
    setProgramation(response.data)
  }

  useEffect(() => {
    guillotinaActionGeneric({
      method: 'GET',
      action: getEmailFromToken(),
      setCallBack: setFavouriteIdsHandler,
    })
  }, [authState.edition])

  const setFavouriteIdsHandler = (response) => {
    setFavouriteIds(response.data.preferits)
  }

  const addFavourite = (element) => {
    // Subscribe a events TODO

    postFavouriteGuillotina(getEmailFromToken(), element.UID, false, true)
    setFavouriteIds(favouiteIds.concat(element.UID))
  }

  const removeFavourite = (element) => {
    // console.log(element);
    guillotinaActionGeneric({
      method: 'PATCH',
      action: getEmailFromToken(),
      data: { preferits: { op: 'remove', value: element.UID } },
    })
    setFavouriteIds(favouiteIds.filter((item) => item !== element.UID))
  }

  return (
    <div>
      {isLoading === true ? (
        <LoadingComponent />
      ) : currentProgramation.length === 0 ? (
        <ProfessionalActivities
          favouiteIds={favouiteIds}
          removeFavourite={removeFavourite}
          addFavourite={addFavourite}
          programationList={programationList || []}
          isLoading={isLoading}
        />
      ) : (
        <ProfessionalActivity
          setReRender={setReRender}
          reRender={reRender}
          programationList={programationList || []}
          removeFavourite={removeFavourite}
          addFavourite={addFavourite}
          favouiteIds={favouiteIds}
          currentProgramation={currentProgramation}
          setProgramation={setProgramation}
        />
      )}
    </div>
  )
}
