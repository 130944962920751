import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { EditAccreditationForm } from 'NewVersion/components/Forms/EditAccreditationForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { NewInscriptionIcon } from 'NewVersion/icons/NewInscriptionIcon'
import 'NewVersion/styles/Acreditacions.scss'
import { ACCOUNT_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ENS_TYPES_OBJ, getEnsType } from 'utils/entityUtils'

const EditPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { edition } = useEdition()
  const [submitModal, setSubmitModal] = useState(false)
  const { entityId, accreditationId } = useParams()

  const {
    data: accreditation,
    isLoading,
    error: accreditationError,
  } = useGuillotina({
    path: `${edition}/${entityId}/${accreditationId}`,
  })
  const {
    data: entity,
    loading: loadingEntity,
    error: entityError,
  } = useGuillotina({ path: `${edition}/${entityId}` })
  const ensType = getEnsType(entity?.type_name, entity?.tipus)

  return (
    <div className="page-wrapper">
      <div className="acreditacions">
        <GoBackButton onClick={() => navigate(ACCOUNT_PATH)} text={t('Tornar a acreditacions')} />
        <div onClick={() => navigate(ACCOUNT_PATH)} className="cursor-pointer">
          <h4>{t(ENS_TYPES_OBJ[ensType]?.titleForm, { year: edition })}</h4>
        </div>
        <ModalInfo
          open={submitModal}
          setOpen={setSubmitModal}
          handleOk={() => navigate(ACCOUNT_PATH)}
          title={t('accreditation_updated')}
          icon={<NewInscriptionIcon />}
          closeAction={() => navigate(ACCOUNT_PATH)}
        />
        {(entityError || accreditationError) && (
          <AlertComponent type="danger">{t('error_generic')}</AlertComponent>
        )}
        {!isLoading && !loadingEntity ? (
          <EditAccreditationForm
            accreditation={accreditation}
            submitAction={() => setSubmitModal(true)}
          />
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  )
}

export default EditPage
