import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/system'
import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { SaveIcon } from 'NewVersion/icons/Save'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { getCurrentJWToken } from 'utils/utils'
import { TextInputField } from '../Fields/InputText'

const ProjectResumeComponent = ({ entityInfo, dataCanido }) => {
  const { t } = useTranslation()
  const [description, setDescription] = useState(entityInfo.description ?? '')
  const [videos, setVideos] = useState(entityInfo.videos ?? [])
  const [videoInput, setVideoInput] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const token = getCurrentJWToken()

  const saveData = async () => {
    setIsEditing(false)
    setLoading(true)
    setError(null)
    try {
      await fetchGuillotina({
        path: entityInfo.path,
        method: 'PATCH',
        data: {
          'guillotina.behaviors.dublincore.IDublinCore': { description: description },
          videos: videos,
        },
        token: token,
        checkError: true,
      })
      setLoading(false)
    } catch (err) {
      setIsEditing(true)
      setDescription(entityInfo.description ?? '')
      setVideos(entityInfo.videos ?? [])
      setError(t('error_generic'))
      setLoading(false)
    }
  }

  const renderVideos = () => {
    return (
      <Box className="project-resume-videos">
        <p>{t('audiovisual_content')}</p>
        {videos.map((link, index) => (
          <Box
            my={1}
            key={`video:${link}`}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={2}
            flexWrap={'wrap'}
          >
            <a target="_blank" href={link} rel="noreferrer">
              {link}
            </a>
            {isEditing && (
              <Chips
                text={t('delete')}
                icon={<TrashIcon />}
                dataTest={'deleteVideoTest'}
                onClick={() => {
                  const newVideos = [...videos]
                  newVideos.splice(index, 1)
                  setVideos(newVideos)
                }}
                disabled={!dataCanido['guillotina.ModifyContent']}
              />
            )}
          </Box>
        ))}
      </Box>
    )
  }

  const renderInfo = () => {
    let descriptionText = description || '-'
    if (dataCanido['guillotina.ModifyContent'] && descriptionText === '') {
      descriptionText = t('add_description')
    }
    return (
      <>
        <Box mb={2}>
          <p>{descriptionText}</p>
        </Box>
        {renderVideos()}
      </>
    )
  }

  const renderEditing = () => {
    return (
      <>
        <Box mb={2} width={'100%'}>
          <TextInputField
            type="text"
            onChange={({ target }) => setDescription(target.value)}
            dataTest="inputProjectDescriptionTest"
            value={description}
            multiline={true}
            helperText={t('add_description')}
          />
        </Box>
        {renderVideos()}
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={1}
          width={'100%'}
        >
          <TextInputField
            type="text"
            onChange={({ target }) => setVideoInput(target.value)}
            dataTest="inputProjectDescriptionTest"
            value={videoInput}
            helperText={t('new_url_video')}
          />
          <Chips
            text={t('add')}
            icon={<SaveIcon />}
            dataTest={'saveVideoTest'}
            onClick={() => {
              setVideos([...videos, videoInput])
              setVideoInput('')
            }}
            disabled={!videoInput || !videoInput.length > 1}
          />
        </Box>
      </>
    )
  }

  const renderButtons = () => {
    if (loading) {
      return <LoadingComponent height="24px" width="24px" />
    }
    if (!dataCanido['guillotina.ModifyContent']) return null

    return !isEditing ? (
      <Chips
        text={t('editar')}
        icon={<EditIcon />}
        dataTest={'entityDetailItemChip'}
        onClick={() => {
          setIsEditing(!isEditing)
        }}
      />
    ) : (
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Chips
          text={t('cancel')}
          icon={<CrossIcon />}
          dataTest={'entityDetailItemChip'}
          onClick={() => {
            setIsEditing(!isEditing)
            setDescription(entityInfo.description ?? '')
            setVideos(entityInfo.videos ?? [])
          }}
        />
        <Chips
          text={t('save')}
          icon={<SaveIcon />}
          dataTest={'entityDetailItemChip'}
          onClick={() => {
            saveData()
          }}
        />
      </Box>
    )
  }

  return (
    <div className="entityDetailItem_element">
      <div className="entityDetailItem__title mb-10">
        <p>{t('informacio_projecte_title')}</p>
        {renderButtons()}
      </div>
      <Box className="entityDetailItem__box" flexDirection={'column'}>
        {!isEditing ? renderInfo() : renderEditing()}
        {error && (
          <Box mt={1}>
            <AlertComponent type="danger">{error}</AlertComponent>
          </Box>
        )}
      </Box>
    </div>
  )
}

export const ProjectResumeBox = memo(ProjectResumeComponent)
