export const ArtistesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.5C12.8284 12.5 13.5 11.8284 13.5 11C13.5 10.1716 12.8284 9.5 12 9.5C11.1716 9.5 10.5 10.1716 10.5 11C10.5 11.8284 11.1716 12.5 12 12.5ZM12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 6C3.25 5.0335 4.0335 4.25 5 4.25H19C19.9665 4.25 20.75 5.0335 20.75 6V20C20.75 20.9665 19.9665 21.75 19 21.75H5C4.0335 21.75 3.25 20.9665 3.25 20V6ZM5 5.75C4.86193 5.75 4.75 5.86193 4.75 6V20C4.75 20.1381 4.86193 20.25 5 20.25H19C19.1381 20.25 19.25 20.1381 19.25 20V6C19.25 5.86193 19.1381 5.75 19 5.75H5Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.99961 2V5H6.59961V2H7.99961Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9996 2V5H15.5996V2H16.9996Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.36144 18.2197C6.26921 18.8204 5.75 19.6057 5.75 20.5H4.25C4.25 18.8943 5.23079 17.6796 6.63856 16.9053C8.03387 16.1379 9.92003 15.75 12 15.75C13.564 15.75 15.4301 16.008 16.9354 16.6922C18.4445 17.3782 19.75 18.5829 19.75 20.5H18.25C18.25 19.4171 17.5555 18.6218 16.3146 18.0578C15.0699 17.492 13.436 17.25 12 17.25C10.08 17.25 8.46613 17.6121 7.36144 18.2197Z"
      fill="white"
    />
  </svg>
)
