export const ExpanMoreIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75" clipPath="url(#clip0_2529_23983)">
      <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" fill="#2B2A29" />
    </g>
    <defs>
      <clipPath id="clip0_2529_23983">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
