import { AddCalendarIcon } from 'NewVersion/icons/AddCalendarIcon'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { ClockIcon } from 'NewVersion/icons/ClockIcon'
import { ComprarEntradaIcon } from 'NewVersion/icons/ComprarEntradaIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { LocationIcon } from 'NewVersion/icons/LocationIcon'
import { ShareIcon } from 'NewVersion/icons/ShareIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import XIcon from 'NewVersion/icons/XIcon'
import { formatDateOnlyDate, getDayBefore } from 'NewVersion/utils/date'
import { getMainImage, getSecondaryImages } from 'NewVersion/utils/image'
import { formattedPerformancesBySameData } from 'NewVersion/utils/performance'
import { capitalizeFirstLetter } from 'NewVersion/utils/text'
import * as path from 'Routes/Paths'
import { useFavourites } from 'hooks/useFavourites'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { get } from 'utils/objectUtils'
import { getCurrentLanguage } from 'utils/utils'
import Button from '../UI/Buttons/Button'
import Chips from '../UI/Chips/Chips'

export function TopShow({ show, vocabulary, edition, currentEdition }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentLanguage = getCurrentLanguage()
  const [locationsState, setLocationsState] = useState([])
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()
  const [loading, setLoading] = useState(true)
  const [shareButton, setShareButton] = useState(false)
  const [imagePrimary, setImagePrimary] = useState(null)
  const [imagesSecondary, setImagesSecondary] = useState([])
  const slider = React.createRef()

  const getImages = () => {
    const imagePrimaryResponse = getMainImage({ item: show, edition, size: 'large' })
    const imageSecondaryResponse = getSecondaryImages({ item: show, edition, size: 'large' })
    if (imagePrimaryResponse) {
      setImagePrimary(imagePrimaryResponse)
    }
    if (imageSecondaryResponse) {
      setImagesSecondary(imageSecondaryResponse)
    }
    setLoading(false)
  }

  useEffect(() => {
    if ((!imagePrimary || !imagesSecondary) && show) {
      setLoading(true)
      getImages()
    }
  }, [show])

  const searchInVocabulary = (itemName, vocabulary, lang = null) => {
    const result = vocabulary.find((element) => element.token === itemName)
    if (lang) {
      return get(result, `title.lang-${lang}`, '')
    } else {
      return result.title.default
    }
  }

  const topBadge = (color, text, key) => {
    return (
      <div key={key} className="topBadge" style={{ backgroundColor: color }}>
        <b>{t(text)}</b>
      </div>
    )
  }

  const formattedPerformances = useMemo(() => {
    if (show && show.actuacions) {
      const res = formattedPerformancesBySameData(show.actuacions)
      const espais = []

      res.forEach((item) => {
        if (
          item.espai &&
          vocabulary?.espai &&
          !espais.includes(searchInVocabulary(item.espai, vocabulary.espai))
        ) {
          espais.push(
            item.amagar_info_espai
              ? t('hide_performance_place')
              : searchInVocabulary(item.espai, vocabulary.espai)
          )
        }
      })
      setLocationsState(espais)
      return res
    }
    return []
  }, [show])

  const isAllPerformancesCanceled = useMemo(() => {
    if (show && (show.actuacions ?? []).length > 0) {
      return !(show.actuacions ?? []).some((actuacio) => !actuacio.cancelat)
    }
    return false
  }, [show])

  // amb_canvis
  const isShowChanges = useMemo(() => {
    if (show && (show.actuacions ?? []).length > 0) {
      return show.actuacions.some((actuacio) => actuacio.canvis && actuacio.canvis.length > 0)
    }
    return false
  }, [show])

  const renderScheduleButton = (performance) => {
    const isFavourite = favouiteIds && favouiteIds.includes(performance['@uid'])
    if (isFavourite) {
      return (
        <Chips
          text={t('delete')}
          icon={
            <div style={{ width: '16px', height: '16px' }}>
              <TrashIcon />
            </div>
          }
          dataTest={'deleteCalendar'}
          tooltip={t('remove_to_shecule')}
          disabled={isFavouritesLoading}
          onClick={() => {
            removeFavourite(performance['@uid'])
          }}
        />
      )
    }
    return (
      <Chips
        text={t('afegir')}
        icon={
          <div style={{ width: '16px', height: '16px' }}>
            <AddCalendarIcon />
          </div>
        }
        dataTest={'addCalendar'}
        tooltip={t('add_to_shecule')}
        disabled={isFavouritesLoading}
        onClick={() => {
          addFavourite(performance['@uid'])
        }}
      />
    )
  }

  const carrousel = () => (
    <div className={'carrousel-container'}>
      <Slider
        arrows={
          (imagePrimary ? 1 : 0) + imagesSecondary.length + show.videos.length > 1 ? true : false
        }
        className={'carrousel'}
        ref={slider}
        slidesToShow={1}
        slidesToScroll={1}
        speed={500}
        infinite={false}
      >
        {imagePrimary && (
          <div>
            <img
              alt="Main performance image"
              width="100%"
              height="75%"
              style={{ aspectRatio: '4:3' }}
              src={imagePrimary}
            />
          </div>
        )}
        {imagesSecondary.map((src) => (
          <div key={src}>
            <img alt="Secondary performance image" width="100%" height="75%" src={src} />
          </div>
        ))}
        {show.videos.map((src) => (
          <div className={'carrousel__item-container'} key={src}>
            <iframe allowFullScreen frameBorder="0" loading="lazy" src={src} />
          </div>
        ))}
      </Slider>

      {/* Cancel·lat */}
      {isAllPerformancesCanceled && (
        <div className={'mask'}>
          <div>{t('cancelat')}</div>
        </div>
      )}

      {/* Canvi hora */}
      {isShowChanges && !isAllPerformancesCanceled && (
        <div className={'mask-time-change'}>
          <div className={'mask-time-change__icon'}>
            <AlertIcon />
          </div>
          <div className={'mask-time-change__label'}>{t('show_with_changes')}</div>
        </div>
      )}
    </div>
  )

  return (
    <>
      {vocabulary && show && !loading && (
        <div className="topShowBox">
          <div className="topShowContentBox">
            <div className="topShowContentBadges">
              {show.estrena &&
                show.estrena !== 'no' &&
                topBadge('#F2B02B', t(`estrena:${show.estrena}`), 'showEstrenaBadge')}
              {vocabulary?.eix &&
                show.eix.length > 0 &&
                show.eix.map((item) =>
                  topBadge('#8FC88F', searchInVocabulary(item, vocabulary.eix), `eix_value_${item}`)
                )}
            </div>

            <div className="topShowContent">
              <div className="topShowContentDetails">
                <p>{searchInVocabulary(show.ambit, vocabulary.ambits, currentLanguage)}</p>
                <h1>{show.artistes_info[0]}</h1>
                <h4>{show.title}</h4>

                {/* Calendar, Location & Duration */}
                {vocabulary.espai &&
                  formattedPerformances.map((item, index) => {
                    return (
                      <div className="topShowContentDetail" key={'topShowContentDetail' + index}>
                        <div className="topShowContentDetailElement">
                          <CalendarIcon />
                          <span className={item.cancelat ? 'canceled' : ''}>
                            {item.amagar_info_data
                              ? t('hide_performance_date')
                              : capitalizeFirstLetter(
                                  formatDateOnlyDate(item.data, currentLanguage)
                                )}
                            <span> {item.amagar_info_data ? '' : item.hores.join(' / ')}</span>
                            <span>
                              {item.night
                                ? ` (${getDayBefore(item.data, currentLanguage).toLowerCase()} ${t(
                                    'night'
                                  )})`
                                : ''}
                            </span>
                          </span>
                          {item.cancelat && isAllPerformancesCanceled ? (
                            <span className="red">{t('cancelat')}</span>
                          ) : (
                            renderScheduleButton(item)
                          )}
                        </div>

                        {locationsState.length !== 1 && item.espai && (
                          <div className="topShowContentDetailElement">
                            <LocationIcon />
                            {item.amagar_info_espai
                              ? t('hide_performance_place')
                              : searchInVocabulary(item.espai, vocabulary.espai)}
                          </div>
                        )}
                      </div>
                    )
                  })}

                {locationsState.length === 1 && (
                  <div className="topShowContentDetailElement">
                    <LocationIcon />
                    {locationsState[0]}
                  </div>
                )}

                <div className="topShowContentDetailElement">
                  <ClockIcon />
                  <span>
                    {t('durada')}&nbsp;
                    {(Math.floor(show.durada / 60) < 10
                      ? '0' +
                        (Math.floor(show.durada / 60) < 1 ? '0' : Math.floor(show.durada / 60))
                      : Math.floor(show.durada / 60)) +
                      ':' +
                      (show.durada % 60 < 10 ? '0' + (show.durada % 60) : show.durada % 60)}
                  </span>
                </div>
                <div className="topShowContentButtonsMobile">
                  <Button icon={<ComprarEntradaIcon />} onClick={() => navigate(path.TIKETS_PATH)}>
                    {t('buy_ticket')}
                  </Button>
                  <div className="shareBox" onClick={() => setShareButton(!shareButton)}>
                    <ShareIcon />
                    <p>{t('Compartir')}</p>
                    {shareButton && (
                      <>
                        <FacebookIcon color="#5C5958" />
                        <XIcon color="#5C5958" />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="topShowImgBox">
                {carrousel()}
                {!currentEdition.callIsFinished() && (
                  <div className="topShowButtonsDesktop">
                    <Button
                      icon={<ComprarEntradaIcon />}
                      onClick={() => navigate(path.TIKETS_PATH)}
                    >
                      {t('buy_ticket')}
                    </Button>
                    <div className="shareBox" onClick={() => setShareButton(!shareButton)}>
                      <ShareIcon />
                      <p>{t('Compartir')}</p>
                      {shareButton && (
                        <>
                          <FacebookIcon color="#5C5958" />
                          <XIcon color="#5C5958" />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
