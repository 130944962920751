export const EsborranyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2506_22611)">
      <circle cx="7.99992" cy="7.99999" r="6.16667" fill="#5C5958" stroke="#5C5958" />
    </g>
    <defs>
      <clipPath id="clip0_2506_22611">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
