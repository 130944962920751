// const pushServerPublicKey = "TUZrd0V3WUhLb1pJemowQ0FRWUlLb1pJemowREFRY0RRZ0FFM0NLM0FEd0lTSjhBMzM3MUtVenZFcU9YajZwMWU4WE1SMWM3eXFPSUZqclhFaXZzSGJ1bUFMR0dKd0s1aHVEdGpwWERRWUZVRHQ0ODlpdzdRQjNoSUE9PQ";
// MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE3CK3ADwISJ8A3371KUzvEqOXj6p1e8XMR1c7yqOIFjrXEivsHbumALGGJwK5huDtjpXDQYFUDt489iw7QB3hIA==
/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission()
}
/**
 * shows a notification
 */
function sendNotification(name) {
  if (isPushNotificationSupported()) {
    navigator.serviceWorker.ready.then(function (serviceWorker) {
      const title = "L'espectacle esta a punt de començar!"
      const options = {
        body: name,
        icon: 'logo.jpeg',
        badge: 'logo.jpeg',
        vibrate: [200, 100, 200],
      }
      serviceWorker.showNotification(title, options)
    })
  }
}

function receivePushNotification(event) {
  console.log(event)
  sendNotification(event)
}

if (isPushNotificationSupported()) {
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    navigator.serviceWorker.addEventListener('push', (event) => {
      console.log('skip waiting2')
    })
  })
  window.addEventListener('push', receivePushNotification)
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  if (isPushNotificationSupported()) {
    // wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready
    // subscribe and return the subscription
    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }

    const vapidPublicKey =
      'BBPhhbEZEJ5X_UM81Xcn_Mf3OgNXcarc7pF-kC_MnDE2DxbvUuixdVAxaCoDf9jCFlrqURuw3F6uFSgmJ9OG67o'
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
    return await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    })
  }
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  if (isPushNotificationSupported()) {
    // wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
      .then(function (serviceWorker) {
        return serviceWorker.pushManager.getSubscription()
      })
      .then(function (pushSubscription) {
        return pushSubscription
      })
  }
}

export {
  askUserPermission,
  createNotificationSubscription,
  getUserSubscription,
  isPushNotificationSupported,
  sendNotification,
}
