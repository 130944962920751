import 'NewVersion/styles/UI/InputCheckBox.scss'

export const InputCheckbox = ({
  label = '',
  onChange = null,
  checked = undefined,
  error = '',
  required = false,
  name,
  dataTest,
  ...rest
}) => {
  return (
    <>
      <div className="checkbox">
        <label>
          <input
            data-test={dataTest}
            name={name}
            type="checkbox"
            onChange={onChange}
            checked={checked && 'checked'}
            {...rest}
          />
          <span>{label}</span>
        </label>
      </div>
      {error && (
        <div style={{ color: 'red' }} data-test={`errorTest_${dataTest}`}>
          {error}
        </div>
      )}
    </>
  )
}
