import React, { useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import axios from 'axios'
import AccreditationDetail from 'Components/AccreditationComponents/AccreditationDetail'
import { getEmailFromToken } from 'utils/utils'
import { getCurrentJWToken } from 'utils/utils'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { useParams } from 'react-router'
import { AlertComponent } from 'Components/HelperComponents'
import { useTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'

export function AccreditationPage() {
  const { t } = useTranslation()
  const [errorFetchData, setErrorFetchData] = useState(undefined)
  const [data, setData] = useSetState({
    accreditation: undefined,
    loading: false,
  })
  const { acreditatId } = useParams()

  useEffect(() => {
    if (!data.accreditation && !data.loading) {
      axios({
        method: 'get',
        url: JSON.parse(localStorage.edition)[1] + '@search',
        params: {
          advanced_search: true,
          metadata_fields: '_all',
          portal_type: 'mediterrania.core.acreditacio',
          mediterrania_propietari: getEmailFromToken(),
          UID: acreditatId,
        },
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getCurrentJWToken()}`,
        },
      })
        .then(function (response) {
          if (get(response, 'data.items[0]', null)) {
            setData({
              accreditation: get(response, 'data.items[0]', null),
              loading: false,
            })
          } else {
            setErrorFetchData('not-found')
            setData({
              accreditation: {},
              loading: false,
            })
          }
        })
        .catch(function (error) {
          setErrorFetchData(error.response)
          setData({
            accreditation: {},
            loading: false,
          })
        })
    }
  }, [data, acreditatId, setData])

  if (data.loading || !data.accreditation) return <LoadingComponent />
  if (errorFetchData) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }
  return (
    <div>
      <AccreditationDetail accreditation={data.accreditation} />
    </div>
  )
}
export default AccreditationPage
