import 'NewVersion/styles/UI/Button.scss'

const Button = ({
  type,
  variant = '',
  icon = undefined,
  iconEnd = undefined,
  color = 'primary',
  children = undefined,
  size = 'medium',
  dataTest,
  onClick,
  disabled,
  ariaLabel,
}) => {
  return (
    <button
      type={type}
      className={`base-button ${color} ${variant} ${size}`}
      data-test={dataTest}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {icon && <div style={{ height: 24 }}>{icon}</div>} {children}
      {iconEnd && <span className="icon-end">{iconEnd}</span>}
    </button>
  )
}

export default Button
