import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import 'NewVersion/styles/UI/BasicSelector.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function MenuSelector({ label, items, onClick = undefined, icon }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="base-selector">
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="name"
      >
        <div className="label-selector">
          {icon}
          <span>{label}</span>
        </div>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(items ?? []).map((item) => {
          return (
            <MenuItem
              key={item.key}
              onClick={() => {
                onClick && onClick(item)
              }}
            >
              <div className="selected-icon">{item.icon}</div>
              {t(item.key)}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
