export const CrossIcon = ({ fill, width = '36', height = '36' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.36841 7.50072L28.5 30.6323L30.6316 28.5007L7.49999 5.36914L5.36841 7.50072ZM28.5 5.36914L5.36841 28.5007L7.49999 30.6323L30.6316 7.50072L28.5 5.36914Z"
      fill={fill ? fill : 'white'}
    />
  </svg>
)
