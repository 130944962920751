import { AuthContext } from 'App'
import * as path from 'Routes/Paths'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { useEdition } from './useEdition'

export function useLogout() {
  const { dispatch } = useContext(AuthContext)
  const { reset } = useEdition()
  const navigate = useNavigate()

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    })
    reset()
    navigate(path.LOGIN_PATH)
  }

  return logout
}
