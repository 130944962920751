import React, { useState, useEffect } from 'react'
import Notification from '../Components/NotificationComponents/Notification'
import Notifications from '../Components/NotificationComponents/Notifications'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { guillotinaActionGeneric, getApiCallGuillotinaDirectUrl } from 'utils/apiCalls'
import { getCurrentJWToken } from '../utils/utils'

function NotificationsHandler() {
  const { t } = useTranslation()
  const [currentNotification, renderNotification] = useState([])
  const [notificationList, setNotificationList] = useState([])

  const callNotificationsList = React.useCallback(
    (elements) => {
      elements.forEach(function (entry) {
        getApiCallGuillotinaDirectUrl(entry['@id'], getCurrentJWToken(), (element) => {
          setNotificationList((notificationsArray) => [...notificationsArray, element])
        })
      })
    },
    [setNotificationList]
  )

  useEffect(() => {
    localStorage.removeItem('broadcastNOW')
    const element = document.getElementById('notificationBoxID')
    if (element) {
      element.classList.remove('notificated')
    }
    guillotinaActionGeneric({
      method: 'GET',
      action: '@search?type_name=Notificacio',
      setCallBack: (response) => {
        callNotificationsList(response.data.items)
      },
    })
  }, [callNotificationsList])

  const formatTimestamp = (timestamp) => {
    const time = timestamp
    const d = new Date(time)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const hour = d.getHours()
    // let minutes = d.getMinutes()
    const text = t('a les ')
    const minutes = (d.getMinutes() < 10 ? '0' : '').concat(d.getMinutes().toString())
    return (
      <div>
        {day}/{month} {text}
        {hour}:{minutes}
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className="Notification">
        <div className="notification-container-mobile">
          {currentNotification && currentNotification.length === 0 ? (
            <Notifications
              formatTimestamp={formatTimestamp}
              currentNotification={currentNotification}
              renderNotification={renderNotification}
              notificationList={notificationList}
            />
          ) : (
            <Notification
              formatTimestamp={formatTimestamp}
              currentNotification={currentNotification}
              renderNotification={renderNotification}
              notificationList={notificationList}
            />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="limited-desktop-height">
        <div className="title-generic account-1">{t('Avisos')}</div>
        <div className="Notification">
          <div className="notification-container">
            <Notifications
              formatTimestamp={formatTimestamp}
              currentNotification={currentNotification}
              renderNotification={renderNotification}
              notificationList={notificationList}
            />
          </div>
          <div className="notification-element">
            <Notification
              formatTimestamp={formatTimestamp}
              currentNotification={currentNotification}
              renderNotification={renderNotification}
              notificationList={notificationList}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default NotificationsHandler
