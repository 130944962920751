import ClearIcon from '@mui/icons-material/Clear'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import 'NewVersion/styles/UI/SelectInputField.scss'
import { useState } from 'react'
export const SelectInputField = ({
  label = '',
  value = '',
  dataTest = '',
  description = '',
  disabled = false,
  error = '',
  name,
  onBlur,
  required = false,
  isMulti = false,
  items = [],
  onChange,
  onClear,
}) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <FormControl variant="standard" className="select" error={error}>
      <InputLabel className={'label'} id={`${name}-label`}>
        {label} {<span className="asterisk">{required ? '*' : ''}</span>}{' '}
        <span className="description">{description}</span>
      </InputLabel>
      <Select
        variant="standard"
        labelId={`${name}-label`}
        id={name}
        multiple={isMulti}
        value={isMulti && !value ? [] : value}
        onChange={onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        data-test={dataTest}
        open={open}
        SelectDisplayProps={{ className: 'selected-field', style: { whiteSpace: 'normal' } }}
        MenuProps={{ className: 'selector-menu' }}
        endAdornment={
          !required &&
          ((isMulti && value.length > 0) || (!isMulti && value !== '' && value)) && (
            <InputAdornment sx={{ marginRight: '10px' }} position="end">
              <IconButton
                onClick={() => {
                  onClear()
                }}
              >
                <ClearIcon></ClearIcon>
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {items.map((item, index) => (
          <MenuItem
            key={`${item.value}-${index}`}
            value={item.value}
            name={item.value}
            onClick={() => handleClose()}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {/* {description && <FormHelperText>{description}</FormHelperText>} */}
    </FormControl>
  )
}
