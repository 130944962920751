export const swrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
}

export const swrOptionsStatic = {
  ...swrOptions,
  revalidateIfStale: false,
  dedupingInterval: 3600000,
}
