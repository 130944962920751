import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CloseOutlined } from '@mui/icons-material'
import { Box } from '@mui/system'
import { requiredError } from 'Components/Forms/FormErrors'
import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { AddProfessionalsIconOutline } from 'NewVersion/icons/AddProfessionalsIconOutline'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Form.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useVocabularies } from 'hooks/useVocabularies'
import {
  ACCREDITATIONS_SIZES_IMAGE,
  AFIRMATIVE_VALUE,
  OBJECT_TYPE_HAVE_RATE,
} from 'utils/constants'
import { ENS_TYPES, getVocabularies } from 'utils/entityUtils'
import { get } from 'utils/objectUtils'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'
import EditComponent from '../Fields/EditComponent'
import { InputAcceptRegulation } from '../Fields/InputAcceptRegulation'
import Button from '../UI/Buttons/Button'

const EntityAndAccreditationsForm = ({ ensTypeObj, submitAction, accreditationTypeObj }) => {
  const entityVocabularies = useVocabularies(getVocabularies(ensTypeObj))
  const { edition, currentEdition, loading } = useEdition()
  const accreditationVocabularies = useVocabularies([
    {
      vocabulary: accreditationTypeObj.tipologia,
    },
  ])

  const {
    data: entitySchemaData,
    isLoading: entitySchemaLoading,
    error: entitySchemaError,
  } = useGuillotina({
    path: `@types/${ensTypeObj['interface']}`,
  })

  const {
    data: accreditationSchemaData = {},
    isLoading: accreditationSchemaLoading,
    error: accreditationSchemaError,
  } = useGuillotina({
    path: `@types/${accreditationTypeObj['interface']}`,
  })

  if (
    loading ||
    !accreditationVocabularies.data ||
    accreditationVocabularies.loading ||
    !entityVocabularies.data ||
    entityVocabularies.loading ||
    accreditationSchemaLoading ||
    entitySchemaLoading
  ) {
    return <LoadingComponent />
  }

  return (
    <EntityAndAccreditationsFormContent
      entityVocabularies={entityVocabularies}
      edition={edition}
      currentEdition={currentEdition}
      entitySchema={{
        data: entitySchemaData,
        error: entitySchemaError,
        loading: entitySchemaLoading,
      }}
      accreditationSchema={{
        data: accreditationSchemaData,
        error: accreditationSchemaError,
        loading: accreditationSchemaLoading,
      }}
      submitAction={submitAction}
      ensTypeObj={ensTypeObj}
      accreditationTypeObj={accreditationTypeObj}
    />
  )
}

const EntityAndAccreditationsFormContent = ({
  edition,
  currentEdition,
  entitySchema,
  accreditationSchema,
  submitAction,
  ensTypeObj,
  accreditationTypeObj,
  readOnlyForm = false,
}) => {
  const [totalAccreditationsToAdd, setTotalAccreditationsToAdd] = useState(1)
  const { t } = useTranslation()

  const [submitModal, setSubmitModal] = useState(false)

  const [submitError, setSubmitError] = React.useState({
    hasError: false,
    message: '',
  })

  // eslint-disable-next-line no-unused-vars
  const handleEntityError = (error) => {
    console.error(`Entity and Accreditation form error: ${error}`)
    if (error) {
      if (get(error, 'code') === 'already-exists') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('title_already_exists')}`,
        })
      } else if (get(error, 'code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const isArtistOrManager = () => {
    return (
      ensTypeObj['interface'] === ENS_TYPES.artist || ensTypeObj['interface'] === ENS_TYPES.manager
    )
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const valuesEntity = {}
    const token = getCurrentJWToken()

    Object.keys(values).forEach((valueKey) => {
      if (valueKey.indexOf('entity_') === 0) {
        valuesEntity[valueKey.replace('entity_', '')] = values[valueKey]
      }
    })

    const dataEntity = Object.assign({}, valuesEntity, {
      '@type': ensTypeObj['interface'],
      tarifa: currentEdition.getCurrentRateByType(
        isArtistOrManager()
          ? OBJECT_TYPE_HAVE_RATE.ens_artist_or_manager
          : OBJECT_TYPE_HAVE_RATE.ens
      ),
    })

    if (dataEntity.pais !== 'country-ES') {
      dataEntity.comunitat_autonoma = null
      dataEntity.comarca = null
    } else if (dataEntity.comunitat_autonoma !== 'catalunya') {
      dataEntity.comarca = null
      dataEntity.comunitat_autonoma = dataEntity.comunitat_autonoma.value
    }

    const dataAccreditations = []
    const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
    keysAccreditationForm.forEach((index) => {
      const valuesAccreditation = {}
      Object.keys(values).forEach((valueKey) => {
        if (valueKey.indexOf(`accreditation_${index}_`) === 0) {
          valuesAccreditation[valueKey.replace(`accreditation_${index}_`, '')] = values[valueKey]
        }
      })

      const dataAccreditation = {}
      dataAccreditation['values'] = Object.assign({}, valuesAccreditation, {
        '@type': accreditationTypeObj.interface,
        tarifa: 'estandard', // TODO mirar de l'edicio si ja ha passat la data per posar reduida o estandard
        idioma: dataEntity.idioma,
      })

      dataAccreditation['values']['primer_cop_fira'] =
        valuesAccreditation['primer_cop_fira'] === AFIRMATIVE_VALUE

      dataAccreditation['values'].tipologia =
        (dataAccreditation['values'].tipologia && dataAccreditation['values']?.tipologia?.value) ||
        []
      if (dataAccreditation['values']['imatge'] !== null) {
        dataAccreditation['imatge'] = dataAccreditation['values']['imatge']
        delete dataAccreditation['values']['imatge']
      }

      dataAccreditations.push(dataAccreditation)
    })

    try {
      const response = await fetchGuillotina({
        path: `${edition}`,
        method: 'POST',
        data: dataEntity,
        token: token,
      })
      const entityPath = response['@id']
      const entityId = response['@name']
      for (let i = 0; i < dataAccreditations.length; i++) {
        const dataToSend = dataAccreditations[i]
        let path = cleanObjectIds(entityPath)
        const responseAccreditation = await fetchGuillotina({
          path: path,
          method: 'POST',
          data: dataToSend['values'],
          token: token,
        })

        path = `${path}/${responseAccreditation['@name']}`

        if (dataToSend['imatge'] && dataToSend['imatge'] instanceof File) {
          let filename = undefined
          if (dataToSend['imatge'].filename) {
            filename = dataToSend['imatge'].filename
          } else if (dataToSend['imatge'].name) {
            filename = dataToSend['imatge'].name
          }
          const fileHeaders = {
            'X-UPLOAD-EXTENSION': dataToSend['imatge'].type.split('/')[1],
            // 'X-UPLOAD-SIZE': dataToSend['imatge'].size,
            'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
            'CONTENT-TYPE': dataToSend['imatge'].type,
          }
          await fetchGuillotina({
            path: `${path}/@upload/imatge`,
            method: 'PATCH',
            token: token,
            headers: {
              ...fileHeaders,
            },
            data: dataToSend['imatge'],
            notStringify: true,
          })

          for (let i = 0; i < ACCREDITATIONS_SIZES_IMAGE.length; i++) {
            await fetchGuillotina({
              path: `${path}/@images/imatge/${ACCREDITATIONS_SIZES_IMAGE[i]}`,
              method: 'PATCH',
              token: token,
              headers: {
                ...fileHeaders,
              },
            })
          }
        }
      }
      const responsePayment = await fetchGuillotina({
        path: `${edition}/${entityId}/@search?type_name=Pagament`,
        token: getCurrentJWToken(),
        checkError: true,
        method: 'GET',
      })
      if (responsePayment.items_total > 0) {
        const payment = responsePayment.items[0]
        await fetchGuillotina({
          path: `${payment['path']}/@workflow/pendent`,
          method: 'POST',
          token: token,
        })
        if (submitAction) {
          const promoCode = dataEntity['descompte_promocional']
          await submitAction(payment.uuid, promoCode)
        }
      }
      if (ensTypeObj['interface'] !== ENS_TYPES.press) {
        throw new Error('Error')
      } else {
        await submitAction()
      }
    } catch (error) {
      console.error(`Error create entity and accreditation ${error}`)
      handleEntityError(error)
    }
    setSubmitting(false)
  }

  const getInitialValues = React.useMemo(() => {
    const result = {}
    ensTypeObj['requiredFields'].forEach((item) => {
      result[`entity_${item}`] = undefined
    })

    const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
    keysAccreditationForm.forEach((index) => {
      accreditationTypeObj['requiredFields'].forEach((item) => {
        result[`accreditation_${index}_${item}`] = undefined
      })
    })

    result['declaracio_acceptar'] = false
    return result
  }, [ensTypeObj])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      ensTypeObj['requiredFields'].forEach((key) => {
        if (key !== 'declaracio_acceptar') {
          const keyForm = `entity_${key}`
          if ((!(keyForm in values) || !values[keyForm]) && renderLocationFields(key)) {
            errors[keyForm] = requiredError()
          }
        }
      })

      const keyForm = `entity_correu`
      if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        errors[keyForm] = t('invalid_email')
      }

      const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
      keysAccreditationForm.forEach((index) => {
        accreditationTypeObj['requiredFields'].forEach((key) => {
          if (key !== 'declaracio_acceptar') {
            const keyForm = `accreditation_${index}_${key}`
            if (!(keyForm in values) || !values[keyForm]) {
              errors[keyForm] = requiredError()
            }
          }
        })
        const keyForm = `accreditation_${index}_correu`
        if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
          errors[keyForm] = t('invalid_email')
        }
      })
      if (!('declaracio_acceptar' in values) || !values['declaracio_acceptar']) {
        errors['declaracio_acceptar'] = requiredError()
      }
      return errors
    },
  })

  const fieldsWithPrefixLabel = ['idioma', 'correu']

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `ens_${key}`
    return key
  }

  const renderInputAcceptRegulation = (key) => {
    return (
      <InputAcceptRegulation
        onChange={(e) => {
          formik.setFieldValue(key, e.target.checked)
          formik.validateForm(Object.assign({}, formik.values, { key: e.target.checked }))
        }}
        onBlur={formik.onBlur}
        checked={!!formik.values[key]}
        error={formik.touched[key] && formik.errors[key]}
        readOnly={readOnlyForm}
        name={key}
      />
    )
  }

  const renderLocationFields = (key) => {
    if (key === 'comunitat_autonoma' && get(formik, 'values.entity_pais', '') !== 'country-ES') {
      return false
    }
    if (
      key === 'comarca' &&
      (get(formik, 'values.entity_pais', '') !== 'country-ES' ||
        get(formik, 'values.entity_comunitat_autonoma', '') !== 'catalunya')
    ) {
      return false
    }
    return true
  }

  const renderFields = (key) => {
    if (key === 'declaracio_acceptar') return false
    return renderLocationFields(key)
  }

  const getDescription = (value, key) => {
    /*
    TODO: Refactor de les descripcions dels camps, evitar casos unics i utilitzar el type_name per crear les claus,
    es necessari modificar back per retornar la clau en el camp descripcio per exemple, analitzar-ho bé.
    */
    if (key === 'correu') {
      return t('ens_correu_description')
    }
    return value.description ? t(`${key}_description`) : null
  }

  const resetForm = () => {
    const result = formik.values
    accreditationTypeObj['requiredFields'].forEach((item) => {
      result[`accreditation_${totalAccreditationsToAdd}_${item}`] = undefined
    })
    formik.resetForm({
      values: result,
    })
    setTotalAccreditationsToAdd(totalAccreditationsToAdd + 1)
  }

  return (
    <>
      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}
      <form
        className="Form"
        onSubmit={(values) => formik.handleSubmit(values)}
        data-test="formTest"
      >
        {entitySchema &&
          entitySchema.data &&
          !entitySchema.loading &&
          ensTypeObj['orderFieldsForm']
            .filter((item) => item.title !== 'declaracio_fieldset')
            .map((item) => {
              return (
                <Fragment key={item['title']}>
                  <h5>{t(item['title'])}&nbsp;</h5>
                  {item['subtitle'] && item['subtitle'] !== '' && <p>{t(item['subtitle'])}</p>}
                  <div className="fragment">
                    {item['fields'].map((key) => {
                      if (renderFields(key) && get(entitySchema, `data.properties[${key}]`, null)) {
                        const value = entitySchema.data.properties[key]
                        const keyForm = `entity_${key}`
                        return (
                          <EditComponent
                            key={keyForm}
                            name={keyForm}
                            label={
                              key === 'title'
                                ? t(getLabel(ensTypeObj['labelName']))
                                : t(getLabel(key))
                            }
                            schema={value}
                            description={getDescription(value, key)}
                            value={formik.values[keyForm]}
                            required={(ensTypeObj['requiredFields'] ?? []).includes(key)}
                            onBlur={() => {
                              formik.setFieldTouched(keyForm)
                            }}
                            onChange={(ev) => {
                              formik.setFieldValue(keyForm, ev)
                              formik.validateForm()
                            }}
                            error={formik.touched[keyForm] && formik.errors[keyForm]}
                            dataTest={`inputFieldTest_${keyForm}`}
                          />
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}

        <h4 className="header-add-accreditations">
          {`${
            ensTypeObj['interface'] === ENS_TYPES.professional
              ? t('professional_registrations')
              : t('press_registrations')
          }`}
        </h4>
        <p>{t('professional_registrations_subtitle')}</p>
        {[...Array(totalAccreditationsToAdd).keys()].map((index) => {
          return (
            <div key={`accreditationForm_${index}`} className="acreditacio">
              <div>
                <h5>
                  {t('acreditacio')}&nbsp;{index + 1}
                </h5>
                {index > 0 && (
                  <Button
                    type="button"
                    variant="outlined"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      const result = formik.values
                      accreditationTypeObj['requiredFields'].forEach((item) => {
                        delete result[`accreditation_${totalAccreditationsToAdd - 1}_${item}`]
                      })
                      formik.resetForm({
                        values: result,
                      })
                      setTotalAccreditationsToAdd(totalAccreditationsToAdd - 1)
                    }}
                  >
                    {t('delete')}
                  </Button>
                )}
              </div>

              {accreditationSchema &&
                accreditationSchema.data &&
                !accreditationSchema.loading &&
                accreditationTypeObj['orderFieldsForm']
                  .filter((item) => item.title !== 'declaracio_fieldset')
                  .map((item, indexItem) => {
                    return (
                      <div
                        className="fragment"
                        key={`accreditationFormSection_${item['title']}_${indexItem}`}
                      >
                        {item['fields'].map((key) => {
                          if (get(accreditationSchema, `data.properties[${key}]`, null)) {
                            const value = accreditationSchema.data.properties[key]
                            const keyForm = `accreditation_${index}_${key}`
                            return (
                              <EditComponent
                                key={keyForm}
                                name={keyForm}
                                label={t(key)}
                                schema={value}
                                description={value.description ? t(`${key}_description`) : null}
                                value={formik.values[keyForm]}
                                required={(accreditationTypeObj['requiredFields'] ?? []).includes(
                                  key
                                )}
                                onBlur={() => {
                                  formik.setFieldTouched(keyForm)
                                }}
                                onChange={(ev) => {
                                  formik.setFieldValue(keyForm, ev)
                                  formik.validateForm()
                                }}
                                error={formik.touched[keyForm] && formik.errors[keyForm]}
                                dataTest={`inputFieldTest_${keyForm}`}
                              />
                            )
                          }
                        })}
                      </div>
                    )
                  })}
            </div>
          )
        })}
        <div>
          <Button
            type={'button'}
            onClick={() => {
              const maxAccreditationNumber =
                currentEdition.getConfigData()[ensTypeObj.totalAccreditationAttribute]
              if (totalAccreditationsToAdd >= maxAccreditationNumber) {
                setSubmitModal(true)
              } else {
                resetForm()
              }
            }}
            icon={<AddProfessionalsIconOutline fill={'white'} />}
          >
            {t('add_more_accreditations')}
          </Button>
        </div>
        <Box mt={5} mb={3}>
          <h5>{t('declaracio_fieldset')}</h5>
          {renderInputAcceptRegulation('declaracio_acceptar')}
        </Box>

        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!readOnlyForm && !formik.isSubmitting && (
            <div className="footer-buttons">
              <Button type="submit" data-test="btnSubmitFormTest" disabled={formik.isSubmitting}>
                {t('next')}
              </Button>
            </div>
          )}
        </div>
      </form>
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        text={<Trans i18nKey="alert_professional_acreditat" />}
        icon={<AddProfessionalsIconOutline fill={'#273BF3'} />}
        handleOk={resetForm}
        handleCancel={() => setSubmitModal(false)}
        textOk={t('alert_professional_acreditat_ok')}
        textCancel={t('alert_professional_acreditat_cancel')}
      ></ModalInfo>
    </>
  )
}

export default EntityAndAccreditationsForm
