import { Edition2021Home } from 'Components/Home/Edition2021Home'
import { BACKEND_TYPE, OBJECT_TYPE_HAVE_RATE, RATES } from 'utils/constants'
import { Edition } from './Edition'

export default class Edition2021 extends Edition {
  canCreateEnsProfessionals() {
    if (!this.data) return false
    return this.data && this.data.registrar_professionals
  }

  canCreateEnsPremsa() {
    if (!this.data) return false
    return this.data && this.data.registrar_premsa
  }

  canCreateEnsServeis() {
    if (!this.data) return false
    return this.data.registrar_fira_passeig
  }

  getRegulationInfo(lang) {
    if (!this.data) return ''
    return this.data[`normativa_proposta_${lang}`]
  }

  getPaymentInfo(lang) {
    if (!this.data) return ''
    return this.data[`informacio_pagament_${lang}`]
  }

  getStatementOfResponsibility(lang) {
    if (!this.data) return ''
    return this.data[`declaracio_responsable_proposta_${lang}`]
  }

  callIsFinished() {
    if (!this.data || !this.userPerms) return true
    return (
      new Date(this.data.data_hora_limit_presentar_proposta).getTime() < new Date().getTime() &&
      !this.userPerms['fm.PresentarProposta']
    )
  }

  homeComponent() {
    return <Edition2021Home />
  }

  getUrl() {
    return `${process.env.REACT_APP_GUILLOTINA}2021/`
  }

  getCurrentRateByType(type) {
    let result = RATES.standard
    if (type === OBJECT_TYPE_HAVE_RATE.ens_artist_or_manager) {
      result = RATES.free
    } else if (type === OBJECT_TYPE_HAVE_RATE.ens || type === OBJECT_TYPE_HAVE_RATE.stand) {
      if (
        new Date(this.data.data_hora_final_tarifa_reduida_entitats_professionals).getTime() >
        new Date().getTime()
      ) {
        result = RATES.reduced
      }
    } else if (type === OBJECT_TYPE_HAVE_RATE.service) {
      if (
        new Date(this.data.data_hora_final_tarifa_reduida_parades).getTime() > new Date().getTime()
      ) {
        result = RATES.reduced
      }
    }
    return result
  }

  getBackendType() {
    return BACKEND_TYPE.GUILLOTINA
  }

  async canAccesToInfo() {
    return true
  }

  getFiraDaysFilters(t) {
    return [
      [t('Dijous'), '14', '1634169600'],
      [t('Divendres'), '15', '1634256000'],
      [t('Dissabte'), '16', '1634342400'],
      [t('Diumenge'), '17', '1634428800'],
    ]
  }

  getFiraProfessionalActivitiesDaysFilters(t) {
    return [
      [t('Dilluns'), '4', '1633305600'],
      [t('Dimarts'), '5', '1633392000'],
      [t('Dimecres'), '6', '1633478400'],
      [t('Dijous'), '7', '1633564800'],
      [t('Divendres'), '8', '1633651200'],
      [t('Dijous'), '14', '1634169600'],
      [t('Divendres'), '15', '1634256000'],
      [t('Dissabte'), '16', '1634342400'],
      [t('Diumenge'), '17', '1634428800'],
    ]
  }
}
