import WarningRounded from '@mui/icons-material/WarningRounded'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as path from '../Routes/Paths'

function NotAllowPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname === '/index.html') {
      navigate(path.HOME_PATH)
    }
  }, [])

  return (
    <div className="wrapper-empty-info">
      <b>{t('not_allow_page')}</b>
      <br></br>
      <br></br>
      <WarningRounded style={{ fontSize: 80 }} />
      <br></br>
      <br></br>
      <b>
        <div style={{ color: 'black', textTransform: 'none' }}>{t('contact_admin')}</div>
      </b>
    </div>
  )
}
export default NotAllowPage
