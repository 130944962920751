import axios from 'axios'
import { getEmailFromToken } from 'utils/utils'
import * as path from '../Routes/Paths'
import { GOOGLE_RECAPTCHAKEY, LIMIT_ELEMENTS_GUILLOTINA } from './constants'
import { getCurrentJWToken, getCurrentLanguage } from './utils'

const getItemsRecursively = (data, list, token, setCallBack, finishCallBack) => {
  if (data.next) {
    axios({
      method: 'get',
      url: data.next,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        let tmpArray = []
        if (response.status === 200) {
          if (response.data.items) {
            tmpArray = response.data.items
          }
        }
        const newList = list.concat(tmpArray)
        setCallBack(newList)
        getItemsRecursively(response.data.batching, newList, token, setCallBack, finishCallBack)
      })
      .catch(function () {
        console.log('failed part, proceding')
      })
  } else {
    if (finishCallBack) {
      finishCallBack(list)
    }
    return
  }
}

const getApiCallPlone = (
  url = '',
  token = '',
  setCallBack = '',
  loadingCallBack = '',
  finishCallBack = '',
  extraParams = {}
) => {
  let params = {
    advanced_search: true,
    metadata_fields: '_all',
    sort_on: 'mediterrania_timestamp',
    b_size: LIMIT_ELEMENTS_GUILLOTINA,
  }
  params = Object.assign(params, extraParams)

  axios({
    method: 'get',
    url: url,
    params: params,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function (response) {
      let tmpArray = []
      if (response.status === 200) {
        tmpArray = response.data.items
      }
      if (setCallBack) {
        setCallBack(tmpArray)
      }
      if (loadingCallBack) {
        loadingCallBack(false)
      }

      if (response.data.batching) {
        getItemsRecursively(response.data.batching, tmpArray, token, setCallBack, finishCallBack)
      }
    })
    .catch(function (response) {
      // handle error
      if (setCallBack) {
        setCallBack([])
      }
      if (loadingCallBack) {
        loadingCallBack(false)
      }
    })
}

const getApiCallImagePlone = (url, token) => {
  return axios({
    method: 'get',
    url: url,
    responseType: 'blob',
    headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return window.URL.createObjectURL(response.data)
    })
    .catch(function (response) {
      return ''
    })
}

const getApiCallPloneDirectUrlCustom = (
  url = '',
  token = '',
  setCallBack = false,
  customId = '',
  customId2 = ''
) => {
  axios({
    method: 'get',
    url: url,
    params: {
      advanced_search: true,
      metadata_fields: '_all',
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function (response) {
      let tmpArray = []
      if (response.status === 200) {
        if (response.data) {
          tmpArray = response.data
        }
      }
      setCallBack(tmpArray, customId, customId2)
    })
    .catch(function (response) {
      console.log('failed fetch, proceding')
    })
}

const postFavouriteGuillotina = (
  user = '',
  uuid = '',
  finishCallBack = false,
  flag = true,
  operation = 'appendunique',
  token = ''
) => {
  // mirariem primer si el usuari te carpeta, si no la te, la creem
  axios({
    method: 'get',
    url: process.env.REACT_APP_GUILLOTINA_DB.concat(user),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then(function (response) {
      axios({
        method: 'PATCH',
        url: process.env.REACT_APP_GUILLOTINA_DB.concat(user),
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getCurrentJWToken()}`,
        },
        data: {
          op: 'append',
          preferits: {
            op: operation,
            value: uuid,
          },
        },
      })
        .then(function (response) {
          if (finishCallBack) {
            finishCallBack(!flag)
          }
        })
        .catch(function (response) {
          console.log('failed part, proceding')
        })
    })
    .catch(function (response) {
      console.log('not found')
      axios({
        method: 'POST',
        url: process.env.REACT_APP_GUILLOTINA_DB + '@creaUsuari',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getCurrentJWToken()}`,
        },
        data: {
          preferits: [uuid],
        },
      })
        .then(function (response) {
          if (finishCallBack) {
            finishCallBack()
          }
        })
        .catch(function (response) {
          console.log('failed part, proceding')
        })
    })
}

const getItemsRecursivelyGuillotina = (URL, list, index, setCallBack, isFinished = false) => {
  if (!isFinished) {
    axios({
      method: 'get',
      url: URL,
      params: { b_size: LIMIT_ELEMENTS_GUILLOTINA, _from: index },
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getCurrentJWToken()}`,
      },
    }).then(function (response) {
      if (response.data.items.length !== 0) {
        let tmpArray = []
        if (response.status === 200) {
          if (response.data.items) {
            tmpArray = response.data.items
          }
        }
        const newList = list.concat(tmpArray)
        getItemsRecursivelyGuillotina(URL, newList, LIMIT_ELEMENTS_GUILLOTINA + index, setCallBack)
      } else {
        getItemsRecursivelyGuillotina(
          URL,
          list,
          LIMIT_ELEMENTS_GUILLOTINA + index,
          setCallBack,
          true
        )
      }
    })
  } else {
    setCallBack(list)
  }
}

const getApiCallPloneByEmail = (url = '', uid = '', token = '', finishCallBack = false) => {
  const params = {
    advanced_search: true,
    metadata_fields: '_all',
    mediterrania_propietari: uid,
  }
  axios({
    method: 'get',
    url: url,
    params: params,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function (response) {
      let tmpArray = []
      if (response.status === 200) {
        if (response.data.items.length !== 0) {
          const result = response.data.items.filter(
            (item) => item['@type'] === 'mediterrania.core.acreditacio'
          )
          tmpArray = result[0]
        }
      }
      if (finishCallBack) {
        finishCallBack(tmpArray)
      }
    })
    .catch(function (response) {
      console.log('No tens acces a aquest recurs')
    })
}

const getApiCallGuillotinaDirectUrl = (url = '', token = '', setCallBack = false) => {
  axios({
    method: 'get',
    url: url,
    params: {
      advanced_search: true,
      metadata_fields: '_all',
    },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function (response) {
      let tmpArray = []
      if (response.status === 200) {
        if (response.data) {
          tmpArray = response.data
        }
      }
      if (response.status === 401) {
        // Unauthorize
        localStorage.clear()
        window.location = 'https://pro.firamediterrania.cat' + path.LOGIN_PATH
      }
      setCallBack(tmpArray)
    })
    .catch(function (response) {
      console.log('failed fetch, proceding')
    })
}

const getSubscriptionsGuillotina = (user, callback, currentSub) => {
  // mirariem primer si el usuari te carpeta, si no la te, la creem
  axios({
    method: 'get',
    url: process.env.REACT_APP_GUILLOTINA_DB.concat(user),
    params: { b_size: LIMIT_ELEMENTS_GUILLOTINA },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then(function (response) {
      callback(response.data.subscripcions, currentSub)
    })
    .catch(function (response) {
      callback([])
    })
}

const getAdminPermissions = (loading) => {
  axios({
    method: 'GET',
    url: 'https://oldplone.firamediterrania.cat/mediterrania2019/@sharing?search=admin',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then(function (response) {
      if (response.data === {}) {
        // navigate(path.HOME_PATH)
      } else {
        loading(false)
      }
    })
    .catch(function (response) {
      // navigate(path.HOME_PATH)
    })
}

const getAllAnysGuillotina = (callback = false) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA + '@search?type_name=Year',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      const arrayAnys = []
      response.data.items.forEach((any) => {
        arrayAnys.push(any.id)
      })
      arrayAnys.sort().reverse() // Sort years by most recent
      callback && callback(arrayAnys)
    })
    .catch((error) => {
      console.log(error)
    })
}

const getAllEnsGuillotinaPromise = (tipus = '', year = '') => {
  let tipusQs = ''
  if (tipus === 'artista') {
    tipusQs = '&tipus=artista'
  } else if (tipus === 'representant') {
    tipusQs = '&tipus=representant'
  }

  const url = process.env.REACT_APP_GUILLOTINA.concat(
    `${year}/@search?type_name=Ens${tipusQs}&owners=${getEmailFromToken()}&_sort_des=creation_date`
  )
  return axios({
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  }).then((response) => {
    const getResult = (list) => {
      return list.map((ens) => {
        return {
          label: ens.title + ' (' + ens.year + ')',
          value: ens.path,
          tipus: ens.tipus,
          year: ens.year,
        }
      })
    }
    if (response.data.items_total > LIMIT_ELEMENTS_GUILLOTINA) {
      getItemsRecursivelyGuillotina(url, response.data.items, LIMIT_ELEMENTS_GUILLOTINA, (list) => {
        return new Promise((resolve, _reject) => {
          resolve(getResult(list))
        })
      })
    }
    return new Promise((resolve, _reject) => {
      resolve(response.data.items)
    })
  })
}

const getAllEnsGuillotina = (tipus = '', year = '', callback = false) => {
  let tipusQs = ''
  if (tipus === 'artista') {
    tipusQs = '&tipus=artista'
  } else if (tipus === 'representant') {
    tipusQs = '&tipus=representant'
  }
  const url = process.env.REACT_APP_GUILLOTINA.concat(
    `${year}/@search?type_name=Ens${tipusQs}&owners=${getEmailFromToken()}&_sort_des=creation_date`
  )
  axios({
    method: 'GET',
    url,
    params: { b_size: LIMIT_ELEMENTS_GUILLOTINA, _from: 0 },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      const getResult = (list) => {
        return list.map((ens) => {
          return {
            label: ens.title + ' (' + ens.year + ')',
            value: ens.path,
            tipus: ens.tipus,
            year: ens.year,
          }
        })
      }
      if (response.data.items_total > LIMIT_ELEMENTS_GUILLOTINA) {
        getItemsRecursivelyGuillotina(
          url,
          response.data.items,
          LIMIT_ELEMENTS_GUILLOTINA,
          (list) => {
            callback && callback(getResult(list))
          }
        )
      }
      callback && callback(getResult(response.data.items))
    })
    .catch((error) => {
      console.log(error)
    })
}

const getAllPropostesGuillotina = (year = '', callback = false, onError = null) => {
  const url = process.env.REACT_APP_GUILLOTINA.concat(
    `${year}/@search?type_name=Proposta&_sort_des=creation_date`
  )
  axios({
    method: 'GET',
    url,
    params: { b_size: LIMIT_ELEMENTS_GUILLOTINA, _from: 0 },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      if (response.data.items_total > LIMIT_ELEMENTS_GUILLOTINA) {
        getItemsRecursivelyGuillotina(url, response.data.items, LIMIT_ELEMENTS_GUILLOTINA, callback)
      } else {
        callback && callback(response.data.items)
      }
    })
    .catch((error) => {
      console.log(error)
      if (onError) {
        onError(error)
      }
    })
}

const postEnsGuillotina = (values = {}, callback = false, callbackError = false) => {
  axios({
    method: 'POST',
    url: process.env.REACT_APP_GUILLOTINA.concat(`${JSON.parse(localStorage.edition)[0]}/`),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
    data: values,
  })
    .then((response) => {
      const path = '/' + response.data['@id'].split('/').slice(-2).join('/')
      callback &&
        callback({
          label: values.title,
          value: path,
          year: JSON.parse(localStorage.edition)[0],
        })
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(error)
      }
      console.log(error)
    })
}

const patchEnsGuillotina = (path = '', values = {}, callback = false, callbackError = false) => {
  axios({
    method: 'PATCH',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
    data: values,
  })
    .then(() => {
      callback && callback()
    })
    .catch((error) => {
      if (callbackError) {
        callbackError(error)
      }
      console.log(error)
    })
}

const getEnsGuillotina = (path = '', callback = false) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      callback && callback(response.data)
    })
    .catch((error) => {
      console.log(error)
    })
}

const getEnsGuillotinaExtra = (path = '', callback = false, setter, extra) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      callback && callback(response.data, setter, extra)
    })
    .catch((error) => {
      console.log(error)
    })
}

const postPropostaGuillotina = (values = {}, path = '', callback = false) => {
  axios({
    method: 'POST',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
    data: values,
  })
    .then((response) => {
      const nameProposta = response.data['@name']
      callback && callback(nameProposta)
    })
    .catch((error) => {
      console.log(error)
    })
}

const patchFileGuillotina = (values = {}, path = '', field) => {
  let filename = undefined
  if (values.filename) {
    filename = values.filename
  } else if (values.name) {
    filename = values.name
  }
  return axios({
    method: 'PATCH',
    url: process.env.REACT_APP_GUILLOTINA.concat(path + '/@upload/' + field),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
      'X-UPLOAD-EXTENSION': values.type.split('/')[1],
      'X-UPLOAD-SIZE': values.size,
      'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
      'CONTENT-TYPE': values.type,
    },
    data: values,
  })
}

const patchPropostaGuillotina = (
  path = '',
  values = {},
  callback = false,
  callbackError = false
) => {
  axios({
    method: 'PATCH',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
    data: values,
  })
    .then(() => {
      callback && callback()
    })
    .catch((error) => {
      callbackError && callbackError()
      console.log(error)
    })
}

const getPropostaGuillotina = (path = '', callback = false) => {
  axios({
    method: 'GET',
    url: `${process.env.REACT_APP_GUILLOTINA}${path}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      callback && callback(response.data)
    })
    .catch((error) => {
      console.log(error)
    })
}

const presentarPropostaGuillotina = (
  path = '',
  arrayPropostes = [],
  callback = false,
  callbackError = false
) => {
  axios({
    method: 'POST',
    url: process.env.REACT_APP_GUILLOTINA.concat(
      path + '/@workflow/presentar?lan=' + getCurrentLanguage()
    ),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      arrayPropostes.forEach((proposta) => {
        if (proposta.path === path) {
          proposta.review_state = response.data.title.toLowerCase()
        }
      })
      callback && callback(arrayPropostes)
    })
    .catch((error) => {
      console.log(error)
      callbackError && callbackError(error)
    })
}

const duplicarEnsGuillotina = (path = '', callback = false, callbackError = false) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA.concat(path),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      axios({
        method: 'POST',
        url: process.env.REACT_APP_GUILLOTINA.concat(JSON.parse(localStorage.edition)[0]),
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getCurrentJWToken()}`,
        },
        data: {
          '@type': 'Ens',
          idioma: response.data.idioma,
          tarifa: response.data.tarifa,
          tipus: response.data.tipus,
          representant: response.data.representant,
          title: response.data.title,
          nom_fiscal: response.data.nom_fiscal,
          nif: response.data.nif,
          adreca: response.data.adreca,
          codi_postal: response.data.codi_postal,
          poblacio: response.data.poblacio,
          pais: response.data.pais,
          comunitat_autonoma: response.data.comunitat_autonoma,
          comarca: response.data.comarca,
          telefon: response.data.telefon,
          mobil: response.data.mobil,
          correu: response.data.correu,
          nom_contacte: response.data.nom_contacte,
          cognoms_contacte: response.data.cognoms_contacte,
          web: response.data.web,
          facebook: response.data.facebook,
          twitter: response.data.twitter,
          instagram: response.data.instagram,
          linkedin: response.data.linkedin,
          youtube: response.data.youtube,
        },
      })
        .then((response) => {
          const nameEns = JSON.parse(response.config.data).title
          const path = '/' + response.data['@id'].split('/').slice(-2).join('/')
          callback &&
            callback({
              label: nameEns,
              value: path,
              year: JSON.parse(localStorage.edition)[0],
            })
        })
        .catch((error) => {
          callbackError && callbackError(error)
          console.log(error)
        })
    })
    .catch((error) => {
      console.log(error)
    })
}

const duplicarEnsGuillotinaObj = (repr, callback = false) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA.concat(repr.value),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      callback(repr, response.data)
    })
    .catch((error) => {
      console.log(error)
    })
}

const getDateLimitGuillotina = (callback = false) => {
  axios({
    method: 'GET',
    url: process.env.REACT_APP_GUILLOTINA.concat(`${JSON.parse(localStorage.edition)[0]}/`),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then((response) => {
      callback && callback(response.data.data_hora_limit_presentar_proposta)
    })
    .catch((error) => {
      console.log(error)
    })
}

const downloadImageGuillotina = (path, field, type = 'download') => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_GUILLOTINA.concat(`${path}/@${type}/${field}`),
      responseType: 'blob',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getCurrentJWToken()}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response)
        }

        const objectURL = URL.createObjectURL(response.data)
        resolve(objectURL)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// BEGIN GENERIC API CALLS (REFACTOR)
const getApiCallPloneGeneric = (parameters) => {
  const { path, loadingCallBack, finishCallBack, params = {}, sort = true } = parameters
  let extraParams = {
    advanced_search: true,
    metadata_fields: '_all',
  }
  if (sort) {
    // ByUID no acepten aquests params?
    extraParams.sort_on = 'mediterrania_timestamp'
    extraParams.b_size = LIMIT_ELEMENTS_GUILLOTINA
  }
  extraParams = Object.assign(params, extraParams)
  axios({
    method: 'GET',
    url: path,
    params: extraParams,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
    .then(function (response) {
      if (loadingCallBack) {
        loadingCallBack(false)
      }
      finishCallBack(response)
    })
    .catch(function (response) {
      if (loadingCallBack) {
        loadingCallBack(false)
      }
      finishCallBack(response)
    })
}

const guillotinaActionGeneric = (params) => {
  const {
    method,
    action,
    setCallBack = false,
    loadingCallBack = false,
    setErrorCallback = false,
    guillotinaData = false,
    data = {},
  } = params

  let absolutePath = `${process.env.REACT_APP_GUILLOTINA_DB}${action}`
  if (guillotinaData) {
    absolutePath = `${process.env.REACT_APP_GUILLOTINA}${action}`
  }

  axios({
    method: method,
    url: absolutePath,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
    data: data,
  })
    .then(function (response) {
      if (setCallBack) {
        setCallBack(response)
      }
      if (loadingCallBack) {
        loadingCallBack(false)
      }
      if (response.status === 500 && setErrorCallback) {
        setErrorCallback(true)
      }
    })
    .catch(function (response) {
      if (response.status === 500 && setErrorCallback) {
        setErrorCallback(true)
      }
    })
}

const postGuillotinaObjectUnAuth = async (path, values = {}) => {
  const recaptchaToken = await createNewCaptchaCode()
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_GUILLOTINA}${path}`,
    data: values,
    headers: {
      Accept: 'application/json',
      'X-VALIDATION-G': recaptchaToken,
    },
  })
}

const fetchPlone = (parameters) => {
  const { path, params = {}, sort = true } = parameters
  let extraParams = {
    advanced_search: true,
    metadata_fields: '_all',
  }
  if (sort) {
    // ByUID no acepten aquests params?
    extraParams.sort_on = 'mediterrania_timestamp'
    extraParams.b_size = LIMIT_ELEMENTS_GUILLOTINA
  }
  extraParams = Object.assign(params, extraParams)
  return axios({
    method: 'GET',
    url: path,
    params: extraParams,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
    },
  })
}

const fetchGuillotina = (
  path,
  { method, data, params, guillotinadb = false, headers = {} } = {}
) => {
  let absolutePath = `${process.env.REACT_APP_GUILLOTINA}${path}`
  if (guillotinadb) {
    absolutePath = `${process.env.REACT_APP_GUILLOTINA_DB}${path}`
  }
  return axios({
    method: method ?? 'GET',
    url: absolutePath,
    params: params ?? {},
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getCurrentJWToken()}`,
      ...headers,
    },
    data: data ?? null,
  })
}

function createNewCaptchaCode() {
  return window.grecaptcha.execute(GOOGLE_RECAPTCHAKEY, {
    action: '',
  })
}

// END GENERIC API CALLS (REFACTOR)

export {
  createNewCaptchaCode,
  downloadImageGuillotina,
  duplicarEnsGuillotina,
  duplicarEnsGuillotinaObj,
  fetchGuillotina,
  fetchPlone,
  getAdminPermissions,
  getAllAnysGuillotina,
  getAllEnsGuillotina,
  getAllEnsGuillotinaPromise,
  getAllPropostesGuillotina,
  getApiCallGuillotinaDirectUrl,
  getApiCallImagePlone,
  getApiCallPlone,
  getApiCallPloneByEmail,
  getApiCallPloneDirectUrlCustom,
  getApiCallPloneGeneric,
  getDateLimitGuillotina,
  getEnsGuillotina,
  getEnsGuillotinaExtra,
  getPropostaGuillotina,
  getSubscriptionsGuillotina,
  guillotinaActionGeneric,
  patchEnsGuillotina,
  patchFileGuillotina,
  patchPropostaGuillotina,
  postEnsGuillotina,
  postFavouriteGuillotina,
  postGuillotinaObjectUnAuth,
  postPropostaGuillotina,
  presentarPropostaGuillotina,
}
