import { LoadingComponent } from 'Components/HelperComponents'
import 'NewVersion/styles/EntradesPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { get } from 'utils/objectUtils'
import { getCurrentLanguage, getEmailFromToken } from 'utils/utils'

function EntradesPage() {
  const { t } = useTranslation()
  const { currentEdition, edition, loading } = useEdition()
  const [hasAccreditations, setHasAccreditations] = useState({
    data: undefined,
    loading: false,
  })

  const { data: accreditations, isLoading: loadingAccreditations } = useGuillotina({
    path: `${edition}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa&owners=${getEmailFromToken()}`,
  })

  useEffect(() => {
    if (edition && hasAccreditations.data === undefined && !loadingAccreditations) {
      setHasAccreditations({
        data: get(accreditations, 'items', []),
        loading: false,
      })
    }
  }, [edition, setHasAccreditations, hasAccreditations, loadingAccreditations])

  const showTicketsLink = useMemo(() => {
    const edtition_data = currentEdition.getConfigData()
    if (
      currentEdition &&
      edtition_data['solicitar_entrades_pro'] &&
      hasAccreditations.data &&
      hasAccreditations.data.length > 0 &&
      !loading
    ) {
      return true
    }
    return false
  }, [hasAccreditations, loading])

  return (
    <div className="Entrades">
      <h3>{t('Les meves entrades')}</h3>
      {hasAccreditations.loading && <LoadingComponent />}
      {!hasAccreditations.loading && (
        <div>
          {showTicketsLink ? (
            <>
              {getCurrentLanguage() === 'en' ? (
                <div>
                  <p>
                    {t('Pots reservar entrades EN')}&nbsp;
                    <Link
                      to={
                        'https://docs.google.com/forms/d/e/1FAIpQLSfNTcOqDMrBhvL-zL8WsTJU71-B7GFLnIn26CpzvDN3HfxMug/viewform'
                      }
                    >
                      {t('entity_enllacos')}.
                    </Link>
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    {t('Pots reservar entrades')}&nbsp;
                    <Link to={'https://kursaal.koobin.cat/index.php?action=PU_zona_personal'}>
                      {t('get_tickets')}.
                    </Link>
                  </p>
                </div>
              )}
              <p>{t('Rebras un correu electronic')}</p>
              <p>{t('Si vols cancel·lar entrades')}</p>
              <p>{t('Pots reservar les teves entrades')}</p>
              <p>{t('Durant els dies de la Fira les Taquilles')}</p>
            </>
          ) : (
            <b>{t('can_not_request_ticket')}</b>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(EntradesPage)
