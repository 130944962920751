import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProposalEntitiesListItem } from './ProposalEntitiesListItem'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'

const ProposalEntitiesListComponent = ({ entities = [] }) => {
  const { t } = useTranslation()
  if (entities.length === 0) {
    return (
      <div className="notInYear-container">
        <AlertIcon />
        <p>{t('not_have_entities_in_year')}</p>
      </div>
    )
  }

  return entities.map((entity) => {
    return <ProposalEntitiesListItem entity={entity} key={entity.path} />
  })
}

export const ProposalEntitiesList = React.memo(ProposalEntitiesListComponent)
