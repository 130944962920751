import React from 'react'
import 'NewVersion/styles/Form.scss'

export const InputCheckbox = ({
  label = '',
  onChange = null,
  checked = undefined,
  error = '',
  required = false,
  name,
  dataTest,
  ...rest
}) => {
  return (
    <div className="checkbox">
      <label className="label">
        <input
          data-test={dataTest}
          name={name}
          type="checkbox"
          onChange={onChange}
          checked={checked && 'checked'}
          {...rest}
        />
        <span className="checkbox">{label}</span>
      </label>
      {error && (
        <div className="Form-error" data-test={`errorTest_${dataTest}`}>
          {error}
        </div>
      )}
    </div>
  )
}
