import * as React from 'react'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ProposalsList } from './ProposalsList'
import { useGuillotina } from 'hooks/useGuillotina'

const ProposalsComponent = ({ year }) => {
  const { data: { items: proposals = [] } = {}, isLoading: loading } = useGuillotina({
    path: `${year}/@search?type_name=Proposta&_sort_des=creation_date`,
  })

  return (
    <div className="proposalItem">
      {loading && <LoadingComponent />}
      <ProposalsList proposals={proposals} />
    </div>
  )
}

export const Proposals = React.memo(ProposalsComponent)
