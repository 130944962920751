import Badges from 'NewVersion/components/UI/Badges/Badges'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { EsborranyIcon } from 'NewVersion/icons/EsborranyIcon'
import { OKIconBlue } from 'NewVersion/icons/OKIcon'
import { ViewIcon } from 'NewVersion/icons/ViewIcon'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const ProposalsListItemComponent = ({ proposal }) => {
  const { t } = useTranslation()
  const { currentEdition } = useEdition()

  return (
    <div className="proposalItem-container" data-test={`boxProposalTest_${proposal['id']}`}>
      <div className="proposalItem-header">
        <p>{proposal.nom}</p>
        {proposal.review_state === 'presentada' || currentEdition.callIsFinished() ? (
          <Chips
            text={t('veure')}
            icon={<ViewIcon />}
            dataTest={'viewChip'}
            url={path.PROPOSALS_FORM_PATH.concat(`${proposal.path}`)}
          ></Chips>
        ) : (
          <Chips
            text={t('editar')}
            icon={<EditIcon />}
            dataTest={'editChip'}
            url={path.PROPOSALS_FORM_PATH.concat(`${proposal.path}`)}
          ></Chips>
        )}
      </div>

      <div className="proposalItem-content">
        <div className="proposalItem-content_element">
          <p>{t('representant')}</p>
          <p>{proposal.title_representant ? proposal.title_representant : t('without_manager')}</p>
        </div>
        <div className="proposalItem-content_element">
          <p>{t('Artista')}</p>
          <p>{proposal.title_artist}</p>
        </div>
        <div className="proposalItem-content_element">
          <p>{t('Estat de la proposta')}</p>
          <Badges
            text={t(proposal.review_state)}
            icon={proposal.review_state === 'presentada' ? <OKIconBlue /> : <EsborranyIcon />}
            dataTest={'proposalListItemBadges'}
            className={proposal.review_state === 'presentada' ? 'presentada' : 'esborrany'}
          ></Badges>
        </div>
      </div>
      {/* <table>
        <tbody>
          <tr>
            <td>{t('representant')}</td>
            <td>{t('Artista')}</td>
            <td>{t('Estat de la proposta')}</td>
          </tr>

          <tr>
            <td>
              {proposal.title_representant ? proposal.title_representant : t('without_manager')}
            </td>
            <td>{proposal.title_artist}</td>
            <td>
              <Badges
                text={t(proposal.review_state)}
                icon={proposal.review_state === 'presentada' ? <OKIconBlue /> : <EsborranyIcon />}
                dataTest={'proposalListItemBadges'}
                className={proposal.review_state === 'presentada' ? 'presentada' : 'esborrany'}
              ></Badges>
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  )
}

export const ProposalsListItem = React.memo(ProposalsListItemComponent)
