import { requiredError } from 'Components/Forms/FormErrors'
import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { EditComponent } from 'NewVersion/components/Fields/EditComponent'
import { InputAcceptRegulation } from 'NewVersion/components/Fields/InputAcceptRegulation'
import { DialogProposalForm } from 'NewVersion/components/Proposal/DialogProposalForm'
import Chips from 'NewVersion/components/UI/Chips/Chips'
import { AlertIcon } from 'NewVersion/icons/AlertIcon'
import { EditIcon } from 'NewVersion/icons/EditIcon'
import { OKIconBlue } from 'NewVersion/icons/OKIcon'
import { SaveIcon } from 'NewVersion/icons/Save'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useFormik } from 'formik'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import React, { useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PROPOSAL_WORKFLOW_STATE } from 'utils/constants'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken, getCurrentLanguage } from 'utils/utils'
import Button from '../UI/Buttons/Button'

const Form = ({ ensTypeObj, artista, representant, showDetailObj = undefined, toggleInit }) => {
  const { edition, currentEdition, loading } = useEdition()

  const {
    data: entitySchemaData,
    isLoading: entitySchemaLoading,
    error: entitySchemaError,
  } = useGuillotina({
    path: `@types/${ensTypeObj['interface']}`,
  })

  if (loading || entitySchemaLoading) {
    return <LoadingComponent />
  }

  return (
    <EntityFormContent
      edition={edition}
      currentEdition={currentEdition}
      schema={{
        data: entitySchemaData,
        error: entitySchemaError,
        loading: entitySchemaLoading,
      }}
      ensTypeObj={ensTypeObj}
      artista={artista}
      representant={representant}
      proposalObject={showDetailObj}
      toggleInit={toggleInit}
    />
  )
}

const EntityFormContent = ({
  edition,
  currentEdition,
  schema,
  ensTypeObj,
  artista,
  representant,
  proposalObject = undefined,
  toggleInit,
  readOnlyForm = false,
}) => {
  const { t } = useTranslation()
  const fieldsWithPrefixLabel = ['idioma', 'correu']
  const [submitError, setSubmitError] = React.useState({
    hasError: false,
    message: '',
  })

  const [step, setStep] = useState(1)
  const [currentProposta, setCurrentProposta] = useState(null)
  const [loadingData, setLoadingData] = useState(false)

  const [dialogProposalForm, setDialogProposalForm] = useState({
    open: false,
    proposal: null,
    hasErrors: false,
    dialogIcon: '',
    dialogTitle: '',
    dialogDescription: '',
    dialogHandleOk: null,
    dialogHandleCancel: null,
  })

  const [modifiedFiles, setModifiedFiles] = useState({
    dossier_artistic_modified: false,
    fitxa_artistica_modified: false,
    imatge_companyia_modified: false,
    imatge_companyia2_modified: false,
    fitxa_tecnica_modified: false,
  })

  const stepsMap = [
    { index: 1, id: 'proposta_espectacle', title: '1/ ' + t('proposta_espectacle') },
    { index: 2, id: 'proposta_artista', title: '2/ ' + t('proposta_artista') },
    { index: 3, id: 'proposta_economia_fieldset', title: '3/ ' + t('proposta_economia_fieldset') },
    { index: 4, id: 'economia', title: '4/ ' + t('economia') },
    { index: 5, id: 'declaracio_fieldset', title: '5/ ' + t('declaracio_fieldset') },
  ]

  const getInitialValues = React.useMemo(() => {
    if (proposalObject) {
      return {
        ...proposalObject,
        ambits: proposalObject['ambits'].value,
        altres_ambits: proposalObject['altres_ambits'],
        tipus: proposalObject['tipus']?.value,
        caracteristiques: proposalObject['caracteristiques'].value,
        public: proposalObject['public'].value,
        idioma: proposalObject['idioma'],
        estrena: proposalObject['estrena'].value,
        claus: proposalObject['claus'],
        declaracio_drets: proposalObject['declaracio_drets'].value,
      }
    }
    const result = {}
    ensTypeObj['requiredFields'].forEach((item) => {
      if (item === 'caixet' || item === 'proposta_economica') {
        result[item] = 0
      } else if (item === 'idioma' || item === 'claus' || item === 'pais') {
        result[item] = []
      } else {
        result[item] = undefined
      }
    })

    return result
  }, [proposalObject, ensTypeObj])

  const isFromCatalonia = (values) => {
    if (values) {
      if (values.length > 0) {
        if (
          values.some((values) => {
            return values === 'catalunya'
          })
        ) {
          return true
        }
      }
    }
    return false
  }

  const isFromSpain = (values) => {
    if (values) {
      if (values.length > 0) {
        if (
          values.some((values) => {
            return values === 'country-ES'
          })
        ) {
          return true
        }
      }
    }
    return false
  }

  const isFieldDisabled = (values) => {
    // TODO: Revisar funcionament.
    if (
      currentEdition.callIsFinished() ||
      (values.review_state && values.review_state !== PROPOSAL_WORKFLOW_STATE.draft)
    ) {
      readOnlyForm = true
      return true
    } else {
      return false
    }
    /* return (
      currentEdition.callIsFinished() ||
      (values.review_state && values.review_state !== PROPOSAL_WORKFLOW_STATE.draft)
    ) */
  }

  const handleFormError = (error) => {
    console.error(`Proposal form error: ${error}`)
    if (error.response && error.response.status === 412) {
      if (get(error, 'response.data.code') === 'already-exists') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('title_already_exists')}`,
        })
      } else if (get(error, 'response.data.code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'response.data.code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else if (error) {
      setSubmitError({
        hasError: true,
        message: 'Error: ' + error,
      })
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    scrollToTop()
  }

  const getProposalPath = () => {
    return proposalObject
      ? proposalObject['@id'].split('/').slice(-3).join('/')
      : artista['@id'].split('/').slice(-2).join('/') + '/' + currentProposta
  }

  const token = getCurrentJWToken()

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const data = prepareDataObject(values)

    let path
    if (proposalObject) {
      path = proposalObject['@id'].split('/').slice(-1).join('/')
    } else {
      path = artista['@id'].split('/').slice(-2).join('/')
    }

    if (step === 1 && !proposalObject && !currentProposta && !currentEdition.callIsFinished()) {
      setLoadingData(true)

      await fetchGuillotina({
        path: path,
        method: 'POST',
        data: data,
        token: token,
      })
        .then((response) => {
          const nameProposta = response['@name']
          setCurrentProposta(nameProposta)
          setLoadingData(false)
          setStep(step + 1)
        })
        .catch((error) => {
          handleFormError(error)
          setSubmitting(false)
          return
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else if (
      values.review_state !== PROPOSAL_WORKFLOW_STATE.submitted &&
      !currentEdition.callIsFinished()
    ) {
      setLoadingData(true)
      const path = '/' + getProposalPath()

      delete data['estat_intern']
      delete data['observacions_comissio']
      delete data['observacions_fira']

      const patchFile = (values = {}, path = '', field) => {
        let filename = undefined
        if (values.filename) {
          filename = values.filename
        } else if (values.name) {
          filename = values.name
        }

        return fetchGuillotina({
          path: path + '/@upload/' + field,
          method: 'PATCH',
          token: token,
          headers: {
            'X-UPLOAD-EXTENSION': values.type.split('/')[1],
            // 'X-UPLOAD-SIZE': values.size,
            'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
            'CONTENT-TYPE': values.type,
          },
          notStringify: true,
          data: values,
        })
      }
      if (step === 4) {
        const errors = []
        if (data.dossier_artistic && modifiedFiles.dossier_artistic_modified) {
          try {
            await patchFile(data.dossier_artistic, path, 'dossier_artistic')
          } catch (error) {
            console.log(error)
            handleFormError(t('proposta_dossie') + error)
            errors.push(t('proposta_dossier'))
          }
        }
        if (data.fitxa_artistica && modifiedFiles.fitxa_artistica_modified) {
          try {
            await patchFile(data.fitxa_artistica, path, 'fitxa_artistica')
          } catch (error) {
            console.log(error)
            handleFormError(t('proposta_fitxa') + error)
            errors.push(t('proposta_fitxa'))
          }
        }
        if (data.imatge_companyia && modifiedFiles.imatge_companyia_modified) {
          try {
            await patchFile(data.imatge_companyia, path, 'imatge_companyia')
          } catch (error) {
            console.log(error)
            handleFormError(t('proposta_imatge') + error)
            errors.push(t('proposta_imatge'))
          }
        }
        if (data.imatge_companyia2 && modifiedFiles.imatge_companyia2_modified) {
          try {
            await patchFile(data.imatge_companyia2, path, 'imatge_companyia2')
          } catch (error) {
            console.log(error)
            handleFormError(t('proposta_imatge2') + error)
            errors.push(t('proposta_imatge2'))
          }
        }
        if (data.fitxa_tecnica && modifiedFiles.fitxa_tecnica_modified) {
          try {
            await patchFile(data.fitxa_tecnica, path, 'fitxa_tecnica')
          } catch (error) {
            console.log(error)
            handleFormError(t('proposta_fitxa_tecnica') + error)
            errors.push(t('proposta_fitxa_tecnica'))
          }
        }
        if (errors.length > 0) {
          setLoadingData(false)
        } else {
          patchProposal(path, data, step)
          setStep(step + 1)
        }
      } else {
        patchProposal(path, data, step)
        setStep(step + 1)
      }
    } else {
      setStep(step + 1)
    }
    scrollToTop()
    setSubmitting(false)
    setModifiedFiles({
      dossier_artistic_modified: false,
      fitxa_artistica_modified: false,
      imatge_companyia_modified: false,
      imatge_companyia2_modified: false,
      fitxa_tecnica_modified: false,
    })
  }

  const patchProposal = (path, data, step, formSubmitOption = '') => {
    const a = (proposal) => {
      if (step === 5) {
        if (formSubmitOption === 'guardar') {
          guardarEsborrany()
        } else if (formSubmitOption === 'enviar') {
          enviarProposta(proposal)
        }
      }
      setLoadingData(false)
    }
    fetchGuillotina({ path: path, method: 'PATCH', data, token: token })
      .then(a(data))
      .catch((error) => {
        console.log(error)
        setLoadingData(false)
        handleFormError(error)
      })
  }

  const prepareDataObject = (values) => {
    const data = Object.assign({}, values, {
      '@type': ensTypeObj['interface'],
    })

    const setDataVocabulary = (attribute, values, defaultValue) => {
      if (values[attribute]?.value) {
        values[attribute] = values[attribute].value
      }
      if (values[attribute] === undefined || values[attribute] === null) {
        values[attribute] = defaultValue
      }
    }

    setDataVocabulary('altres_ambits', data, [])
    setDataVocabulary('claus', data, [])
    setDataVocabulary('pais', data, [])
    setDataVocabulary('comunitat_autonoma', data, [])
    setDataVocabulary('comarca', data, [])

    if (data.ambits === 'music') {
      data.idioma = []
    } else {
      setDataVocabulary('idioma', data, [])
    }
    return data
  }
  const handleSubmitStep5 = (formSubmitOption) => {
    const values = formik.values
    const data = prepareDataObject(values)
    setLoadingData(true)
    const path = '/' + getProposalPath()
    delete data['estat_intern']
    delete data['observacions_comissio']
    delete data['observacions_fira']

    patchProposal(path, data, step, formSubmitOption)
    scrollToTop()
  }

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}

      ensTypeObj['orderFieldsForm'][step - 1].fields.forEach((key) => {
        const checkRequired = () => {
          let check = false
          ensTypeObj['requiredFields'].forEach((field) => {
            if (field === key) {
              check = true
            }
          })
          return check
        }
        const isFieldRequired = checkRequired()
        if (isFieldRequired) {
          if (key !== 'declaracio_acceptar') {
            const keyForm = `${key}`
            if ((!(keyForm in values) || !values[keyForm]) && renderLocationFields(key)) {
              errors[keyForm] = requiredError()
            }
          }
        }
        if (step === 3) {
          if (values.caixet === '0') {
            errors['caixet'] = requiredError()
          }
          if (values.proposta_economica === '0') {
            errors['proposta_economica'] = requiredError()
          }
        }
      })

      console.log('ERRORS: ' + JSON.stringify(errors))
      return errors
    },
  })

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `ens_${key}`
    return key
  }

  const getFormValue = (key) => {
    return formik.values[key]?.value || formik.values[key]
  }

  const renderLocationFields = (key) => {
    if (key === 'comunitat_autonoma' && !isFromSpain(getFormValue('pais'))) {
      return false
    }
    if (key === 'comarca' && !isFromCatalonia(getFormValue('comunitat_autonoma'))) {
      return false
    }
    if (key === 'tipus' && getFormValue('ambits') !== 'music') {
      return false
    }
    if (
      key === 'franja' &&
      (!getFormValue('public') ||
        getFormValue('public') === 'familiar' ||
        getFormValue('public') === 'adults')
    ) {
      return false
    }
    if (key === 'dataestrena' && getFormValue('estrena') !== 'no') {
      return false
    }

    let checkClaus
    if (getFormValue('claus').length > 0) {
      checkClaus = getFormValue('claus')?.some((values) => {
        return values === 'present'
      })
    }

    if (key === 'claus_altres' && !checkClaus) {
      return false
    }

    if (key === 'altres_drets' && getFormValue('declaracio_drets') !== 'altres') {
      return false
    }
    return true
  }

  const renderFields = (key) => {
    if (key === 'declaracio_acceptar' || key === 'representant') return false
    return renderLocationFields(key)
  }

  const getDescription = (value, key) => {
    /*
    TODO: Refactor de les descripcions dels camps, evitar casos unics i utilitzar el type_name per crear les claus,
    es necessari modificar back per retornar la clau en el camp descripcio per exemple, analitzar-ho bé.
    */
    if (key === 'correu') {
      return t('ens_correu_description')
    }
    if (key === 'pais') {
      return t('pais_multiple_description')
    }
    if (key === 'altres_ambits') {
      return t('proposta_engloba')
    }
    if (
      key === 'imatge_companyia' ||
      key === 'dossier_artistic' ||
      key === 'fitxa_artistica' ||
      key === 'imatge_companyia2'
    ) {
      return value.description ? t(`proposta_${key}_descripcio`) : null
    }
    if (key === 'fitxa_tecnica') {
      return value.description ? t('fitxa tecnica') : null
    }
    if (key === 'enllacos') {
      return t('link audio')
    }
    if (key === 'enllacos2') {
      return t('link a video')
    }
    if (key === 'comunitat_autonoma' || key === 'comarca') {
      return ''
    }

    return value.description ? t(`${key}_descripcio`) : null
  }

  const getTitle = (itemTitle) => {
    const result = stepsMap.find((item) => item.id === itemTitle)
    return result.title
  }

  // FUNCTIONS TO HANDLE BUTTONS "Save" & "Send" IN STEP 5
  const guardarEsborrany = () => {
    setDialogProposalForm({
      ...dialogProposalForm,
      open: true,
      hasErrors: false,
      dialogIcon: 'SaveIcon',
      dialogTitle: t('proposalSaved'),
      dialogDescription: t('proposalSavedDesc'),
      dialogHandleOk: toggleInit,
    })
  }

  const enviarProposta = (proposal) => {
    setDialogProposalForm({
      ...dialogProposalForm,
      open: true,
      hasErrors: false,
      dialogIcon: 'AlertIcon',
      dialogTitle: 'Vols presentar ara la teva proposta?',
      dialogDescription: '',
      dialogHandleOk: () => {
        presentarProposta(proposal)
      },
      dialogHandleCancel: () => {
        toggleInit()
        setDialogProposalForm({ ...dialogProposalForm, open: false })
      },
    })
  }

  const presentarProposta = async (proposal) => {
    const path = '/' + getProposalPath()
    await fetchGuillotina({
      path: path + '/@workflow/presentar?lan=' + getCurrentLanguage(),
      method: 'POST',
      data: proposal,
      token: token,
    })
      .then(() => {
        setDialogProposalForm({
          ...dialogProposalForm,
          open: true,
          proposal: null,
          hasErrors: false,
          dialogIcon: 'SaveIcon',
          dialogTitle: t('dialog_title_proposal_send'),
          dialogDescription: t('dialog_title_proposal_send_desc', { edition: edition }),
          dialogHandleOk: toggleInit,
        })
      })
      .catch(() => {
        setDialogProposalForm({
          ...dialogProposalForm,
          open: true,
          hasErrors: true,
          dialogTitle: t('dialog_title_proposal_error'),
          dialogDescription: t('dialog_description_proposal_error'),
          dialogHandleOk: () => {
            setDialogProposalForm({ ...dialogProposalForm, open: false })
          },
        })
        return
      })
  }

  // FUNCTIONS TO PRINT HTML CODE
  const printAccordionPanel = (item) => {
    if (item.title === 'economia') {
      return printEconomicFragment(item)
    } else if (item.title === 'declaracio_fieldset') {
      return (
        <InputAcceptRegulation
          onChange={(e) => {
            formik.setFieldValue('declaracio_acceptar', e.target.checked)
            formik.validateForm(
              Object.assign({}, formik.values, {
                declaracio_acceptar: e.target.checked,
              })
            )
          }}
          onBlur={formik.onBlur}
          checked={!!formik.values['declaracio_acceptar']}
          error={formik.touched['declaracio_acceptar'] && formik.errors['declaracio_acceptar']}
          readOnly={readOnlyForm}
          name={'declaracio_acceptar'}
        />
      )
    } else {
      const middleIndex = Math.ceil(item['fields'].length / 2)
      return (
        <div className="fragment">
          <div className="fragmentColumn">
            {item['fields'].slice(0, middleIndex).map((key) => {
              if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
                const value = schema.data.properties[key]
                const keyForm = key
                if (key.search('proposta_') < 0) {
                  // els fitxers de traducció tenen les propietats amb un propostes_ inicial.
                  key = 'proposta_' + key
                }
                return printEditComponent(key, keyForm, ensTypeObj, value)
              }
            })}
          </div>
          <div className="fragmentColumn">
            {item['fields'].slice(middleIndex).map((key) => {
              if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
                const value = schema.data.properties[key]
                const keyForm = key
                if (key.search('proposta_') < 0) {
                  // els fitxers de traducció tenen les propietats amb un propostes_ inicial.
                  key = 'proposta_' + key
                }
                return printEditComponent(key, keyForm, ensTypeObj, value)
              }
            })}
          </div>
        </div>
      )
    }
  }

  const printEditComponent = (key, keyForm, ensTypeObj, value) => {
    let onChangeFunction = (ev) => {
      formik.setFieldValue(keyForm, ev)
      formik.validateForm()
    }

    if (keyForm === 'pais') {
      onChangeFunction = (ev) => {
        if (!isFromSpain(ev)) {
          formik.setFieldValue('comunitat_autonoma', [])
          formik.setFieldValue('comarca', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }
    if (keyForm === 'comunitat_autonoma') {
      onChangeFunction = (ev) => {
        if (!isFromCatalonia(ev)) {
          formik.setFieldValue('comarca', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }
    if (keyForm === 'ambits') {
      onChangeFunction = (ev) => {
        if (!(ev === 'music')) {
          formik.setFieldValue('tipus', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }

    if (keyForm === 'public') {
      onChangeFunction = (ev) => {
        if (ev === 'familiar' || ev === 'adults') {
          formik.setFieldValue('franja', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }

    if (keyForm === 'estrena') {
      onChangeFunction = (ev) => {
        if (ev === 'no') {
          formik.setFieldValue('dataestrena', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }

    if (keyForm === 'claus') {
      onChangeFunction = (ev) => {
        if (ev !== 'present') {
          formik.setFieldValue('claus_altres', '')
        }
        formik.setFieldValue(keyForm, ev)
      }
    }

    if (keyForm === 'declaracio_drets') {
      onChangeFunction = (ev) => {
        if (ev === 'altres') {
          formik.setFieldValue('altres_drets', null)
        }
        formik.setFieldValue(keyForm, ev)
      }
    }

    if (
      keyForm === 'dossier_artistic' ||
      keyForm === 'fitxa_artistica' ||
      keyForm === 'fitxa_tecnica' ||
      keyForm === 'imatge_companyia' ||
      keyForm === 'imatge_companyia2'
    ) {
      const propName = keyForm + '_modified'
      onChangeFunction = (ev) => {
        formik.setFieldValue(keyForm, ev)
        setModifiedFiles({ ...modifiedFiles, [propName]: true })
      }
    }
    if (keyForm === 'idioma') {
      console.log(keyForm, value)
    }
    return (
      <EditComponent
        key={key}
        name={keyForm}
        label={keyForm === 'title' ? t(getLabel(ensTypeObj['labelName'])) : t(getLabel(key))}
        schema={value}
        description={getDescription(value, keyForm)}
        value={getFormValue(keyForm)}
        disabled={isFieldDisabled(formik.values)}
        required={(ensTypeObj['requiredFields'] ?? []).includes(keyForm)}
        onBlur={() => {
          formik.setFieldTouched(keyForm)
        }}
        onChange={onChangeFunction}
        error={formik.touched[keyForm] && formik.errors[keyForm]}
        dataTest={`inputFieldTest_${keyForm}`}
      />
    )
  }

  const printEconomicFragment = (item) => {
    const economiaFragmentLinks = []
    const economiaFragmentFiles = []
    item['fields'].map((key) => {
      if (schema.data.properties[key].widget === 'file') {
        economiaFragmentFiles.push(key)
      } else {
        economiaFragmentLinks.push(key)
      }
    })
    return (
      <>
        <div className="economicLinks">
          {economiaFragmentLinks.map((key) => {
            if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
              const value = schema.data.properties[key]
              const keyForm = key
              if (key.search('proposta_') < 0) {
                // els fitxers de traducció tenen les propietats amb un propostes_ inicial.
                key = 'proposta_' + key
              }
              return printEditComponent(key, keyForm, ensTypeObj, value)
            }
          })}
        </div>
        <div className="fragment economicFiles">
          {economiaFragmentFiles.map((key) => {
            if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
              const value = schema.data.properties[key]
              const keyForm = key
              if (key.search('proposta_') < 0) {
                // els fitxers de traducció tenen les propietats amb un propostes_ inicial.
                key = 'proposta_' + key
              }
              return printEditComponent(key, keyForm, ensTypeObj, value)
            }
          })}
        </div>
      </>
    )
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const changeStep = (item) => {
    const selfStep = stepsMap.find((element) => element.id === item.title)
    setStep(selfStep.index)
  }

  const isFilled = (item) => {
    const selfStep = stepsMap.find((element) => element.id === item.title)
    if (selfStep.index < step) return true
    return false
  }

  const getDialogIcon = () => {
    switch (dialogProposalForm.dialogIcon) {
      case 'SaveIcon':
        return <SaveIcon />
      case 'AlertIcon':
        return <AlertIcon />
      case 'OkIcon':
        return <OKIconBlue />
      default:
        return <SaveIcon />
    }
  }

  return (
    <>
      <DialogProposalForm
        open={dialogProposalForm.open}
        handleOk={dialogProposalForm.dialogHandleOk}
        handleCancel={dialogProposalForm.dialogHandleCancel}
        dialogIcon={() => getDialogIcon()}
        dialogTitle={dialogProposalForm.dialogTitle}
        dialogDescription={dialogProposalForm.dialogDescription}
      />

      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}

      <h4>
        {t('presentar_proposta')} {edition}
      </h4>

      {artista && (
        <div className="selectedEntities">
          <div>
            <p>{t('representant')}</p>
            <p>{representant?.title || 'Sense representant'}</p>
          </div>
          <div className="separatorVertical"></div>
          <div>
            <p>{t('artista')}</p>
            <p>{artista?.title}</p>
          </div>
        </div>
      )}

      {schema &&
        schema.data &&
        !schema.loading &&
        ensTypeObj['orderFieldsForm'].map((item) => {
          return (
            <>
              <form
                className="Form"
                onSubmit={formik.handleSubmit}
                data-test="formTest"
                key={item['title']}
              >
                <div
                  className={
                    'accordion ' + (stepsMap[step - 1].id === item.title ? 'active' : 'disabled')
                  }
                >
                  <div className="accordionHeader">
                    <h5>{getTitle(item.title)}</h5>
                    {isFilled(item) && (
                      <Chips
                        text={t('editar')}
                        icon={<EditIcon />}
                        url=""
                        datatest="accordionChip"
                        iconDirection="left"
                        style="withoutBorder"
                        onClick={() => changeStep(item)}
                      />
                    )}
                  </div>
                  <div className="accordionPanel">
                    {printAccordionPanel(item)}

                    {/* PRINT BUTTONS*/}
                    {step !== 5 && (
                      <div>
                        {formik.isSubmitting && loadingData && <LoadingComponent />}
                        {!readOnlyForm ? (
                          <Button
                            type="submit"
                            dataTest={`btnNextTest_item_${item['title']}`}
                            disabled={formik.isSubmitting}
                            width="136px"
                          >
                            {t('next')}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            dataTest={`btnNextTest_item_${item['title']}`}
                            width="136px"
                            onClick={() => setStep(step + 1)}
                          >
                            {t('next')}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <div className="separator"></div>
            </>
          )
        })}
      {step === 5 && (
        <div className="footer-buttons">
          {formik.isSubmitting && loadingData && <LoadingComponent />}
          {!readOnlyForm && (
            <>
              <Button
                type="button"
                data-test="btnEsborranyTest"
                disabled={formik.isSubmitting}
                color="primaryOutlinedIcon outlined"
                onClick={() => {
                  handleSubmitStep5('guardar')
                }}
              >
                {t('saveDraft')}
              </Button>

              <Button
                type="button"
                data-test="btnEnviarFormTest"
                disabled={formik.isSubmitting}
                onClick={() => {
                  handleSubmitStep5('enviar')
                }}
              >
                {t('Enviar')}
              </Button>
            </>
          )}
        </div>
      )}
      {(step !== 5 || readOnlyForm) && (
        <div className="footer-buttons">
          <Button
            type="button"
            data-test="btnCancelFormTest"
            disabled={formik.isSubmitting}
            onClick={toggleInit}
            color="primaryOutlinedIcon outlined"
          >
            {t('cancel')}
          </Button>
        </div>
      )}
    </>
  )
}

export const ProposalForm = withTranslation()(Form)
