import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { StandForm } from 'NewVersion/components/Forms/StandForm'
import { GoBackButton } from 'NewVersion/components/UI/GoBack/GoBackButton'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { FiraPasseigIcon } from 'NewVersion/icons/FiraPasseigIcon'
import { ACCOUNT_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useVocabularies } from 'hooks/useVocabularies'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

const EditPage = () => {
  const { t } = useTranslation()
  const { entityId, standId } = useParams()
  const navigate = useNavigate()
  const [submitModal, setSubmitModal] = useState(false)
  const { edition, currentEdition, loading } = useEdition()
  const {
    data: entityDetail,
    loading: loadingEntity,
    error: entityError,
  } = useGuillotina({
    path: `${edition}/${entityId}`,
  })
  const {
    data: dataStand,
    isLoading: loadingStand,
    error: standError,
  } = useGuillotina({
    path: `${edition}/${entityId}/${standId}`,
  })
  const { loading: loadingVocabularies, data: vocabularies } = useVocabularies([
    {
      vocabulary: 'estand',
    },
  ])
  const { data: schema, isLoading: loadingSchema } = useGuillotina({ path: '@types/Estand' })

  return !loading && !loadingStand && !loadingVocabularies && !loadingEntity && !loadingSchema ? (
    <div className="page-wrapper">
      <GoBackButton onClick={() => navigate(ACCOUNT_PATH)} text={t('tornar_perfil')} />
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        handleOk={() => navigate(ACCOUNT_PATH)}
        title={t('stand_updated')}
        text=""
        icon={<FiraPasseigIcon fill="#273BF3" />}
        closeAction={() => navigate(ACCOUNT_PATH)}
      />
      {(entityError || standError) && (
        <AlertComponent type="danger">{t('error_generic')}</AlertComponent>
      )}
      {!entityError && !standError && (
        <StandForm
          entity={entityDetail}
          dataStand={dataStand}
          submitAction={() => setSubmitModal(true)}
          currentEdition={currentEdition}
          vocabularies={vocabularies}
          schema={schema}
        />
      )}
    </div>
  ) : (
    <LoadingComponent />
  )
}

export default EditPage
