import React, { useState, useEffect } from 'react'
import Xat from 'Components/Plone/XatComponents/Xat'
import Xats from 'Components/Plone/XatComponents/Xats'
import {
  getApiCallPloneGeneric,
  guillotinaActionGeneric,
  getApiCallGuillotinaDirectUrl,
} from 'utils/apiCalls'
import { getEmailFromToken } from 'utils/utils'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { getApiCallPloneByEmail } from 'utils/apiCalls'
import { getCurrentJWToken } from 'utils/utils'
import { useEdition } from 'hooks/useEdition'

export function XatPage() {
  const { t } = useTranslation()
  const [currentXat, renderXat] = useState([])
  const [currentTarget, renderTarget] = useState([])
  const [xatsList, setXatList] = useState([])
  const [targets, setTargetsList] = useState([])
  const [xatChanged, switchXatChanged] = useState(false)
  const [socket, setSocket] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [forceRender, setForceRender] = useState(0)
  const { currentEdition } = useEdition()

  useEffect(() => {
    if (currentEdition && (localStorage.UID === 'undefined' || localStorage.UID === undefined)) {
      getApiCallPloneByEmail(
        currentEdition.getUrl() + '@search',
        localStorage.user,
        getCurrentJWToken(),
        setUID
      )
    }
  }, [currentEdition])

  const setUID = (user) => {
    localStorage.setItem('UID', user.UID)
  }

  const updateTargetsListHandler = React.useCallback((response) => {
    let tmpArray = []
    if (response.status === 200) {
      if (response.data.items.length !== 0) {
        tmpArray = response.data.items[0]
      }
    }
    updateTargetsList(tmpArray)
  }, [])

  const updateXatsList = React.useCallback(
    (element) => {
      if (currentEdition) {
        const target =
          localStorage.UID === element.recipientID2 ? element.recipientID1 : element.recipientID2
        getApiCallPloneGeneric({
          path: currentEdition.getUrl() + '@search',
          finishCallBack: updateTargetsListHandler,
          params: { UID: target },
          sort: false,
        })
        if (
          localStorage.newXatID === element.recipientID2 ||
          localStorage.newXatID === element.recipientID1
        ) {
          localStorage.removeItem('newXatID')
          renderXat(element)
        }
        setXatList((xatsList) => [...xatsList, element])
      }
    },
    [updateTargetsListHandler, currentEdition]
  )

  const updateXatsListStraight = (element) => {
    const target =
      localStorage.UID === element.recipientID2 ? element.recipientID1 : element.recipientID2
    getApiCallPloneGeneric({
      path: currentEdition.getUrl() + '@search',
      finishCallBack: updateTargetsListHandler,
      params: { UID: target },
      sort: false,
    })
    setXatList((xatsList) => [...xatsList, element])
    switchXatChanged(!xatChanged)
    renderXat(element)
  }

  const renderXatSpecificStep1 = (UID) => {
    renderXat([])
    const renderXatSpecificStep2 = (updatedXat) => {
      xatsList.splice(UID, 1, updatedXat)
      renderXat(xatsList[UID])
    }
    getApiCallGuillotinaDirectUrl(xatsList[UID]['@id'], getCurrentJWToken(), renderXatSpecificStep2)
  }

  useEffect(() => {
    localStorage.removeItem('xatNOW')
    const element = document.getElementById('msgBoxID')
    if (element) {
      element.classList.remove('notificated')
    }
    if (currentXat.length !== 0 && (socket.url || '').indexOf(currentXat['@name']) === -1) {
      if (socket) {
        socket.close()
      }

      guillotinaActionGeneric({
        method: 'GET',
        action: '@wstoken',
        setCallBack: (response) => {
          const data = response.data.token
          setLoading(false)
          const SOCKET = new WebSocket(
            `wss:///${process.env.REACT_APP_GUILLOTINA_DB.replace('https://', '')}${
              currentXat['@name']
            }/@subscriu?ws_token=${data}`
          )
          setSocket(SOCKET)
          SOCKET.onopen = function (e) {
            console.log(e)
            return false
          }
          SOCKET.onmessage = function (msg) {
            console.log(msg)
            const data = JSON.parse(msg.data)
            if (data.propietari !== localStorage.UID) {
              currentXat.conversation.push(data)
              renderXat(currentXat)
              setForceRender((forceRender) => forceRender + 1)
              return false
            }
          }
          SOCKET.onclose = function (e) {
            console.log(e)
            console.log('closed')
          }

          SOCKET.onerror = function (e) {
            console.log(e)
            console.log('error')
          }
        },
      })
    }
  }, [currentXat, socket, isLoading])

  const updateTargetsList = (element) => {
    setTargetsList((targets) => [...targets, element])
  }

  const setXats = React.useCallback(
    (xats) => {
      const token = getCurrentJWToken()
      xats.forEach(function (entry) {
        // Api call de cada xat
        getApiCallGuillotinaDirectUrl(entry['@id'], token, updateXatsList)
      })
    },
    [updateXatsList]
  )

  const setTarget = (uid) => {
    function getTarget(valor) {
      if (valor.UID === uid) {
        return valor
      }
    }
    setLoading(true)
    renderTarget(targets.find(getTarget))
  }

  useEffect(() => {
    if (currentEdition) {
      guillotinaActionGeneric({
        method: 'GET',
        action: '@search?type_name=Conversacio',
        setCallBack: (response) => {
          setXats(response.data.items)
        },
      })
      if (localStorage.newXatID) {
        getApiCallPloneGeneric({
          path: currentEdition.getUrl() + '@search',
          finishCallBack: renderTargetHandler,
          params: { UID: localStorage.newXatID },
          sort: false,
        })
      }
    }
  }, [setXats, currentEdition])

  const renderTargetHandler = (response) => {
    let tmpArray = []
    if (response.status === 200) {
      if (response.data.items.length !== 0) {
        tmpArray = response.data.items[0]
      }
    }
    renderTarget(tmpArray)
  }
  const getTargetCreator = (currentTarget) => {
    if (currentTarget.propietaris.length > 1) {
      return currentTarget.propietaris.filter((res) => res !== currentTarget.Creator)[0]
    } else {
      return currentTarget.Creator
    }
  }

  const sendMessage = (message) => {
    if (localStorage.newXatID) {
      localStorage.removeItem('newXatID')
      guillotinaActionGeneric({
        method: 'POST',
        action: '@creaConversacio',
        setCallBack: getXats,
        data: {
          recipientID1: localStorage.UID,
          recipientID2: currentTarget.UID,
          propietariId1: getEmailFromToken(),
          propietariId2: getTargetCreator(currentTarget),
          conversation: [
            { propietari: localStorage.UID, contingut: message, timestamp: Date.parse(Date()) },
          ],
        },
      })
    } else {
      const msg = {
        propietari: localStorage.UID,
        contingut: message,
        timestamp: Date.parse(Date()),
      }
      const convo = currentXat.conversation
      convo.push(msg)
      guillotinaActionGeneric({
        method: 'PATCH',
        action: currentXat['@name'],
        data: { conversation: { op: 'append', value: msg } },
      })
    }
  }

  const getXats = (response) => {
    getApiCallGuillotinaDirectUrl(response.data['@id'], getCurrentJWToken(), updateXatsListStraight)
  }
  if (isMobile) {
    return (
      <div className="Xat">
        <div className="xats-container-mobile">
          {currentTarget && currentTarget.length === 0 ? (
            <Xats
              xatChanged={xatChanged}
              targets={targets}
              xatsList={xatsList}
              currentXat={currentXat}
              renderXat={renderXat}
              setTarget={setTarget}
              renderXatSpecific={renderXatSpecificStep1}
            />
          ) : (
            <Xat
              targets={targets}
              forceRender={forceRender}
              renderTarget={renderTarget}
              isLoading={isLoading}
              currentXat={currentXat}
              renderXat={renderXat}
              currentTarget={currentTarget}
              sendMessage={sendMessage}
            />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="limited-desktop-height">
        <div className="title-generic account-1">{t('Missatges')}</div>
        <div className="Xat">
          <div className="xats-container">
            <Xats
              xatChanged={xatChanged}
              targets={targets}
              xatsList={xatsList}
              currentXat={currentXat}
              renderXat={renderXat}
              currentTarget={currentTarget}
              setTarget={setTarget}
              renderXatSpecific={renderXatSpecificStep1}
            />
          </div>
          <div className="xat-element">
            <Xat
              targets={targets}
              forceRender={forceRender}
              isLoading={isLoading}
              currentXat={currentXat}
              renderXat={renderXat}
              renderTarget={renderTarget}
              currentTarget={currentTarget}
              sendMessage={sendMessage}
            />
          </div>
        </div>
      </div>
    )
  }
}
