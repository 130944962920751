export const HomeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2730_4213)">
        <path d="M12 3L4 9V21H9V14H15V21H20V9L12 3Z" fill="#2B2A29" />
      </g>
      <defs>
        <clipPath id="clip0_2730_4213">
          <rect width="24" height="24" fill="#2B2A29" />
        </clipPath>
      </defs>
    </svg>
  )
}
