import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

function Notifications(props) {
  const { t } = useTranslation()
  const selectNotification = (element) => {
    props.renderNotification(element)
  }
  const [orderedNotifications, setOrderedNotifications] = useState([])
  useEffect(() => {
    function sortByThenBy(items) {
      return items.sort(function (it1, it2) {
        return compare(it1, it2)
      })
    }

    function compare(a, b) {
      return a.creation_date < b.creation_date ? 1 : -1
      // return it1[currentKey] < it2[currentKey] ? 1 : (it1[currentKey] > it2[currentKey] ? -1 : compare(it1, it2, keys, index + 1));
    }

    const ord = sortByThenBy(props.notificationList)
    setOrderedNotifications(ord.slice())
  }, [props.notificationList])

  if (orderedNotifications.length !== 0) {
    return (
      <div>
        {isMobile && <div className="title-generic account-1">{t('Avisos')}</div>}
        <div className="notification-list">
          {orderedNotifications.map((element, key) => (
            <div
              key={key}
              className={
                element === props.currentNotification
                  ? 'notification-list-element selected'
                  : 'notification-list-element'
              }
              onClick={() => selectNotification(element)}
            >
              <div className="notification-list-info">
                <div className="blue">{props.formatTimestamp(element.creation_date)}</div>
                <div className="bold">{element.titol}</div>
                <div className="limited">{element.cos}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}
export default Notifications
