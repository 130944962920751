import { LoadingComponent } from 'Components/HelperComponents'
import 'NewVersion/styles/ShowGrid.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { useTranslation } from 'react-i18next'
import { ShowGridItem } from './ShowGridItem'

export const ShowGrid = ({ shows, loading }) => {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()

  const { data: { items: vocabularyEspai } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/espai`,
    useStatic: true,
  })

  const { data: { items: vocabularyEix } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/eix`,
    useStatic: true,
  })

  const { data: { items: vocabularyAmbits } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/ambits`,
    useStatic: true,
  })

  return loading ? (
    <LoadingComponent />
  ) : shows && shows.length < 1 ? (
    <p style={{ textAlign: 'center' }}>{t('no_results')}</p>
  ) : (
    <div className="show-grid" data-test="show-grid">
      {shows &&
        shows.map((item, index) => (
          <ShowGridItem
            key={`scheduleTableItem-${item.id}`}
            item={item}
            vocabularies={{
              espai: vocabularyEspai,
              eix: vocabularyEix,
              ambits: vocabularyAmbits,
            }}
            addFavourite={addFavourite}
            removeFavourite={removeFavourite}
            isFavourite={favouiteIds && favouiteIds.includes(item['@uid'])}
            isFavouritesLoading={isFavouritesLoading}
          />
        ))}
    </div>
  )
}
