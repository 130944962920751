import { AuthContext } from 'App'
import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import Account from 'NewVersion/components/Account/Account'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Account.scss'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSetState } from 'react-use'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken, getEmailFromToken } from 'utils/utils'

function AccountPage() {
  const { dispatch } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [errorFetchData, setErrorFetchData] = useState(undefined)
  const { edition, currentEdition, loading: loadingCurrentEdition } = useEdition()
  const [data, setData] = useSetState({
    accreditations: undefined,
    entities: undefined,
    shops: undefined,
    stands: undefined,
    loading: false,
  })

  useEffect(() => {
    ;(async () => {
      if (!data.accreditations && !data.entities && !data.loading) {
        setData({ loading: true })
        try {
          const token = getCurrentJWToken()
          const responseAccreditations = await fetchGuillotina({
            path: `${edition}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa&owners=${getEmailFromToken()}`,
            token: token,
          })

          const accreditations = get(responseAccreditations, 'items', null) ?? []
          const accreditationsEnsUuid = accreditations.map((item) => item.parent_uuid)
          const setAccreditationsEnsUuid = new Set(accreditationsEnsUuid)
          let filterUuid = `__or=`
          setAccreditationsEnsUuid.forEach((uuid) => {
            filterUuid = `${filterUuid}uuid%3D${uuid}%26`
          })

          let responseEntities = null
          responseEntities = await fetchGuillotina({
            path: `${edition}/@search?type_name__in=EnsProfessional,EnsPremsa,Ens,EnsServeis&owners=${getEmailFromToken()}&_sort_des=tipus`,
            token: token,
          })

          if (
            (get(responseEntities, 'items', null) ?? []).length === 0 &&
            setAccreditationsEnsUuid.size > 0
          ) {
            responseEntities = await fetchGuillotina({
              path: `${edition}/@search?${filterUuid}`,
              token: token,
            })
          }

          const responseShops = await fetchGuillotina({
            path: `${edition}/@search?type_name__in=Parada`,
            token: token,
          })

          const responseStand = await fetchGuillotina({
            path: `${edition}/@search?type_name__in=Estand`,
            token: token,
          })

          const responsePayments = await fetchGuillotina({
            path: `${edition}/@search?type_name__in=Pagament`,
            token: token,
          })

          setData({
            accreditations: accreditations,
            entities: get(responseEntities, 'items', null) ?? [],
            shops: get(responseShops, 'items', null) ?? [],
            stands: get(responseStand, 'items', null) ?? [],
            payments: get(responsePayments, 'items', null) ?? [],
            loading: false,
          })
        } catch (error) {
          if (error.status === 401) {
            dispatch({
              type: 'LOGOUT',
            })
            navigate(path.LOGIN_PATH)
          } else {
            setErrorFetchData(error.response)
            setData({
              accreditations: [],
              entities: [],
              loading: false,
            })
          }
        }
      }
    })()
  }, [data, currentEdition, setData, edition, dispatch])

  if (
    data.loading ||
    !data.accreditations ||
    !data.entities ||
    loadingCurrentEdition ||
    !currentEdition
  )
    return <LoadingComponent />
  if (errorFetchData) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }
  return (
    <Account
      accreditations={data.accreditations}
      entities={data.entities}
      shops={data.shops}
      stands={data.stands}
      payments={data.payments}
      currentEdition={currentEdition}
    />
  )
}

export default AccountPage
