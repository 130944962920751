import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo } from 'react'
import { getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'
import { SelectInputField as UISelectInputField } from '../UI/Selector/SelectInputField'
export const VocabularyDynamicSelectInputField = ({
  label,
  icon,
  type,
  value,
  dataTest,
  description,
  disabled,
  error,
  name,
  callback,
  onBlur,
  required,
  isSearchable,
  isMulti,
  onChange,
  vocabularyName,
}) => {
  const { edition } = useEdition()

  const vocabularyData = useGuillotina({
    path: edition && `${edition}/@vocabularies/${vocabularyName}`,
  })
  // console.log('vocabularyData', vocabularyData, vocabularyName, edition)
  const options = useMemo(() => {
    if (vocabularyData?.data) {
      return vocabularyData.data.items.map((item) => ({
        label: getValueVocabularyDynamic([item], item.token, getCurrentLanguage()),
        value: item.token,
      }))
    }
    return []
  }, [vocabularyData])

  const handleChange = (value) => {
    // This is going to call setFieldValue and manually update values.id if there's any
    onChange(name, value)
    callback && callback(value, name)
  }

  return (
    <UISelectInputField
      label={label}
      icon={icon}
      items={options}
      type={type}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      isMulti={isMulti}
      searchable={isSearchable}
      error={error}
      required={required}
      description={description}
      dataTest={dataTest}
    />
  )
}
