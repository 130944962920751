import { TableCell, TableHead as TableHeadMaterial, TableRow, TableSortLabel } from '@mui/material'
import { Box } from '@mui/system'
import { ExpanMoreIcon } from 'NewVersion/icons/ExpanMoreIcon'
import 'NewVersion/styles/UI/Table.scss'
import { useSearchParams } from 'react-router-dom'

export const TableHead = ({ headCells = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <TableHeadMaterial>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={'center'}
              sortDirection={
                headCell.sortable && searchParams.get('sort-by') === headCell.id
                  ? searchParams.get('sort-direction')
                  : false
              }
            >
              <Box display={'flex'} sx={{ alignItems: 'center' }} gap={'8px'}>
                {headCell.icon && headCell.icon}
                {headCell.sortable ? (
                  <TableSortLabel
                    active={searchParams.get('sort-by') === headCell.id}
                    IconComponent={(props) => {
                      return (
                        <span className={props.className}>
                          <ExpanMoreIcon />
                        </span>
                      )
                    }}
                    direction={
                      searchParams.get('sort-by') === headCell.id
                        ? searchParams.get('sort-direction')
                        : 'des'
                    }
                    onClick={() => {
                      const isdes =
                        searchParams.get('sort-by') === headCell.id &&
                        searchParams.get('sort-direction') === 'des'

                      searchParams.set('sort-by', headCell.id)
                      searchParams.set('sort-direction', isdes ? 'asc' : 'des')
                      setSearchParams(searchParams)
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </Box>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHeadMaterial>
  )
}
