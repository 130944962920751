import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { NewInscriptionIcon } from 'NewVersion/icons/NewInscriptionIcon'
import { WarningIcon } from 'NewVersion/icons/WarningIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/PaymentInfoPage.scss'
import { ACCOUNT_PATH } from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useSetState } from 'react-use'
import { PAYMENT_TYPE_LINES, PAYMENT_WORKFLOW_STATE } from 'utils/constants'
import { cleanObjectIds, getCurrentJWToken, getCurrentLanguage } from 'utils/utils'

const PaymentInfoDetail = ({ payment, currentEdition }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const token = getCurrentJWToken()
  const [loadingAction, setLoadingAction] = useState()
  const [submitModal, setSubmitModal] = useState()
  const [errorSubmit, setErrorSubmit] = useSetState({
    hasError: false,
    message: '',
  })

  const getTotalPrice = () => {
    if (payment.preu_final) {
      return `${payment.preu_final}€`
    }
    return t('free')
  }

  const handleError = (error) => {
    if (error.response && error.response.status === 412) {
      setErrorSubmit({
        hasError: true,
        message: `Error: ${t('error_change_workflow_payment')}`,
      })
    } else {
      setErrorSubmit({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const changeWorkflowToPending = async () => {
    setLoadingAction(true)
    try {
      const path = cleanObjectIds(payment['@id'])
      await fetchGuillotina({ path: `${path}/@workflow/pendent`, method: 'POST', token: token })
      navigate(ACCOUNT_PATH)
    } catch (err) {
      handleError(err)
      setLoadingAction(false)
    }
  }

  const getPaymentInfo = () => {
    const divElement = document.createElement('div')
    divElement.innerHTML = currentEdition.getPaymentInfo(getCurrentLanguage())

    // Get all div elements with class "field"
    const divElements = divElement.querySelectorAll('div.field')

    // Get the last two div elements
    const lastTwoDivs = Array.from(divElements).slice(-2)
    const paymentInfoDivs = Array.from(divElements).slice(0, -2)

    const warningPaymentInfo = []
    lastTwoDivs.forEach(function (div) {
      warningPaymentInfo.push(div.outerHTML)
    })

    const paymentInfo = []
    paymentInfoDivs.forEach(function (div) {
      paymentInfo.push(div.outerHTML)
    })

    return {
      paymentInfo: paymentInfo.join(''),
      warningPaymentInfo: warningPaymentInfo.join(''),
    }
  }

  const { paymentInfo, warningPaymentInfo } = getPaymentInfo()

  const totalDiscount = useMemo(() => {
    return payment.informacio_pagament
      .filter((line) => line.type === PAYMENT_TYPE_LINES.DISCOUNT)
      .reduce((a, b) => ({ value: parseFloat(a.value) + parseFloat(b.value) }), { value: 0 })[
      'value'
    ]
  }, [payment.informacio_pagament])

  const subTotal = useMemo(() => {
    return payment.informacio_pagament
      .filter((line) => line.type === PAYMENT_TYPE_LINES.AMOUNT)
      .reduce((a, b) => ({ value: parseFloat(a.value) + parseFloat(b.value) }), { value: 0 })[
      'value'
    ]
  }, [payment.informacio_pagament])

  return (
    <div className="pagament-detail-page page-wrapper">
      <div>
        <h4>{t('payment')}</h4>
        {payment.review_state !== PAYMENT_WORKFLOW_STATE.paid && (
          <p className="pagament-pendent">{t('payment_pending', { price: getTotalPrice() })}</p>
        )}
      </div>
      {errorSubmit.hasError && errorSubmit.message && (
        <AlertComponent className="" type="danger">
          {errorSubmit.message}
        </AlertComponent>
      )}
      <div className="pagament-detail-page_content">
        <div className="pagament-info">
          <div className="pagament-row pagament-title">
            <div>
              <b>{t('order_line_name')}</b>
            </div>
            <div>
              <b>{t('order_line_value')}</b>
            </div>
          </div>

          {payment.informacio_pagament.map((line) => {
            return (
              <div key={line.title} className="pagament-row">
                <div>{t(line.title)}</div>
                <div>
                  {PAYMENT_TYPE_LINES.DISCOUNT === line.type && '-'}&nbsp;{line.value}&nbsp;€
                </div>
              </div>
            )
          })}

          {totalDiscount !== 0 && (
            <>
              <div className="pagament-row">
                <div>
                  <b>{t('sub_total_payment')}</b>
                </div>
                <div>
                  <b>{subTotal}&nbsp;€</b>
                </div>
              </div>
              <div className="pagament-row">
                <div>
                  <b>{t('total_discount_payment')}</b>
                </div>
                <div>
                  <b>{totalDiscount}&nbsp;€</b>
                </div>
              </div>
            </>
          )}
          <div className="separator"></div>
          <div className="pagament-last-row" data-test="totalPayment">
            <div>
              <b>{t('total_payment')}</b>
            </div>
            <div>
              <b>{payment.preu_final}&nbsp;€</b>
            </div>
          </div>
        </div>

        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: paymentInfo,
            }}
          ></div>
          <div className="warning-info">
            <div>
              <WarningIcon />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: warningPaymentInfo,
              }}
            ></div>
          </div>
        </div>
      </div>

      {!loadingAction && (
        <Button dataTest="btnSendPaymentTest" onClick={() => navigate(ACCOUNT_PATH)}>
          {t('Tornar al perfil')}
        </Button>
      )}
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        handleOk={changeWorkflowToPending}
        title={t('inscripcio_realitzada')}
        text={t('confirmacio_per_email')}
        icon={<NewInscriptionIcon fill={'#273BF3'} />}
      />
      {loadingAction && <LoadingComponent />}
    </div>
  )
}

function PaymentInfoPage() {
  const { paymentId } = useParams()
  const { edition, currentEdition } = useEdition()
  const { t } = useTranslation()

  const {
    data: { items: payments } = {},
    isLoading,
    error,
  } = useGuillotina({
    path: `${edition}/@search?uuid=${paymentId}`,
  })

  const payment = payments ? payments[0] : {}

  if (isLoading) {
    return <LoadingComponent />
  } else if (error) {
    return <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>
  }

  return <PaymentInfoDetail payment={payment} currentEdition={currentEdition} />
}

export default PaymentInfoPage
