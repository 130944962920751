import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { AcreditacioIcon } from 'NewVersion/icons/AcreditacioIcon'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TableHead } from '../UI/Table/TableHead'
import { ArtistTableItem } from './ArtistTableItem'

const ArtistTableComponent = ({ artists = [], loading, onClick, gridView }) => {
  const { t } = useTranslation()

  const tableHeader = [
    {
      id: 'title',
      label: t('artists'),
      sortable: true,
      icon: <AcreditacioIcon />,
    },
    {
      id: 'title_representant',
      label: t('Representant'),
      sortable: true,
      icon: <AcreditacioIcon />,
    },
  ]

  const renderArtists = (item) => (
    <ArtistTableItem
      key={`artistTableItem-${item.id}`}
      artist={item}
      onClick={onClick}
      gridView={gridView}
    />
  )

  return (
    <>
      <TableContainer className={`table-container ${gridView ? 'grid-view' : ''}`}>
        <Table aria-label="artists table" data-test={'table'}>
          <TableHead headCells={tableHeader} />
          <TableBody>
            {!loading && artists.length > 0 ? (
              artists.map((item) => renderArtists(item))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  {loading ? <LoadingComponent /> : t('no_results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        className={`table-container-mobile ${gridView ? 'grid-view' : ''}`}
        data-test="table-container"
      >
        {!loading && artists.length > 0 ? (
          artists.map((item) => renderArtists(item))
        ) : (
          <Box>{loading ? <LoadingComponent /> : t('no_results')}</Box>
        )}
        <Box flexGrow={1}></Box>
      </Box>
    </>
  )
}

export const ArtistTable = React.memo(ArtistTableComponent)
