export const CalendarAccpetIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2524_8346)">
      <path
        d="M33.06 22.12L30.94 20L21.18 29.76L16.94 25.52L14.82 27.64L21.18 34L33.06 22.12ZM38 6H36V2H32V6H16V2H12V6H10C7.78 6 6.02 7.8 6.02 10L6 38C6 40.2 7.78 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM38 38H10V16H38V38Z"
        fill="#273BF3"
      />
    </g>
    <defs>
      <clipPath id="clip0_2524_8346">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
