import 'NewVersion/styles/UI/InputAcceptRegulation.scss'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'utils/utils'
import { InputCheckbox } from './InputCheckBox'

export const InputAcceptRegulation = ({
  onChange,
  onBlur,
  checked,
  error,
  name,
  renderLabel,
  readOnly,
}) => {
  const { currentEdition } = useEdition()
  const { t } = useTranslation()
  return (
    <div className="accept-regulation-wrapper">
      {renderLabel && <legend>{t('declaracio_fieldset')}</legend>}

      <div
        style={{ /* maxHeight: '136px', maxWidth: '776px', */ overflowY: 'scroll' }}
        dangerouslySetInnerHTML={{
          __html: currentEdition.getStatementOfResponsibility(getCurrentLanguage()),
        }}
        className="accept-regulation"
      ></div>
      {!readOnly && (
        <InputCheckbox
          name={name}
          label={t('accept_protection_policy')}
          checked={checked}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          dataTest={`inputFieldTest_${name}`}
          required
        />
      )}
    </div>
  )
}
