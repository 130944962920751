import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import WrapperUnauthAction from 'Components/WrapperUnauthAction'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { TextInputField } from 'NewVersion/components/UI/Forms/TextInputField'
import Link from 'NewVersion/components/UI/Link/Link'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Login.scss'
import * as path from 'Routes/Paths'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function Register() {
  const [email, setEmail] = useState('')
  const [userName, setUsername] = useState('')
  const [isLoading, setLoader] = useState(false)
  const [signUpComplete, setSignUpComplete] = useState(false)
  const [signupError, setSignupError] = useState({
    haveError: false,
    message: '',
  })
  const { t } = useTranslation()

  const validateForm = () => {
    return false
  }

  const onRegister = async (data) => {
    setSignupError({
      haveError: false,
      message: '',
    })
    data.preventDefault()
    if (!email || !userName) {
      setSignupError({
        haveError: true,
        message: t('Aquest camp és obligatori'),
      })
    } else {
      setLoader(true)

      await fetchGuillotina({
        path: '@users',
        method: 'POST',
        data: {
          id: email,
          fullname: userName,
          email: email,
        },
        checkError: true,
        unAuth: true,
      })
        .then(function () {
          console.log('signIn send')
          setSignUpComplete(true)
          setUsername('')
          setEmail('')
        })
        .catch(function (error) {
          if (error && error.info && error.info.text === 'Invalid login') {
            setSignupError({
              haveError: true,
              message: t('email_already_exists_register'),
            })
          } else {
            setSignupError({
              haveError: true,
              message: t('error_generic'),
            })
          }
          setUsername('')
          setEmail('')
        })
    }
    setLoader(false)
  }

  return (
    <WrapperUnauthAction>
      <section className="login">
        <div className="input-container">
          <h2 className="input-title">{t('Registrar_pro')}</h2>

          <form className="inputs" onSubmit={onRegister} data-test="formRegisterTest">
            <TextInputField
              type="text"
              label={t('Correu electrònic')}
              onChange={({ target }) => setEmail(target.value)}
              error={signupError.haveError}
              dataTest="inputEmailRegisterTest"
              value={email}
            />
            <TextInputField
              type="text"
              label={t('username')}
              dataTest="inputUserNameRegisterTest"
              onChange={({ target }) => setUsername(target.value)}
              error={signupError.haveError}
              value={userName}
            />
            {signupError.haveError && (
              <p className="error" data-test="campObligatoriTest">
                {signupError.message}
              </p>
            )}
            {!isLoading ? (
              <Button
                disabled={validateForm()}
                color="primary"
                type="submit"
                dataTest="btnRegisterTest"
              >
                {t('Registrat')}
              </Button>
            ) : (
              <div style={{ height: '56px' }}>
                <LoadingComponent noClass={true} />
              </div>
            )}
            <div className="signup-complete" data-test="signup-completeTest">
              {signUpComplete && t('registre completat')}
            </div>

            <div className="links">
              <Link href={path.LOGIN_PATH}>{t('Iniciar sessió')}</Link>
            </div>
          </form>
        </div>
        <div className="login-image" />
      </section>
    </WrapperUnauthAction>
  )
}

export default Register
