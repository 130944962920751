export const FacebookIcon = ({ color = '#FAFAFA' }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9927 0C6.7139 0 0 6.71343 0 14.9916C0 23.2699 6.7139 29.9833 14.9927 29.9833C23.2715 29.9833 29.9854 23.2699 29.9854 14.9916C29.9854 6.71343 23.2883 0 14.9927 0ZM20.1922 8.31186H18.3076C16.8268 8.31186 16.5408 9.01854 16.5408 10.0449V12.3164H20.0576L19.6032 15.8666H16.5408V24.9861H12.8725V15.8666H9.81003V12.3164H12.8725V9.69157C12.8725 6.64613 14.7235 4.99721 17.4494 4.99721C18.7451 4.99721 19.8725 5.09817 20.1922 5.13182V8.31186Z"
        fill={color}
      />
    </svg>
  )
}
