import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useMemo } from 'react'
import useSWR from 'swr'

export function useGetVocabularyDynamic(name, lang, edition) {
  const { data } = useSWR(
    `/${edition}/@getTranslationsVocabulary?vocabulary=${name}&lang=${lang}`,
    (url) => fetchGuillotina({ path: url }),
    { dedupingInterval: 1800 * 1000 }
  )

  const vocabulary = useMemo(() => {
    if (data) {
      return Object.keys(data ?? {}).map((key) => {
        return {
          title: data[key],
          token: key,
        }
      })
    }
    return []
  }, [data])

  return { vocabulary, vocabularyWithoutFormat: data }
}
