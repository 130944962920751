import * as React from 'react'
import { getEmailFromToken } from 'utils/utils'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ProposalEntitiesList } from './ProposalEntitiesList'
import { useGuillotina } from 'hooks/useGuillotina'

const ProposalEntitiesComponent = ({ year, type = '' }) => {
  const { data: { items: entities = [] } = {}, isLoading: loading } = useGuillotina({
    path: `${year}/@search?type_name=Ens&tipus=${type}&owners=${getEmailFromToken()}&_sort_des=creation_date`,
  })

  return (
    <div id="EntityItem">
      {loading && <LoadingComponent />}
      <div className="entity-container">
        <ProposalEntitiesList entities={entities} />
      </div>
    </div>
  )
}

export const ProposalEntities = React.memo(ProposalEntitiesComponent)
