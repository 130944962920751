import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Button, Menu, MenuItem } from '@mui/material'
import { EditUserIcon } from 'NewVersion/icons/EditUserIcon'
import { ExitIcon } from 'NewVersion/icons/ExitIcon'
import 'NewVersion/styles/UI/UserOptionsSelector.scss'
import * as path from 'Routes/Paths'
import { useLogout } from 'hooks'
import { useEdition } from 'hooks/useEdition'
import { useState } from 'react'
import { useNavigate } from 'react-router'

export const UserOptionsSelector = ({ isMobile = false }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()
  const { reset } = useEdition()
  const logout = useLogout()
  const user = localStorage.user

  const logoutHandler = () => {
    reset()
    logout()
  }

  return (
    <div className="user-options-selector">
      <Button
        className="name"
        onClick={handleClick}
        data-test="btnUserInfoTest"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <div className="first-letter-round">
          <span className="letter">{user && user[0].toUpperCase()}</span>
        </div>
        {!isMobile && user}
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            navigate(path.ACCOUNT_PATH)
          }}
          data-test="btnProfileInfoTest"
        >
          <div className="user-options-selected-icon">
            <EditUserIcon />
          </div>
          <span>Editar el meu perfil</span>
        </MenuItem>
        <MenuItem onClick={() => logoutHandler()}>
          <div className="user-options-selected-icon">
            <ExitIcon />
          </div>
          <span>Sortir</span>
        </MenuItem>
      </Menu>
    </div>
  )
}
