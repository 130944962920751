import React, { memo, useState, useEffect } from 'react'
import { useSetState } from 'react-use'
import { useTranslation } from 'react-i18next'
import placeholder from 'Statics/placeholder.png'
import { AlertComponent, LoadingComponent } from 'Components/HelperComponents'
import { useEdition } from 'hooks/useEdition'
import { fetchGuillotina } from 'utils/apiCalls'

const EntitiyDetailInfoComponent = ({ entity }) => {
  const { currentEdition } = useEdition()
  const [errorFetchData, setErrorFetchData] = useState(undefined)
  const [data, setData] = useSetState({
    entityData: undefined,
    loading: false,
  })

  useEffect(() => {
    ;(async () => {
      if (!data.entityData && !data.loading) {
        setData({ loading: true })
        try {
          const responseEntitiy = await fetchGuillotina(entity['path'])
          setData({
            entityData: responseEntitiy.data,
            loading: false,
          })
        } catch (error) {
          if (error.response.status === 401) {
            // Logout
          } else {
            setErrorFetchData(error.response)
            setData({
              entityData: {},
              loading: false,
            })
          }
        }
      }
    })()
  }, [data, currentEdition, setData, entity])

  const { t } = useTranslation()
  return (
    <div className="user-info-container">
      {(data.loading || !data.entityData) && <LoadingComponent />}
      {errorFetchData && <AlertComponent type="danger"> {t('error-fetching-data')}</AlertComponent>}
      {data.entityData && (
        <>
          <div className="user-image">
            <img src={placeholder} alt="placeholder" />
          </div>
          <div className="user-info-1">
            <div>
              <b>{t('NIF')}</b>: {data.entityData.nif || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('adreca')}</b>: {data.entityData.adreca || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('codi_postal')}</b>: {data.entityData.codi_postal || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('comarca')}</b>: {data.entityData.comarca || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('telefon')}</b>: {data.entityData.telefon || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('Correu electrònic')}</b>:{' '}
              {data.entityData.name || data.entityData.correu || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('web')}</b>: {data.entityData.web || '--'}
            </div>
            <br></br>
            <div>
              <b>{t('Tipología de professional')}</b>: {data.entityData.ambit || '--'}
            </div>
            <br></br>
          </div>
          <div className="user-info-2">
            <div>
              <b>Facebook:</b>&nbsp;
              {data.entityData.facebook ? (
                <a className="link-no-style" href={data.entityData.facebook}>
                  {data.entityData.facebook}
                </a>
              ) : (
                '--'
              )}
            </div>
            <br></br>
            <div>
              <b>Instagram:</b>&nbsp;
              {data.entityData.instagram ? (
                <a className="link-no-style" href={data.entityData.instagram}>
                  {data.entityData.instagram}
                </a>
              ) : (
                '--'
              )}
            </div>
            <br></br>
            <div>
              <b>LinkedIn:</b>&nbsp;
              {data.entityData.linkedin ? (
                <a className="link-no-style" href={data.entityData.linkedin}>
                  {data.entityData.linkedin}
                </a>
              ) : (
                '--'
              )}
            </div>
            <br></br>
            <div>
              <b>Twitter:</b>&nbsp;
              {data.entityData.twitter ? (
                <a className="link-no-style" href={data.entityData.twitter}>
                  {data.entityData.twitter}
                </a>
              ) : (
                '--'
              )}
            </div>
            <br></br>
            <div>
              <b>Youtube:</b>&nbsp;
              {data.entityData.youtube ? (
                <a className="link-no-style" href={data.entityData.youtube}>
                  {data.entityData.youtube}
                </a>
              ) : (
                '--'
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export const EntitiyDetailInfo = memo(EntitiyDetailInfoComponent)
