export const GridViewIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 10.6666H10.6673V5.33331H5.33398V10.6666ZM13.334 26.6666H18.6673V21.3333H13.334V26.6666ZM10.6673 26.6666H5.33398V21.3333H10.6673V26.6666ZM5.33398 18.6666H10.6673V13.3333H5.33398V18.6666ZM18.6673 18.6666H13.334V13.3333H18.6673V18.6666ZM21.334 5.33331V10.6666H26.6673V5.33331H21.334ZM18.6673 10.6666H13.334V5.33331H18.6673V10.6666ZM21.334 18.6666H26.6673V13.3333H21.334V18.6666ZM26.6673 26.6666H21.334V21.3333H26.6673V26.6666Z"
        fill="#5C5958"
      />
    </svg>
  )
}
