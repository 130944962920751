import React from 'react'
import { useEdition } from 'hooks/useEdition'

import { BACKEND_TYPE } from 'utils/constants'
import { ProfessionalActivityPage as ProfessionalActivityPagePlone } from './Plone/ProfessionalActivityPage'
import { ProfessionalActivityDetailPage as ProfessionalActivityDetailPageGuillotina } from './Guillotina/ProfessionalActivityDetailPage'

export function ProfessionalActivityDetailPage(props) {
  const { currentEdition } = useEdition()

  if (currentEdition.getBackendType() === BACKEND_TYPE.PLONE) {
    return <ProfessionalActivityPagePlone {...props} />
  } else {
    return <ProfessionalActivityDetailPageGuillotina />
  }
}
export default ProfessionalActivityDetailPage
