export const FastMeetingIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2463_17365)">
        <path
          d="M20.991 4.8C20.991 3.81 20.19 3 19.2 3H4.8C3.81 3 3 3.81 3 4.8V15.6C3 16.59 3.81 17.4 4.8 17.4H17.4L21 21L20.991 4.8ZM16.5 11.1H12.9V14.7H11.1V11.1H7.5V9.3H11.1V5.7H12.9V9.3H16.5V11.1Z"
          fill="#2B2A29"
        />
      </g>
      <defs>
        <clipPath id="clip0_2463_17365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
