const host =
  process.env.NODE_ENV === 'production'
    ? 'https://api.firamediterrania.cat'
    : 'http://localhost:5000'

function post(path, body) {
  console.log('Faig post')
  return fetch(`${host}${path}`, {
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      'sec-fetch-mode': 'cors',
      Authorization: 'Basic' + btoa('root:M33dIt3Rr4n14'),
    },
    body: JSON.stringify(body),
    method: 'POST',
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (data) {
      return data
    })
}

function get(path) {
  return fetch(`${host}${path}`, {
    credentials: 'omit',
    headers: { 'content-type': 'application/json;charset=UTF-8', 'sec-fetch-mode': 'cors' },
    method: 'GET',
    mode: 'cors',
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (data) {
      return data
    })
}

const http = {
  post: post,
  get: get,
}

export default http
