import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { EntradaIcon } from 'NewVersion/icons/EntradaIcon'
import { HomeIcon } from 'NewVersion/icons/HomeIcon'
import { LocationIcon } from 'NewVersion/icons/LocationIcon'
import { PropostesIcon } from 'NewVersion/icons/PropostesIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TableHead } from '../UI/Table/TableHead'
import { ScheduleTableItem } from './ScheduleTableItem'

const ScheduleTableComponent = ({ showsOfSchedule = [], loading, type }) => {
  const { edition } = useEdition()
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()

  const { t } = useTranslation()

  const { data: { items: vocabularyEspai } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/espai`,
    useStatic: true,
  })

  const { data: { items: vocabularyEix } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/eix`,
    useStatic: true,
  })

  const { data: { items: vocabularyAmbits } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/ambits`,
    useStatic: true,
  })

  const tableHeader = [
    {
      id: 'data',
      label: t('Day_and_hour'),
      sortable: true,
      icon: <HomeIcon />,
    },
    {
      id: 'ambit',
      label: t('Àmbit'),
      icon: <PropostesIcon />,
    },
    {
      id: 'shows_names',
      label: t('Activitat'),
      icon: <UserIcon />,
    },
    {
      id: 'espai',
      label: t('Espai'),
      sortable: true,
      icon: <LocationIcon />,
    },
    {
      id: 'entrada',
      label: t('Entrada'),
      icon: <EntradaIcon />,
    },
    {
      id: 'actions',
      label: t('Agenda'),
    },
  ]

  const renderSchedule = (item, format) => (
    <ScheduleTableItem
      key={`scheduleTableItem-${item.id}`}
      showOfSchedule={item}
      vocabularies={{
        espai: vocabularyEspai,
        eix: vocabularyEix,
        ambits: vocabularyAmbits,
      }}
      addFavourite={addFavourite}
      removeFavourite={removeFavourite}
      isFavourite={favouiteIds && favouiteIds.includes(item['@uid'])}
      isFavouritesLoading={isFavouritesLoading}
      type={type}
      format={format}
    />
  )

  return (
    <>
      <TableContainer className="table-container" data-test={'table-container'}>
        <Table aria-label="Shows Table" data-test={'table'}>
          <TableHead headCells={tableHeader} />
          <TableBody>
            {!loading && showsOfSchedule.length > 0 ? (
              showsOfSchedule.map((item) => renderSchedule(item, 'table'))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {loading ? <LoadingComponent /> : t('no_results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="table-container-mobile">
        {!loading && showsOfSchedule.length > 0 ? (
          showsOfSchedule.map((item) => renderSchedule(item, 'grid'))
        ) : (
          <Box>{loading ? <LoadingComponent /> : t('no_results')}</Box>
        )}
      </Box>
    </>
  )
}

export const ScheduleTable = React.memo(ScheduleTableComponent)
