import React, { useEffect, useState } from 'react'
import { ReactComponent as PersonPlaceholder } from 'Statics/person-24px.svg'

import cloneDeep from 'lodash/cloneDeep'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { getApiCallImagePlone } from 'utils/apiCalls'
import { getCurrentJWToken } from 'utils/utils'
function XatsElement(props) {
  // Aixo tambe gestionara quants missatges pendents tens
  const [currentImage, setImagea] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    const target =
      localStorage.UID === props.element.recipientID2
        ? props.element.recipientID1
        : props.element.recipientID2

    if (props.targets.length !== 0) {
      function getTarget(valor) {
        if (valor.UID === target) {
          return valor
        }
      }
      const element = props.targets.find(getTarget)
      if (element) {
        getApiCallImagePlone(element['@id'] + '/@@images/image/preview', getCurrentJWToken()).then(
          (value) => {
            setImagea(value)
          }
        )
      }
    }
  }, [props.targets, props.element.recipientID1, props.element.recipientID2])

  const selectXat = () => {
    // actualitzes ultima hora mirada
    let objects = JSON.parse(localStorage.getItem('xatSeen'))
    let element
    const target =
      localStorage.UID === props.element.recipientID2
        ? props.element.recipientID1
        : props.element.recipientID2
    if (objects) {
      element = objects.find((v) => v.uid === target)
    } else {
      objects = []
    }

    if (element) {
      objects.find((v) => v.uid === target).timestamp = Date.parse(Date())
    } else {
      objects.push({ uid: target, timestamp: Date.parse(Date()) })
    }
    localStorage.setItem('xatSeen', JSON.stringify(objects))
    localStorage.removeItem('newXatID')
    props.renderXatSpecific(props.identifier)

    props.setTarget(target)
    // reseteja missatges nous del target
  }
  const getMemberName = () => {
    const target =
      localStorage.UID === props.element.recipientID2
        ? props.element.recipientID1
        : props.element.recipientID2
    if (props.targets.length !== 0) {
      function getTarget(valor) {
        if (valor.UID === target) {
          return valor
        }
      }
      const element = props.targets.find(getTarget)
      if (element) {
        return (
          <>
            <div className="name">{element.Title}</div>
            <div className="entity">{element.description}</div>
          </>
        )
      }
    }
    // compara el uid del xat amb el uid del membre amb el que habies parlat
  }
  const getLastMessageHour = () => {
    const oldConvo = cloneDeep(props.element.conversation)
    const time = oldConvo.reverse()[0].timestamp
    const d = new Date(time)
    const day = d.getDate()
    const month = d.getMonth() + 1
    const hour = d.getHours()
    return (
      <div className="date">
        {t('Ultim missatge')} {day}/{month} - {hour}:
        {(d.getMinutes() < 10 ? '0' : '') + d.getMinutes()}
      </div>
    )
  }
  const getCurrentMessagesPending = () => {
    if (props.currentXat && props.currentXat.uuid === props.element.uuid) {
      return undefined
    } else {
      const target =
        localStorage.UID === props.element.recipientID2
          ? props.element.recipientID1
          : props.element.recipientID2
      const objects = JSON.parse(localStorage.getItem('xatSeen'))
      let previousSeen
      if (objects) {
        previousSeen = objects.find((v) => v.uid === target)
      }

      const convo = props.element.conversation.reverse()
      let unseen = 0
      if (previousSeen) {
        previousSeen = previousSeen.timestamp
      } else {
        previousSeen = 0
      }
      for (let i = 0; i < convo.length; i++) {
        if (previousSeen < convo[i].timestamp && convo[i].propietari === target) {
          unseen = unseen + 1
        } else {
          break
        }
      }
      props.element.conversation.reverse()
      return unseen === 0 ? undefined : <div className="pending-container"> {unseen}</div>
    }
    // mira quants misssatges nous te desde l'ultim cop
  }
  return (
    <div
      key={props.identifier}
      className={
        props.element.uuid === props.currentXat.uuid
          ? 'xat-list-element selected'
          : 'xat-list-element'
      }
      onClick={selectXat}
    >
      <div className="xat-list-image">
        {/* <img src={personPlaceholder} alt="placeholder"/> */}
        {currentImage !== '' ? (
          <div
            className="image"
            style={{ backgroundImage: 'url(' + currentImage + ')' }}
            alt="Image"
          ></div>
        ) : (
          <PersonPlaceholder className="svg-icon-class" />
        )}
      </div>
      <div className="xat-list-info">
        {getMemberName()}
        {getLastMessageHour()}
      </div>
      {getCurrentMessagesPending()}
    </div>
  )
}

function Xats(props) {
  const [orderedXats, setOrderedXats] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    function sortByThenBy(items) {
      return items.sort(function (it1, it2) {
        return compare(it1, it2)
      })
    }

    function compare(a, b) {
      return a.conversation[a.conversation.length - 1].timestamp <
        b.conversation[b.conversation.length - 1].timestamp
        ? 1
        : -1
      // return it1[currentKey] < it2[currentKey] ? 1 : (it1[currentKey] > it2[currentKey] ? -1 : compare(it1, it2, keys, index + 1));
    }

    const ord = sortByThenBy(props.xatsList)
    setOrderedXats(ord.slice())
  }, [props.xatsList, props.xatChanged])

  return (
    <div>
      {isMobile && <div className="title-generic account-1">Els meus missatges</div>}
      <div className="xats-list">
        {localStorage.newXatID && (
          <div className="xat-list-element selected">
            <div className="xat-list-image">
              {/* <img src={personPlaceholder} alt="placeholder"/> */}
              <PersonPlaceholder className="svg-icon-class" />
            </div>
            {props.currentTarget && (
              <div className="xat-list-info">{props.currentTarget.Title}</div>
            )}
            <div className="xat-list-info">
              :&nbsp;&nbsp; {t('Envia un missatge i inicia el Xat!')}
            </div>
          </div>
        )}
        {orderedXats.map((element, key) => (
          <XatsElement
            currentXat={props.currentXat}
            key={key}
            targets={props.targets}
            identifier={key}
            element={element}
            setTarget={props.setTarget}
            renderXatSpecific={props.renderXatSpecific}
          />
        ))}
      </div>
    </div>
  )
}
export default Xats
