import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOutsideClick from '../../hooks/useOutsideClick'

export function SelectorComponent(props) {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const ref = useRef()
  const setOption = (element) => {
    setOpen(false)
    props.changeCallback(element)
  }
  useOutsideClick(ref, () => {
    setOpen(false)
  })
  const renderFilter = () => {
    if (props.filterText) {
      return (
        <div className="current-option">
          <div className="option-text">
            {props.notTranslateOptions ? props.filterText : t(props.filterText)}
          </div>
        </div>
      )
    } else {
      return <div className="current-option">{props.currentOption}</div>
    }
  }

  const getOptions = () => {
    if (props.arrayOptionsKeyValue) {
      return props.arrayOptionsKeyValue
    }

    return props.arrayOptions.map((option) => {
      return { key: option, value: option }
    })
  }

  return (
    <div
      ref={ref}
      className={
        !props.deixaDeCanviarDissenysNassos ? 'selector-class' : 'selector-class change-colors '
      }
      onClick={() => setOpen(!isOpen)}
    >
      <div className="selector">
        {getOptions().length !== 1 && (
          <div>
            <KeyboardArrowDownRounded style={{ fontSize: 20 }} />{' '}
          </div>
        )}
        {renderFilter()}
      </div>
      {getOptions().length !== 1 && (
        <div className={isOpen ? 'options' : 'd-none'}>
          {getOptions().map((element, key) => (
            <div
              key={key}
              className={
                props.currentOption === element['key'] ? 'option ripple selected' : 'option ripple'
              }
              onClick={() => setOption(element['key'])}
            >
              {props.notTranslateOptions ? element['value'] : t(element['value'])}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default SelectorComponent
