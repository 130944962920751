export const PremsaIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3109 8.29266L16.5025 9.10103L15.699 8.29266L14.8906 9.10103L14.0823 8.29266L13.2788 9.10103L12.4704 8.29266L11.662 9.10103L10.8585 8.29266L10.0501 9.10103L9.24177 8.29266L8.43825 9.10103L7.62988 8.29266V16.0375C7.62988 16.5699 8.06553 17.0056 8.59798 17.0056H16.3428C16.8752 17.0056 17.3109 16.5699 17.3109 16.0375V8.29266ZM11.9863 16.0375H8.59798V13.1332H11.9863V16.0375ZM16.3428 16.0375H12.9544V15.0694H16.3428V16.0375ZM16.3428 14.1013H12.9544V13.1332H16.3428V14.1013ZM16.3428 12.1651H8.59798V10.7129H16.3428V12.1651Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.8724C4 4.93673 4.75851 4.17822 5.69418 4.17822H19.2476C20.1833 4.17822 20.9418 4.93673 20.9418 5.8724V19.4258C20.9418 20.3615 20.1833 21.12 19.2476 21.12H5.69418C4.75851 21.12 4 20.3615 4 19.4258V5.8724ZM5.69418 5.63037C5.56051 5.63037 5.45215 5.73873 5.45215 5.8724V19.4258C5.45215 19.5595 5.56051 19.6678 5.69418 19.6678H19.2476C19.3813 19.6678 19.4896 19.5595 19.4896 19.4258V5.8724C19.4896 5.73873 19.3813 5.63037 19.2476 5.63037H5.69418Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M18 2V5H16.5V3.5L16.5 2H18Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.59851 2V5H7.24316V2H8.59851Z" fill="white" />
  </svg>
)
