import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { MeetingTable } from 'NewVersion/components/Meeting/MeetingTable'
import { ScheduleTable } from 'NewVersion/components/Schedule/ScheduleTable'
import { Filter } from 'NewVersion/components/UI/Filters/Filter'
import FilterSearch from 'NewVersion/components/UI/Filters/FilterSearch'
import { MAX_TIEMS_PAGE_DEFAULT, Pagination } from 'NewVersion/components/UI/Pagination/Pagination'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { MessageIcon } from 'NewVersion/icons/MessageIcon'
import 'NewVersion/styles/AgendaListPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getCurrentLanguage, getEmailFromToken } from 'utils/utils'

function AgendaListPage() {
  const { t } = useTranslation()
  const { edition, currentEdition } = useEdition()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentLanguage = getCurrentLanguage()
  const { favouiteIds, loading: loadingFavourites } = useFavourites()

  const getFiltreFavourite = () => {
    return favouiteIds ? `&uuid__or=${favouiteIds.map((item) => `uuid=${item}`).join('%26')}` : ''
  }

  const getFiltersSearch = () => {
    let search = ''
    let filterTypeName = ''
    let filterDay = ''

    if (searchParams.get('type_event') === 'act_prof') {
      filterTypeName = 'type_name=ActivitatProfessional'
    } else if (searchParams.get('type_event') === 'act_art') {
      filterTypeName = 'type_name=Actuacio'
    } else {
      filterTypeName = 'type_name__in=ActivitatProfessional,Actuacio'
    }

    if (searchParams.get('day') && searchParams.get('day') !== 'all') {
      const dayArray = searchParams.get('day').split(',')
      filterDay = `&data_timestamp__or=${dayArray
        .map((item) => `data_timestamp=${item}`)
        .join('%26')}`
    }

    if (searchParams.get('search') && searchParams.get('search') !== '') {
      search = `&__or=${['artista', `nom_${currentLanguage}`, 'shows_names']
        .map((item) => `${item}=${searchParams.get('search')}`)
        .join('%26')}`
    }

    const filterOrder = `&_sort_${
      searchParams.get('sort-direction') ? searchParams.get('sort-direction') : 'des'
    }=${searchParams.get('sort-by') ? searchParams.get('sort-by') : 'data'}`

    return `${filterTypeName}${filterDay}&${getFiltreFavourite()}${search}${filterOrder}`
  }

  const getFiltersSearchMeet = () => {
    const filterOrder = `&_sort_${
      searchParams.get('sort-direction') ? searchParams.get('sort-direction') : 'des'
    }=${searchParams.get('sort-by') ? searchParams.get('sort-by') : 'data'}`

    return `${filterOrder}`
  }

  const getPaginationFilter = () => {
    let paginationFilter = `&b_size=${MAX_TIEMS_PAGE_DEFAULT}`
    if (searchParams.get('page')) {
      paginationFilter = `&b_start=${
        (parseInt(searchParams.get('page')) - 1) * MAX_TIEMS_PAGE_DEFAULT
      }${paginationFilter}`
    } else paginationFilter = `&b_start=0${paginationFilter}`
    console.log(paginationFilter)
    return paginationFilter
  }

  const { data: { items_total: itemsTotal } = 0 } = useGuillotina({
    path: `${edition}/@search?type_name__in=Actuacio,ActivitatProfessional${getFiltreFavourite()}${getPaginationFilter()}`,
  })

  const { isLoading, data: { items: favouriteShows, items_total: showingItems } = [] } =
    useGuillotina({
      path: `${edition}/@search?${getFiltersSearch()}&b_start=0&b_size=150`,
    })

  const { data: { items: acreditation } = [], isLoading: isLoadingAcreditation } = useGuillotina({
    path: `${edition}/@search?type_name=AcreditacioProfessional&owners=${getEmailFromToken()}&correu=${getEmailFromToken()}&b_start=0&b_size=1`,
  })

  const {
    refresh,
    data: { items: meetings } = [],
    isLoading: isLoadingMeetings,
  } = useGuillotina({
    path:
      acreditation && acreditation[0] && acreditation[0].id
        ? `${edition}/@search?type_name=FranjaReunioRapida${getFiltersSearchMeet()}&acreditat=${
            acreditation[0].id
          }`
        : null,
  })

  const filtersDefiniton = useMemo(() => {
    const filters = [
      {
        key: 'type_event',
        label: t('Activitat'),
        sorted: true,
        unique: true,
        data: [
          { value: 'all', label: t('show_all'), default: true },
          { value: 'quick_meet', label: t('reunions_rapides') },
          { value: 'act_art', label: t('Activitat artística') },
          { value: 'act_prof', label: t('Activitat professional') },
        ],
      },
    ]
    if (currentEdition) {
      filters.push({
        key: 'day',
        label: t('day'),
        sorted: false,
        data: [
          { value: 'all', label: t('show_all_days'), default: true },
          ...currentEdition
            .getFiraProfessionalActivitiesDaysFilters(t)
            .map((day) => ({ value: day[2], label: `${day[0]} ${day[1]}` })),
        ],
      })
    }

    return filters
  }, [currentEdition, currentLanguage])

  return (
    <Box className="container-agenda page-wrapper">
      <Box
        className={'laMevaAgendaTitle'}
        display={'flex'}
        alignItems={'end'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box maxWidth={'500px'}>
          <h1>{t('agenda')}</h1>
          <p>{t('agenda_page_desc')}</p>
        </Box>
        <FilterSearch
          placeholder={t('agenda_search')}
          onChange={({ target }) => {
            searchParams.set('search', target.value)
            searchParams.set('page', 1)
            setSearchParams(searchParams)
          }}
          dataTest="inputSearchAgendaTest"
          value={searchParams.get('search') || ''}
          variant={'outlined'}
        />
      </Box>
      <Filter
        itemsTotal={itemsTotal}
        showingItems={showingItems || 0}
        filtersDefiniton={filtersDefiniton}
        showTypeView={false}
      />
      <Box className="agenda-title">
        <h2>{t('meves_reunions')}</h2>
        <MessageIcon />
      </Box>

      {isLoadingAcreditation ? (
        <LoadingComponent />
      ) : (acreditation ?? []).length === 0 ? (
        <Box textAlign={'center'} my={3}>
          {t('no_acreditation_active_to_meeting')}
        </Box>
      ) : (
        <MeetingTable meetings={meetings} loading={isLoadingMeetings} refresh={refresh} />
      )}
      <Box className="agenda-title">
        <h2>{t('activities/programation')}</h2>
        <CalendarIcon />
      </Box>

      <ScheduleTable
        showsOfSchedule={searchParams.get('type_event') === 'quick_meet' ? [] : favouriteShows}
        loading={isLoading || loadingFavourites}
        type="agenda"
      />

      {!isLoading && <Pagination itemsTotal={showingItems} />}
    </Box>
  )
}

export default AgendaListPage
