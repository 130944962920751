export const WarningIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM13.1988 16.8C13.1988 17.4628 12.6616 18 11.9988 18C11.3361 18 10.7988 17.4628 10.7988 16.8C10.7988 16.1373 11.3361 15.6 11.9988 15.6C12.6616 15.6 13.1988 16.1373 13.1988 16.8ZM10.7988 12C10.7988 12.6627 11.3361 13.2 11.9988 13.2C12.6616 13.2 13.1988 12.6627 13.1988 12V7.2C13.1988 6.53726 12.6616 6 11.9988 6C11.3361 6 10.7988 6.53726 10.7988 7.2V12Z"
      fill="#2B2A29"
    />
  </svg>
)
