import 'NewVersion/styles/UI/Badges.scss'

const Badges = ({
  text,
  icon = undefined,
  dataTest,
  className = 'presentada',
  width = 'fit-content',
}) => {
  return (
    <div className={`badges_basic ${className}`} data-test={dataTest} style={{ width: width }}>
      {icon} {text}
    </div>
  )
}

export default Badges
