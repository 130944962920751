import React, { useState } from 'react'

const Context = React.createContext({})

export function YearsContextProvider({ children }) {
  const [years, setYears] = useState(undefined)

  return <Context.Provider value={{ years, setYears }}>{children}</Context.Provider>
}

export default Context
