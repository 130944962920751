export const FiraPasseigCalendarIcon = () => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 0.5V3.5H12.6001V0.5H14.0001Z"
      fill="#2B2A29"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 4.5C0.25 3.5335 1.0335 2.75 2 2.75H16C16.9665 2.75 17.75 3.5335 17.75 4.5V18.5C17.75 19.4665 16.9665 20.25 16 20.25H2C1.0335 20.25 0.25 19.4665 0.25 18.5V4.5ZM2 4.25C1.86193 4.25 1.75 4.36193 1.75 4.5V18.5C1.75 18.6381 1.86193 18.75 2 18.75H16C16.1381 18.75 16.25 18.6381 16.25 18.5V4.5C16.25 4.36193 16.1381 4.25 16 4.25H2Z"
      fill="#2B2A29"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0001 0.5V3.5H3.6001V0.5H5.0001Z"
      fill="#2B2A29"
    />
    <g clipPath="url(#clip0_2761_32865)">
      <path
        d="M13.95 9.945L13.425 7.76C13.315 7.31 12.925 7 12.47 7H5.525C5.075 7 4.68 7.315 4.575 7.76L4.05 9.945C3.93 10.455 4.04 10.975 4.36 11.385C4.4 11.44 4.455 11.48 4.5 11.53V15C4.5 15.55 4.95 16 5.5 16H12.5C13.05 16 13.5 15.55 13.5 15V11.53C13.545 11.485 13.6 11.44 13.64 11.39C13.96 10.98 14.075 10.455 13.95 9.945ZM12.455 7.995L12.98 10.18C13.03 10.39 12.985 10.6 12.855 10.765C12.785 10.855 12.635 11 12.385 11C12.08 11 11.815 10.755 11.78 10.43L11.49 8L12.455 7.995ZM9.5 8H10.48L10.75 10.26C10.775 10.455 10.715 10.65 10.585 10.795C10.475 10.925 10.315 11 10.11 11C9.775 11 9.5 10.705 9.5 10.345V8ZM7.245 10.26L7.52 8H8.5V10.345C8.5 10.705 8.225 11 7.855 11C7.685 11 7.53 10.925 7.41 10.795C7.285 10.65 7.225 10.455 7.245 10.26ZM5.02 10.18L5.525 8H6.51L6.22 10.43C6.18 10.755 5.92 11 5.615 11C5.37 11 5.215 10.855 5.15 10.765C5.015 10.605 4.97 10.39 5.02 10.18ZM5.5 15V11.985C5.54 11.99 5.575 12 5.615 12C6.05 12 6.445 11.82 6.735 11.525C7.035 11.825 7.435 12 7.89 12C8.325 12 8.715 11.82 9.005 11.535C9.3 11.82 9.7 12 10.15 12C10.57 12 10.97 11.825 11.27 11.525C11.56 11.82 11.955 12 12.39 12C12.43 12 12.465 11.99 12.505 11.985V15H5.5Z"
        fill="#2B2A29"
      />
    </g>
    <defs>
      <clipPath id="clip0_2761_32865">
        <rect width="12" height="12" fill="white" transform="translate(3 5.5)" />
      </clipPath>
    </defs>
  </svg>
)
