const SpotifyIcon = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4935 0C7.21475 0 0.500854 6.71343 0.500854 14.9916C0.500854 23.2699 7.21475 29.9833 15.4935 29.9833C23.7723 29.9833 30.4862 23.2699 30.4862 14.9916C30.4862 6.71343 23.7723 0 15.4935 0ZM15.4935 23.2194C10.9503 23.2194 7.26523 19.5346 7.26523 14.9916C7.26523 10.4487 10.9503 6.76391 15.4935 6.76391C20.0368 6.76391 23.7218 10.4487 23.7218 14.9916C23.7218 19.5346 20.0368 23.2194 15.4935 23.2194ZM20.0536 15.5132C20.3565 15.6983 20.4575 16.0853 20.2724 16.3882C20.0873 16.691 19.7002 16.792 19.3974 16.6069C17.193 15.244 13.8109 14.857 11.2027 15.6478C10.8662 15.7488 10.5128 15.5637 10.395 15.2272C10.2941 14.8907 10.4791 14.5373 10.8157 14.4364C13.794 13.5278 17.5128 13.9653 20.0536 15.5301V15.5132ZM19.0945 17.9361C19.3301 18.0876 19.4142 18.4072 19.2628 18.6428C19.1113 18.8784 18.7916 18.9625 18.556 18.8111C16.6209 17.6333 14.1979 17.3641 11.3373 18.0203C11.0681 18.0876 10.782 17.9025 10.7315 17.6333C10.6642 17.3641 10.8325 17.078 11.1186 17.0275C14.2484 16.304 16.9406 16.6237 19.1113 17.9361H19.0945ZM21.1473 12.7538C21.5175 12.9726 21.6353 13.4437 21.4166 13.8138C21.1978 14.184 20.7267 14.3018 20.3565 14.0831C17.7147 12.5014 13.3397 12.3668 10.7989 13.1408C10.395 13.2586 9.95752 13.0399 9.83973 12.6361C9.72194 12.2322 9.94069 11.7948 10.3445 11.677C13.2556 10.8021 18.068 10.9703 21.1305 12.7707L21.1473 12.7538Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default SpotifyIcon
