import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken, getCurrentLanguage, getValueVocabularyDynamic } from 'utils/utils'
import { useEdition } from './useEdition'

const getOptionsFormat = (items, t) => {
  const options = []
  items.forEach((item) => {
    options.push({ label: t(item.token), value: item.token })
  })
  return options
}

const getOptionsFormatDynamicVocabulary = (items, t) => {
  const options = []
  items.forEach((item) => {
    options.push({
      label: getValueVocabularyDynamic([item], item.token, getCurrentLanguage()),
      value: item.token,
    })
  })
  return options
}

export function useGetVocabulariesProposalForm() {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const [loading, setLoading] = useState(false)
  const [proposalType, setProposalType] = useState(undefined)
  const [area, setArea] = useState(undefined)
  const [languages, setLanguages] = useState(undefined)
  const [features, setFeatures] = useState(undefined)
  const [audience, setAudience] = useState(undefined)
  const [release, setRelease] = useState(undefined)
  const [keys, setKeys] = useState(undefined)
  const [statement, setStatement] = useState(undefined)

  const token = getCurrentJWToken()
  useEffect(() => {
    if (
      (!proposalType ||
        !area ||
        !languages ||
        !features ||
        !audience ||
        !release ||
        !keys ||
        !statement) &&
      !loading
    ) {
      setLoading(true)

      Promise.all([
        fetchGuillotina({ path: `${edition}/@vocabularies/ambits`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/tipus_proposta`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/caracteristiques`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/public`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/languagesfm`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/estrena`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/claus`, token }),
        fetchGuillotina({ path: `${edition}/@vocabularies/declaracio`, token }),
      ]).then(([area, proposalType, features, audience, languages, release, keys, statement]) => {
        setProposalType(getOptionsFormat(get(proposalType, 'items', []), t))
        setArea(getOptionsFormatDynamicVocabulary(get(area, 'items', []), t))
        setLanguages(getOptionsFormat(get(languages, 'items', []), t))
        setFeatures(getOptionsFormat(get(features, 'items', []), t))
        setAudience(getOptionsFormat(get(audience, 'items', []), t))
        setRelease(getOptionsFormat(get(release, 'items', []), t))
        setKeys(getOptionsFormat(get(keys, 'items', []), t))
        setStatement(getOptionsFormat(get(statement, 'items', []), t))
        setLoading(false)
      })
    }
  }, [
    area,
    proposalType,
    features,
    audience,
    languages,
    release,
    keys,
    statement,
    loading,
    t,
    edition,
  ])

  const isLoaded = () => {
    return (
      area &&
      proposalType &&
      features &&
      audience &&
      languages &&
      release &&
      keys &&
      statement &&
      !loading
    )
  }

  return {
    isLoaded,
    area,
    proposalType,
    features,
    audience,
    languages,
    release,
    keys,
    statement,
  }
}
