import { Box } from '@mui/system'
import { AcreditacioIcon } from 'NewVersion/icons/AcreditacioIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { EntradaIcon } from 'NewVersion/icons/EntradaIcon'
import { MessageIcon } from 'NewVersion/icons/MessageIcon'
import { PropostesIcon } from 'NewVersion/icons/PropostesIcon'
import 'NewVersion/styles/HomePage.scss'
import {
  MEETINGS_PATH,
  PROGRAMMING_PATH,
  PROPOSALS_PATH,
  REGISTER_ENTITY_PATH,
  TIKETS_PATH,
} from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function HomePage() {
  const { t } = useTranslation()
  const { currentEdition, loading: loadingCurrentEdition, edition } = useEdition()

  const linkButtons = [
    {
      id: 'propostes',
      icon: <PropostesIcon />,
      text: t('present_your_artistic_proposal'),
      link: PROPOSALS_PATH,
      isActive: () => {
        return !loadingCurrentEdition && currentEdition && !currentEdition.callIsFinished()
      },
    },
    {
      id: 'professionals',
      icon: <AcreditacioIcon />,
      text: t('accredit_as_professional'),
      link: REGISTER_ENTITY_PATH,
      isActive: () => {
        return (
          !loadingCurrentEdition &&
          currentEdition &&
          (currentEdition.canCreateEnsPremsa() || currentEdition.canCreateEnsProfessionals())
        )
      },
    },
    {
      id: 'programacio',
      icon: <CalendarIcon />,
      text: t('consult_program', { edition: edition }),
      link: PROGRAMMING_PATH,
      isActive: () => currentEdition && currentEdition.canShowShedule(),
    },
    {
      id: 'xats',
      icon: <MessageIcon />,
      text: t('request_meet'),
      link: MEETINGS_PATH,
      isActive: () => currentEdition && currentEdition.canRegisterToQuickMeetings(),
    },
    {
      id: 'entrades',
      icon: <EntradaIcon />,
      text: t('Les meves entrades'),
      link: TIKETS_PATH,
      isActive: () => {
        const edtition_data = currentEdition.getConfigData()
        return currentEdition && edtition_data['solicitar_entrades_pro']
      },
    },
  ]

  return (
    <Box className="page-wrapper home-page-container">
      <Box className="home-text">
        <h1>{t('Benvinguda')}</h1>
        <p>{t('home-page-desc')}</p>
      </Box>
      <Box className="home-button-grid">
        {linkButtons.map((linkButton) => (
          <Link
            to={!linkButton.isActive() ? '#' : linkButton.link}
            className={`home-button ${!linkButton.isActive() ? 'disabled' : ''}`}
            key={`homeLinkButton${linkButton.id}`}
            data-test={`btnHomeTest${linkButton.id}`}
          >
            {!linkButton.isActive() && <Box className={'home-button-diabled-mask'} />}
            <Box className={'home-button-icon'}>{linkButton.icon}</Box>
            <h4>{linkButton.text}</h4>
          </Link>
        ))}
      </Box>
    </Box>
  )
}

export default HomePage
