export const HOME_PATH = '/home'

export const SHOWS_PATH = '/espectacles'
export const SHOWS_DETAIL_PATH = '/espectacles/:espectacleId'

export const PROGRAMMING_PATH = '/programacio'
export const PROGRAMMING_DETAIL_PATH = '/programacio/:programacioId'

export const PROFESSIONALS_PATH = '/professionals'
export const ARTIST_PATH = '/artists'

export const PROPOSALS_PATH = '/propostes'
export const PROPOSALS_FORM_PATH = '/propostes/form'
export const PROPOSALS_FORM_DETAIL_PATH = '/propostes/form/:year/:idArtist/:idProposal'
export const PROPOSALS_ENTITY_PATH = '/propostes/entitat'
export const PROPOSALS_ENTITY_DETAIL_PATH = '/propostes/entitat/:year/:entityId'

export const REGISTER_ENTITY_PATH = '/registrar-entitat'

export const ENTITY_PATH = '/entitat'
export const CREATE_ENTITY_PATH = `${ENTITY_PATH}/add`
export const EDIT_ENTITY_FORM_PATH = `${ENTITY_PATH}/edit/:year/:entityId`
export const CREATE_ENTITY_FORM_PATH = `${CREATE_ENTITY_PATH}/:type`

export const ACCREDITATION = 'acreditacio'
export const CREATE_ACCREDITATION_PATH = `${ENTITY_PATH}/:year/:entityId/${ACCREDITATION}/add`
export const EDIT_ACCREDITATION_PATH = `${ENTITY_PATH}/:year/:entityId/${ACCREDITATION}/edit/:accreditationId`

export const PAYMENT = '/pagament'
export const PAYMENT_INFO_PATH = `${PAYMENT}/info/:paymentId`

export const STAND = 'estand'
export const CREATE_STAND_PATH = `${ENTITY_PATH}/:year/:entityId/${STAND}/add`
export const EDIT_STAND_PATH = `${ENTITY_PATH}/:year/:entityId/${STAND}/edit/:standId`

export const SHOP = 'parada'
export const CREATE_SHOP_PATH = `${ENTITY_PATH}/:year/:entityId/${SHOP}/add`
export const EDIT_SHOP_PATH = `${ENTITY_PATH}/:year/:entityId/${SHOP}/edit/:shopId`

export const LIVE_PATH = '/directe'
export const LIVE_DETAIL_PATH = '/directe/:espectacleId'

export const TIKETS_PATH = '/entrades'

export const FIRAPASSEIG = '/firapasseig'

export const PREGUNTES_FREQUENTS = '/preguntes-frequents'

export const AGENDA_PATH = '/agenda'

export const NOTIFICATIONS_PATH = '/notificacions'

export const USER_SETTINGS_PATH = '/preferencies'

export const XATS_PATH = '/xats'

export const MEETINGS_PATH = '/reunions'

export const MY_MEETINGS_PATH = '/reunions/meves'

export const ACCOUNT_PATH = '/perfil'

export const ACCREDITED_PATH = '/acreditat'
export const ACCREDITED_DETAIL_PATH = `${ACCREDITED_PATH}/:acreditatId`

export const ADMIN_PATH = '/admin'

export const LOGIN_PATH = '/login'

export const REGISTER_PATH = '/registre'

export const RECOVERY_PATH = '/recuperar-contrasenya'

export const NEW_PASSWORD_PATH = '/nou-password'

export const ERROR_404_PATH = '/404'

export const ERROR_NOT_ALLOW = '/not-allow'
