import { useEffect, useState } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'utils/utils'
import { askUserPermission } from '../push-notifications'
import usePushNotifications from '../usePushNotifications'
import { getSubscriptionsGuillotina, guillotinaActionGeneric } from '../utils/apiCalls'
import { getEmailFromToken } from '../utils/utils'

const Switch = ({ isOn, handleToggle }) => {
  return (
    <div className="switch" onClick={() => handleToggle(!isOn)}>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <span className={`slider round`} />
    </div>
  )
}

function UserSettings() {
  const { onClickSusbribeToPushNotification } = usePushNotifications()

  const { t, i18n } = useTranslation()
  const [notificationsAcepted, setNotification] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState('')

  useEffect(() => {
    ;(async () => {
      if ('serviceWorker' in navigator) {
        const serviceWorker = await navigator.serviceWorker.ready
        console.log('checkSubscriptionCurrent serviceWorker', serviceWorker)
        serviceWorker.pushManager
          .getSubscription()
          .then(function (subscription) {
            console.log('checkSubscriptionCurrent', subscription)
            setCurrentSubscription(subscription.endpoint)
            getSubscriptionsGuillotina(
              getEmailFromToken(),
              compareSubscriptions,
              subscription.endpoint
            )
          })
          .catch((err) => {
            console.log('checkSubscriptionCurrent error', err)
            setNotification(false)
          })
      }
    })()
  }, [])

  const toggleSwitchNotifications = () => {
    if (notificationsAcepted) {
      localStorage.setItem('acceptNotifications', false)
      localStorage.setItem('deniedNotifications', true)
      setNotification(false)
      guillotinaActionGeneric({
        method: 'PATCH',
        action: getEmailFromToken(),
        data: { subscripcions: { op: 'del', value: currentSubscription } },
      })
    } else {
      askUserPermission().then((consent) => {
        if (consent !== 'granted') {
          alert('Notifications blocked. Please enable them in your browser.')
        } else {
          localStorage.setItem('acceptNotifications', true)
          onClickSusbribeToPushNotification()
          setNotification(true)
        }
      })
    }
  }

  const compareSubscriptions = (result, currentSub) => {
    if (Object.keys(result).length) {
      Object.keys(result).forEach((key) => {
        if (result[key].endpoint === currentSub) {
          setNotification(true)
        }
      })
    }
  }

  function switchLang(lang) {
    i18n.use(initReactI18next).init({ lng: lang })
  }

  return (
    <div className="user-preferences">
      <div className="preferences-title">{t("Preferències d'usuari")}</div>
      <div className="profile-separator"></div>
      <div className="preferences-title">{t('Idioma')}</div>
      <br />
      <br />
      <input
        onClick={() => switchLang('ca')}
        type="radio"
        id="male"
        name="gender"
        value="ca"
        defaultChecked={getCurrentLanguage() === 'ca'}
      ></input>
      <label>&nbsp;&nbsp;{t('Catala')} </label>
      <br></br>
      <input
        onClick={() => switchLang('es')}
        type="radio"
        id="male"
        name="gender"
        value="es"
        defaultChecked={getCurrentLanguage() === 'es'}
      ></input>
      <label>&nbsp;&nbsp;{t('Castella')} </label>
      <br></br>
      <input
        onClick={() => switchLang('en')}
        type="radio"
        id="male"
        name="gender"
        value="eb"
        defaultChecked={getCurrentLanguage() === 'en'}
      ></input>
      <label>&nbsp;&nbsp;{t('Angles')} </label>
      <br></br>
      <div className="profile-separator"></div>
      {!['iPhone'].includes(navigator.platform) && (
        <div>
          <span className="preferences-title">{t('Subscriute a notificacions')}:&nbsp;&nbsp;</span>

          <Switch isOn={notificationsAcepted} handleToggle={toggleSwitchNotifications} />
        </div>
      )}
    </div>
  )
}
export default UserSettings
