import { TableCell, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { ClockIcon } from 'NewVersion/icons/ClockIcon'
import { GroupIcon } from 'NewVersion/icons/GroupIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Chips from '../UI/Chips/Chips'

const MeetingTableItemComponent = ({ meeting, removeMeet }) => {
  const { t } = useTranslation()
  const { currentEdition } = useEdition()
  const { data: parentAccreditation, isLoading } = useGuillotina({
    path: meeting ? meeting.path.split('/').slice(0, 4).join('/') : null,
  })

  const renderMeetButton = () => {
    return (
      <Chips
        text={t('cancel')}
        icon={
          <div style={{ width: '16px', height: '16px' }}>
            <TrashIcon />
          </div>
        }
        dataTest={'cancelMeet'}
        onClick={() => {
          removeMeet(meeting['path'])
        }}
      ></Chips>
    )
  }

  const getDateSeparate = (date) => {
    const dateTime = date.split('T')
    const yearMonthDay = dateTime[0].split('-')
    const hourMinutSeconds = dateTime[1].split(':')

    let day = yearMonthDay[2]
    const month = yearMonthDay[1]
    const year = yearMonthDay[0]
    let hour = hourMinutSeconds[0]
    const minutes = hourMinutSeconds[1]
    if (hour === 0) {
      hour = 24
      day = day - 1
    }
    return { day: day, month: month, year: year, hour: hour, minutes: minutes }
  }

  const seperateDate = React.useMemo(() => {
    let newSeperateDate = null
    if (meeting && meeting.data_inici && meeting.data_fi) {
      newSeperateDate = {}
      const iniDate = getDateSeparate(meeting.data_inici)
      const endDate = getDateSeparate(meeting.data_fi)

      newSeperateDate = {
        date: `${iniDate.day}/${iniDate.month}/${iniDate.year.substring(2, 4)}`,
        iniTime: `${iniDate.hour}:${iniDate.minutes}`,
        endTime: `${endDate.hour}:${endDate.minutes}`,
      }
    }
    return newSeperateDate
  }, [meeting])

  const renderStyledName = (name) => {
    const captialize = (words) =>
      words
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ')
    return captialize(name.toLowerCase())
  }

  return (
    <>
      <TableRow key={`scheduleTableItem-${meeting.id}`} className={`table-row`}>
        <TableCell>{seperateDate ? seperateDate.date : ''}</TableCell>
        <TableCell>
          {isLoading ? (
            <div className="skeleton-text" />
          ) : (
            renderStyledName(parentAccreditation?.title || '')
          )}
        </TableCell>
        <TableCell>
          {isLoading ? <div className="skeleton-text" /> : parentAccreditation?.ens_title || ''}
        </TableCell>
        <TableCell>{seperateDate ? seperateDate.iniTime : ''}</TableCell>
        <TableCell>{seperateDate ? seperateDate.endTime : ''}</TableCell>
        {currentEdition && currentEdition.canRegisterToQuickMeetings() && (
          <TableCell>{renderMeetButton()}</TableCell>
        )}
      </TableRow>
      <Box className={`table-row-mobile display-sm`}>
        <Box flex={1}>
          <Box className="line">
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box display={'flex'} gap={1}>
                <CalendarIcon />
                <p>{seperateDate ? seperateDate.date : ''}</p>
              </Box>

              <Box display={'flex'} gap={1}>
                <ClockIcon />
                <p>{`${seperateDate ? seperateDate.iniTime : ''} - ${
                  seperateDate ? seperateDate.endTime : ''
                }`}</p>
              </Box>
            </Box>
          </Box>
          <Box className="line">
            <UserIcon />
            <p className={isLoading ? 'skeleton-text' : ''}>
              {parentAccreditation ? renderStyledName(parentAccreditation.title) : ''}
            </p>
          </Box>
          <Box className="line">
            <GroupIcon />
            <p className={isLoading ? 'skeleton-text' : ''}>
              {parentAccreditation ? parentAccreditation.ens_title : ''}
            </p>
          </Box>
        </Box>
        {renderMeetButton()}
      </Box>
    </>
  )
}

export const MeetingTableItem = React.memo(MeetingTableItemComponent)
