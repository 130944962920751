import React, { useEffect, useMemo, useState } from 'react'

import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import last from 'Statics/b7.jpg'
import firaEnLinia from 'Statics/fira_linia_2023.jpg'
import registerFira from 'Statics/registerFira2022.jpeg'
import registerFiraServeis from 'Statics/registerFiraServeis.jpeg'

import { LoadingComponent } from 'Components/HelperComponents'
import { fetchGuillotina } from 'utils/apiCalls'
import { get } from 'utils/objectUtils'
import { getEmailFromToken } from 'utils/utils'

export function Edition2024HomeComponent() {
  const navigate = useNavigate()
  const { currentEdition, edition } = useEdition()
  const { t } = useTranslation()
  const [showRegisterToFira, setShowRegisterToFira] = useState({
    data: undefined,
    loading: false,
  })

  const redirect = (url) => {
    navigate(url)
  }

  const canCreateEns = useMemo(() => {
    if (currentEdition) {
      return (
        currentEdition.canCreateEnsProfessionals() ||
        currentEdition.canCreateEnsPremsa() ||
        currentEdition.canCreateEnsServeis()
      )
    }
    return false
  }, [currentEdition])

  const canCreateEnsServeis = useMemo(() => {
    if (currentEdition) {
      return currentEdition.canCreateEnsServeis()
    }
    return false
  }, [currentEdition])

  useEffect(() => {
    const loadInfo = async () => {
      try {
        const responseEntities = await fetchGuillotina(`${edition}/@search`, {
          params: {
            type_name__in: 'EnsProfessional,EnsPremsa,Ens,EnsServeis',
            owners: getEmailFromToken(),
          },
        })
        const responseAccreditations = await fetchGuillotina(`${edition}/@search`, {
          params: {
            type_name__in: 'AcreditacioProfessional,AcreditacioPremsa',
            owners: getEmailFromToken(),
          },
        })
        setShowRegisterToFira({
          data:
            get(responseEntities, 'data.items', []).length === 0 &&
            get(responseAccreditations, 'data.items', []).length === 0,
          loading: false,
        })
      } catch (error) {
        setShowRegisterToFira({ data: false, loading: false })
      }
    }
    if (currentEdition && showRegisterToFira.data === undefined && !showRegisterToFira.loading) {
      setShowRegisterToFira({ data: undefined, loading: true })
      loadInfo()
    }
  }, [edition, currentEdition, setShowRegisterToFira, showRegisterToFira])

  return (
    <div className="Home">
      {(showRegisterToFira.data === undefined || showRegisterToFira.loading || !currentEdition) && (
        <div className="link-container">
          <LoadingComponent />
        </div>
      )}
      {showRegisterToFira.data !== undefined && !showRegisterToFira.loading && currentEdition && (
        <>
          <div className="home-grid">
            {/* <div className="link-container">
              <Link to={path.SHOWS_PATH}>
                <div
                  className="home-link professional-link"
                  style={{ backgroundImage: `url(${programacio})` }}
                >
                  <span className="text">{t('Programació Artística')}</span>
                </div>
              </Link>
            </div>
            <div className="link-container">
              <Link to={path.PROGRAMMING_PATH}>
                <div
                  className="home-link direct-link"
                  style={{ backgroundImage: `url(${activitats})` }}
                >
                  <span className="text">{t('Programació Professional')}</span>
                </div>
              </Link>
            </div> */}
            {currentEdition && currentEdition.getConfigData()['veure_directe_pwa'] && (
              <div className="link-container">
                <Link to={path.LIVE_PATH}>
                  <div
                    className="home-link programation-link"
                    style={{ backgroundImage: `url(${firaEnLinia})` }}
                  >
                    <span className="text">{t('audiovisual_content')}</span>
                  </div>
                </Link>
              </div>
            )}
            {showRegisterToFira.data && canCreateEns && (
              <div className="link-container">
                <div
                  className="home-link cursor-pointer professional-link "
                  style={{ backgroundImage: `url(${registerFira})` }}
                  onClick={() => redirect(path.REGISTER_ENTITY_PATH)}
                >
                  <div className="encapsulate">
                    <span className="text subtitle">
                      {t('add_professional_press_or_service_entitiy')}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {showRegisterToFira.data && canCreateEnsServeis && (
              <div className="link-container">
                <div
                  className="home-link cursor-pointer professional-link "
                  style={{ backgroundImage: `url(${registerFiraServeis})` }}
                  onClick={() => redirect(`${path.CREATE_ENTITY_PATH}/serveis`)}
                >
                  <div className="encapsulate">
                    <span className="text subtitle">{t('add_service')}</span>
                  </div>
                </div>
              </div>
            )}

            {!currentEdition.callIsFinished() && (
              <div className="link-container">
                <div
                  className="home-link professional-link cursor-pointer"
                  style={{ backgroundImage: `url(${last})` }}
                  onClick={() => redirect(path.PROPOSALS_PATH)}
                >
                  <div className="encapsulate">
                    <span className="text subtitle">
                      {t('presentar_proposta_home_short')} {edition}
                    </span>
                    {currentEdition && currentEdition.callIsFinished() && (
                      <strong className="text text-card-hightligh">{t('call-is-finished')}</strong>
                    )}
                  </div>
                  <div className="propostes-link"></div>
                </div>
              </div>
            )}
          </div>

          {/* <div className="home-grid-lower separator-home">
            <div className={isMobile ? 'small-mobile' : 'link-container '}>
              <Link to={path.PROFESSIONALS_PATH}>
                <div className="home-link small direct-link">
                  <span className="text">
                    <PermContactCalendarRounded style={{ fontSize: 20 }} />
                    {t('Acreditats')}
                  </span>
                </div>
              </Link>
            </div>
            <div className={isMobile ? 'small-mobile' : 'link-container '}>
              <Link to={path.TIKETS_PATH}>
                <div className="home-link small programation-link light">
                  <span className="text">
                    <ConfirmationNumberRounded style={{ fontSize: 20 }} />
                    {t('Les meves entrades')}
                  </span>
                </div>
              </Link>
            </div>
            <div className={isMobile ? 'small-mobile' : 'link-container '}>
              <Link to={path.AGENDA_PATH}>
                <div className="home-link small professional-link lighter">
                  <span className="text">
                    <DateRangeRounded style={{ fontSize: 20 }} />
                    {t('Agenda pro')}
                  </span>
                </div>
              </Link>
            </div>
          </div> */}
        </>
      )}
    </div>
  )
}

export const Edition2024Home = React.memo(Edition2024HomeComponent)
