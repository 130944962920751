export const ArrowDropDownIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3296_772)">
      <path d="M7 10L12 15L17 10H7Z" fill="#2B2A29" />
    </g>
    <defs>
      <clipPath id="clip0_3296_772">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
